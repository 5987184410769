.custom-badge {
  font-size: 13px;
  border-radius: 10rem;
  &.contained {
    background: white;
    color: black;
  }
  &.outlined {
    background: white;
    color: black;
  }
}

.outlined-badge {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #239f87;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  color: #000000;
  &.disabled {
    opacity: 0.22;
    pointer-events: none;
  }
  &:hover {
    background-color: #239f87;
    color: #fff;
    transition: all ease-in-out 0.3s;
  }
}
