.days-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &--row {
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    color: #48506a;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-left: 16px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.slot-timings {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  .days-slot {
    display: flex;
    align-items: center;
    .outlined-badge {
      width: 60px;
      height: 20px;
    }
    label {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      min-width: calc(68px + 19px);
      color: #000000;
    }
  }
}

.batch-sections {
  padding: 8px 0;
  & + .batch-sections {
    border-top: solid 1px #e6e6e6;
  }
}
