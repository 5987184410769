@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://cdn.syncfusion.com/ej2/material.css");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #5567FF;
  --secondary: #868e96;
  --success: #77c13a;
  --info: #17a2b8;
  --warning: #E4A93C;
  --danger: #d9534f;
  --light: #f8f9fa;
  --dark: #303956;
  --black: #272C33;
  --accent: #ED0B4C;
  --accent-red: #ED0B4C;
  --accent-yellow: #E4A93C;
  --accent-dodger-blue: #5567FF;
  --accent-pickled-bluewood: #303956;
  --accent-electric-violet: #9C42FF;
  --primary-purple: #824EE1;
  --primary-red: #ED0B4C;
  --primary-yellow: #E4A93C;
  --primary-light: #99ccff;
  --primary-dodger-blue: #5567FF;
  --primary-pickled-bluewood: #303956;
  --breakpoint-xs: 0;
  --breakpoint-i8: 374px;
  --breakpoint-i8-plus: 413px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1366px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(39, 44, 51, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  text-align: left;
  background-color: #F5F7FA;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(39, 44, 51, 0.7);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: rgba(6, 7, 8, 0.7);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem 1rem;
  padding-bottom: 0.75rem 1rem;
  color: rgba(39, 44, 51, 0.5);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color: #303956;
}

h1, .h1 {
  font-size: 2.827rem;
}

h2, .h2 {
  font-size: 1.999rem;
}

h3, .h3 {
  font-size: 1.414rem;
}

h4, .h4 {
  font-size: 1.414rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: 3.998rem;
  font-weight: 700;
  line-height: 1.5;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(39, 44, 51, 0.1);
}

small,
.small {
  font-size: 0.707rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.707rem;
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F5F7FA;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(39, 44, 51, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code {
  font-size: 87.5%;
  color: #ED0B4C;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(39, 44, 51, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-i8, .container-i8-plus, .container-sm {
    max-width: 520px;
  }
}

@media (min-width: 768px) {
  .container, .container-i8, .container-i8-plus, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-i8, .container-i8-plus, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-i8, .container-i8-plus, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 960px;
  }
}

@media (min-width: 1366px) {
  .container, .container-i8, .container-i8-plus, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1120px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-i8-1, .col-i8-2, .col-i8-3, .col-i8-4, .col-i8-5, .col-i8-6, .col-i8-7, .col-i8-8, .col-i8-9, .col-i8-10, .col-i8-11, .col-i8-12, .col-i8,
.col-i8-auto, .col-i8-plus-1, .col-i8-plus-2, .col-i8-plus-3, .col-i8-plus-4, .col-i8-plus-5, .col-i8-plus-6, .col-i8-plus-7, .col-i8-plus-8, .col-i8-plus-9, .col-i8-plus-10, .col-i8-plus-11, .col-i8-plus-12, .col-i8-plus,
.col-i8-plus-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 374px) {
  .col-i8 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-i8-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-i8-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-i8-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-i8-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-i8-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-i8-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-i8-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-i8-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-i8-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-i8-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-i8-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-i8-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-i8-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-i8-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-i8-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-i8-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-i8-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-i8-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-i8-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-i8-first {
    order: -1;
  }
  .order-i8-last {
    order: 13;
  }
  .order-i8-0 {
    order: 0;
  }
  .order-i8-1 {
    order: 1;
  }
  .order-i8-2 {
    order: 2;
  }
  .order-i8-3 {
    order: 3;
  }
  .order-i8-4 {
    order: 4;
  }
  .order-i8-5 {
    order: 5;
  }
  .order-i8-6 {
    order: 6;
  }
  .order-i8-7 {
    order: 7;
  }
  .order-i8-8 {
    order: 8;
  }
  .order-i8-9 {
    order: 9;
  }
  .order-i8-10 {
    order: 10;
  }
  .order-i8-11 {
    order: 11;
  }
  .order-i8-12 {
    order: 12;
  }
  .offset-i8-0 {
    margin-left: 0;
  }
  .offset-i8-1 {
    margin-left: 8.333333%;
  }
  .offset-i8-2 {
    margin-left: 16.666667%;
  }
  .offset-i8-3 {
    margin-left: 25%;
  }
  .offset-i8-4 {
    margin-left: 33.333333%;
  }
  .offset-i8-5 {
    margin-left: 41.666667%;
  }
  .offset-i8-6 {
    margin-left: 50%;
  }
  .offset-i8-7 {
    margin-left: 58.333333%;
  }
  .offset-i8-8 {
    margin-left: 66.666667%;
  }
  .offset-i8-9 {
    margin-left: 75%;
  }
  .offset-i8-10 {
    margin-left: 83.333333%;
  }
  .offset-i8-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 413px) {
  .col-i8-plus {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-i8-plus-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-i8-plus-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-i8-plus-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-i8-plus-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-i8-plus-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-i8-plus-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-i8-plus-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-i8-plus-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-i8-plus-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-i8-plus-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-i8-plus-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-i8-plus-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-i8-plus-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-i8-plus-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-i8-plus-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-i8-plus-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-i8-plus-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-i8-plus-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-i8-plus-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-i8-plus-first {
    order: -1;
  }
  .order-i8-plus-last {
    order: 13;
  }
  .order-i8-plus-0 {
    order: 0;
  }
  .order-i8-plus-1 {
    order: 1;
  }
  .order-i8-plus-2 {
    order: 2;
  }
  .order-i8-plus-3 {
    order: 3;
  }
  .order-i8-plus-4 {
    order: 4;
  }
  .order-i8-plus-5 {
    order: 5;
  }
  .order-i8-plus-6 {
    order: 6;
  }
  .order-i8-plus-7 {
    order: 7;
  }
  .order-i8-plus-8 {
    order: 8;
  }
  .order-i8-plus-9 {
    order: 9;
  }
  .order-i8-plus-10 {
    order: 10;
  }
  .order-i8-plus-11 {
    order: 11;
  }
  .order-i8-plus-12 {
    order: 12;
  }
  .offset-i8-plus-0 {
    margin-left: 0;
  }
  .offset-i8-plus-1 {
    margin-left: 8.333333%;
  }
  .offset-i8-plus-2 {
    margin-left: 16.666667%;
  }
  .offset-i8-plus-3 {
    margin-left: 25%;
  }
  .offset-i8-plus-4 {
    margin-left: 33.333333%;
  }
  .offset-i8-plus-5 {
    margin-left: 41.666667%;
  }
  .offset-i8-plus-6 {
    margin-left: 50%;
  }
  .offset-i8-plus-7 {
    margin-left: 58.333333%;
  }
  .offset-i8-plus-8 {
    margin-left: 66.666667%;
  }
  .offset-i8-plus-9 {
    margin-left: 75%;
  }
  .offset-i8-plus-10 {
    margin-left: 83.333333%;
  }
  .offset-i8-plus-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1366px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #272C33;
}

.table th,
.table td {
  padding: 0.75rem 1rem;
  vertical-align: top;
  border-top: 1px solid #E9EDF2;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #E9EDF2;
}

.table tbody + tbody {
  border-top: 2px solid #E9EDF2;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #E9EDF2;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #E9EDF2;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(39, 44, 51, 0.05);
}

.table-hover tbody tr:hover {
  color: #272C33;
  background-color: rgba(39, 44, 51, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cfd4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a7b0ff;
}

.table-hover .table-primary:hover {
  background-color: #b6bdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b6bdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c0c4c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d9eec8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b8df99;
}

.table-hover .table-success:hover {
  background-color: #cce8b5;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #cce8b5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7e7c8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-warning:hover {
  background-color: #f4ddb2;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f4ddb2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3;
}

.table-hover .table-danger:hover {
  background-color: #efbbb9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efbbb9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c8d0;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9398a7;
}

.table-hover .table-dark:hover {
  background-color: #b7bbc5;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b7bbc5;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c3c4c6;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #8f9195;
}

.table-hover .table-black:hover {
  background-color: #b6b7ba;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #b6b7ba;
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #fabbcd;
}

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #f680a2;
}

.table-hover .table-accent:hover {
  background-color: #f8a3bc;
}

.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: #f8a3bc;
}

.table-accent-red,
.table-accent-red > th,
.table-accent-red > td {
  background-color: #fabbcd;
}

.table-accent-red th,
.table-accent-red td,
.table-accent-red thead th,
.table-accent-red tbody + tbody {
  border-color: #f680a2;
}

.table-hover .table-accent-red:hover {
  background-color: #f8a3bc;
}

.table-hover .table-accent-red:hover > td,
.table-hover .table-accent-red:hover > th {
  background-color: #f8a3bc;
}

.table-accent-yellow,
.table-accent-yellow > th,
.table-accent-yellow > td {
  background-color: #f7e7c8;
}

.table-accent-yellow th,
.table-accent-yellow td,
.table-accent-yellow thead th,
.table-accent-yellow tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-accent-yellow:hover {
  background-color: #f4ddb2;
}

.table-hover .table-accent-yellow:hover > td,
.table-hover .table-accent-yellow:hover > th {
  background-color: #f4ddb2;
}

.table-accent-dodger-blue,
.table-accent-dodger-blue > th,
.table-accent-dodger-blue > td {
  background-color: #cfd4ff;
}

.table-accent-dodger-blue th,
.table-accent-dodger-blue td,
.table-accent-dodger-blue thead th,
.table-accent-dodger-blue tbody + tbody {
  border-color: #a7b0ff;
}

.table-hover .table-accent-dodger-blue:hover {
  background-color: #b6bdff;
}

.table-hover .table-accent-dodger-blue:hover > td,
.table-hover .table-accent-dodger-blue:hover > th {
  background-color: #b6bdff;
}

.table-accent-pickled-bluewood,
.table-accent-pickled-bluewood > th,
.table-accent-pickled-bluewood > td {
  background-color: #c5c8d0;
}

.table-accent-pickled-bluewood th,
.table-accent-pickled-bluewood td,
.table-accent-pickled-bluewood thead th,
.table-accent-pickled-bluewood tbody + tbody {
  border-color: #9398a7;
}

.table-hover .table-accent-pickled-bluewood:hover {
  background-color: #b7bbc5;
}

.table-hover .table-accent-pickled-bluewood:hover > td,
.table-hover .table-accent-pickled-bluewood:hover > th {
  background-color: #b7bbc5;
}

.table-accent-electric-violet,
.table-accent-electric-violet > th,
.table-accent-electric-violet > td {
  background-color: #e3caff;
}

.table-accent-electric-violet th,
.table-accent-electric-violet td,
.table-accent-electric-violet thead th,
.table-accent-electric-violet tbody + tbody {
  border-color: #cc9dff;
}

.table-hover .table-accent-electric-violet:hover {
  background-color: #d6b1ff;
}

.table-hover .table-accent-electric-violet:hover > td,
.table-hover .table-accent-electric-violet:hover > th {
  background-color: #d6b1ff;
}

.table-primary-purple,
.table-primary-purple > th,
.table-primary-purple > td {
  background-color: #dccdf7;
}

.table-primary-purple th,
.table-primary-purple td,
.table-primary-purple thead th,
.table-primary-purple tbody + tbody {
  border-color: #bea3ef;
}

.table-hover .table-primary-purple:hover {
  background-color: #cdb7f3;
}

.table-hover .table-primary-purple:hover > td,
.table-hover .table-primary-purple:hover > th {
  background-color: #cdb7f3;
}

.table-primary-red,
.table-primary-red > th,
.table-primary-red > td {
  background-color: #fabbcd;
}

.table-primary-red th,
.table-primary-red td,
.table-primary-red thead th,
.table-primary-red tbody + tbody {
  border-color: #f680a2;
}

.table-hover .table-primary-red:hover {
  background-color: #f8a3bc;
}

.table-hover .table-primary-red:hover > td,
.table-hover .table-primary-red:hover > th {
  background-color: #f8a3bc;
}

.table-primary-yellow,
.table-primary-yellow > th,
.table-primary-yellow > td {
  background-color: #f7e7c8;
}

.table-primary-yellow th,
.table-primary-yellow td,
.table-primary-yellow thead th,
.table-primary-yellow tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-primary-yellow:hover {
  background-color: #f4ddb2;
}

.table-hover .table-primary-yellow:hover > td,
.table-hover .table-primary-yellow:hover > th {
  background-color: #f4ddb2;
}

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #e2f1ff;
}

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #cae4ff;
}

.table-hover .table-primary-light:hover {
  background-color: #c9e5ff;
}

.table-hover .table-primary-light:hover > td,
.table-hover .table-primary-light:hover > th {
  background-color: #c9e5ff;
}

.table-primary-dodger-blue,
.table-primary-dodger-blue > th,
.table-primary-dodger-blue > td {
  background-color: #cfd4ff;
}

.table-primary-dodger-blue th,
.table-primary-dodger-blue td,
.table-primary-dodger-blue thead th,
.table-primary-dodger-blue tbody + tbody {
  border-color: #a7b0ff;
}

.table-hover .table-primary-dodger-blue:hover {
  background-color: #b6bdff;
}

.table-hover .table-primary-dodger-blue:hover > td,
.table-hover .table-primary-dodger-blue:hover > th {
  background-color: #b6bdff;
}

.table-primary-pickled-bluewood,
.table-primary-pickled-bluewood > th,
.table-primary-pickled-bluewood > td {
  background-color: #c5c8d0;
}

.table-primary-pickled-bluewood th,
.table-primary-pickled-bluewood td,
.table-primary-pickled-bluewood thead th,
.table-primary-pickled-bluewood tbody + tbody {
  border-color: #9398a7;
}

.table-hover .table-primary-pickled-bluewood:hover {
  background-color: #b7bbc5;
}

.table-hover .table-primary-pickled-bluewood:hover > td,
.table-hover .table-primary-pickled-bluewood:hover > th {
  background-color: #b7bbc5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(39, 44, 51, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(28, 32, 37, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(28, 32, 37, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #E9EDF2;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 373.98px) {
  .table-responsive-i8 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-i8 > .table-bordered {
    border: 0;
  }
}

@media (max-width: 412.98px) {
  .table-responsive-i8-plus {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-i8-plus > .table-bordered {
    border: 0;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1365.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #272C33;
}

.form-control:focus {
  color: #272C33;
  background-color: white;
  border-color: white;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px #5567FF;
}

.form-control::placeholder {
  color: #949595;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #272C33;
  background-color: white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #272C33;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: rgba(39, 44, 51, 0.5);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.707rem;
  color: #77c13a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #77c13a;
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #77c13a;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377c13a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #77c13a;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377c13a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #77c13a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #77c13a;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #77c13a;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #92cf5f;
  background-color: #92cf5f;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #77c13a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #77c13a;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.707rem;
  color: #d9534f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: #d9534f;
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d9534f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #272C33;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #272C33;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 1px #5567FF;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125);
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 1px #5567FF, inset 0 3px 5px rgba(39, 44, 51, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-success:hover {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #5f9a2e;
  border-color: #59902b;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-warning:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.btn-light, .navbar-light .navbar-btn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-light:hover, .navbar-light .navbar-btn:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .navbar-light .navbar-btn:focus, .btn-light.focus, .navbar-light .focus.navbar-btn {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .navbar-light .disabled.navbar-btn, .btn-light:disabled, .navbar-light .navbar-btn:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle,
.navbar-light .show > .dropdown-toggle.navbar-btn {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus,
.navbar-light .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-dark:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-black, .navbar-dark .navbar-btn {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-black:hover, .navbar-dark .navbar-btn:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}

.btn-black:focus, .navbar-dark .navbar-btn:focus, .btn-black.focus, .navbar-dark .focus.navbar-btn {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-black.disabled, .navbar-dark .disabled.navbar-btn, .btn-black:disabled, .navbar-dark .navbar-btn:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.btn-black:not(:disabled):not(.disabled):active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle,
.navbar-dark .show > .dropdown-toggle.navbar-btn {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus,
.navbar-dark .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-accent {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.btn-accent:focus, .btn-accent.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-accent-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.btn-accent-red:focus, .btn-accent-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-accent-red.disabled, .btn-accent-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-accent-red:not(:disabled):not(.disabled):active, .btn-accent-red:not(:disabled):not(.disabled).active,
.show > .btn-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.btn-accent-red:not(:disabled):not(.disabled):active:focus, .btn-accent-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.btn-accent-yellow:focus, .btn-accent-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-accent-yellow.disabled, .btn-accent-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-accent-yellow:not(:disabled):not(.disabled):active, .btn-accent-yellow:not(:disabled):not(.disabled).active,
.show > .btn-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.btn-accent-yellow:not(:disabled):not(.disabled):active:focus, .btn-accent-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.btn-accent-dodger-blue:focus, .btn-accent-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-accent-dodger-blue.disabled, .btn-accent-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-accent-dodger-blue:not(:disabled):not(.disabled):active, .btn-accent-dodger-blue:not(:disabled):not(.disabled).active,
.show > .btn-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.btn-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-accent-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.btn-accent-pickled-bluewood:focus, .btn-accent-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-accent-pickled-bluewood.disabled, .btn-accent-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-accent-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .btn-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.btn-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-accent-electric-violet:hover {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
}

.btn-accent-electric-violet:focus, .btn-accent-electric-violet.focus {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.btn-accent-electric-violet.disabled, .btn-accent-electric-violet:disabled {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.btn-accent-electric-violet:not(:disabled):not(.disabled):active, .btn-accent-electric-violet:not(:disabled):not(.disabled).active,
.show > .btn-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #810fff;
  border-color: #7b02ff;
}

.btn-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .btn-accent-electric-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.btn-primary-purple {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-purple:hover {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
}

.btn-primary-purple:focus, .btn-primary-purple.focus {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.btn-primary-purple.disabled, .btn-primary-purple:disabled {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.btn-primary-purple:not(:disabled):not(.disabled):active, .btn-primary-purple:not(:disabled):not(.disabled).active,
.show > .btn-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #6425d7;
  border-color: #5f23cd;
}

.btn-primary-purple:not(:disabled):not(.disabled):active:focus, .btn-primary-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.btn-primary-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.btn-primary-red:focus, .btn-primary-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-primary-red.disabled, .btn-primary-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-primary-red:not(:disabled):not(.disabled):active, .btn-primary-red:not(:disabled):not(.disabled).active,
.show > .btn-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.btn-primary-red:not(:disabled):not(.disabled):active:focus, .btn-primary-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.btn-primary-yellow:focus, .btn-primary-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-primary-yellow.disabled, .btn-primary-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-primary-yellow:not(:disabled):not(.disabled):active, .btn-primary-yellow:not(:disabled):not(.disabled).active,
.show > .btn-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.btn-primary-yellow:not(:disabled):not(.disabled):active:focus, .btn-primary-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-primary-light {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-light:hover {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
}

.btn-primary-light:focus, .btn-primary-light.focus {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #66b3ff;
  border-color: #59acff;
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.btn-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.btn-primary-dodger-blue:focus, .btn-primary-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-primary-dodger-blue.disabled, .btn-primary-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-primary-dodger-blue:not(:disabled):not(.disabled):active, .btn-primary-dodger-blue:not(:disabled):not(.disabled).active,
.show > .btn-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.btn-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-primary-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.btn-primary-pickled-bluewood:focus, .btn-primary-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-primary-pickled-bluewood.disabled, .btn-primary-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-primary-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .btn-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.btn-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-outline-primary {
  color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #5567FF;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #77c13a;
  border-color: #77c13a;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #77c13a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #303956;
  border-color: #303956;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #303956;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-black {
  color: #272C33;
  border-color: #272C33;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #272C33;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.btn-outline-accent {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-accent-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent-red:focus, .btn-outline-accent-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-accent-red.disabled, .btn-outline-accent-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.btn-outline-accent-red:not(:disabled):not(.disabled):active, .btn-outline-accent-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-accent-red:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-accent-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-accent-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-accent-yellow:focus, .btn-outline-accent-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-accent-yellow.disabled, .btn-outline-accent-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.btn-outline-accent-yellow:not(:disabled):not(.disabled):active, .btn-outline-accent-yellow:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-accent-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-accent-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-accent-dodger-blue:focus, .btn-outline-accent-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-accent-dodger-blue.disabled, .btn-outline-accent-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}

.btn-outline-accent-dodger-blue:not(:disabled):not(.disabled):active, .btn-outline-accent-dodger-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-accent-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}

.btn-outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-accent-pickled-bluewood:focus, .btn-outline-accent-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-accent-pickled-bluewood.disabled, .btn-outline-accent-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}

.btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-accent-electric-violet {
  color: #9C42FF;
  border-color: #9C42FF;
}

.btn-outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.btn-outline-accent-electric-violet:focus, .btn-outline-accent-electric-violet.focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.btn-outline-accent-electric-violet.disabled, .btn-outline-accent-electric-violet:disabled {
  color: #9C42FF;
  background-color: transparent;
}

.btn-outline-accent-electric-violet:not(:disabled):not(.disabled):active, .btn-outline-accent-electric-violet:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.btn-outline-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-electric-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.btn-outline-primary-purple {
  color: #824EE1;
  border-color: #824EE1;
}

.btn-outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.btn-outline-primary-purple:focus, .btn-outline-primary-purple.focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.btn-outline-primary-purple.disabled, .btn-outline-primary-purple:disabled {
  color: #824EE1;
  background-color: transparent;
}

.btn-outline-primary-purple:not(:disabled):not(.disabled):active, .btn-outline-primary-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.btn-outline-primary-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.btn-outline-primary-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-primary-red:focus, .btn-outline-primary-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-primary-red.disabled, .btn-outline-primary-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.btn-outline-primary-red:not(:disabled):not(.disabled):active, .btn-outline-primary-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.btn-outline-primary-red:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-primary-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-primary-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-primary-yellow:focus, .btn-outline-primary-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-primary-yellow.disabled, .btn-outline-primary-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.btn-outline-primary-yellow:not(:disabled):not(.disabled):active, .btn-outline-primary-yellow:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.btn-outline-primary-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-primary-light {
  color: #99ccff;
  border-color: #99ccff;
}

.btn-outline-primary-light:hover {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: #99ccff;
  background-color: transparent;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.btn-outline-primary-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary-dodger-blue:focus, .btn-outline-primary-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-primary-dodger-blue.disabled, .btn-outline-primary-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}

.btn-outline-primary-dodger-blue:not(:disabled):not(.disabled):active, .btn-outline-primary-dodger-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.btn-outline-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-primary-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}

.btn-outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-primary-pickled-bluewood:focus, .btn-outline-primary-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-primary-pickled-bluewood.disabled, .btn-outline-primary-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}

.btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-link {
  font-weight: 400;
  color: rgba(39, 44, 51, 0.7);
  text-decoration: none;
}

.btn-link:hover {
  color: rgba(6, 7, 8, 0.7);
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0;
  font-size: 1rem;
  color: #272C33;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  box-shadow: 0 0.5rem 1rem rgba(39, 44, 51, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 374px) {
  .dropdown-menu-i8-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-i8-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 413px) {
  .dropdown-menu-i8-plus-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-i8-plus-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1366px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.5rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.5rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.2rem 1rem;
  clear: both;
  font-weight: 400;
  color: rgba(39, 44, 51, 0.7);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: rgba(39, 44, 51, 0.9);
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #272C33;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: rgba(39, 44, 51, 0.3);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.2rem 1rem;
  color: rgba(39, 44, 51, 0.7);
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #5567FF;
  background-color: #5567FF;
  box-shadow:;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 0.075rem #fff, 0 0 0 0.2rem #5567FF;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: white;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
  box-shadow:;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: white;
  border: #EDF0F2 solid 1px;
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075);
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5567FF;
  background-color: #5567FF;
  box-shadow:;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 103, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(85, 103, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 103, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #EDF0F2;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 103, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  vertical-align: middle;
  background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(39, 44, 51, 0.075);
  appearance: none;
}

.custom-select:focus {
  border-color: #5567FF;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(39, 44, 51, 0.075), 0 0 0 1px #5567FF;
}

.custom-select:focus::-ms-value {
  color: #272C33;
  background-color: white;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #272C33;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: white;
  box-shadow: 0 0 0 1px #5567FF;
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075);
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1rem);
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #868e96;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #5567FF;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #5567FF;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #5567FF;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5567FF;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(39, 44, 51, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(39, 44, 51, 0.1);
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5567FF;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(39, 44, 51, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: white;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(39, 44, 51, 0.1);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 1px;
  margin-left: 1px;
  background-color: #5567FF;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(39, 44, 51, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: white;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(39, 44, 51, 0.1);
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E9EDF2;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent transparent #E9EDF2;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #272C33;
  background-color: #F5F7FA;
  border-color: transparent transparent #F5F7FA;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5567FF;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 373.98px) {
  .navbar-expand-i8 > .container,
  .navbar-expand-i8 > .container-fluid, .navbar-expand-i8 > .container-sm, .navbar-expand-i8 > .container-md, .navbar-expand-i8 > .container-lg, .navbar-expand-i8 > .container-xl, .navbar-expand-i8 > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 374px) {
  .navbar-expand-i8 {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-i8 .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-i8 .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-i8 .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-i8 > .container,
  .navbar-expand-i8 > .container-fluid, .navbar-expand-i8 > .container-sm, .navbar-expand-i8 > .container-md, .navbar-expand-i8 > .container-lg, .navbar-expand-i8 > .container-xl, .navbar-expand-i8 > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-i8 .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-i8 .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-i8 .navbar-toggler {
    display: none;
  }
}

@media (max-width: 412.98px) {
  .navbar-expand-i8-plus > .container,
  .navbar-expand-i8-plus > .container-fluid, .navbar-expand-i8-plus > .container-sm, .navbar-expand-i8-plus > .container-md, .navbar-expand-i8-plus > .container-lg, .navbar-expand-i8-plus > .container-xl, .navbar-expand-i8-plus > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 413px) {
  .navbar-expand-i8-plus {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-i8-plus .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-i8-plus .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-i8-plus .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-i8-plus > .container,
  .navbar-expand-i8-plus > .container-fluid, .navbar-expand-i8-plus > .container-sm, .navbar-expand-i8-plus > .container-md, .navbar-expand-i8-plus > .container-lg, .navbar-expand-i8-plus > .container-xl, .navbar-expand-i8-plus > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-i8-plus .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-i8-plus .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-i8-plus .navbar-toggler {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1365.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1366px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(39, 44, 51, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(39, 44, 51, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(39, 44, 51, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(39, 44, 51, 0.5);
  border-color: rgba(39, 44, 51, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2839, 44, 51, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(39, 44, 51, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #858D94;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #858D94;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #858D94;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card, .card-nav .tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #DFE2E6;
  border-radius: 0.5rem;
}

.card > hr, .card-nav .tab-content > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group, .card-nav .tab-content > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child, .card-nav .tab-content > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card > .list-group:last-child, .card-nav .tab-content > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card > .card-header + .list-group, .card-nav .tab-content > .card-header + .list-group,
.card > .list-group + .card-footer,
.card-nav .tab-content > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #DFE2E6;
}

.card-header:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: #ffffff;
  border-top: 1px solid #DFE2E6;
}

.card-footer:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-deck .card, .card-deck .card-nav .tab-content, .card-nav .card-deck .tab-content {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card, .card-deck .card-nav .tab-content, .card-nav .card-deck .tab-content {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card, .card-nav .card-group > .tab-content {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card, .card-nav .card-group > .tab-content {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .card-nav .card-group > .tab-content + .card, .card-nav .card-group > .card + .tab-content, .card-nav .card-group > .tab-content + .tab-content {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child), .card-nav .card-group > .tab-content:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top, .card-nav .card-group > .tab-content:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header,
  .card-nav .card-group > .tab-content:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-nav .card-group > .tab-content:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer,
  .card-nav .card-group > .tab-content:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child), .card-nav .card-group > .tab-content:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top, .card-nav .card-group > .tab-content:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header,
  .card-nav .card-group > .tab-content:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-nav .card-group > .tab-content:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer,
  .card-nav .card-group > .tab-content:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card, .card-columns .card-nav .tab-content, .card-nav .card-columns .tab-content {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card, .card-columns .card-nav .tab-content, .card-nav .card-columns .tab-content {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card, .card-nav .accordion > .tab-content {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type), .card-nav .accordion > .tab-content:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type), .card-nav .accordion > .tab-content:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header, .card-nav .accordion > .tab-content > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.707rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0;
  color: rgba(39, 44, 51, 0.5);
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: rgba(39, 44, 51, 0.35);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgba(39, 44, 51, 0.7);
  background-color: transparent;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: rgba(6, 7, 8, 0.7);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 1px #5567FF;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.page-item.disabled .page-link {
  color: #cfcfcf;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #5567FF;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2239ff;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #77c13a;
}

a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #5f9a2e;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #E4A93C;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d0911d;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #d9534f;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c9302c;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #303956;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1e2335;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #272C33;
}

a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: #111316;
}

a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.badge-accent {
  color: #fff;
  background-color: #ED0B4C;
}

a.badge-accent:hover, a.badge-accent:focus {
  color: #fff;
  background-color: #bc093c;
}

a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.badge-accent-red {
  color: #fff;
  background-color: #ED0B4C;
}

a.badge-accent-red:hover, a.badge-accent-red:focus {
  color: #fff;
  background-color: #bc093c;
}

a.badge-accent-red:focus, a.badge-accent-red.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.badge-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
}

a.badge-accent-yellow:hover, a.badge-accent-yellow:focus {
  color: #212529;
  background-color: #d0911d;
}

a.badge-accent-yellow:focus, a.badge-accent-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
}

a.badge-accent-dodger-blue:hover, a.badge-accent-dodger-blue:focus {
  color: #fff;
  background-color: #2239ff;
}

a.badge-accent-dodger-blue:focus, a.badge-accent-dodger-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.badge-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
}

a.badge-accent-pickled-bluewood:hover, a.badge-accent-pickled-bluewood:focus {
  color: #fff;
  background-color: #1e2335;
}

a.badge-accent-pickled-bluewood:focus, a.badge-accent-pickled-bluewood.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.badge-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
}

a.badge-accent-electric-violet:hover, a.badge-accent-electric-violet:focus {
  color: #fff;
  background-color: #810fff;
}

a.badge-accent-electric-violet:focus, a.badge-accent-electric-violet.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.badge-primary-purple {
  color: #fff;
  background-color: #824EE1;
}

a.badge-primary-purple:hover, a.badge-primary-purple:focus {
  color: #fff;
  background-color: #6425d7;
}

a.badge-primary-purple:focus, a.badge-primary-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.badge-primary-red {
  color: #fff;
  background-color: #ED0B4C;
}

a.badge-primary-red:hover, a.badge-primary-red:focus {
  color: #fff;
  background-color: #bc093c;
}

a.badge-primary-red:focus, a.badge-primary-red.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.badge-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
}

a.badge-primary-yellow:hover, a.badge-primary-yellow:focus {
  color: #212529;
  background-color: #d0911d;
}

a.badge-primary-yellow:focus, a.badge-primary-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-primary-light {
  color: #212529;
  background-color: #99ccff;
}

a.badge-primary-light:hover, a.badge-primary-light:focus {
  color: #212529;
  background-color: #66b3ff;
}

a.badge-primary-light:focus, a.badge-primary-light.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.badge-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
}

a.badge-primary-dodger-blue:hover, a.badge-primary-dodger-blue:focus {
  color: #fff;
  background-color: #2239ff;
}

a.badge-primary-dodger-blue:focus, a.badge-primary-dodger-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.badge-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
}

a.badge-primary-pickled-bluewood:hover, a.badge-primary-pickled-bluewood:focus {
  color: #fff;
  background-color: #1e2335;
}

a.badge-primary-pickled-bluewood:focus, a.badge-primary-pickled-bluewood.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 2.5rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem 0.5rem;
  color: inherit;
}

.alert-primary {
  color: #3f4b9d;
  background-color: #dde1ff;
  border-color: #cfd4ff;
}

.alert-primary hr {
  border-top-color: #b6bdff;
}

.alert-primary .alert-link {
  color: #303a79;
}

.alert-secondary {
  color: #585f66;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}

.alert-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-secondary .alert-link {
  color: #40464b;
}

.alert-success {
  color: #517937;
  background-color: #e4f3d8;
  border-color: #d9eec8;
}

.alert-success hr {
  border-top-color: #cce8b5;
}

.alert-success .alert-link {
  color: #3a5627;
}

.alert-info {
  color: #1f6978;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #15464f;
}

.alert-warning {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}

.alert-warning hr {
  border-top-color: #f4ddb2;
}

.alert-warning .alert-link {
  color: #655029;
}

.alert-danger {
  color: #844042;
  background-color: #f7dddc;
  border-color: #f4cfce;
}

.alert-danger hr {
  border-top-color: #efbbb9;
}

.alert-danger .alert-link {
  color: #622f31;
}

.alert-light {
  color: #94979a;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #7a7e81;
}

.alert-dark {
  color: #2c3345;
  background-color: #d6d7dd;
  border-color: #c5c8d0;
}

.alert-dark hr {
  border-top-color: #b7bbc5;
}

.alert-dark .alert-link {
  color: #181c26;
}

.alert-black {
  color: #272c33;
  background-color: #d4d5d6;
  border-color: #c3c4c6;
}

.alert-black hr {
  border-top-color: #b6b7ba;
}

.alert-black .alert-link {
  color: #111316;
}

.alert-accent {
  color: #8e1b40;
  background-color: #fbcedb;
  border-color: #fabbcd;
}

.alert-accent hr {
  border-top-color: #f8a3bc;
}

.alert-accent .alert-link {
  color: #63132d;
}

.alert-accent-red {
  color: #8e1b40;
  background-color: #fbcedb;
  border-color: #fabbcd;
}

.alert-accent-red hr {
  border-top-color: #f8a3bc;
}

.alert-accent-red .alert-link {
  color: #63132d;
}

.alert-accent-yellow {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}

.alert-accent-yellow hr {
  border-top-color: #f4ddb2;
}

.alert-accent-yellow .alert-link {
  color: #655029;
}

.alert-accent-dodger-blue {
  color: #3f4b9d;
  background-color: #dde1ff;
  border-color: #cfd4ff;
}

.alert-accent-dodger-blue hr {
  border-top-color: #b6bdff;
}

.alert-accent-dodger-blue .alert-link {
  color: #303a79;
}

.alert-accent-pickled-bluewood {
  color: #2c3345;
  background-color: #d6d7dd;
  border-color: #c5c8d0;
}

.alert-accent-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}

.alert-accent-pickled-bluewood .alert-link {
  color: #181c26;
}

.alert-accent-electric-violet {
  color: #64379d;
  background-color: #ebd9ff;
  border-color: #e3caff;
}

.alert-accent-electric-violet hr {
  border-top-color: #d6b1ff;
}

.alert-accent-electric-violet .alert-link {
  color: #4c2a77;
}

.alert-primary-purple {
  color: #563e8d;
  background-color: #e6dcf9;
  border-color: #dccdf7;
}

.alert-primary-purple hr {
  border-top-color: #cdb7f3;
}

.alert-primary-purple .alert-link {
  color: #402e6a;
}

.alert-primary-red {
  color: #8e1b40;
  background-color: #fbcedb;
  border-color: #fabbcd;
}

.alert-primary-red hr {
  border-top-color: #f8a3bc;
}

.alert-primary-red .alert-link {
  color: #63132d;
}

.alert-primary-yellow {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}

.alert-primary-yellow hr {
  border-top-color: #f4ddb2;
}

.alert-primary-yellow .alert-link {
  color: #655029;
}

.alert-primary-light {
  color: #627f9d;
  background-color: #ebf5ff;
  border-color: #e2f1ff;
}

.alert-primary-light hr {
  border-top-color: #c9e5ff;
}

.alert-primary-light .alert-link {
  color: #4e667e;
}

.alert-primary-dodger-blue {
  color: #3f4b9d;
  background-color: #dde1ff;
  border-color: #cfd4ff;
}

.alert-primary-dodger-blue hr {
  border-top-color: #b6bdff;
}

.alert-primary-dodger-blue .alert-link {
  color: #303a79;
}

.alert-primary-pickled-bluewood {
  color: #2c3345;
  background-color: #d6d7dd;
  border-color: #c5c8d0;
}

.alert-primary-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}

.alert-primary-pickled-bluewood .alert-link {
  color: #181c26;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(39, 44, 51, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5567FF;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #272C33;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border: 1px solid #E9EDF2;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #272C33;
  background-color: transparent;
  border-color: transparent;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 374px) {
  .list-group-horizontal-i8 {
    flex-direction: row;
  }
  .list-group-horizontal-i8 > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-i8 > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-i8 > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-i8 > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-i8 > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 413px) {
  .list-group-horizontal-i8-plus {
    flex-direction: row;
  }
  .list-group-horizontal-i8-plus > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-i8-plus > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-i8-plus > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-i8-plus > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-i8-plus > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1366px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #3f4b9d;
  background-color: #cfd4ff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #3f4b9d;
  background-color: #b6bdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #3f4b9d;
  border-color: #3f4b9d;
}

.list-group-item-secondary {
  color: #585f66;
  background-color: #dddfe2;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #585f66;
  background-color: #cfd2d6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #585f66;
  border-color: #585f66;
}

.list-group-item-success {
  color: #517937;
  background-color: #d9eec8;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #517937;
  background-color: #cce8b5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #517937;
  border-color: #517937;
}

.list-group-item-info {
  color: #1f6978;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1f6978;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1f6978;
  border-color: #1f6978;
}

.list-group-item-warning {
  color: #896d38;
  background-color: #f7e7c8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-danger {
  color: #844042;
  background-color: #f4cfce;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #844042;
  background-color: #efbbb9;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #844042;
  border-color: #844042;
}

.list-group-item-light {
  color: #94979a;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #94979a;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #94979a;
  border-color: #94979a;
}

.list-group-item-dark {
  color: #2c3345;
  background-color: #c5c8d0;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2c3345;
  background-color: #b7bbc5;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2c3345;
  border-color: #2c3345;
}

.list-group-item-black {
  color: #272c33;
  background-color: #c3c4c6;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: #272c33;
  background-color: #b6b7ba;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #272c33;
  border-color: #272c33;
}

.list-group-item-accent {
  color: #8e1b40;
  background-color: #fabbcd;
}

.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #8e1b40;
  background-color: #f8a3bc;
}

.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #8e1b40;
  border-color: #8e1b40;
}

.list-group-item-accent-red {
  color: #8e1b40;
  background-color: #fabbcd;
}

.list-group-item-accent-red.list-group-item-action:hover, .list-group-item-accent-red.list-group-item-action:focus {
  color: #8e1b40;
  background-color: #f8a3bc;
}

.list-group-item-accent-red.list-group-item-action.active {
  color: #fff;
  background-color: #8e1b40;
  border-color: #8e1b40;
}

.list-group-item-accent-yellow {
  color: #896d38;
  background-color: #f7e7c8;
}

.list-group-item-accent-yellow.list-group-item-action:hover, .list-group-item-accent-yellow.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}

.list-group-item-accent-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-accent-dodger-blue {
  color: #3f4b9d;
  background-color: #cfd4ff;
}

.list-group-item-accent-dodger-blue.list-group-item-action:hover, .list-group-item-accent-dodger-blue.list-group-item-action:focus {
  color: #3f4b9d;
  background-color: #b6bdff;
}

.list-group-item-accent-dodger-blue.list-group-item-action.active {
  color: #fff;
  background-color: #3f4b9d;
  border-color: #3f4b9d;
}

.list-group-item-accent-pickled-bluewood {
  color: #2c3345;
  background-color: #c5c8d0;
}

.list-group-item-accent-pickled-bluewood.list-group-item-action:hover, .list-group-item-accent-pickled-bluewood.list-group-item-action:focus {
  color: #2c3345;
  background-color: #b7bbc5;
}

.list-group-item-accent-pickled-bluewood.list-group-item-action.active {
  color: #fff;
  background-color: #2c3345;
  border-color: #2c3345;
}

.list-group-item-accent-electric-violet {
  color: #64379d;
  background-color: #e3caff;
}

.list-group-item-accent-electric-violet.list-group-item-action:hover, .list-group-item-accent-electric-violet.list-group-item-action:focus {
  color: #64379d;
  background-color: #d6b1ff;
}

.list-group-item-accent-electric-violet.list-group-item-action.active {
  color: #fff;
  background-color: #64379d;
  border-color: #64379d;
}

.list-group-item-primary-purple {
  color: #563e8d;
  background-color: #dccdf7;
}

.list-group-item-primary-purple.list-group-item-action:hover, .list-group-item-primary-purple.list-group-item-action:focus {
  color: #563e8d;
  background-color: #cdb7f3;
}

.list-group-item-primary-purple.list-group-item-action.active {
  color: #fff;
  background-color: #563e8d;
  border-color: #563e8d;
}

.list-group-item-primary-red {
  color: #8e1b40;
  background-color: #fabbcd;
}

.list-group-item-primary-red.list-group-item-action:hover, .list-group-item-primary-red.list-group-item-action:focus {
  color: #8e1b40;
  background-color: #f8a3bc;
}

.list-group-item-primary-red.list-group-item-action.active {
  color: #fff;
  background-color: #8e1b40;
  border-color: #8e1b40;
}

.list-group-item-primary-yellow {
  color: #896d38;
  background-color: #f7e7c8;
}

.list-group-item-primary-yellow.list-group-item-action:hover, .list-group-item-primary-yellow.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}

.list-group-item-primary-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-primary-light {
  color: #627f9d;
  background-color: #e2f1ff;
}

.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: #627f9d;
  background-color: #c9e5ff;
}

.list-group-item-primary-light.list-group-item-action.active {
  color: #fff;
  background-color: #627f9d;
  border-color: #627f9d;
}

.list-group-item-primary-dodger-blue {
  color: #3f4b9d;
  background-color: #cfd4ff;
}

.list-group-item-primary-dodger-blue.list-group-item-action:hover, .list-group-item-primary-dodger-blue.list-group-item-action:focus {
  color: #3f4b9d;
  background-color: #b6bdff;
}

.list-group-item-primary-dodger-blue.list-group-item-action.active {
  color: #fff;
  background-color: #3f4b9d;
  border-color: #3f4b9d;
}

.list-group-item-primary-pickled-bluewood {
  color: #2c3345;
  background-color: #c5c8d0;
}

.list-group-item-primary-pickled-bluewood.list-group-item-action:hover, .list-group-item-primary-pickled-bluewood.list-group-item-action:focus {
  color: #2c3345;
  background-color: #b7bbc5;
}

.list-group-item-primary-pickled-bluewood.list-group-item-action.active {
  color: #fff;
  background-color: #2c3345;
  border-color: #2c3345;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #272C33;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #272C33;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(39, 44, 51, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #868e96;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(39, 44, 51, 0.2);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(39, 44, 51, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(39, 44, 51, 0.8);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E9EDF2;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E9EDF2;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(39, 44, 51, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.5rem;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: white;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: white;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: white;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: white;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 1rem;
  color: #272C33;
  text-align: center;
  background-color: white;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 304px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E9EDF2;
  border-radius: 0.5rem;
  box-shadow: 0 2px 3px rgba(39, 44, 51, 0.05);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #e9edf2;
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #e9edf2;
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #e9edf2;
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #F5F7FA;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #e9edf2;
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #303956;
  background-color: #F5F7FA;
  border-bottom: 1px solid #e4e9f2;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #272C33;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #5567FF !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2239ff !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #77c13a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #5f9a2e !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #E4A93C !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d0911d !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #303956 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1e2335 !important;
}

.bg-black {
  background-color: #272C33 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #111316 !important;
}

.bg-accent {
  background-color: #ED0B4C !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #bc093c !important;
}

.bg-accent-red {
  background-color: #ED0B4C !important;
}

a.bg-accent-red:hover, a.bg-accent-red:focus,
button.bg-accent-red:hover,
button.bg-accent-red:focus {
  background-color: #bc093c !important;
}

.bg-accent-yellow {
  background-color: #E4A93C !important;
}

a.bg-accent-yellow:hover, a.bg-accent-yellow:focus,
button.bg-accent-yellow:hover,
button.bg-accent-yellow:focus {
  background-color: #d0911d !important;
}

.bg-accent-dodger-blue {
  background-color: #5567FF !important;
}

a.bg-accent-dodger-blue:hover, a.bg-accent-dodger-blue:focus,
button.bg-accent-dodger-blue:hover,
button.bg-accent-dodger-blue:focus {
  background-color: #2239ff !important;
}

.bg-accent-pickled-bluewood {
  background-color: #303956 !important;
}

a.bg-accent-pickled-bluewood:hover, a.bg-accent-pickled-bluewood:focus,
button.bg-accent-pickled-bluewood:hover,
button.bg-accent-pickled-bluewood:focus {
  background-color: #1e2335 !important;
}

.bg-accent-electric-violet {
  background-color: #9C42FF !important;
}

a.bg-accent-electric-violet:hover, a.bg-accent-electric-violet:focus,
button.bg-accent-electric-violet:hover,
button.bg-accent-electric-violet:focus {
  background-color: #810fff !important;
}

.bg-primary-purple {
  background-color: #824EE1 !important;
}

a.bg-primary-purple:hover, a.bg-primary-purple:focus,
button.bg-primary-purple:hover,
button.bg-primary-purple:focus {
  background-color: #6425d7 !important;
}

.bg-primary-red {
  background-color: #ED0B4C !important;
}

a.bg-primary-red:hover, a.bg-primary-red:focus,
button.bg-primary-red:hover,
button.bg-primary-red:focus {
  background-color: #bc093c !important;
}

.bg-primary-yellow {
  background-color: #E4A93C !important;
}

a.bg-primary-yellow:hover, a.bg-primary-yellow:focus,
button.bg-primary-yellow:hover,
button.bg-primary-yellow:focus {
  background-color: #d0911d !important;
}

.bg-primary-light {
  background-color: #99ccff !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #66b3ff !important;
}

.bg-primary-dodger-blue {
  background-color: #5567FF !important;
}

a.bg-primary-dodger-blue:hover, a.bg-primary-dodger-blue:focus,
button.bg-primary-dodger-blue:hover,
button.bg-primary-dodger-blue:focus {
  background-color: #2239ff !important;
}

.bg-primary-pickled-bluewood {
  background-color: #303956 !important;
}

a.bg-primary-pickled-bluewood:hover, a.bg-primary-pickled-bluewood:focus,
button.bg-primary-pickled-bluewood:hover,
button.bg-primary-pickled-bluewood:focus {
  background-color: #1e2335 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #E9EDF2 !important;
}

.border-top {
  border-top: 1px solid #E9EDF2 !important;
}

.border-right {
  border-right: 1px solid #E9EDF2 !important;
}

.border-bottom {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-left {
  border-left: 1px solid #E9EDF2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #5567FF !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #77c13a !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #E4A93C !important;
}

.border-danger {
  border-color: #d9534f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #303956 !important;
}

.border-black {
  border-color: #272C33 !important;
}

.border-accent {
  border-color: #ED0B4C !important;
}

.border-accent-red {
  border-color: #ED0B4C !important;
}

.border-accent-yellow {
  border-color: #E4A93C !important;
}

.border-accent-dodger-blue {
  border-color: #5567FF !important;
}

.border-accent-pickled-bluewood {
  border-color: #303956 !important;
}

.border-accent-electric-violet {
  border-color: #9C42FF !important;
}

.border-primary-purple {
  border-color: #824EE1 !important;
}

.border-primary-red {
  border-color: #ED0B4C !important;
}

.border-primary-yellow {
  border-color: #E4A93C !important;
}

.border-primary-light {
  border-color: #99ccff !important;
}

.border-primary-dodger-blue {
  border-color: #5567FF !important;
}

.border-primary-pickled-bluewood {
  border-color: #303956 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 374px) {
  .d-i8-none {
    display: none !important;
  }
  .d-i8-inline {
    display: inline !important;
  }
  .d-i8-inline-block {
    display: inline-block !important;
  }
  .d-i8-block {
    display: block !important;
  }
  .d-i8-table {
    display: table !important;
  }
  .d-i8-table-row {
    display: table-row !important;
  }
  .d-i8-table-cell {
    display: table-cell !important;
  }
  .d-i8-flex {
    display: flex !important;
  }
  .d-i8-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 413px) {
  .d-i8-plus-none {
    display: none !important;
  }
  .d-i8-plus-inline {
    display: inline !important;
  }
  .d-i8-plus-inline-block {
    display: inline-block !important;
  }
  .d-i8-plus-block {
    display: block !important;
  }
  .d-i8-plus-table {
    display: table !important;
  }
  .d-i8-plus-table-row {
    display: table-row !important;
  }
  .d-i8-plus-table-cell {
    display: table-cell !important;
  }
  .d-i8-plus-flex {
    display: flex !important;
  }
  .d-i8-plus-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1366px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 374px) {
  .flex-i8-row {
    flex-direction: row !important;
  }
  .flex-i8-column {
    flex-direction: column !important;
  }
  .flex-i8-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-i8-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-i8-wrap {
    flex-wrap: wrap !important;
  }
  .flex-i8-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-i8-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-i8-fill {
    flex: 1 1 auto !important;
  }
  .flex-i8-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-i8-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-i8-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-i8-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-i8-start {
    justify-content: flex-start !important;
  }
  .justify-content-i8-end {
    justify-content: flex-end !important;
  }
  .justify-content-i8-center {
    justify-content: center !important;
  }
  .justify-content-i8-between {
    justify-content: space-between !important;
  }
  .justify-content-i8-around {
    justify-content: space-around !important;
  }
  .align-items-i8-start {
    align-items: flex-start !important;
  }
  .align-items-i8-end {
    align-items: flex-end !important;
  }
  .align-items-i8-center {
    align-items: center !important;
  }
  .align-items-i8-baseline {
    align-items: baseline !important;
  }
  .align-items-i8-stretch {
    align-items: stretch !important;
  }
  .align-content-i8-start {
    align-content: flex-start !important;
  }
  .align-content-i8-end {
    align-content: flex-end !important;
  }
  .align-content-i8-center {
    align-content: center !important;
  }
  .align-content-i8-between {
    align-content: space-between !important;
  }
  .align-content-i8-around {
    align-content: space-around !important;
  }
  .align-content-i8-stretch {
    align-content: stretch !important;
  }
  .align-self-i8-auto {
    align-self: auto !important;
  }
  .align-self-i8-start {
    align-self: flex-start !important;
  }
  .align-self-i8-end {
    align-self: flex-end !important;
  }
  .align-self-i8-center {
    align-self: center !important;
  }
  .align-self-i8-baseline {
    align-self: baseline !important;
  }
  .align-self-i8-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 413px) {
  .flex-i8-plus-row {
    flex-direction: row !important;
  }
  .flex-i8-plus-column {
    flex-direction: column !important;
  }
  .flex-i8-plus-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-i8-plus-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-i8-plus-wrap {
    flex-wrap: wrap !important;
  }
  .flex-i8-plus-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-i8-plus-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-i8-plus-fill {
    flex: 1 1 auto !important;
  }
  .flex-i8-plus-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-i8-plus-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-i8-plus-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-i8-plus-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-i8-plus-start {
    justify-content: flex-start !important;
  }
  .justify-content-i8-plus-end {
    justify-content: flex-end !important;
  }
  .justify-content-i8-plus-center {
    justify-content: center !important;
  }
  .justify-content-i8-plus-between {
    justify-content: space-between !important;
  }
  .justify-content-i8-plus-around {
    justify-content: space-around !important;
  }
  .align-items-i8-plus-start {
    align-items: flex-start !important;
  }
  .align-items-i8-plus-end {
    align-items: flex-end !important;
  }
  .align-items-i8-plus-center {
    align-items: center !important;
  }
  .align-items-i8-plus-baseline {
    align-items: baseline !important;
  }
  .align-items-i8-plus-stretch {
    align-items: stretch !important;
  }
  .align-content-i8-plus-start {
    align-content: flex-start !important;
  }
  .align-content-i8-plus-end {
    align-content: flex-end !important;
  }
  .align-content-i8-plus-center {
    align-content: center !important;
  }
  .align-content-i8-plus-between {
    align-content: space-between !important;
  }
  .align-content-i8-plus-around {
    align-content: space-around !important;
  }
  .align-content-i8-plus-stretch {
    align-content: stretch !important;
  }
  .align-self-i8-plus-auto {
    align-self: auto !important;
  }
  .align-self-i8-plus-start {
    align-self: flex-start !important;
  }
  .align-self-i8-plus-end {
    align-self: flex-end !important;
  }
  .align-self-i8-plus-center {
    align-self: center !important;
  }
  .align-self-i8-plus-baseline {
    align-self: baseline !important;
  }
  .align-self-i8-plus-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1366px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 374px) {
  .float-i8-left {
    float: left !important;
  }
  .float-i8-right {
    float: right !important;
  }
  .float-i8-none {
    float: none !important;
  }
}

@media (min-width: 413px) {
  .float-i8-plus-left {
    float: left !important;
  }
  .float-i8-plus-right {
    float: right !important;
  }
  .float-i8-plus-none {
    float: none !important;
  }
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1366px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(39, 44, 51, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(39, 44, 51, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(39, 44, 51, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-64 {
  width: 64px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-64 {
  height: 64px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-4pt {
  margin: 0.25rem !important;
}

.mt-4pt,
.my-4pt {
  margin-top: 0.25rem !important;
}

.mr-4pt,
.mx-4pt {
  margin-right: 0.25rem !important;
}

.mb-4pt,
.my-4pt {
  margin-bottom: 0.25rem !important;
}

.ml-4pt,
.mx-4pt {
  margin-left: 0.25rem !important;
}

.m-8pt {
  margin: 0.5rem !important;
}

.mt-8pt,
.my-8pt {
  margin-top: 0.5rem !important;
}

.mr-8pt,
.mx-8pt {
  margin-right: 0.5rem !important;
}

.mb-8pt,
.my-8pt {
  margin-bottom: 0.5rem !important;
}

.ml-8pt,
.mx-8pt {
  margin-left: 0.5rem !important;
}

.m-12pt {
  margin: 0.75rem !important;
}

.mt-12pt,
.my-12pt {
  margin-top: 0.75rem !important;
}

.mr-12pt,
.mx-12pt {
  margin-right: 0.75rem !important;
}

.mb-12pt,
.my-12pt {
  margin-bottom: 0.75rem !important;
}

.ml-12pt,
.mx-12pt {
  margin-left: 0.75rem !important;
}

.m-16pt {
  margin: 1rem !important;
}

.mt-16pt,
.my-16pt {
  margin-top: 1rem !important;
}

.mr-16pt,
.mx-16pt {
  margin-right: 1rem !important;
}

.mb-16pt,
.my-16pt {
  margin-bottom: 1rem !important;
}

.ml-16pt,
.mx-16pt {
  margin-left: 1rem !important;
}

.m-20pt {
  margin: 1.25rem !important;
}

.mt-20pt,
.my-20pt {
  margin-top: 1.25rem !important;
}

.mr-20pt,
.mx-20pt {
  margin-right: 1.25rem !important;
}

.mb-20pt,
.my-20pt {
  margin-bottom: 1.25rem !important;
}

.ml-20pt,
.mx-20pt {
  margin-left: 1.25rem !important;
}

.m-24pt {
  margin: 1.5rem !important;
}

.mt-24pt,
.my-24pt {
  margin-top: 1.5rem !important;
}

.mr-24pt,
.mx-24pt {
  margin-right: 1.5rem !important;
}

.mb-24pt,
.my-24pt {
  margin-bottom: 1.5rem !important;
}

.ml-24pt,
.mx-24pt {
  margin-left: 1.5rem !important;
}

.m-32pt {
  margin: 2rem !important;
}

.mt-32pt,
.my-32pt {
  margin-top: 2rem !important;
}

.mr-32pt,
.mx-32pt {
  margin-right: 2rem !important;
}

.mb-32pt,
.my-32pt {
  margin-bottom: 2rem !important;
}

.ml-32pt,
.mx-32pt {
  margin-left: 2rem !important;
}

.m-40pt {
  margin: 2.5rem !important;
}

.mt-40pt,
.my-40pt {
  margin-top: 2.5rem !important;
}

.mr-40pt,
.mx-40pt {
  margin-right: 2.5rem !important;
}

.mb-40pt,
.my-40pt {
  margin-bottom: 2.5rem !important;
}

.ml-40pt,
.mx-40pt {
  margin-left: 2.5rem !important;
}

.m-44pt {
  margin: 2.75rem !important;
}

.mt-44pt,
.my-44pt {
  margin-top: 2.75rem !important;
}

.mr-44pt,
.mx-44pt {
  margin-right: 2.75rem !important;
}

.mb-44pt,
.my-44pt {
  margin-bottom: 2.75rem !important;
}

.ml-44pt,
.mx-44pt {
  margin-left: 2.75rem !important;
}

.m-48pt {
  margin: 3rem !important;
}

.mt-48pt,
.my-48pt {
  margin-top: 3rem !important;
}

.mr-48pt,
.mx-48pt {
  margin-right: 3rem !important;
}

.mb-48pt,
.my-48pt {
  margin-bottom: 3rem !important;
}

.ml-48pt,
.mx-48pt {
  margin-left: 3rem !important;
}

.m-64pt {
  margin: 4rem !important;
}

.mt-64pt,
.my-64pt {
  margin-top: 4rem !important;
}

.mr-64pt,
.mx-64pt {
  margin-right: 4rem !important;
}

.mb-64pt,
.my-64pt {
  margin-bottom: 4rem !important;
}

.ml-64pt,
.mx-64pt {
  margin-left: 4rem !important;
}

.m-112pt {
  margin: 7rem !important;
}

.mt-112pt,
.my-112pt {
  margin-top: 7rem !important;
}

.mr-112pt,
.mx-112pt {
  margin-right: 7rem !important;
}

.mb-112pt,
.my-112pt {
  margin-bottom: 7rem !important;
}

.ml-112pt,
.mx-112pt {
  margin-left: 7rem !important;
}

.m-128pt {
  margin: 8rem !important;
}

.mt-128pt,
.my-128pt {
  margin-top: 8rem !important;
}

.mr-128pt,
.mx-128pt {
  margin-right: 8rem !important;
}

.mb-128pt,
.my-128pt {
  margin-bottom: 8rem !important;
}

.ml-128pt,
.mx-128pt {
  margin-left: 8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-4pt {
  padding: 0.25rem !important;
}

.pt-4pt,
.py-4pt {
  padding-top: 0.25rem !important;
}

.pr-4pt,
.px-4pt {
  padding-right: 0.25rem !important;
}

.pb-4pt,
.py-4pt {
  padding-bottom: 0.25rem !important;
}

.pl-4pt,
.px-4pt {
  padding-left: 0.25rem !important;
}

.p-8pt {
  padding: 0.5rem !important;
}

.pt-8pt,
.py-8pt {
  padding-top: 0.5rem !important;
}

.pr-8pt,
.px-8pt {
  padding-right: 0.5rem !important;
}

.pb-8pt,
.py-8pt {
  padding-bottom: 0.5rem !important;
}

.pl-8pt,
.px-8pt {
  padding-left: 0.5rem !important;
}

.p-12pt {
  padding: 0.75rem !important;
}

.pt-12pt,
.py-12pt {
  padding-top: 0.75rem !important;
}

.pr-12pt,
.px-12pt {
  padding-right: 0.75rem !important;
}

.pb-12pt,
.py-12pt {
  padding-bottom: 0.75rem !important;
}

.pl-12pt,
.px-12pt {
  padding-left: 0.75rem !important;
}

.p-16pt {
  padding: 1rem !important;
}

.pt-16pt,
.py-16pt {
  padding-top: 1rem !important;
}

.pr-16pt,
.px-16pt {
  padding-right: 1rem !important;
}

.pb-16pt,
.py-16pt {
  padding-bottom: 1rem !important;
}

.pl-16pt,
.px-16pt {
  padding-left: 1rem !important;
}

.p-20pt {
  padding: 1.25rem !important;
}

.pt-20pt,
.py-20pt {
  padding-top: 1.25rem !important;
}

.pr-20pt,
.px-20pt {
  padding-right: 1.25rem !important;
}

.pb-20pt,
.py-20pt {
  padding-bottom: 1.25rem !important;
}

.pl-20pt,
.px-20pt {
  padding-left: 1.25rem !important;
}

.p-24pt {
  padding: 1.5rem !important;
}

.pt-24pt,
.py-24pt {
  padding-top: 1.5rem !important;
}

.pr-24pt,
.px-24pt {
  padding-right: 1.5rem !important;
}

.pb-24pt,
.py-24pt {
  padding-bottom: 1.5rem !important;
}

.pl-24pt,
.px-24pt {
  padding-left: 1.5rem !important;
}

.p-32pt {
  padding: 2rem !important;
}

.pt-32pt,
.py-32pt {
  padding-top: 2rem !important;
}

.pr-32pt,
.px-32pt {
  padding-right: 2rem !important;
}

.pb-32pt,
.py-32pt {
  padding-bottom: 2rem !important;
}

.pl-32pt,
.px-32pt {
  padding-left: 2rem !important;
}

.p-40pt {
  padding: 2.5rem !important;
}

.pt-40pt,
.py-40pt {
  padding-top: 2.5rem !important;
}

.pr-40pt,
.px-40pt {
  padding-right: 2.5rem !important;
}

.pb-40pt,
.py-40pt {
  padding-bottom: 2.5rem !important;
}

.pl-40pt,
.px-40pt {
  padding-left: 2.5rem !important;
}

.p-44pt {
  padding: 2.75rem !important;
}

.pt-44pt,
.py-44pt {
  padding-top: 2.75rem !important;
}

.pr-44pt,
.px-44pt {
  padding-right: 2.75rem !important;
}

.pb-44pt,
.py-44pt {
  padding-bottom: 2.75rem !important;
}

.pl-44pt,
.px-44pt {
  padding-left: 2.75rem !important;
}

.p-48pt {
  padding: 3rem !important;
}

.pt-48pt,
.py-48pt {
  padding-top: 3rem !important;
}

.pr-48pt,
.px-48pt {
  padding-right: 3rem !important;
}

.pb-48pt,
.py-48pt {
  padding-bottom: 3rem !important;
}

.pl-48pt,
.px-48pt {
  padding-left: 3rem !important;
}

.p-64pt {
  padding: 4rem !important;
}

.pt-64pt,
.py-64pt {
  padding-top: 4rem !important;
}

.pr-64pt,
.px-64pt {
  padding-right: 4rem !important;
}

.pb-64pt,
.py-64pt {
  padding-bottom: 4rem !important;
}

.pl-64pt,
.px-64pt {
  padding-left: 4rem !important;
}

.p-112pt {
  padding: 7rem !important;
}

.pt-112pt,
.py-112pt {
  padding-top: 7rem !important;
}

.pr-112pt,
.px-112pt {
  padding-right: 7rem !important;
}

.pb-112pt,
.py-112pt {
  padding-bottom: 7rem !important;
}

.pl-112pt,
.px-112pt {
  padding-left: 7rem !important;
}

.p-128pt {
  padding: 8rem !important;
}

.pt-128pt,
.py-128pt {
  padding-top: 8rem !important;
}

.pr-128pt,
.px-128pt {
  padding-right: 8rem !important;
}

.pb-128pt,
.py-128pt {
  padding-bottom: 8rem !important;
}

.pl-128pt,
.px-128pt {
  padding-left: 8rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n4pt {
  margin: -0.25rem !important;
}

.mt-n4pt,
.my-n4pt {
  margin-top: -0.25rem !important;
}

.mr-n4pt,
.mx-n4pt {
  margin-right: -0.25rem !important;
}

.mb-n4pt,
.my-n4pt {
  margin-bottom: -0.25rem !important;
}

.ml-n4pt,
.mx-n4pt {
  margin-left: -0.25rem !important;
}

.m-n8pt {
  margin: -0.5rem !important;
}

.mt-n8pt,
.my-n8pt {
  margin-top: -0.5rem !important;
}

.mr-n8pt,
.mx-n8pt {
  margin-right: -0.5rem !important;
}

.mb-n8pt,
.my-n8pt {
  margin-bottom: -0.5rem !important;
}

.ml-n8pt,
.mx-n8pt {
  margin-left: -0.5rem !important;
}

.m-n12pt {
  margin: -0.75rem !important;
}

.mt-n12pt,
.my-n12pt {
  margin-top: -0.75rem !important;
}

.mr-n12pt,
.mx-n12pt {
  margin-right: -0.75rem !important;
}

.mb-n12pt,
.my-n12pt {
  margin-bottom: -0.75rem !important;
}

.ml-n12pt,
.mx-n12pt {
  margin-left: -0.75rem !important;
}

.m-n16pt {
  margin: -1rem !important;
}

.mt-n16pt,
.my-n16pt {
  margin-top: -1rem !important;
}

.mr-n16pt,
.mx-n16pt {
  margin-right: -1rem !important;
}

.mb-n16pt,
.my-n16pt {
  margin-bottom: -1rem !important;
}

.ml-n16pt,
.mx-n16pt {
  margin-left: -1rem !important;
}

.m-n20pt {
  margin: -1.25rem !important;
}

.mt-n20pt,
.my-n20pt {
  margin-top: -1.25rem !important;
}

.mr-n20pt,
.mx-n20pt {
  margin-right: -1.25rem !important;
}

.mb-n20pt,
.my-n20pt {
  margin-bottom: -1.25rem !important;
}

.ml-n20pt,
.mx-n20pt {
  margin-left: -1.25rem !important;
}

.m-n24pt {
  margin: -1.5rem !important;
}

.mt-n24pt,
.my-n24pt {
  margin-top: -1.5rem !important;
}

.mr-n24pt,
.mx-n24pt {
  margin-right: -1.5rem !important;
}

.mb-n24pt,
.my-n24pt {
  margin-bottom: -1.5rem !important;
}

.ml-n24pt,
.mx-n24pt {
  margin-left: -1.5rem !important;
}

.m-n32pt {
  margin: -2rem !important;
}

.mt-n32pt,
.my-n32pt {
  margin-top: -2rem !important;
}

.mr-n32pt,
.mx-n32pt {
  margin-right: -2rem !important;
}

.mb-n32pt,
.my-n32pt {
  margin-bottom: -2rem !important;
}

.ml-n32pt,
.mx-n32pt {
  margin-left: -2rem !important;
}

.m-n40pt {
  margin: -2.5rem !important;
}

.mt-n40pt,
.my-n40pt {
  margin-top: -2.5rem !important;
}

.mr-n40pt,
.mx-n40pt {
  margin-right: -2.5rem !important;
}

.mb-n40pt,
.my-n40pt {
  margin-bottom: -2.5rem !important;
}

.ml-n40pt,
.mx-n40pt {
  margin-left: -2.5rem !important;
}

.m-n44pt {
  margin: -2.75rem !important;
}

.mt-n44pt,
.my-n44pt {
  margin-top: -2.75rem !important;
}

.mr-n44pt,
.mx-n44pt {
  margin-right: -2.75rem !important;
}

.mb-n44pt,
.my-n44pt {
  margin-bottom: -2.75rem !important;
}

.ml-n44pt,
.mx-n44pt {
  margin-left: -2.75rem !important;
}

.m-n48pt {
  margin: -3rem !important;
}

.mt-n48pt,
.my-n48pt {
  margin-top: -3rem !important;
}

.mr-n48pt,
.mx-n48pt {
  margin-right: -3rem !important;
}

.mb-n48pt,
.my-n48pt {
  margin-bottom: -3rem !important;
}

.ml-n48pt,
.mx-n48pt {
  margin-left: -3rem !important;
}

.m-n64pt {
  margin: -4rem !important;
}

.mt-n64pt,
.my-n64pt {
  margin-top: -4rem !important;
}

.mr-n64pt,
.mx-n64pt {
  margin-right: -4rem !important;
}

.mb-n64pt,
.my-n64pt {
  margin-bottom: -4rem !important;
}

.ml-n64pt,
.mx-n64pt {
  margin-left: -4rem !important;
}

.m-n112pt {
  margin: -7rem !important;
}

.mt-n112pt,
.my-n112pt {
  margin-top: -7rem !important;
}

.mr-n112pt,
.mx-n112pt {
  margin-right: -7rem !important;
}

.mb-n112pt,
.my-n112pt {
  margin-bottom: -7rem !important;
}

.ml-n112pt,
.mx-n112pt {
  margin-left: -7rem !important;
}

.m-n128pt {
  margin: -8rem !important;
}

.mt-n128pt,
.my-n128pt {
  margin-top: -8rem !important;
}

.mr-n128pt,
.mx-n128pt {
  margin-right: -8rem !important;
}

.mb-n128pt,
.my-n128pt {
  margin-bottom: -8rem !important;
}

.ml-n128pt,
.mx-n128pt {
  margin-left: -8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 374px) {
  .m-i8-0 {
    margin: 0 !important;
  }
  .mt-i8-0,
  .my-i8-0 {
    margin-top: 0 !important;
  }
  .mr-i8-0,
  .mx-i8-0 {
    margin-right: 0 !important;
  }
  .mb-i8-0,
  .my-i8-0 {
    margin-bottom: 0 !important;
  }
  .ml-i8-0,
  .mx-i8-0 {
    margin-left: 0 !important;
  }
  .m-i8-1 {
    margin: 0.25rem !important;
  }
  .mt-i8-1,
  .my-i8-1 {
    margin-top: 0.25rem !important;
  }
  .mr-i8-1,
  .mx-i8-1 {
    margin-right: 0.25rem !important;
  }
  .mb-i8-1,
  .my-i8-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-i8-1,
  .mx-i8-1 {
    margin-left: 0.25rem !important;
  }
  .m-i8-2 {
    margin: 0.5rem !important;
  }
  .mt-i8-2,
  .my-i8-2 {
    margin-top: 0.5rem !important;
  }
  .mr-i8-2,
  .mx-i8-2 {
    margin-right: 0.5rem !important;
  }
  .mb-i8-2,
  .my-i8-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-i8-2,
  .mx-i8-2 {
    margin-left: 0.5rem !important;
  }
  .m-i8-3 {
    margin: 1rem !important;
  }
  .mt-i8-3,
  .my-i8-3 {
    margin-top: 1rem !important;
  }
  .mr-i8-3,
  .mx-i8-3 {
    margin-right: 1rem !important;
  }
  .mb-i8-3,
  .my-i8-3 {
    margin-bottom: 1rem !important;
  }
  .ml-i8-3,
  .mx-i8-3 {
    margin-left: 1rem !important;
  }
  .m-i8-4 {
    margin: 1.5rem !important;
  }
  .mt-i8-4,
  .my-i8-4 {
    margin-top: 1.5rem !important;
  }
  .mr-i8-4,
  .mx-i8-4 {
    margin-right: 1.5rem !important;
  }
  .mb-i8-4,
  .my-i8-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-i8-4,
  .mx-i8-4 {
    margin-left: 1.5rem !important;
  }
  .m-i8-5 {
    margin: 3rem !important;
  }
  .mt-i8-5,
  .my-i8-5 {
    margin-top: 3rem !important;
  }
  .mr-i8-5,
  .mx-i8-5 {
    margin-right: 3rem !important;
  }
  .mb-i8-5,
  .my-i8-5 {
    margin-bottom: 3rem !important;
  }
  .ml-i8-5,
  .mx-i8-5 {
    margin-left: 3rem !important;
  }
  .m-i8-4pt {
    margin: 0.25rem !important;
  }
  .mt-i8-4pt,
  .my-i8-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-i8-4pt,
  .mx-i8-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-i8-4pt,
  .my-i8-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-i8-4pt,
  .mx-i8-4pt {
    margin-left: 0.25rem !important;
  }
  .m-i8-8pt {
    margin: 0.5rem !important;
  }
  .mt-i8-8pt,
  .my-i8-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-i8-8pt,
  .mx-i8-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-i8-8pt,
  .my-i8-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-i8-8pt,
  .mx-i8-8pt {
    margin-left: 0.5rem !important;
  }
  .m-i8-12pt {
    margin: 0.75rem !important;
  }
  .mt-i8-12pt,
  .my-i8-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-i8-12pt,
  .mx-i8-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-i8-12pt,
  .my-i8-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-i8-12pt,
  .mx-i8-12pt {
    margin-left: 0.75rem !important;
  }
  .m-i8-16pt {
    margin: 1rem !important;
  }
  .mt-i8-16pt,
  .my-i8-16pt {
    margin-top: 1rem !important;
  }
  .mr-i8-16pt,
  .mx-i8-16pt {
    margin-right: 1rem !important;
  }
  .mb-i8-16pt,
  .my-i8-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-i8-16pt,
  .mx-i8-16pt {
    margin-left: 1rem !important;
  }
  .m-i8-20pt {
    margin: 1.25rem !important;
  }
  .mt-i8-20pt,
  .my-i8-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-i8-20pt,
  .mx-i8-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-i8-20pt,
  .my-i8-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-i8-20pt,
  .mx-i8-20pt {
    margin-left: 1.25rem !important;
  }
  .m-i8-24pt {
    margin: 1.5rem !important;
  }
  .mt-i8-24pt,
  .my-i8-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-i8-24pt,
  .mx-i8-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-i8-24pt,
  .my-i8-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-i8-24pt,
  .mx-i8-24pt {
    margin-left: 1.5rem !important;
  }
  .m-i8-32pt {
    margin: 2rem !important;
  }
  .mt-i8-32pt,
  .my-i8-32pt {
    margin-top: 2rem !important;
  }
  .mr-i8-32pt,
  .mx-i8-32pt {
    margin-right: 2rem !important;
  }
  .mb-i8-32pt,
  .my-i8-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-i8-32pt,
  .mx-i8-32pt {
    margin-left: 2rem !important;
  }
  .m-i8-40pt {
    margin: 2.5rem !important;
  }
  .mt-i8-40pt,
  .my-i8-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-i8-40pt,
  .mx-i8-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-i8-40pt,
  .my-i8-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-i8-40pt,
  .mx-i8-40pt {
    margin-left: 2.5rem !important;
  }
  .m-i8-44pt {
    margin: 2.75rem !important;
  }
  .mt-i8-44pt,
  .my-i8-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-i8-44pt,
  .mx-i8-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-i8-44pt,
  .my-i8-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-i8-44pt,
  .mx-i8-44pt {
    margin-left: 2.75rem !important;
  }
  .m-i8-48pt {
    margin: 3rem !important;
  }
  .mt-i8-48pt,
  .my-i8-48pt {
    margin-top: 3rem !important;
  }
  .mr-i8-48pt,
  .mx-i8-48pt {
    margin-right: 3rem !important;
  }
  .mb-i8-48pt,
  .my-i8-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-i8-48pt,
  .mx-i8-48pt {
    margin-left: 3rem !important;
  }
  .m-i8-64pt {
    margin: 4rem !important;
  }
  .mt-i8-64pt,
  .my-i8-64pt {
    margin-top: 4rem !important;
  }
  .mr-i8-64pt,
  .mx-i8-64pt {
    margin-right: 4rem !important;
  }
  .mb-i8-64pt,
  .my-i8-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-i8-64pt,
  .mx-i8-64pt {
    margin-left: 4rem !important;
  }
  .m-i8-112pt {
    margin: 7rem !important;
  }
  .mt-i8-112pt,
  .my-i8-112pt {
    margin-top: 7rem !important;
  }
  .mr-i8-112pt,
  .mx-i8-112pt {
    margin-right: 7rem !important;
  }
  .mb-i8-112pt,
  .my-i8-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-i8-112pt,
  .mx-i8-112pt {
    margin-left: 7rem !important;
  }
  .m-i8-128pt {
    margin: 8rem !important;
  }
  .mt-i8-128pt,
  .my-i8-128pt {
    margin-top: 8rem !important;
  }
  .mr-i8-128pt,
  .mx-i8-128pt {
    margin-right: 8rem !important;
  }
  .mb-i8-128pt,
  .my-i8-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-i8-128pt,
  .mx-i8-128pt {
    margin-left: 8rem !important;
  }
  .p-i8-0 {
    padding: 0 !important;
  }
  .pt-i8-0,
  .py-i8-0 {
    padding-top: 0 !important;
  }
  .pr-i8-0,
  .px-i8-0 {
    padding-right: 0 !important;
  }
  .pb-i8-0,
  .py-i8-0 {
    padding-bottom: 0 !important;
  }
  .pl-i8-0,
  .px-i8-0 {
    padding-left: 0 !important;
  }
  .p-i8-1 {
    padding: 0.25rem !important;
  }
  .pt-i8-1,
  .py-i8-1 {
    padding-top: 0.25rem !important;
  }
  .pr-i8-1,
  .px-i8-1 {
    padding-right: 0.25rem !important;
  }
  .pb-i8-1,
  .py-i8-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-i8-1,
  .px-i8-1 {
    padding-left: 0.25rem !important;
  }
  .p-i8-2 {
    padding: 0.5rem !important;
  }
  .pt-i8-2,
  .py-i8-2 {
    padding-top: 0.5rem !important;
  }
  .pr-i8-2,
  .px-i8-2 {
    padding-right: 0.5rem !important;
  }
  .pb-i8-2,
  .py-i8-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-i8-2,
  .px-i8-2 {
    padding-left: 0.5rem !important;
  }
  .p-i8-3 {
    padding: 1rem !important;
  }
  .pt-i8-3,
  .py-i8-3 {
    padding-top: 1rem !important;
  }
  .pr-i8-3,
  .px-i8-3 {
    padding-right: 1rem !important;
  }
  .pb-i8-3,
  .py-i8-3 {
    padding-bottom: 1rem !important;
  }
  .pl-i8-3,
  .px-i8-3 {
    padding-left: 1rem !important;
  }
  .p-i8-4 {
    padding: 1.5rem !important;
  }
  .pt-i8-4,
  .py-i8-4 {
    padding-top: 1.5rem !important;
  }
  .pr-i8-4,
  .px-i8-4 {
    padding-right: 1.5rem !important;
  }
  .pb-i8-4,
  .py-i8-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-i8-4,
  .px-i8-4 {
    padding-left: 1.5rem !important;
  }
  .p-i8-5 {
    padding: 3rem !important;
  }
  .pt-i8-5,
  .py-i8-5 {
    padding-top: 3rem !important;
  }
  .pr-i8-5,
  .px-i8-5 {
    padding-right: 3rem !important;
  }
  .pb-i8-5,
  .py-i8-5 {
    padding-bottom: 3rem !important;
  }
  .pl-i8-5,
  .px-i8-5 {
    padding-left: 3rem !important;
  }
  .p-i8-4pt {
    padding: 0.25rem !important;
  }
  .pt-i8-4pt,
  .py-i8-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-i8-4pt,
  .px-i8-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-i8-4pt,
  .py-i8-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-i8-4pt,
  .px-i8-4pt {
    padding-left: 0.25rem !important;
  }
  .p-i8-8pt {
    padding: 0.5rem !important;
  }
  .pt-i8-8pt,
  .py-i8-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-i8-8pt,
  .px-i8-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-i8-8pt,
  .py-i8-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-i8-8pt,
  .px-i8-8pt {
    padding-left: 0.5rem !important;
  }
  .p-i8-12pt {
    padding: 0.75rem !important;
  }
  .pt-i8-12pt,
  .py-i8-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-i8-12pt,
  .px-i8-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-i8-12pt,
  .py-i8-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-i8-12pt,
  .px-i8-12pt {
    padding-left: 0.75rem !important;
  }
  .p-i8-16pt {
    padding: 1rem !important;
  }
  .pt-i8-16pt,
  .py-i8-16pt {
    padding-top: 1rem !important;
  }
  .pr-i8-16pt,
  .px-i8-16pt {
    padding-right: 1rem !important;
  }
  .pb-i8-16pt,
  .py-i8-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-i8-16pt,
  .px-i8-16pt {
    padding-left: 1rem !important;
  }
  .p-i8-20pt {
    padding: 1.25rem !important;
  }
  .pt-i8-20pt,
  .py-i8-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-i8-20pt,
  .px-i8-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-i8-20pt,
  .py-i8-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-i8-20pt,
  .px-i8-20pt {
    padding-left: 1.25rem !important;
  }
  .p-i8-24pt {
    padding: 1.5rem !important;
  }
  .pt-i8-24pt,
  .py-i8-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-i8-24pt,
  .px-i8-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-i8-24pt,
  .py-i8-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-i8-24pt,
  .px-i8-24pt {
    padding-left: 1.5rem !important;
  }
  .p-i8-32pt {
    padding: 2rem !important;
  }
  .pt-i8-32pt,
  .py-i8-32pt {
    padding-top: 2rem !important;
  }
  .pr-i8-32pt,
  .px-i8-32pt {
    padding-right: 2rem !important;
  }
  .pb-i8-32pt,
  .py-i8-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-i8-32pt,
  .px-i8-32pt {
    padding-left: 2rem !important;
  }
  .p-i8-40pt {
    padding: 2.5rem !important;
  }
  .pt-i8-40pt,
  .py-i8-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-i8-40pt,
  .px-i8-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-i8-40pt,
  .py-i8-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-i8-40pt,
  .px-i8-40pt {
    padding-left: 2.5rem !important;
  }
  .p-i8-44pt {
    padding: 2.75rem !important;
  }
  .pt-i8-44pt,
  .py-i8-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-i8-44pt,
  .px-i8-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-i8-44pt,
  .py-i8-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-i8-44pt,
  .px-i8-44pt {
    padding-left: 2.75rem !important;
  }
  .p-i8-48pt {
    padding: 3rem !important;
  }
  .pt-i8-48pt,
  .py-i8-48pt {
    padding-top: 3rem !important;
  }
  .pr-i8-48pt,
  .px-i8-48pt {
    padding-right: 3rem !important;
  }
  .pb-i8-48pt,
  .py-i8-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-i8-48pt,
  .px-i8-48pt {
    padding-left: 3rem !important;
  }
  .p-i8-64pt {
    padding: 4rem !important;
  }
  .pt-i8-64pt,
  .py-i8-64pt {
    padding-top: 4rem !important;
  }
  .pr-i8-64pt,
  .px-i8-64pt {
    padding-right: 4rem !important;
  }
  .pb-i8-64pt,
  .py-i8-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-i8-64pt,
  .px-i8-64pt {
    padding-left: 4rem !important;
  }
  .p-i8-112pt {
    padding: 7rem !important;
  }
  .pt-i8-112pt,
  .py-i8-112pt {
    padding-top: 7rem !important;
  }
  .pr-i8-112pt,
  .px-i8-112pt {
    padding-right: 7rem !important;
  }
  .pb-i8-112pt,
  .py-i8-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-i8-112pt,
  .px-i8-112pt {
    padding-left: 7rem !important;
  }
  .p-i8-128pt {
    padding: 8rem !important;
  }
  .pt-i8-128pt,
  .py-i8-128pt {
    padding-top: 8rem !important;
  }
  .pr-i8-128pt,
  .px-i8-128pt {
    padding-right: 8rem !important;
  }
  .pb-i8-128pt,
  .py-i8-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-i8-128pt,
  .px-i8-128pt {
    padding-left: 8rem !important;
  }
  .m-i8-n1 {
    margin: -0.25rem !important;
  }
  .mt-i8-n1,
  .my-i8-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-i8-n1,
  .mx-i8-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-i8-n1,
  .my-i8-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-i8-n1,
  .mx-i8-n1 {
    margin-left: -0.25rem !important;
  }
  .m-i8-n2 {
    margin: -0.5rem !important;
  }
  .mt-i8-n2,
  .my-i8-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-i8-n2,
  .mx-i8-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-i8-n2,
  .my-i8-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-i8-n2,
  .mx-i8-n2 {
    margin-left: -0.5rem !important;
  }
  .m-i8-n3 {
    margin: -1rem !important;
  }
  .mt-i8-n3,
  .my-i8-n3 {
    margin-top: -1rem !important;
  }
  .mr-i8-n3,
  .mx-i8-n3 {
    margin-right: -1rem !important;
  }
  .mb-i8-n3,
  .my-i8-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-i8-n3,
  .mx-i8-n3 {
    margin-left: -1rem !important;
  }
  .m-i8-n4 {
    margin: -1.5rem !important;
  }
  .mt-i8-n4,
  .my-i8-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-i8-n4,
  .mx-i8-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-i8-n4,
  .my-i8-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-i8-n4,
  .mx-i8-n4 {
    margin-left: -1.5rem !important;
  }
  .m-i8-n5 {
    margin: -3rem !important;
  }
  .mt-i8-n5,
  .my-i8-n5 {
    margin-top: -3rem !important;
  }
  .mr-i8-n5,
  .mx-i8-n5 {
    margin-right: -3rem !important;
  }
  .mb-i8-n5,
  .my-i8-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-i8-n5,
  .mx-i8-n5 {
    margin-left: -3rem !important;
  }
  .m-i8-n4pt {
    margin: -0.25rem !important;
  }
  .mt-i8-n4pt,
  .my-i8-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-i8-n4pt,
  .mx-i8-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-i8-n4pt,
  .my-i8-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-i8-n4pt,
  .mx-i8-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-i8-n8pt {
    margin: -0.5rem !important;
  }
  .mt-i8-n8pt,
  .my-i8-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-i8-n8pt,
  .mx-i8-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-i8-n8pt,
  .my-i8-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-i8-n8pt,
  .mx-i8-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-i8-n12pt {
    margin: -0.75rem !important;
  }
  .mt-i8-n12pt,
  .my-i8-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-i8-n12pt,
  .mx-i8-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-i8-n12pt,
  .my-i8-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-i8-n12pt,
  .mx-i8-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-i8-n16pt {
    margin: -1rem !important;
  }
  .mt-i8-n16pt,
  .my-i8-n16pt {
    margin-top: -1rem !important;
  }
  .mr-i8-n16pt,
  .mx-i8-n16pt {
    margin-right: -1rem !important;
  }
  .mb-i8-n16pt,
  .my-i8-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-i8-n16pt,
  .mx-i8-n16pt {
    margin-left: -1rem !important;
  }
  .m-i8-n20pt {
    margin: -1.25rem !important;
  }
  .mt-i8-n20pt,
  .my-i8-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-i8-n20pt,
  .mx-i8-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-i8-n20pt,
  .my-i8-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-i8-n20pt,
  .mx-i8-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-i8-n24pt {
    margin: -1.5rem !important;
  }
  .mt-i8-n24pt,
  .my-i8-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-i8-n24pt,
  .mx-i8-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-i8-n24pt,
  .my-i8-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-i8-n24pt,
  .mx-i8-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-i8-n32pt {
    margin: -2rem !important;
  }
  .mt-i8-n32pt,
  .my-i8-n32pt {
    margin-top: -2rem !important;
  }
  .mr-i8-n32pt,
  .mx-i8-n32pt {
    margin-right: -2rem !important;
  }
  .mb-i8-n32pt,
  .my-i8-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-i8-n32pt,
  .mx-i8-n32pt {
    margin-left: -2rem !important;
  }
  .m-i8-n40pt {
    margin: -2.5rem !important;
  }
  .mt-i8-n40pt,
  .my-i8-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-i8-n40pt,
  .mx-i8-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-i8-n40pt,
  .my-i8-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-i8-n40pt,
  .mx-i8-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-i8-n44pt {
    margin: -2.75rem !important;
  }
  .mt-i8-n44pt,
  .my-i8-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-i8-n44pt,
  .mx-i8-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-i8-n44pt,
  .my-i8-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-i8-n44pt,
  .mx-i8-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-i8-n48pt {
    margin: -3rem !important;
  }
  .mt-i8-n48pt,
  .my-i8-n48pt {
    margin-top: -3rem !important;
  }
  .mr-i8-n48pt,
  .mx-i8-n48pt {
    margin-right: -3rem !important;
  }
  .mb-i8-n48pt,
  .my-i8-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-i8-n48pt,
  .mx-i8-n48pt {
    margin-left: -3rem !important;
  }
  .m-i8-n64pt {
    margin: -4rem !important;
  }
  .mt-i8-n64pt,
  .my-i8-n64pt {
    margin-top: -4rem !important;
  }
  .mr-i8-n64pt,
  .mx-i8-n64pt {
    margin-right: -4rem !important;
  }
  .mb-i8-n64pt,
  .my-i8-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-i8-n64pt,
  .mx-i8-n64pt {
    margin-left: -4rem !important;
  }
  .m-i8-n112pt {
    margin: -7rem !important;
  }
  .mt-i8-n112pt,
  .my-i8-n112pt {
    margin-top: -7rem !important;
  }
  .mr-i8-n112pt,
  .mx-i8-n112pt {
    margin-right: -7rem !important;
  }
  .mb-i8-n112pt,
  .my-i8-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-i8-n112pt,
  .mx-i8-n112pt {
    margin-left: -7rem !important;
  }
  .m-i8-n128pt {
    margin: -8rem !important;
  }
  .mt-i8-n128pt,
  .my-i8-n128pt {
    margin-top: -8rem !important;
  }
  .mr-i8-n128pt,
  .mx-i8-n128pt {
    margin-right: -8rem !important;
  }
  .mb-i8-n128pt,
  .my-i8-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-i8-n128pt,
  .mx-i8-n128pt {
    margin-left: -8rem !important;
  }
  .m-i8-auto {
    margin: auto !important;
  }
  .mt-i8-auto,
  .my-i8-auto {
    margin-top: auto !important;
  }
  .mr-i8-auto,
  .mx-i8-auto {
    margin-right: auto !important;
  }
  .mb-i8-auto,
  .my-i8-auto {
    margin-bottom: auto !important;
  }
  .ml-i8-auto,
  .mx-i8-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 413px) {
  .m-i8-plus-0 {
    margin: 0 !important;
  }
  .mt-i8-plus-0,
  .my-i8-plus-0 {
    margin-top: 0 !important;
  }
  .mr-i8-plus-0,
  .mx-i8-plus-0 {
    margin-right: 0 !important;
  }
  .mb-i8-plus-0,
  .my-i8-plus-0 {
    margin-bottom: 0 !important;
  }
  .ml-i8-plus-0,
  .mx-i8-plus-0 {
    margin-left: 0 !important;
  }
  .m-i8-plus-1 {
    margin: 0.25rem !important;
  }
  .mt-i8-plus-1,
  .my-i8-plus-1 {
    margin-top: 0.25rem !important;
  }
  .mr-i8-plus-1,
  .mx-i8-plus-1 {
    margin-right: 0.25rem !important;
  }
  .mb-i8-plus-1,
  .my-i8-plus-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-i8-plus-1,
  .mx-i8-plus-1 {
    margin-left: 0.25rem !important;
  }
  .m-i8-plus-2 {
    margin: 0.5rem !important;
  }
  .mt-i8-plus-2,
  .my-i8-plus-2 {
    margin-top: 0.5rem !important;
  }
  .mr-i8-plus-2,
  .mx-i8-plus-2 {
    margin-right: 0.5rem !important;
  }
  .mb-i8-plus-2,
  .my-i8-plus-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-i8-plus-2,
  .mx-i8-plus-2 {
    margin-left: 0.5rem !important;
  }
  .m-i8-plus-3 {
    margin: 1rem !important;
  }
  .mt-i8-plus-3,
  .my-i8-plus-3 {
    margin-top: 1rem !important;
  }
  .mr-i8-plus-3,
  .mx-i8-plus-3 {
    margin-right: 1rem !important;
  }
  .mb-i8-plus-3,
  .my-i8-plus-3 {
    margin-bottom: 1rem !important;
  }
  .ml-i8-plus-3,
  .mx-i8-plus-3 {
    margin-left: 1rem !important;
  }
  .m-i8-plus-4 {
    margin: 1.5rem !important;
  }
  .mt-i8-plus-4,
  .my-i8-plus-4 {
    margin-top: 1.5rem !important;
  }
  .mr-i8-plus-4,
  .mx-i8-plus-4 {
    margin-right: 1.5rem !important;
  }
  .mb-i8-plus-4,
  .my-i8-plus-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-i8-plus-4,
  .mx-i8-plus-4 {
    margin-left: 1.5rem !important;
  }
  .m-i8-plus-5 {
    margin: 3rem !important;
  }
  .mt-i8-plus-5,
  .my-i8-plus-5 {
    margin-top: 3rem !important;
  }
  .mr-i8-plus-5,
  .mx-i8-plus-5 {
    margin-right: 3rem !important;
  }
  .mb-i8-plus-5,
  .my-i8-plus-5 {
    margin-bottom: 3rem !important;
  }
  .ml-i8-plus-5,
  .mx-i8-plus-5 {
    margin-left: 3rem !important;
  }
  .m-i8-plus-4pt {
    margin: 0.25rem !important;
  }
  .mt-i8-plus-4pt,
  .my-i8-plus-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-i8-plus-4pt,
  .mx-i8-plus-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-i8-plus-4pt,
  .my-i8-plus-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-i8-plus-4pt,
  .mx-i8-plus-4pt {
    margin-left: 0.25rem !important;
  }
  .m-i8-plus-8pt {
    margin: 0.5rem !important;
  }
  .mt-i8-plus-8pt,
  .my-i8-plus-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-i8-plus-8pt,
  .mx-i8-plus-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-i8-plus-8pt,
  .my-i8-plus-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-i8-plus-8pt,
  .mx-i8-plus-8pt {
    margin-left: 0.5rem !important;
  }
  .m-i8-plus-12pt {
    margin: 0.75rem !important;
  }
  .mt-i8-plus-12pt,
  .my-i8-plus-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-i8-plus-12pt,
  .mx-i8-plus-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-i8-plus-12pt,
  .my-i8-plus-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-i8-plus-12pt,
  .mx-i8-plus-12pt {
    margin-left: 0.75rem !important;
  }
  .m-i8-plus-16pt {
    margin: 1rem !important;
  }
  .mt-i8-plus-16pt,
  .my-i8-plus-16pt {
    margin-top: 1rem !important;
  }
  .mr-i8-plus-16pt,
  .mx-i8-plus-16pt {
    margin-right: 1rem !important;
  }
  .mb-i8-plus-16pt,
  .my-i8-plus-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-i8-plus-16pt,
  .mx-i8-plus-16pt {
    margin-left: 1rem !important;
  }
  .m-i8-plus-20pt {
    margin: 1.25rem !important;
  }
  .mt-i8-plus-20pt,
  .my-i8-plus-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-i8-plus-20pt,
  .mx-i8-plus-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-i8-plus-20pt,
  .my-i8-plus-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-i8-plus-20pt,
  .mx-i8-plus-20pt {
    margin-left: 1.25rem !important;
  }
  .m-i8-plus-24pt {
    margin: 1.5rem !important;
  }
  .mt-i8-plus-24pt,
  .my-i8-plus-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-i8-plus-24pt,
  .mx-i8-plus-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-i8-plus-24pt,
  .my-i8-plus-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-i8-plus-24pt,
  .mx-i8-plus-24pt {
    margin-left: 1.5rem !important;
  }
  .m-i8-plus-32pt {
    margin: 2rem !important;
  }
  .mt-i8-plus-32pt,
  .my-i8-plus-32pt {
    margin-top: 2rem !important;
  }
  .mr-i8-plus-32pt,
  .mx-i8-plus-32pt {
    margin-right: 2rem !important;
  }
  .mb-i8-plus-32pt,
  .my-i8-plus-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-i8-plus-32pt,
  .mx-i8-plus-32pt {
    margin-left: 2rem !important;
  }
  .m-i8-plus-40pt {
    margin: 2.5rem !important;
  }
  .mt-i8-plus-40pt,
  .my-i8-plus-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-i8-plus-40pt,
  .mx-i8-plus-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-i8-plus-40pt,
  .my-i8-plus-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-i8-plus-40pt,
  .mx-i8-plus-40pt {
    margin-left: 2.5rem !important;
  }
  .m-i8-plus-44pt {
    margin: 2.75rem !important;
  }
  .mt-i8-plus-44pt,
  .my-i8-plus-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-i8-plus-44pt,
  .mx-i8-plus-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-i8-plus-44pt,
  .my-i8-plus-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-i8-plus-44pt,
  .mx-i8-plus-44pt {
    margin-left: 2.75rem !important;
  }
  .m-i8-plus-48pt {
    margin: 3rem !important;
  }
  .mt-i8-plus-48pt,
  .my-i8-plus-48pt {
    margin-top: 3rem !important;
  }
  .mr-i8-plus-48pt,
  .mx-i8-plus-48pt {
    margin-right: 3rem !important;
  }
  .mb-i8-plus-48pt,
  .my-i8-plus-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-i8-plus-48pt,
  .mx-i8-plus-48pt {
    margin-left: 3rem !important;
  }
  .m-i8-plus-64pt {
    margin: 4rem !important;
  }
  .mt-i8-plus-64pt,
  .my-i8-plus-64pt {
    margin-top: 4rem !important;
  }
  .mr-i8-plus-64pt,
  .mx-i8-plus-64pt {
    margin-right: 4rem !important;
  }
  .mb-i8-plus-64pt,
  .my-i8-plus-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-i8-plus-64pt,
  .mx-i8-plus-64pt {
    margin-left: 4rem !important;
  }
  .m-i8-plus-112pt {
    margin: 7rem !important;
  }
  .mt-i8-plus-112pt,
  .my-i8-plus-112pt {
    margin-top: 7rem !important;
  }
  .mr-i8-plus-112pt,
  .mx-i8-plus-112pt {
    margin-right: 7rem !important;
  }
  .mb-i8-plus-112pt,
  .my-i8-plus-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-i8-plus-112pt,
  .mx-i8-plus-112pt {
    margin-left: 7rem !important;
  }
  .m-i8-plus-128pt {
    margin: 8rem !important;
  }
  .mt-i8-plus-128pt,
  .my-i8-plus-128pt {
    margin-top: 8rem !important;
  }
  .mr-i8-plus-128pt,
  .mx-i8-plus-128pt {
    margin-right: 8rem !important;
  }
  .mb-i8-plus-128pt,
  .my-i8-plus-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-i8-plus-128pt,
  .mx-i8-plus-128pt {
    margin-left: 8rem !important;
  }
  .p-i8-plus-0 {
    padding: 0 !important;
  }
  .pt-i8-plus-0,
  .py-i8-plus-0 {
    padding-top: 0 !important;
  }
  .pr-i8-plus-0,
  .px-i8-plus-0 {
    padding-right: 0 !important;
  }
  .pb-i8-plus-0,
  .py-i8-plus-0 {
    padding-bottom: 0 !important;
  }
  .pl-i8-plus-0,
  .px-i8-plus-0 {
    padding-left: 0 !important;
  }
  .p-i8-plus-1 {
    padding: 0.25rem !important;
  }
  .pt-i8-plus-1,
  .py-i8-plus-1 {
    padding-top: 0.25rem !important;
  }
  .pr-i8-plus-1,
  .px-i8-plus-1 {
    padding-right: 0.25rem !important;
  }
  .pb-i8-plus-1,
  .py-i8-plus-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-i8-plus-1,
  .px-i8-plus-1 {
    padding-left: 0.25rem !important;
  }
  .p-i8-plus-2 {
    padding: 0.5rem !important;
  }
  .pt-i8-plus-2,
  .py-i8-plus-2 {
    padding-top: 0.5rem !important;
  }
  .pr-i8-plus-2,
  .px-i8-plus-2 {
    padding-right: 0.5rem !important;
  }
  .pb-i8-plus-2,
  .py-i8-plus-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-i8-plus-2,
  .px-i8-plus-2 {
    padding-left: 0.5rem !important;
  }
  .p-i8-plus-3 {
    padding: 1rem !important;
  }
  .pt-i8-plus-3,
  .py-i8-plus-3 {
    padding-top: 1rem !important;
  }
  .pr-i8-plus-3,
  .px-i8-plus-3 {
    padding-right: 1rem !important;
  }
  .pb-i8-plus-3,
  .py-i8-plus-3 {
    padding-bottom: 1rem !important;
  }
  .pl-i8-plus-3,
  .px-i8-plus-3 {
    padding-left: 1rem !important;
  }
  .p-i8-plus-4 {
    padding: 1.5rem !important;
  }
  .pt-i8-plus-4,
  .py-i8-plus-4 {
    padding-top: 1.5rem !important;
  }
  .pr-i8-plus-4,
  .px-i8-plus-4 {
    padding-right: 1.5rem !important;
  }
  .pb-i8-plus-4,
  .py-i8-plus-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-i8-plus-4,
  .px-i8-plus-4 {
    padding-left: 1.5rem !important;
  }
  .p-i8-plus-5 {
    padding: 3rem !important;
  }
  .pt-i8-plus-5,
  .py-i8-plus-5 {
    padding-top: 3rem !important;
  }
  .pr-i8-plus-5,
  .px-i8-plus-5 {
    padding-right: 3rem !important;
  }
  .pb-i8-plus-5,
  .py-i8-plus-5 {
    padding-bottom: 3rem !important;
  }
  .pl-i8-plus-5,
  .px-i8-plus-5 {
    padding-left: 3rem !important;
  }
  .p-i8-plus-4pt {
    padding: 0.25rem !important;
  }
  .pt-i8-plus-4pt,
  .py-i8-plus-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-i8-plus-4pt,
  .px-i8-plus-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-i8-plus-4pt,
  .py-i8-plus-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-i8-plus-4pt,
  .px-i8-plus-4pt {
    padding-left: 0.25rem !important;
  }
  .p-i8-plus-8pt {
    padding: 0.5rem !important;
  }
  .pt-i8-plus-8pt,
  .py-i8-plus-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-i8-plus-8pt,
  .px-i8-plus-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-i8-plus-8pt,
  .py-i8-plus-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-i8-plus-8pt,
  .px-i8-plus-8pt {
    padding-left: 0.5rem !important;
  }
  .p-i8-plus-12pt {
    padding: 0.75rem !important;
  }
  .pt-i8-plus-12pt,
  .py-i8-plus-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-i8-plus-12pt,
  .px-i8-plus-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-i8-plus-12pt,
  .py-i8-plus-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-i8-plus-12pt,
  .px-i8-plus-12pt {
    padding-left: 0.75rem !important;
  }
  .p-i8-plus-16pt {
    padding: 1rem !important;
  }
  .pt-i8-plus-16pt,
  .py-i8-plus-16pt {
    padding-top: 1rem !important;
  }
  .pr-i8-plus-16pt,
  .px-i8-plus-16pt {
    padding-right: 1rem !important;
  }
  .pb-i8-plus-16pt,
  .py-i8-plus-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-i8-plus-16pt,
  .px-i8-plus-16pt {
    padding-left: 1rem !important;
  }
  .p-i8-plus-20pt {
    padding: 1.25rem !important;
  }
  .pt-i8-plus-20pt,
  .py-i8-plus-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-i8-plus-20pt,
  .px-i8-plus-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-i8-plus-20pt,
  .py-i8-plus-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-i8-plus-20pt,
  .px-i8-plus-20pt {
    padding-left: 1.25rem !important;
  }
  .p-i8-plus-24pt {
    padding: 1.5rem !important;
  }
  .pt-i8-plus-24pt,
  .py-i8-plus-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-i8-plus-24pt,
  .px-i8-plus-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-i8-plus-24pt,
  .py-i8-plus-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-i8-plus-24pt,
  .px-i8-plus-24pt {
    padding-left: 1.5rem !important;
  }
  .p-i8-plus-32pt {
    padding: 2rem !important;
  }
  .pt-i8-plus-32pt,
  .py-i8-plus-32pt {
    padding-top: 2rem !important;
  }
  .pr-i8-plus-32pt,
  .px-i8-plus-32pt {
    padding-right: 2rem !important;
  }
  .pb-i8-plus-32pt,
  .py-i8-plus-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-i8-plus-32pt,
  .px-i8-plus-32pt {
    padding-left: 2rem !important;
  }
  .p-i8-plus-40pt {
    padding: 2.5rem !important;
  }
  .pt-i8-plus-40pt,
  .py-i8-plus-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-i8-plus-40pt,
  .px-i8-plus-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-i8-plus-40pt,
  .py-i8-plus-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-i8-plus-40pt,
  .px-i8-plus-40pt {
    padding-left: 2.5rem !important;
  }
  .p-i8-plus-44pt {
    padding: 2.75rem !important;
  }
  .pt-i8-plus-44pt,
  .py-i8-plus-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-i8-plus-44pt,
  .px-i8-plus-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-i8-plus-44pt,
  .py-i8-plus-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-i8-plus-44pt,
  .px-i8-plus-44pt {
    padding-left: 2.75rem !important;
  }
  .p-i8-plus-48pt {
    padding: 3rem !important;
  }
  .pt-i8-plus-48pt,
  .py-i8-plus-48pt {
    padding-top: 3rem !important;
  }
  .pr-i8-plus-48pt,
  .px-i8-plus-48pt {
    padding-right: 3rem !important;
  }
  .pb-i8-plus-48pt,
  .py-i8-plus-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-i8-plus-48pt,
  .px-i8-plus-48pt {
    padding-left: 3rem !important;
  }
  .p-i8-plus-64pt {
    padding: 4rem !important;
  }
  .pt-i8-plus-64pt,
  .py-i8-plus-64pt {
    padding-top: 4rem !important;
  }
  .pr-i8-plus-64pt,
  .px-i8-plus-64pt {
    padding-right: 4rem !important;
  }
  .pb-i8-plus-64pt,
  .py-i8-plus-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-i8-plus-64pt,
  .px-i8-plus-64pt {
    padding-left: 4rem !important;
  }
  .p-i8-plus-112pt {
    padding: 7rem !important;
  }
  .pt-i8-plus-112pt,
  .py-i8-plus-112pt {
    padding-top: 7rem !important;
  }
  .pr-i8-plus-112pt,
  .px-i8-plus-112pt {
    padding-right: 7rem !important;
  }
  .pb-i8-plus-112pt,
  .py-i8-plus-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-i8-plus-112pt,
  .px-i8-plus-112pt {
    padding-left: 7rem !important;
  }
  .p-i8-plus-128pt {
    padding: 8rem !important;
  }
  .pt-i8-plus-128pt,
  .py-i8-plus-128pt {
    padding-top: 8rem !important;
  }
  .pr-i8-plus-128pt,
  .px-i8-plus-128pt {
    padding-right: 8rem !important;
  }
  .pb-i8-plus-128pt,
  .py-i8-plus-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-i8-plus-128pt,
  .px-i8-plus-128pt {
    padding-left: 8rem !important;
  }
  .m-i8-plus-n1 {
    margin: -0.25rem !important;
  }
  .mt-i8-plus-n1,
  .my-i8-plus-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-i8-plus-n1,
  .mx-i8-plus-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-i8-plus-n1,
  .my-i8-plus-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-i8-plus-n1,
  .mx-i8-plus-n1 {
    margin-left: -0.25rem !important;
  }
  .m-i8-plus-n2 {
    margin: -0.5rem !important;
  }
  .mt-i8-plus-n2,
  .my-i8-plus-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-i8-plus-n2,
  .mx-i8-plus-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-i8-plus-n2,
  .my-i8-plus-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-i8-plus-n2,
  .mx-i8-plus-n2 {
    margin-left: -0.5rem !important;
  }
  .m-i8-plus-n3 {
    margin: -1rem !important;
  }
  .mt-i8-plus-n3,
  .my-i8-plus-n3 {
    margin-top: -1rem !important;
  }
  .mr-i8-plus-n3,
  .mx-i8-plus-n3 {
    margin-right: -1rem !important;
  }
  .mb-i8-plus-n3,
  .my-i8-plus-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-i8-plus-n3,
  .mx-i8-plus-n3 {
    margin-left: -1rem !important;
  }
  .m-i8-plus-n4 {
    margin: -1.5rem !important;
  }
  .mt-i8-plus-n4,
  .my-i8-plus-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-i8-plus-n4,
  .mx-i8-plus-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-i8-plus-n4,
  .my-i8-plus-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-i8-plus-n4,
  .mx-i8-plus-n4 {
    margin-left: -1.5rem !important;
  }
  .m-i8-plus-n5 {
    margin: -3rem !important;
  }
  .mt-i8-plus-n5,
  .my-i8-plus-n5 {
    margin-top: -3rem !important;
  }
  .mr-i8-plus-n5,
  .mx-i8-plus-n5 {
    margin-right: -3rem !important;
  }
  .mb-i8-plus-n5,
  .my-i8-plus-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-i8-plus-n5,
  .mx-i8-plus-n5 {
    margin-left: -3rem !important;
  }
  .m-i8-plus-n4pt {
    margin: -0.25rem !important;
  }
  .mt-i8-plus-n4pt,
  .my-i8-plus-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-i8-plus-n4pt,
  .mx-i8-plus-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-i8-plus-n4pt,
  .my-i8-plus-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-i8-plus-n4pt,
  .mx-i8-plus-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-i8-plus-n8pt {
    margin: -0.5rem !important;
  }
  .mt-i8-plus-n8pt,
  .my-i8-plus-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-i8-plus-n8pt,
  .mx-i8-plus-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-i8-plus-n8pt,
  .my-i8-plus-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-i8-plus-n8pt,
  .mx-i8-plus-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-i8-plus-n12pt {
    margin: -0.75rem !important;
  }
  .mt-i8-plus-n12pt,
  .my-i8-plus-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-i8-plus-n12pt,
  .mx-i8-plus-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-i8-plus-n12pt,
  .my-i8-plus-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-i8-plus-n12pt,
  .mx-i8-plus-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-i8-plus-n16pt {
    margin: -1rem !important;
  }
  .mt-i8-plus-n16pt,
  .my-i8-plus-n16pt {
    margin-top: -1rem !important;
  }
  .mr-i8-plus-n16pt,
  .mx-i8-plus-n16pt {
    margin-right: -1rem !important;
  }
  .mb-i8-plus-n16pt,
  .my-i8-plus-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-i8-plus-n16pt,
  .mx-i8-plus-n16pt {
    margin-left: -1rem !important;
  }
  .m-i8-plus-n20pt {
    margin: -1.25rem !important;
  }
  .mt-i8-plus-n20pt,
  .my-i8-plus-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-i8-plus-n20pt,
  .mx-i8-plus-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-i8-plus-n20pt,
  .my-i8-plus-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-i8-plus-n20pt,
  .mx-i8-plus-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-i8-plus-n24pt {
    margin: -1.5rem !important;
  }
  .mt-i8-plus-n24pt,
  .my-i8-plus-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-i8-plus-n24pt,
  .mx-i8-plus-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-i8-plus-n24pt,
  .my-i8-plus-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-i8-plus-n24pt,
  .mx-i8-plus-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-i8-plus-n32pt {
    margin: -2rem !important;
  }
  .mt-i8-plus-n32pt,
  .my-i8-plus-n32pt {
    margin-top: -2rem !important;
  }
  .mr-i8-plus-n32pt,
  .mx-i8-plus-n32pt {
    margin-right: -2rem !important;
  }
  .mb-i8-plus-n32pt,
  .my-i8-plus-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-i8-plus-n32pt,
  .mx-i8-plus-n32pt {
    margin-left: -2rem !important;
  }
  .m-i8-plus-n40pt {
    margin: -2.5rem !important;
  }
  .mt-i8-plus-n40pt,
  .my-i8-plus-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-i8-plus-n40pt,
  .mx-i8-plus-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-i8-plus-n40pt,
  .my-i8-plus-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-i8-plus-n40pt,
  .mx-i8-plus-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-i8-plus-n44pt {
    margin: -2.75rem !important;
  }
  .mt-i8-plus-n44pt,
  .my-i8-plus-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-i8-plus-n44pt,
  .mx-i8-plus-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-i8-plus-n44pt,
  .my-i8-plus-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-i8-plus-n44pt,
  .mx-i8-plus-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-i8-plus-n48pt {
    margin: -3rem !important;
  }
  .mt-i8-plus-n48pt,
  .my-i8-plus-n48pt {
    margin-top: -3rem !important;
  }
  .mr-i8-plus-n48pt,
  .mx-i8-plus-n48pt {
    margin-right: -3rem !important;
  }
  .mb-i8-plus-n48pt,
  .my-i8-plus-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-i8-plus-n48pt,
  .mx-i8-plus-n48pt {
    margin-left: -3rem !important;
  }
  .m-i8-plus-n64pt {
    margin: -4rem !important;
  }
  .mt-i8-plus-n64pt,
  .my-i8-plus-n64pt {
    margin-top: -4rem !important;
  }
  .mr-i8-plus-n64pt,
  .mx-i8-plus-n64pt {
    margin-right: -4rem !important;
  }
  .mb-i8-plus-n64pt,
  .my-i8-plus-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-i8-plus-n64pt,
  .mx-i8-plus-n64pt {
    margin-left: -4rem !important;
  }
  .m-i8-plus-n112pt {
    margin: -7rem !important;
  }
  .mt-i8-plus-n112pt,
  .my-i8-plus-n112pt {
    margin-top: -7rem !important;
  }
  .mr-i8-plus-n112pt,
  .mx-i8-plus-n112pt {
    margin-right: -7rem !important;
  }
  .mb-i8-plus-n112pt,
  .my-i8-plus-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-i8-plus-n112pt,
  .mx-i8-plus-n112pt {
    margin-left: -7rem !important;
  }
  .m-i8-plus-n128pt {
    margin: -8rem !important;
  }
  .mt-i8-plus-n128pt,
  .my-i8-plus-n128pt {
    margin-top: -8rem !important;
  }
  .mr-i8-plus-n128pt,
  .mx-i8-plus-n128pt {
    margin-right: -8rem !important;
  }
  .mb-i8-plus-n128pt,
  .my-i8-plus-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-i8-plus-n128pt,
  .mx-i8-plus-n128pt {
    margin-left: -8rem !important;
  }
  .m-i8-plus-auto {
    margin: auto !important;
  }
  .mt-i8-plus-auto,
  .my-i8-plus-auto {
    margin-top: auto !important;
  }
  .mr-i8-plus-auto,
  .mx-i8-plus-auto {
    margin-right: auto !important;
  }
  .mb-i8-plus-auto,
  .my-i8-plus-auto {
    margin-bottom: auto !important;
  }
  .ml-i8-plus-auto,
  .mx-i8-plus-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-4pt {
    margin: 0.25rem !important;
  }
  .mt-sm-4pt,
  .my-sm-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-sm-4pt,
  .mx-sm-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-sm-4pt,
  .my-sm-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-4pt,
  .mx-sm-4pt {
    margin-left: 0.25rem !important;
  }
  .m-sm-8pt {
    margin: 0.5rem !important;
  }
  .mt-sm-8pt,
  .my-sm-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-sm-8pt,
  .mx-sm-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-sm-8pt,
  .my-sm-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-8pt,
  .mx-sm-8pt {
    margin-left: 0.5rem !important;
  }
  .m-sm-12pt {
    margin: 0.75rem !important;
  }
  .mt-sm-12pt,
  .my-sm-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-sm-12pt,
  .mx-sm-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-sm-12pt,
  .my-sm-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-12pt,
  .mx-sm-12pt {
    margin-left: 0.75rem !important;
  }
  .m-sm-16pt {
    margin: 1rem !important;
  }
  .mt-sm-16pt,
  .my-sm-16pt {
    margin-top: 1rem !important;
  }
  .mr-sm-16pt,
  .mx-sm-16pt {
    margin-right: 1rem !important;
  }
  .mb-sm-16pt,
  .my-sm-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-sm-16pt,
  .mx-sm-16pt {
    margin-left: 1rem !important;
  }
  .m-sm-20pt {
    margin: 1.25rem !important;
  }
  .mt-sm-20pt,
  .my-sm-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-sm-20pt,
  .mx-sm-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-sm-20pt,
  .my-sm-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-20pt,
  .mx-sm-20pt {
    margin-left: 1.25rem !important;
  }
  .m-sm-24pt {
    margin: 1.5rem !important;
  }
  .mt-sm-24pt,
  .my-sm-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-sm-24pt,
  .mx-sm-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-sm-24pt,
  .my-sm-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-24pt,
  .mx-sm-24pt {
    margin-left: 1.5rem !important;
  }
  .m-sm-32pt {
    margin: 2rem !important;
  }
  .mt-sm-32pt,
  .my-sm-32pt {
    margin-top: 2rem !important;
  }
  .mr-sm-32pt,
  .mx-sm-32pt {
    margin-right: 2rem !important;
  }
  .mb-sm-32pt,
  .my-sm-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-sm-32pt,
  .mx-sm-32pt {
    margin-left: 2rem !important;
  }
  .m-sm-40pt {
    margin: 2.5rem !important;
  }
  .mt-sm-40pt,
  .my-sm-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-sm-40pt,
  .mx-sm-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-sm-40pt,
  .my-sm-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-40pt,
  .mx-sm-40pt {
    margin-left: 2.5rem !important;
  }
  .m-sm-44pt {
    margin: 2.75rem !important;
  }
  .mt-sm-44pt,
  .my-sm-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-sm-44pt,
  .mx-sm-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-sm-44pt,
  .my-sm-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-sm-44pt,
  .mx-sm-44pt {
    margin-left: 2.75rem !important;
  }
  .m-sm-48pt {
    margin: 3rem !important;
  }
  .mt-sm-48pt,
  .my-sm-48pt {
    margin-top: 3rem !important;
  }
  .mr-sm-48pt,
  .mx-sm-48pt {
    margin-right: 3rem !important;
  }
  .mb-sm-48pt,
  .my-sm-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-sm-48pt,
  .mx-sm-48pt {
    margin-left: 3rem !important;
  }
  .m-sm-64pt {
    margin: 4rem !important;
  }
  .mt-sm-64pt,
  .my-sm-64pt {
    margin-top: 4rem !important;
  }
  .mr-sm-64pt,
  .mx-sm-64pt {
    margin-right: 4rem !important;
  }
  .mb-sm-64pt,
  .my-sm-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-sm-64pt,
  .mx-sm-64pt {
    margin-left: 4rem !important;
  }
  .m-sm-112pt {
    margin: 7rem !important;
  }
  .mt-sm-112pt,
  .my-sm-112pt {
    margin-top: 7rem !important;
  }
  .mr-sm-112pt,
  .mx-sm-112pt {
    margin-right: 7rem !important;
  }
  .mb-sm-112pt,
  .my-sm-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-sm-112pt,
  .mx-sm-112pt {
    margin-left: 7rem !important;
  }
  .m-sm-128pt {
    margin: 8rem !important;
  }
  .mt-sm-128pt,
  .my-sm-128pt {
    margin-top: 8rem !important;
  }
  .mr-sm-128pt,
  .mx-sm-128pt {
    margin-right: 8rem !important;
  }
  .mb-sm-128pt,
  .my-sm-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-sm-128pt,
  .mx-sm-128pt {
    margin-left: 8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-4pt {
    padding: 0.25rem !important;
  }
  .pt-sm-4pt,
  .py-sm-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-sm-4pt,
  .px-sm-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-sm-4pt,
  .py-sm-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-4pt,
  .px-sm-4pt {
    padding-left: 0.25rem !important;
  }
  .p-sm-8pt {
    padding: 0.5rem !important;
  }
  .pt-sm-8pt,
  .py-sm-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-sm-8pt,
  .px-sm-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-sm-8pt,
  .py-sm-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-8pt,
  .px-sm-8pt {
    padding-left: 0.5rem !important;
  }
  .p-sm-12pt {
    padding: 0.75rem !important;
  }
  .pt-sm-12pt,
  .py-sm-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-sm-12pt,
  .px-sm-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-sm-12pt,
  .py-sm-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-12pt,
  .px-sm-12pt {
    padding-left: 0.75rem !important;
  }
  .p-sm-16pt {
    padding: 1rem !important;
  }
  .pt-sm-16pt,
  .py-sm-16pt {
    padding-top: 1rem !important;
  }
  .pr-sm-16pt,
  .px-sm-16pt {
    padding-right: 1rem !important;
  }
  .pb-sm-16pt,
  .py-sm-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-sm-16pt,
  .px-sm-16pt {
    padding-left: 1rem !important;
  }
  .p-sm-20pt {
    padding: 1.25rem !important;
  }
  .pt-sm-20pt,
  .py-sm-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-sm-20pt,
  .px-sm-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-sm-20pt,
  .py-sm-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-20pt,
  .px-sm-20pt {
    padding-left: 1.25rem !important;
  }
  .p-sm-24pt {
    padding: 1.5rem !important;
  }
  .pt-sm-24pt,
  .py-sm-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-sm-24pt,
  .px-sm-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-sm-24pt,
  .py-sm-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-24pt,
  .px-sm-24pt {
    padding-left: 1.5rem !important;
  }
  .p-sm-32pt {
    padding: 2rem !important;
  }
  .pt-sm-32pt,
  .py-sm-32pt {
    padding-top: 2rem !important;
  }
  .pr-sm-32pt,
  .px-sm-32pt {
    padding-right: 2rem !important;
  }
  .pb-sm-32pt,
  .py-sm-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-sm-32pt,
  .px-sm-32pt {
    padding-left: 2rem !important;
  }
  .p-sm-40pt {
    padding: 2.5rem !important;
  }
  .pt-sm-40pt,
  .py-sm-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-sm-40pt,
  .px-sm-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-sm-40pt,
  .py-sm-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-40pt,
  .px-sm-40pt {
    padding-left: 2.5rem !important;
  }
  .p-sm-44pt {
    padding: 2.75rem !important;
  }
  .pt-sm-44pt,
  .py-sm-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-sm-44pt,
  .px-sm-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-sm-44pt,
  .py-sm-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-sm-44pt,
  .px-sm-44pt {
    padding-left: 2.75rem !important;
  }
  .p-sm-48pt {
    padding: 3rem !important;
  }
  .pt-sm-48pt,
  .py-sm-48pt {
    padding-top: 3rem !important;
  }
  .pr-sm-48pt,
  .px-sm-48pt {
    padding-right: 3rem !important;
  }
  .pb-sm-48pt,
  .py-sm-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-sm-48pt,
  .px-sm-48pt {
    padding-left: 3rem !important;
  }
  .p-sm-64pt {
    padding: 4rem !important;
  }
  .pt-sm-64pt,
  .py-sm-64pt {
    padding-top: 4rem !important;
  }
  .pr-sm-64pt,
  .px-sm-64pt {
    padding-right: 4rem !important;
  }
  .pb-sm-64pt,
  .py-sm-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-sm-64pt,
  .px-sm-64pt {
    padding-left: 4rem !important;
  }
  .p-sm-112pt {
    padding: 7rem !important;
  }
  .pt-sm-112pt,
  .py-sm-112pt {
    padding-top: 7rem !important;
  }
  .pr-sm-112pt,
  .px-sm-112pt {
    padding-right: 7rem !important;
  }
  .pb-sm-112pt,
  .py-sm-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-sm-112pt,
  .px-sm-112pt {
    padding-left: 7rem !important;
  }
  .p-sm-128pt {
    padding: 8rem !important;
  }
  .pt-sm-128pt,
  .py-sm-128pt {
    padding-top: 8rem !important;
  }
  .pr-sm-128pt,
  .px-sm-128pt {
    padding-right: 8rem !important;
  }
  .pb-sm-128pt,
  .py-sm-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-sm-128pt,
  .px-sm-128pt {
    padding-left: 8rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n4pt {
    margin: -0.25rem !important;
  }
  .mt-sm-n4pt,
  .my-sm-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n4pt,
  .mx-sm-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n4pt,
  .my-sm-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n4pt,
  .mx-sm-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-sm-n8pt {
    margin: -0.5rem !important;
  }
  .mt-sm-n8pt,
  .my-sm-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n8pt,
  .mx-sm-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n8pt,
  .my-sm-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n8pt,
  .mx-sm-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-sm-n12pt {
    margin: -0.75rem !important;
  }
  .mt-sm-n12pt,
  .my-sm-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n12pt,
  .mx-sm-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n12pt,
  .my-sm-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n12pt,
  .mx-sm-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-sm-n16pt {
    margin: -1rem !important;
  }
  .mt-sm-n16pt,
  .my-sm-n16pt {
    margin-top: -1rem !important;
  }
  .mr-sm-n16pt,
  .mx-sm-n16pt {
    margin-right: -1rem !important;
  }
  .mb-sm-n16pt,
  .my-sm-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n16pt,
  .mx-sm-n16pt {
    margin-left: -1rem !important;
  }
  .m-sm-n20pt {
    margin: -1.25rem !important;
  }
  .mt-sm-n20pt,
  .my-sm-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n20pt,
  .mx-sm-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n20pt,
  .my-sm-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n20pt,
  .mx-sm-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-sm-n24pt {
    margin: -1.5rem !important;
  }
  .mt-sm-n24pt,
  .my-sm-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n24pt,
  .mx-sm-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n24pt,
  .my-sm-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n24pt,
  .mx-sm-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-sm-n32pt {
    margin: -2rem !important;
  }
  .mt-sm-n32pt,
  .my-sm-n32pt {
    margin-top: -2rem !important;
  }
  .mr-sm-n32pt,
  .mx-sm-n32pt {
    margin-right: -2rem !important;
  }
  .mb-sm-n32pt,
  .my-sm-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n32pt,
  .mx-sm-n32pt {
    margin-left: -2rem !important;
  }
  .m-sm-n40pt {
    margin: -2.5rem !important;
  }
  .mt-sm-n40pt,
  .my-sm-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n40pt,
  .mx-sm-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n40pt,
  .my-sm-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n40pt,
  .mx-sm-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-sm-n44pt {
    margin: -2.75rem !important;
  }
  .mt-sm-n44pt,
  .my-sm-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-sm-n44pt,
  .mx-sm-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-sm-n44pt,
  .my-sm-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-sm-n44pt,
  .mx-sm-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-sm-n48pt {
    margin: -3rem !important;
  }
  .mt-sm-n48pt,
  .my-sm-n48pt {
    margin-top: -3rem !important;
  }
  .mr-sm-n48pt,
  .mx-sm-n48pt {
    margin-right: -3rem !important;
  }
  .mb-sm-n48pt,
  .my-sm-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n48pt,
  .mx-sm-n48pt {
    margin-left: -3rem !important;
  }
  .m-sm-n64pt {
    margin: -4rem !important;
  }
  .mt-sm-n64pt,
  .my-sm-n64pt {
    margin-top: -4rem !important;
  }
  .mr-sm-n64pt,
  .mx-sm-n64pt {
    margin-right: -4rem !important;
  }
  .mb-sm-n64pt,
  .my-sm-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n64pt,
  .mx-sm-n64pt {
    margin-left: -4rem !important;
  }
  .m-sm-n112pt {
    margin: -7rem !important;
  }
  .mt-sm-n112pt,
  .my-sm-n112pt {
    margin-top: -7rem !important;
  }
  .mr-sm-n112pt,
  .mx-sm-n112pt {
    margin-right: -7rem !important;
  }
  .mb-sm-n112pt,
  .my-sm-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n112pt,
  .mx-sm-n112pt {
    margin-left: -7rem !important;
  }
  .m-sm-n128pt {
    margin: -8rem !important;
  }
  .mt-sm-n128pt,
  .my-sm-n128pt {
    margin-top: -8rem !important;
  }
  .mr-sm-n128pt,
  .mx-sm-n128pt {
    margin-right: -8rem !important;
  }
  .mb-sm-n128pt,
  .my-sm-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n128pt,
  .mx-sm-n128pt {
    margin-left: -8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-4pt {
    margin: 0.25rem !important;
  }
  .mt-md-4pt,
  .my-md-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-md-4pt,
  .mx-md-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-md-4pt,
  .my-md-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-4pt,
  .mx-md-4pt {
    margin-left: 0.25rem !important;
  }
  .m-md-8pt {
    margin: 0.5rem !important;
  }
  .mt-md-8pt,
  .my-md-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-md-8pt,
  .mx-md-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-md-8pt,
  .my-md-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-8pt,
  .mx-md-8pt {
    margin-left: 0.5rem !important;
  }
  .m-md-12pt {
    margin: 0.75rem !important;
  }
  .mt-md-12pt,
  .my-md-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-md-12pt,
  .mx-md-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-md-12pt,
  .my-md-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-12pt,
  .mx-md-12pt {
    margin-left: 0.75rem !important;
  }
  .m-md-16pt {
    margin: 1rem !important;
  }
  .mt-md-16pt,
  .my-md-16pt {
    margin-top: 1rem !important;
  }
  .mr-md-16pt,
  .mx-md-16pt {
    margin-right: 1rem !important;
  }
  .mb-md-16pt,
  .my-md-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-md-16pt,
  .mx-md-16pt {
    margin-left: 1rem !important;
  }
  .m-md-20pt {
    margin: 1.25rem !important;
  }
  .mt-md-20pt,
  .my-md-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-md-20pt,
  .mx-md-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-md-20pt,
  .my-md-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-20pt,
  .mx-md-20pt {
    margin-left: 1.25rem !important;
  }
  .m-md-24pt {
    margin: 1.5rem !important;
  }
  .mt-md-24pt,
  .my-md-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-md-24pt,
  .mx-md-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-md-24pt,
  .my-md-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-24pt,
  .mx-md-24pt {
    margin-left: 1.5rem !important;
  }
  .m-md-32pt {
    margin: 2rem !important;
  }
  .mt-md-32pt,
  .my-md-32pt {
    margin-top: 2rem !important;
  }
  .mr-md-32pt,
  .mx-md-32pt {
    margin-right: 2rem !important;
  }
  .mb-md-32pt,
  .my-md-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-md-32pt,
  .mx-md-32pt {
    margin-left: 2rem !important;
  }
  .m-md-40pt {
    margin: 2.5rem !important;
  }
  .mt-md-40pt,
  .my-md-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-md-40pt,
  .mx-md-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-md-40pt,
  .my-md-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-40pt,
  .mx-md-40pt {
    margin-left: 2.5rem !important;
  }
  .m-md-44pt {
    margin: 2.75rem !important;
  }
  .mt-md-44pt,
  .my-md-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-md-44pt,
  .mx-md-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-md-44pt,
  .my-md-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-md-44pt,
  .mx-md-44pt {
    margin-left: 2.75rem !important;
  }
  .m-md-48pt {
    margin: 3rem !important;
  }
  .mt-md-48pt,
  .my-md-48pt {
    margin-top: 3rem !important;
  }
  .mr-md-48pt,
  .mx-md-48pt {
    margin-right: 3rem !important;
  }
  .mb-md-48pt,
  .my-md-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-md-48pt,
  .mx-md-48pt {
    margin-left: 3rem !important;
  }
  .m-md-64pt {
    margin: 4rem !important;
  }
  .mt-md-64pt,
  .my-md-64pt {
    margin-top: 4rem !important;
  }
  .mr-md-64pt,
  .mx-md-64pt {
    margin-right: 4rem !important;
  }
  .mb-md-64pt,
  .my-md-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-md-64pt,
  .mx-md-64pt {
    margin-left: 4rem !important;
  }
  .m-md-112pt {
    margin: 7rem !important;
  }
  .mt-md-112pt,
  .my-md-112pt {
    margin-top: 7rem !important;
  }
  .mr-md-112pt,
  .mx-md-112pt {
    margin-right: 7rem !important;
  }
  .mb-md-112pt,
  .my-md-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-md-112pt,
  .mx-md-112pt {
    margin-left: 7rem !important;
  }
  .m-md-128pt {
    margin: 8rem !important;
  }
  .mt-md-128pt,
  .my-md-128pt {
    margin-top: 8rem !important;
  }
  .mr-md-128pt,
  .mx-md-128pt {
    margin-right: 8rem !important;
  }
  .mb-md-128pt,
  .my-md-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-md-128pt,
  .mx-md-128pt {
    margin-left: 8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-4pt {
    padding: 0.25rem !important;
  }
  .pt-md-4pt,
  .py-md-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-md-4pt,
  .px-md-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-md-4pt,
  .py-md-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-4pt,
  .px-md-4pt {
    padding-left: 0.25rem !important;
  }
  .p-md-8pt {
    padding: 0.5rem !important;
  }
  .pt-md-8pt,
  .py-md-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-md-8pt,
  .px-md-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-md-8pt,
  .py-md-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-8pt,
  .px-md-8pt {
    padding-left: 0.5rem !important;
  }
  .p-md-12pt {
    padding: 0.75rem !important;
  }
  .pt-md-12pt,
  .py-md-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-md-12pt,
  .px-md-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-md-12pt,
  .py-md-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-12pt,
  .px-md-12pt {
    padding-left: 0.75rem !important;
  }
  .p-md-16pt {
    padding: 1rem !important;
  }
  .pt-md-16pt,
  .py-md-16pt {
    padding-top: 1rem !important;
  }
  .pr-md-16pt,
  .px-md-16pt {
    padding-right: 1rem !important;
  }
  .pb-md-16pt,
  .py-md-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-md-16pt,
  .px-md-16pt {
    padding-left: 1rem !important;
  }
  .p-md-20pt {
    padding: 1.25rem !important;
  }
  .pt-md-20pt,
  .py-md-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-md-20pt,
  .px-md-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-md-20pt,
  .py-md-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-20pt,
  .px-md-20pt {
    padding-left: 1.25rem !important;
  }
  .p-md-24pt {
    padding: 1.5rem !important;
  }
  .pt-md-24pt,
  .py-md-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-md-24pt,
  .px-md-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-md-24pt,
  .py-md-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-24pt,
  .px-md-24pt {
    padding-left: 1.5rem !important;
  }
  .p-md-32pt {
    padding: 2rem !important;
  }
  .pt-md-32pt,
  .py-md-32pt {
    padding-top: 2rem !important;
  }
  .pr-md-32pt,
  .px-md-32pt {
    padding-right: 2rem !important;
  }
  .pb-md-32pt,
  .py-md-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-md-32pt,
  .px-md-32pt {
    padding-left: 2rem !important;
  }
  .p-md-40pt {
    padding: 2.5rem !important;
  }
  .pt-md-40pt,
  .py-md-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-md-40pt,
  .px-md-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-md-40pt,
  .py-md-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-40pt,
  .px-md-40pt {
    padding-left: 2.5rem !important;
  }
  .p-md-44pt {
    padding: 2.75rem !important;
  }
  .pt-md-44pt,
  .py-md-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-md-44pt,
  .px-md-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-md-44pt,
  .py-md-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-md-44pt,
  .px-md-44pt {
    padding-left: 2.75rem !important;
  }
  .p-md-48pt {
    padding: 3rem !important;
  }
  .pt-md-48pt,
  .py-md-48pt {
    padding-top: 3rem !important;
  }
  .pr-md-48pt,
  .px-md-48pt {
    padding-right: 3rem !important;
  }
  .pb-md-48pt,
  .py-md-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-md-48pt,
  .px-md-48pt {
    padding-left: 3rem !important;
  }
  .p-md-64pt {
    padding: 4rem !important;
  }
  .pt-md-64pt,
  .py-md-64pt {
    padding-top: 4rem !important;
  }
  .pr-md-64pt,
  .px-md-64pt {
    padding-right: 4rem !important;
  }
  .pb-md-64pt,
  .py-md-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-md-64pt,
  .px-md-64pt {
    padding-left: 4rem !important;
  }
  .p-md-112pt {
    padding: 7rem !important;
  }
  .pt-md-112pt,
  .py-md-112pt {
    padding-top: 7rem !important;
  }
  .pr-md-112pt,
  .px-md-112pt {
    padding-right: 7rem !important;
  }
  .pb-md-112pt,
  .py-md-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-md-112pt,
  .px-md-112pt {
    padding-left: 7rem !important;
  }
  .p-md-128pt {
    padding: 8rem !important;
  }
  .pt-md-128pt,
  .py-md-128pt {
    padding-top: 8rem !important;
  }
  .pr-md-128pt,
  .px-md-128pt {
    padding-right: 8rem !important;
  }
  .pb-md-128pt,
  .py-md-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-md-128pt,
  .px-md-128pt {
    padding-left: 8rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n4pt {
    margin: -0.25rem !important;
  }
  .mt-md-n4pt,
  .my-md-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-md-n4pt,
  .mx-md-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-md-n4pt,
  .my-md-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n4pt,
  .mx-md-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-md-n8pt {
    margin: -0.5rem !important;
  }
  .mt-md-n8pt,
  .my-md-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-md-n8pt,
  .mx-md-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-md-n8pt,
  .my-md-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n8pt,
  .mx-md-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-md-n12pt {
    margin: -0.75rem !important;
  }
  .mt-md-n12pt,
  .my-md-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-md-n12pt,
  .mx-md-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-md-n12pt,
  .my-md-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n12pt,
  .mx-md-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-md-n16pt {
    margin: -1rem !important;
  }
  .mt-md-n16pt,
  .my-md-n16pt {
    margin-top: -1rem !important;
  }
  .mr-md-n16pt,
  .mx-md-n16pt {
    margin-right: -1rem !important;
  }
  .mb-md-n16pt,
  .my-md-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-md-n16pt,
  .mx-md-n16pt {
    margin-left: -1rem !important;
  }
  .m-md-n20pt {
    margin: -1.25rem !important;
  }
  .mt-md-n20pt,
  .my-md-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-md-n20pt,
  .mx-md-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-md-n20pt,
  .my-md-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n20pt,
  .mx-md-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-md-n24pt {
    margin: -1.5rem !important;
  }
  .mt-md-n24pt,
  .my-md-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-md-n24pt,
  .mx-md-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-md-n24pt,
  .my-md-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n24pt,
  .mx-md-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-md-n32pt {
    margin: -2rem !important;
  }
  .mt-md-n32pt,
  .my-md-n32pt {
    margin-top: -2rem !important;
  }
  .mr-md-n32pt,
  .mx-md-n32pt {
    margin-right: -2rem !important;
  }
  .mb-md-n32pt,
  .my-md-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-md-n32pt,
  .mx-md-n32pt {
    margin-left: -2rem !important;
  }
  .m-md-n40pt {
    margin: -2.5rem !important;
  }
  .mt-md-n40pt,
  .my-md-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-md-n40pt,
  .mx-md-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-md-n40pt,
  .my-md-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n40pt,
  .mx-md-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-md-n44pt {
    margin: -2.75rem !important;
  }
  .mt-md-n44pt,
  .my-md-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-md-n44pt,
  .mx-md-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-md-n44pt,
  .my-md-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-md-n44pt,
  .mx-md-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-md-n48pt {
    margin: -3rem !important;
  }
  .mt-md-n48pt,
  .my-md-n48pt {
    margin-top: -3rem !important;
  }
  .mr-md-n48pt,
  .mx-md-n48pt {
    margin-right: -3rem !important;
  }
  .mb-md-n48pt,
  .my-md-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-md-n48pt,
  .mx-md-n48pt {
    margin-left: -3rem !important;
  }
  .m-md-n64pt {
    margin: -4rem !important;
  }
  .mt-md-n64pt,
  .my-md-n64pt {
    margin-top: -4rem !important;
  }
  .mr-md-n64pt,
  .mx-md-n64pt {
    margin-right: -4rem !important;
  }
  .mb-md-n64pt,
  .my-md-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-md-n64pt,
  .mx-md-n64pt {
    margin-left: -4rem !important;
  }
  .m-md-n112pt {
    margin: -7rem !important;
  }
  .mt-md-n112pt,
  .my-md-n112pt {
    margin-top: -7rem !important;
  }
  .mr-md-n112pt,
  .mx-md-n112pt {
    margin-right: -7rem !important;
  }
  .mb-md-n112pt,
  .my-md-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-md-n112pt,
  .mx-md-n112pt {
    margin-left: -7rem !important;
  }
  .m-md-n128pt {
    margin: -8rem !important;
  }
  .mt-md-n128pt,
  .my-md-n128pt {
    margin-top: -8rem !important;
  }
  .mr-md-n128pt,
  .mx-md-n128pt {
    margin-right: -8rem !important;
  }
  .mb-md-n128pt,
  .my-md-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-md-n128pt,
  .mx-md-n128pt {
    margin-left: -8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-4pt {
    margin: 0.25rem !important;
  }
  .mt-lg-4pt,
  .my-lg-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-lg-4pt,
  .mx-lg-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-lg-4pt,
  .my-lg-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-4pt,
  .mx-lg-4pt {
    margin-left: 0.25rem !important;
  }
  .m-lg-8pt {
    margin: 0.5rem !important;
  }
  .mt-lg-8pt,
  .my-lg-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-lg-8pt,
  .mx-lg-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-lg-8pt,
  .my-lg-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-8pt,
  .mx-lg-8pt {
    margin-left: 0.5rem !important;
  }
  .m-lg-12pt {
    margin: 0.75rem !important;
  }
  .mt-lg-12pt,
  .my-lg-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-lg-12pt,
  .mx-lg-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-lg-12pt,
  .my-lg-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-12pt,
  .mx-lg-12pt {
    margin-left: 0.75rem !important;
  }
  .m-lg-16pt {
    margin: 1rem !important;
  }
  .mt-lg-16pt,
  .my-lg-16pt {
    margin-top: 1rem !important;
  }
  .mr-lg-16pt,
  .mx-lg-16pt {
    margin-right: 1rem !important;
  }
  .mb-lg-16pt,
  .my-lg-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-lg-16pt,
  .mx-lg-16pt {
    margin-left: 1rem !important;
  }
  .m-lg-20pt {
    margin: 1.25rem !important;
  }
  .mt-lg-20pt,
  .my-lg-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-lg-20pt,
  .mx-lg-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-lg-20pt,
  .my-lg-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-20pt,
  .mx-lg-20pt {
    margin-left: 1.25rem !important;
  }
  .m-lg-24pt {
    margin: 1.5rem !important;
  }
  .mt-lg-24pt,
  .my-lg-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-lg-24pt,
  .mx-lg-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-lg-24pt,
  .my-lg-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-24pt,
  .mx-lg-24pt {
    margin-left: 1.5rem !important;
  }
  .m-lg-32pt {
    margin: 2rem !important;
  }
  .mt-lg-32pt,
  .my-lg-32pt {
    margin-top: 2rem !important;
  }
  .mr-lg-32pt,
  .mx-lg-32pt {
    margin-right: 2rem !important;
  }
  .mb-lg-32pt,
  .my-lg-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-lg-32pt,
  .mx-lg-32pt {
    margin-left: 2rem !important;
  }
  .m-lg-40pt {
    margin: 2.5rem !important;
  }
  .mt-lg-40pt,
  .my-lg-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-lg-40pt,
  .mx-lg-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-lg-40pt,
  .my-lg-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-40pt,
  .mx-lg-40pt {
    margin-left: 2.5rem !important;
  }
  .m-lg-44pt {
    margin: 2.75rem !important;
  }
  .mt-lg-44pt,
  .my-lg-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-lg-44pt,
  .mx-lg-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-lg-44pt,
  .my-lg-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-lg-44pt,
  .mx-lg-44pt {
    margin-left: 2.75rem !important;
  }
  .m-lg-48pt {
    margin: 3rem !important;
  }
  .mt-lg-48pt,
  .my-lg-48pt {
    margin-top: 3rem !important;
  }
  .mr-lg-48pt,
  .mx-lg-48pt {
    margin-right: 3rem !important;
  }
  .mb-lg-48pt,
  .my-lg-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-lg-48pt,
  .mx-lg-48pt {
    margin-left: 3rem !important;
  }
  .m-lg-64pt {
    margin: 4rem !important;
  }
  .mt-lg-64pt,
  .my-lg-64pt {
    margin-top: 4rem !important;
  }
  .mr-lg-64pt,
  .mx-lg-64pt {
    margin-right: 4rem !important;
  }
  .mb-lg-64pt,
  .my-lg-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-lg-64pt,
  .mx-lg-64pt {
    margin-left: 4rem !important;
  }
  .m-lg-112pt {
    margin: 7rem !important;
  }
  .mt-lg-112pt,
  .my-lg-112pt {
    margin-top: 7rem !important;
  }
  .mr-lg-112pt,
  .mx-lg-112pt {
    margin-right: 7rem !important;
  }
  .mb-lg-112pt,
  .my-lg-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-lg-112pt,
  .mx-lg-112pt {
    margin-left: 7rem !important;
  }
  .m-lg-128pt {
    margin: 8rem !important;
  }
  .mt-lg-128pt,
  .my-lg-128pt {
    margin-top: 8rem !important;
  }
  .mr-lg-128pt,
  .mx-lg-128pt {
    margin-right: 8rem !important;
  }
  .mb-lg-128pt,
  .my-lg-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-lg-128pt,
  .mx-lg-128pt {
    margin-left: 8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-4pt {
    padding: 0.25rem !important;
  }
  .pt-lg-4pt,
  .py-lg-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-lg-4pt,
  .px-lg-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-lg-4pt,
  .py-lg-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-4pt,
  .px-lg-4pt {
    padding-left: 0.25rem !important;
  }
  .p-lg-8pt {
    padding: 0.5rem !important;
  }
  .pt-lg-8pt,
  .py-lg-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-lg-8pt,
  .px-lg-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-lg-8pt,
  .py-lg-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-8pt,
  .px-lg-8pt {
    padding-left: 0.5rem !important;
  }
  .p-lg-12pt {
    padding: 0.75rem !important;
  }
  .pt-lg-12pt,
  .py-lg-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-lg-12pt,
  .px-lg-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-lg-12pt,
  .py-lg-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-12pt,
  .px-lg-12pt {
    padding-left: 0.75rem !important;
  }
  .p-lg-16pt {
    padding: 1rem !important;
  }
  .pt-lg-16pt,
  .py-lg-16pt {
    padding-top: 1rem !important;
  }
  .pr-lg-16pt,
  .px-lg-16pt {
    padding-right: 1rem !important;
  }
  .pb-lg-16pt,
  .py-lg-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-lg-16pt,
  .px-lg-16pt {
    padding-left: 1rem !important;
  }
  .p-lg-20pt {
    padding: 1.25rem !important;
  }
  .pt-lg-20pt,
  .py-lg-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-lg-20pt,
  .px-lg-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-lg-20pt,
  .py-lg-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-20pt,
  .px-lg-20pt {
    padding-left: 1.25rem !important;
  }
  .p-lg-24pt {
    padding: 1.5rem !important;
  }
  .pt-lg-24pt,
  .py-lg-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-lg-24pt,
  .px-lg-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-lg-24pt,
  .py-lg-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-24pt,
  .px-lg-24pt {
    padding-left: 1.5rem !important;
  }
  .p-lg-32pt {
    padding: 2rem !important;
  }
  .pt-lg-32pt,
  .py-lg-32pt {
    padding-top: 2rem !important;
  }
  .pr-lg-32pt,
  .px-lg-32pt {
    padding-right: 2rem !important;
  }
  .pb-lg-32pt,
  .py-lg-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-lg-32pt,
  .px-lg-32pt {
    padding-left: 2rem !important;
  }
  .p-lg-40pt {
    padding: 2.5rem !important;
  }
  .pt-lg-40pt,
  .py-lg-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-lg-40pt,
  .px-lg-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-lg-40pt,
  .py-lg-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-40pt,
  .px-lg-40pt {
    padding-left: 2.5rem !important;
  }
  .p-lg-44pt {
    padding: 2.75rem !important;
  }
  .pt-lg-44pt,
  .py-lg-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-lg-44pt,
  .px-lg-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-lg-44pt,
  .py-lg-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-lg-44pt,
  .px-lg-44pt {
    padding-left: 2.75rem !important;
  }
  .p-lg-48pt {
    padding: 3rem !important;
  }
  .pt-lg-48pt,
  .py-lg-48pt {
    padding-top: 3rem !important;
  }
  .pr-lg-48pt,
  .px-lg-48pt {
    padding-right: 3rem !important;
  }
  .pb-lg-48pt,
  .py-lg-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-lg-48pt,
  .px-lg-48pt {
    padding-left: 3rem !important;
  }
  .p-lg-64pt {
    padding: 4rem !important;
  }
  .pt-lg-64pt,
  .py-lg-64pt {
    padding-top: 4rem !important;
  }
  .pr-lg-64pt,
  .px-lg-64pt {
    padding-right: 4rem !important;
  }
  .pb-lg-64pt,
  .py-lg-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-lg-64pt,
  .px-lg-64pt {
    padding-left: 4rem !important;
  }
  .p-lg-112pt {
    padding: 7rem !important;
  }
  .pt-lg-112pt,
  .py-lg-112pt {
    padding-top: 7rem !important;
  }
  .pr-lg-112pt,
  .px-lg-112pt {
    padding-right: 7rem !important;
  }
  .pb-lg-112pt,
  .py-lg-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-lg-112pt,
  .px-lg-112pt {
    padding-left: 7rem !important;
  }
  .p-lg-128pt {
    padding: 8rem !important;
  }
  .pt-lg-128pt,
  .py-lg-128pt {
    padding-top: 8rem !important;
  }
  .pr-lg-128pt,
  .px-lg-128pt {
    padding-right: 8rem !important;
  }
  .pb-lg-128pt,
  .py-lg-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-lg-128pt,
  .px-lg-128pt {
    padding-left: 8rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n4pt {
    margin: -0.25rem !important;
  }
  .mt-lg-n4pt,
  .my-lg-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n4pt,
  .mx-lg-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n4pt,
  .my-lg-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n4pt,
  .mx-lg-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-lg-n8pt {
    margin: -0.5rem !important;
  }
  .mt-lg-n8pt,
  .my-lg-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n8pt,
  .mx-lg-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n8pt,
  .my-lg-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n8pt,
  .mx-lg-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-lg-n12pt {
    margin: -0.75rem !important;
  }
  .mt-lg-n12pt,
  .my-lg-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n12pt,
  .mx-lg-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n12pt,
  .my-lg-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n12pt,
  .mx-lg-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-lg-n16pt {
    margin: -1rem !important;
  }
  .mt-lg-n16pt,
  .my-lg-n16pt {
    margin-top: -1rem !important;
  }
  .mr-lg-n16pt,
  .mx-lg-n16pt {
    margin-right: -1rem !important;
  }
  .mb-lg-n16pt,
  .my-lg-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n16pt,
  .mx-lg-n16pt {
    margin-left: -1rem !important;
  }
  .m-lg-n20pt {
    margin: -1.25rem !important;
  }
  .mt-lg-n20pt,
  .my-lg-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n20pt,
  .mx-lg-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n20pt,
  .my-lg-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n20pt,
  .mx-lg-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-lg-n24pt {
    margin: -1.5rem !important;
  }
  .mt-lg-n24pt,
  .my-lg-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n24pt,
  .mx-lg-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n24pt,
  .my-lg-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n24pt,
  .mx-lg-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-lg-n32pt {
    margin: -2rem !important;
  }
  .mt-lg-n32pt,
  .my-lg-n32pt {
    margin-top: -2rem !important;
  }
  .mr-lg-n32pt,
  .mx-lg-n32pt {
    margin-right: -2rem !important;
  }
  .mb-lg-n32pt,
  .my-lg-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n32pt,
  .mx-lg-n32pt {
    margin-left: -2rem !important;
  }
  .m-lg-n40pt {
    margin: -2.5rem !important;
  }
  .mt-lg-n40pt,
  .my-lg-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n40pt,
  .mx-lg-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n40pt,
  .my-lg-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n40pt,
  .mx-lg-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-lg-n44pt {
    margin: -2.75rem !important;
  }
  .mt-lg-n44pt,
  .my-lg-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-lg-n44pt,
  .mx-lg-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-lg-n44pt,
  .my-lg-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-lg-n44pt,
  .mx-lg-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-lg-n48pt {
    margin: -3rem !important;
  }
  .mt-lg-n48pt,
  .my-lg-n48pt {
    margin-top: -3rem !important;
  }
  .mr-lg-n48pt,
  .mx-lg-n48pt {
    margin-right: -3rem !important;
  }
  .mb-lg-n48pt,
  .my-lg-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n48pt,
  .mx-lg-n48pt {
    margin-left: -3rem !important;
  }
  .m-lg-n64pt {
    margin: -4rem !important;
  }
  .mt-lg-n64pt,
  .my-lg-n64pt {
    margin-top: -4rem !important;
  }
  .mr-lg-n64pt,
  .mx-lg-n64pt {
    margin-right: -4rem !important;
  }
  .mb-lg-n64pt,
  .my-lg-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n64pt,
  .mx-lg-n64pt {
    margin-left: -4rem !important;
  }
  .m-lg-n112pt {
    margin: -7rem !important;
  }
  .mt-lg-n112pt,
  .my-lg-n112pt {
    margin-top: -7rem !important;
  }
  .mr-lg-n112pt,
  .mx-lg-n112pt {
    margin-right: -7rem !important;
  }
  .mb-lg-n112pt,
  .my-lg-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n112pt,
  .mx-lg-n112pt {
    margin-left: -7rem !important;
  }
  .m-lg-n128pt {
    margin: -8rem !important;
  }
  .mt-lg-n128pt,
  .my-lg-n128pt {
    margin-top: -8rem !important;
  }
  .mr-lg-n128pt,
  .mx-lg-n128pt {
    margin-right: -8rem !important;
  }
  .mb-lg-n128pt,
  .my-lg-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n128pt,
  .mx-lg-n128pt {
    margin-left: -8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-4pt {
    margin: 0.25rem !important;
  }
  .mt-xl-4pt,
  .my-xl-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-xl-4pt,
  .mx-xl-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-xl-4pt,
  .my-xl-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-4pt,
  .mx-xl-4pt {
    margin-left: 0.25rem !important;
  }
  .m-xl-8pt {
    margin: 0.5rem !important;
  }
  .mt-xl-8pt,
  .my-xl-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-xl-8pt,
  .mx-xl-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-xl-8pt,
  .my-xl-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-8pt,
  .mx-xl-8pt {
    margin-left: 0.5rem !important;
  }
  .m-xl-12pt {
    margin: 0.75rem !important;
  }
  .mt-xl-12pt,
  .my-xl-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-xl-12pt,
  .mx-xl-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-xl-12pt,
  .my-xl-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-12pt,
  .mx-xl-12pt {
    margin-left: 0.75rem !important;
  }
  .m-xl-16pt {
    margin: 1rem !important;
  }
  .mt-xl-16pt,
  .my-xl-16pt {
    margin-top: 1rem !important;
  }
  .mr-xl-16pt,
  .mx-xl-16pt {
    margin-right: 1rem !important;
  }
  .mb-xl-16pt,
  .my-xl-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-xl-16pt,
  .mx-xl-16pt {
    margin-left: 1rem !important;
  }
  .m-xl-20pt {
    margin: 1.25rem !important;
  }
  .mt-xl-20pt,
  .my-xl-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-xl-20pt,
  .mx-xl-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-xl-20pt,
  .my-xl-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-20pt,
  .mx-xl-20pt {
    margin-left: 1.25rem !important;
  }
  .m-xl-24pt {
    margin: 1.5rem !important;
  }
  .mt-xl-24pt,
  .my-xl-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-xl-24pt,
  .mx-xl-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-xl-24pt,
  .my-xl-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-24pt,
  .mx-xl-24pt {
    margin-left: 1.5rem !important;
  }
  .m-xl-32pt {
    margin: 2rem !important;
  }
  .mt-xl-32pt,
  .my-xl-32pt {
    margin-top: 2rem !important;
  }
  .mr-xl-32pt,
  .mx-xl-32pt {
    margin-right: 2rem !important;
  }
  .mb-xl-32pt,
  .my-xl-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-xl-32pt,
  .mx-xl-32pt {
    margin-left: 2rem !important;
  }
  .m-xl-40pt {
    margin: 2.5rem !important;
  }
  .mt-xl-40pt,
  .my-xl-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-xl-40pt,
  .mx-xl-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-xl-40pt,
  .my-xl-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-40pt,
  .mx-xl-40pt {
    margin-left: 2.5rem !important;
  }
  .m-xl-44pt {
    margin: 2.75rem !important;
  }
  .mt-xl-44pt,
  .my-xl-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-xl-44pt,
  .mx-xl-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-xl-44pt,
  .my-xl-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-xl-44pt,
  .mx-xl-44pt {
    margin-left: 2.75rem !important;
  }
  .m-xl-48pt {
    margin: 3rem !important;
  }
  .mt-xl-48pt,
  .my-xl-48pt {
    margin-top: 3rem !important;
  }
  .mr-xl-48pt,
  .mx-xl-48pt {
    margin-right: 3rem !important;
  }
  .mb-xl-48pt,
  .my-xl-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-xl-48pt,
  .mx-xl-48pt {
    margin-left: 3rem !important;
  }
  .m-xl-64pt {
    margin: 4rem !important;
  }
  .mt-xl-64pt,
  .my-xl-64pt {
    margin-top: 4rem !important;
  }
  .mr-xl-64pt,
  .mx-xl-64pt {
    margin-right: 4rem !important;
  }
  .mb-xl-64pt,
  .my-xl-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-xl-64pt,
  .mx-xl-64pt {
    margin-left: 4rem !important;
  }
  .m-xl-112pt {
    margin: 7rem !important;
  }
  .mt-xl-112pt,
  .my-xl-112pt {
    margin-top: 7rem !important;
  }
  .mr-xl-112pt,
  .mx-xl-112pt {
    margin-right: 7rem !important;
  }
  .mb-xl-112pt,
  .my-xl-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-xl-112pt,
  .mx-xl-112pt {
    margin-left: 7rem !important;
  }
  .m-xl-128pt {
    margin: 8rem !important;
  }
  .mt-xl-128pt,
  .my-xl-128pt {
    margin-top: 8rem !important;
  }
  .mr-xl-128pt,
  .mx-xl-128pt {
    margin-right: 8rem !important;
  }
  .mb-xl-128pt,
  .my-xl-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-xl-128pt,
  .mx-xl-128pt {
    margin-left: 8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-4pt {
    padding: 0.25rem !important;
  }
  .pt-xl-4pt,
  .py-xl-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-xl-4pt,
  .px-xl-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-xl-4pt,
  .py-xl-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-4pt,
  .px-xl-4pt {
    padding-left: 0.25rem !important;
  }
  .p-xl-8pt {
    padding: 0.5rem !important;
  }
  .pt-xl-8pt,
  .py-xl-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-xl-8pt,
  .px-xl-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-xl-8pt,
  .py-xl-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-8pt,
  .px-xl-8pt {
    padding-left: 0.5rem !important;
  }
  .p-xl-12pt {
    padding: 0.75rem !important;
  }
  .pt-xl-12pt,
  .py-xl-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-xl-12pt,
  .px-xl-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-xl-12pt,
  .py-xl-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-12pt,
  .px-xl-12pt {
    padding-left: 0.75rem !important;
  }
  .p-xl-16pt {
    padding: 1rem !important;
  }
  .pt-xl-16pt,
  .py-xl-16pt {
    padding-top: 1rem !important;
  }
  .pr-xl-16pt,
  .px-xl-16pt {
    padding-right: 1rem !important;
  }
  .pb-xl-16pt,
  .py-xl-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-xl-16pt,
  .px-xl-16pt {
    padding-left: 1rem !important;
  }
  .p-xl-20pt {
    padding: 1.25rem !important;
  }
  .pt-xl-20pt,
  .py-xl-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-xl-20pt,
  .px-xl-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-xl-20pt,
  .py-xl-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-20pt,
  .px-xl-20pt {
    padding-left: 1.25rem !important;
  }
  .p-xl-24pt {
    padding: 1.5rem !important;
  }
  .pt-xl-24pt,
  .py-xl-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-xl-24pt,
  .px-xl-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-xl-24pt,
  .py-xl-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-24pt,
  .px-xl-24pt {
    padding-left: 1.5rem !important;
  }
  .p-xl-32pt {
    padding: 2rem !important;
  }
  .pt-xl-32pt,
  .py-xl-32pt {
    padding-top: 2rem !important;
  }
  .pr-xl-32pt,
  .px-xl-32pt {
    padding-right: 2rem !important;
  }
  .pb-xl-32pt,
  .py-xl-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-xl-32pt,
  .px-xl-32pt {
    padding-left: 2rem !important;
  }
  .p-xl-40pt {
    padding: 2.5rem !important;
  }
  .pt-xl-40pt,
  .py-xl-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-xl-40pt,
  .px-xl-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-xl-40pt,
  .py-xl-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-40pt,
  .px-xl-40pt {
    padding-left: 2.5rem !important;
  }
  .p-xl-44pt {
    padding: 2.75rem !important;
  }
  .pt-xl-44pt,
  .py-xl-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-xl-44pt,
  .px-xl-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-xl-44pt,
  .py-xl-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-xl-44pt,
  .px-xl-44pt {
    padding-left: 2.75rem !important;
  }
  .p-xl-48pt {
    padding: 3rem !important;
  }
  .pt-xl-48pt,
  .py-xl-48pt {
    padding-top: 3rem !important;
  }
  .pr-xl-48pt,
  .px-xl-48pt {
    padding-right: 3rem !important;
  }
  .pb-xl-48pt,
  .py-xl-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-xl-48pt,
  .px-xl-48pt {
    padding-left: 3rem !important;
  }
  .p-xl-64pt {
    padding: 4rem !important;
  }
  .pt-xl-64pt,
  .py-xl-64pt {
    padding-top: 4rem !important;
  }
  .pr-xl-64pt,
  .px-xl-64pt {
    padding-right: 4rem !important;
  }
  .pb-xl-64pt,
  .py-xl-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-xl-64pt,
  .px-xl-64pt {
    padding-left: 4rem !important;
  }
  .p-xl-112pt {
    padding: 7rem !important;
  }
  .pt-xl-112pt,
  .py-xl-112pt {
    padding-top: 7rem !important;
  }
  .pr-xl-112pt,
  .px-xl-112pt {
    padding-right: 7rem !important;
  }
  .pb-xl-112pt,
  .py-xl-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-xl-112pt,
  .px-xl-112pt {
    padding-left: 7rem !important;
  }
  .p-xl-128pt {
    padding: 8rem !important;
  }
  .pt-xl-128pt,
  .py-xl-128pt {
    padding-top: 8rem !important;
  }
  .pr-xl-128pt,
  .px-xl-128pt {
    padding-right: 8rem !important;
  }
  .pb-xl-128pt,
  .py-xl-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-xl-128pt,
  .px-xl-128pt {
    padding-left: 8rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n4pt {
    margin: -0.25rem !important;
  }
  .mt-xl-n4pt,
  .my-xl-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n4pt,
  .mx-xl-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n4pt,
  .my-xl-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n4pt,
  .mx-xl-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-xl-n8pt {
    margin: -0.5rem !important;
  }
  .mt-xl-n8pt,
  .my-xl-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n8pt,
  .mx-xl-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n8pt,
  .my-xl-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n8pt,
  .mx-xl-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-xl-n12pt {
    margin: -0.75rem !important;
  }
  .mt-xl-n12pt,
  .my-xl-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n12pt,
  .mx-xl-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n12pt,
  .my-xl-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n12pt,
  .mx-xl-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-xl-n16pt {
    margin: -1rem !important;
  }
  .mt-xl-n16pt,
  .my-xl-n16pt {
    margin-top: -1rem !important;
  }
  .mr-xl-n16pt,
  .mx-xl-n16pt {
    margin-right: -1rem !important;
  }
  .mb-xl-n16pt,
  .my-xl-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n16pt,
  .mx-xl-n16pt {
    margin-left: -1rem !important;
  }
  .m-xl-n20pt {
    margin: -1.25rem !important;
  }
  .mt-xl-n20pt,
  .my-xl-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n20pt,
  .mx-xl-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n20pt,
  .my-xl-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n20pt,
  .mx-xl-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-xl-n24pt {
    margin: -1.5rem !important;
  }
  .mt-xl-n24pt,
  .my-xl-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n24pt,
  .mx-xl-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n24pt,
  .my-xl-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n24pt,
  .mx-xl-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-xl-n32pt {
    margin: -2rem !important;
  }
  .mt-xl-n32pt,
  .my-xl-n32pt {
    margin-top: -2rem !important;
  }
  .mr-xl-n32pt,
  .mx-xl-n32pt {
    margin-right: -2rem !important;
  }
  .mb-xl-n32pt,
  .my-xl-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n32pt,
  .mx-xl-n32pt {
    margin-left: -2rem !important;
  }
  .m-xl-n40pt {
    margin: -2.5rem !important;
  }
  .mt-xl-n40pt,
  .my-xl-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n40pt,
  .mx-xl-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n40pt,
  .my-xl-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n40pt,
  .mx-xl-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-xl-n44pt {
    margin: -2.75rem !important;
  }
  .mt-xl-n44pt,
  .my-xl-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-xl-n44pt,
  .mx-xl-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-xl-n44pt,
  .my-xl-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-xl-n44pt,
  .mx-xl-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-xl-n48pt {
    margin: -3rem !important;
  }
  .mt-xl-n48pt,
  .my-xl-n48pt {
    margin-top: -3rem !important;
  }
  .mr-xl-n48pt,
  .mx-xl-n48pt {
    margin-right: -3rem !important;
  }
  .mb-xl-n48pt,
  .my-xl-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n48pt,
  .mx-xl-n48pt {
    margin-left: -3rem !important;
  }
  .m-xl-n64pt {
    margin: -4rem !important;
  }
  .mt-xl-n64pt,
  .my-xl-n64pt {
    margin-top: -4rem !important;
  }
  .mr-xl-n64pt,
  .mx-xl-n64pt {
    margin-right: -4rem !important;
  }
  .mb-xl-n64pt,
  .my-xl-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n64pt,
  .mx-xl-n64pt {
    margin-left: -4rem !important;
  }
  .m-xl-n112pt {
    margin: -7rem !important;
  }
  .mt-xl-n112pt,
  .my-xl-n112pt {
    margin-top: -7rem !important;
  }
  .mr-xl-n112pt,
  .mx-xl-n112pt {
    margin-right: -7rem !important;
  }
  .mb-xl-n112pt,
  .my-xl-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n112pt,
  .mx-xl-n112pt {
    margin-left: -7rem !important;
  }
  .m-xl-n128pt {
    margin: -8rem !important;
  }
  .mt-xl-n128pt,
  .my-xl-n128pt {
    margin-top: -8rem !important;
  }
  .mr-xl-n128pt,
  .mx-xl-n128pt {
    margin-right: -8rem !important;
  }
  .mb-xl-n128pt,
  .my-xl-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n128pt,
  .mx-xl-n128pt {
    margin-left: -8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1366px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .m-xxl-4pt {
    margin: 0.25rem !important;
  }
  .mt-xxl-4pt,
  .my-xxl-4pt {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-4pt,
  .mx-xxl-4pt {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-4pt,
  .my-xxl-4pt {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-4pt,
  .mx-xxl-4pt {
    margin-left: 0.25rem !important;
  }
  .m-xxl-8pt {
    margin: 0.5rem !important;
  }
  .mt-xxl-8pt,
  .my-xxl-8pt {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-8pt,
  .mx-xxl-8pt {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-8pt,
  .my-xxl-8pt {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-8pt,
  .mx-xxl-8pt {
    margin-left: 0.5rem !important;
  }
  .m-xxl-12pt {
    margin: 0.75rem !important;
  }
  .mt-xxl-12pt,
  .my-xxl-12pt {
    margin-top: 0.75rem !important;
  }
  .mr-xxl-12pt,
  .mx-xxl-12pt {
    margin-right: 0.75rem !important;
  }
  .mb-xxl-12pt,
  .my-xxl-12pt {
    margin-bottom: 0.75rem !important;
  }
  .ml-xxl-12pt,
  .mx-xxl-12pt {
    margin-left: 0.75rem !important;
  }
  .m-xxl-16pt {
    margin: 1rem !important;
  }
  .mt-xxl-16pt,
  .my-xxl-16pt {
    margin-top: 1rem !important;
  }
  .mr-xxl-16pt,
  .mx-xxl-16pt {
    margin-right: 1rem !important;
  }
  .mb-xxl-16pt,
  .my-xxl-16pt {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-16pt,
  .mx-xxl-16pt {
    margin-left: 1rem !important;
  }
  .m-xxl-20pt {
    margin: 1.25rem !important;
  }
  .mt-xxl-20pt,
  .my-xxl-20pt {
    margin-top: 1.25rem !important;
  }
  .mr-xxl-20pt,
  .mx-xxl-20pt {
    margin-right: 1.25rem !important;
  }
  .mb-xxl-20pt,
  .my-xxl-20pt {
    margin-bottom: 1.25rem !important;
  }
  .ml-xxl-20pt,
  .mx-xxl-20pt {
    margin-left: 1.25rem !important;
  }
  .m-xxl-24pt {
    margin: 1.5rem !important;
  }
  .mt-xxl-24pt,
  .my-xxl-24pt {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-24pt,
  .mx-xxl-24pt {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-24pt,
  .my-xxl-24pt {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-24pt,
  .mx-xxl-24pt {
    margin-left: 1.5rem !important;
  }
  .m-xxl-32pt {
    margin: 2rem !important;
  }
  .mt-xxl-32pt,
  .my-xxl-32pt {
    margin-top: 2rem !important;
  }
  .mr-xxl-32pt,
  .mx-xxl-32pt {
    margin-right: 2rem !important;
  }
  .mb-xxl-32pt,
  .my-xxl-32pt {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-32pt,
  .mx-xxl-32pt {
    margin-left: 2rem !important;
  }
  .m-xxl-40pt {
    margin: 2.5rem !important;
  }
  .mt-xxl-40pt,
  .my-xxl-40pt {
    margin-top: 2.5rem !important;
  }
  .mr-xxl-40pt,
  .mx-xxl-40pt {
    margin-right: 2.5rem !important;
  }
  .mb-xxl-40pt,
  .my-xxl-40pt {
    margin-bottom: 2.5rem !important;
  }
  .ml-xxl-40pt,
  .mx-xxl-40pt {
    margin-left: 2.5rem !important;
  }
  .m-xxl-44pt {
    margin: 2.75rem !important;
  }
  .mt-xxl-44pt,
  .my-xxl-44pt {
    margin-top: 2.75rem !important;
  }
  .mr-xxl-44pt,
  .mx-xxl-44pt {
    margin-right: 2.75rem !important;
  }
  .mb-xxl-44pt,
  .my-xxl-44pt {
    margin-bottom: 2.75rem !important;
  }
  .ml-xxl-44pt,
  .mx-xxl-44pt {
    margin-left: 2.75rem !important;
  }
  .m-xxl-48pt {
    margin: 3rem !important;
  }
  .mt-xxl-48pt,
  .my-xxl-48pt {
    margin-top: 3rem !important;
  }
  .mr-xxl-48pt,
  .mx-xxl-48pt {
    margin-right: 3rem !important;
  }
  .mb-xxl-48pt,
  .my-xxl-48pt {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-48pt,
  .mx-xxl-48pt {
    margin-left: 3rem !important;
  }
  .m-xxl-64pt {
    margin: 4rem !important;
  }
  .mt-xxl-64pt,
  .my-xxl-64pt {
    margin-top: 4rem !important;
  }
  .mr-xxl-64pt,
  .mx-xxl-64pt {
    margin-right: 4rem !important;
  }
  .mb-xxl-64pt,
  .my-xxl-64pt {
    margin-bottom: 4rem !important;
  }
  .ml-xxl-64pt,
  .mx-xxl-64pt {
    margin-left: 4rem !important;
  }
  .m-xxl-112pt {
    margin: 7rem !important;
  }
  .mt-xxl-112pt,
  .my-xxl-112pt {
    margin-top: 7rem !important;
  }
  .mr-xxl-112pt,
  .mx-xxl-112pt {
    margin-right: 7rem !important;
  }
  .mb-xxl-112pt,
  .my-xxl-112pt {
    margin-bottom: 7rem !important;
  }
  .ml-xxl-112pt,
  .mx-xxl-112pt {
    margin-left: 7rem !important;
  }
  .m-xxl-128pt {
    margin: 8rem !important;
  }
  .mt-xxl-128pt,
  .my-xxl-128pt {
    margin-top: 8rem !important;
  }
  .mr-xxl-128pt,
  .mx-xxl-128pt {
    margin-right: 8rem !important;
  }
  .mb-xxl-128pt,
  .my-xxl-128pt {
    margin-bottom: 8rem !important;
  }
  .ml-xxl-128pt,
  .mx-xxl-128pt {
    margin-left: 8rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .p-xxl-4pt {
    padding: 0.25rem !important;
  }
  .pt-xxl-4pt,
  .py-xxl-4pt {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-4pt,
  .px-xxl-4pt {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-4pt,
  .py-xxl-4pt {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-4pt,
  .px-xxl-4pt {
    padding-left: 0.25rem !important;
  }
  .p-xxl-8pt {
    padding: 0.5rem !important;
  }
  .pt-xxl-8pt,
  .py-xxl-8pt {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-8pt,
  .px-xxl-8pt {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-8pt,
  .py-xxl-8pt {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-8pt,
  .px-xxl-8pt {
    padding-left: 0.5rem !important;
  }
  .p-xxl-12pt {
    padding: 0.75rem !important;
  }
  .pt-xxl-12pt,
  .py-xxl-12pt {
    padding-top: 0.75rem !important;
  }
  .pr-xxl-12pt,
  .px-xxl-12pt {
    padding-right: 0.75rem !important;
  }
  .pb-xxl-12pt,
  .py-xxl-12pt {
    padding-bottom: 0.75rem !important;
  }
  .pl-xxl-12pt,
  .px-xxl-12pt {
    padding-left: 0.75rem !important;
  }
  .p-xxl-16pt {
    padding: 1rem !important;
  }
  .pt-xxl-16pt,
  .py-xxl-16pt {
    padding-top: 1rem !important;
  }
  .pr-xxl-16pt,
  .px-xxl-16pt {
    padding-right: 1rem !important;
  }
  .pb-xxl-16pt,
  .py-xxl-16pt {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-16pt,
  .px-xxl-16pt {
    padding-left: 1rem !important;
  }
  .p-xxl-20pt {
    padding: 1.25rem !important;
  }
  .pt-xxl-20pt,
  .py-xxl-20pt {
    padding-top: 1.25rem !important;
  }
  .pr-xxl-20pt,
  .px-xxl-20pt {
    padding-right: 1.25rem !important;
  }
  .pb-xxl-20pt,
  .py-xxl-20pt {
    padding-bottom: 1.25rem !important;
  }
  .pl-xxl-20pt,
  .px-xxl-20pt {
    padding-left: 1.25rem !important;
  }
  .p-xxl-24pt {
    padding: 1.5rem !important;
  }
  .pt-xxl-24pt,
  .py-xxl-24pt {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-24pt,
  .px-xxl-24pt {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-24pt,
  .py-xxl-24pt {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-24pt,
  .px-xxl-24pt {
    padding-left: 1.5rem !important;
  }
  .p-xxl-32pt {
    padding: 2rem !important;
  }
  .pt-xxl-32pt,
  .py-xxl-32pt {
    padding-top: 2rem !important;
  }
  .pr-xxl-32pt,
  .px-xxl-32pt {
    padding-right: 2rem !important;
  }
  .pb-xxl-32pt,
  .py-xxl-32pt {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-32pt,
  .px-xxl-32pt {
    padding-left: 2rem !important;
  }
  .p-xxl-40pt {
    padding: 2.5rem !important;
  }
  .pt-xxl-40pt,
  .py-xxl-40pt {
    padding-top: 2.5rem !important;
  }
  .pr-xxl-40pt,
  .px-xxl-40pt {
    padding-right: 2.5rem !important;
  }
  .pb-xxl-40pt,
  .py-xxl-40pt {
    padding-bottom: 2.5rem !important;
  }
  .pl-xxl-40pt,
  .px-xxl-40pt {
    padding-left: 2.5rem !important;
  }
  .p-xxl-44pt {
    padding: 2.75rem !important;
  }
  .pt-xxl-44pt,
  .py-xxl-44pt {
    padding-top: 2.75rem !important;
  }
  .pr-xxl-44pt,
  .px-xxl-44pt {
    padding-right: 2.75rem !important;
  }
  .pb-xxl-44pt,
  .py-xxl-44pt {
    padding-bottom: 2.75rem !important;
  }
  .pl-xxl-44pt,
  .px-xxl-44pt {
    padding-left: 2.75rem !important;
  }
  .p-xxl-48pt {
    padding: 3rem !important;
  }
  .pt-xxl-48pt,
  .py-xxl-48pt {
    padding-top: 3rem !important;
  }
  .pr-xxl-48pt,
  .px-xxl-48pt {
    padding-right: 3rem !important;
  }
  .pb-xxl-48pt,
  .py-xxl-48pt {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-48pt,
  .px-xxl-48pt {
    padding-left: 3rem !important;
  }
  .p-xxl-64pt {
    padding: 4rem !important;
  }
  .pt-xxl-64pt,
  .py-xxl-64pt {
    padding-top: 4rem !important;
  }
  .pr-xxl-64pt,
  .px-xxl-64pt {
    padding-right: 4rem !important;
  }
  .pb-xxl-64pt,
  .py-xxl-64pt {
    padding-bottom: 4rem !important;
  }
  .pl-xxl-64pt,
  .px-xxl-64pt {
    padding-left: 4rem !important;
  }
  .p-xxl-112pt {
    padding: 7rem !important;
  }
  .pt-xxl-112pt,
  .py-xxl-112pt {
    padding-top: 7rem !important;
  }
  .pr-xxl-112pt,
  .px-xxl-112pt {
    padding-right: 7rem !important;
  }
  .pb-xxl-112pt,
  .py-xxl-112pt {
    padding-bottom: 7rem !important;
  }
  .pl-xxl-112pt,
  .px-xxl-112pt {
    padding-left: 7rem !important;
  }
  .p-xxl-128pt {
    padding: 8rem !important;
  }
  .pt-xxl-128pt,
  .py-xxl-128pt {
    padding-top: 8rem !important;
  }
  .pr-xxl-128pt,
  .px-xxl-128pt {
    padding-right: 8rem !important;
  }
  .pb-xxl-128pt,
  .py-xxl-128pt {
    padding-bottom: 8rem !important;
  }
  .pl-xxl-128pt,
  .px-xxl-128pt {
    padding-left: 8rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-n4pt {
    margin: -0.25rem !important;
  }
  .mt-xxl-n4pt,
  .my-xxl-n4pt {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n4pt,
  .mx-xxl-n4pt {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n4pt,
  .my-xxl-n4pt {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n4pt,
  .mx-xxl-n4pt {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n8pt {
    margin: -0.5rem !important;
  }
  .mt-xxl-n8pt,
  .my-xxl-n8pt {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n8pt,
  .mx-xxl-n8pt {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n8pt,
  .my-xxl-n8pt {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n8pt,
  .mx-xxl-n8pt {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n12pt {
    margin: -0.75rem !important;
  }
  .mt-xxl-n12pt,
  .my-xxl-n12pt {
    margin-top: -0.75rem !important;
  }
  .mr-xxl-n12pt,
  .mx-xxl-n12pt {
    margin-right: -0.75rem !important;
  }
  .mb-xxl-n12pt,
  .my-xxl-n12pt {
    margin-bottom: -0.75rem !important;
  }
  .ml-xxl-n12pt,
  .mx-xxl-n12pt {
    margin-left: -0.75rem !important;
  }
  .m-xxl-n16pt {
    margin: -1rem !important;
  }
  .mt-xxl-n16pt,
  .my-xxl-n16pt {
    margin-top: -1rem !important;
  }
  .mr-xxl-n16pt,
  .mx-xxl-n16pt {
    margin-right: -1rem !important;
  }
  .mb-xxl-n16pt,
  .my-xxl-n16pt {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n16pt,
  .mx-xxl-n16pt {
    margin-left: -1rem !important;
  }
  .m-xxl-n20pt {
    margin: -1.25rem !important;
  }
  .mt-xxl-n20pt,
  .my-xxl-n20pt {
    margin-top: -1.25rem !important;
  }
  .mr-xxl-n20pt,
  .mx-xxl-n20pt {
    margin-right: -1.25rem !important;
  }
  .mb-xxl-n20pt,
  .my-xxl-n20pt {
    margin-bottom: -1.25rem !important;
  }
  .ml-xxl-n20pt,
  .mx-xxl-n20pt {
    margin-left: -1.25rem !important;
  }
  .m-xxl-n24pt {
    margin: -1.5rem !important;
  }
  .mt-xxl-n24pt,
  .my-xxl-n24pt {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n24pt,
  .mx-xxl-n24pt {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n24pt,
  .my-xxl-n24pt {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n24pt,
  .mx-xxl-n24pt {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n32pt {
    margin: -2rem !important;
  }
  .mt-xxl-n32pt,
  .my-xxl-n32pt {
    margin-top: -2rem !important;
  }
  .mr-xxl-n32pt,
  .mx-xxl-n32pt {
    margin-right: -2rem !important;
  }
  .mb-xxl-n32pt,
  .my-xxl-n32pt {
    margin-bottom: -2rem !important;
  }
  .ml-xxl-n32pt,
  .mx-xxl-n32pt {
    margin-left: -2rem !important;
  }
  .m-xxl-n40pt {
    margin: -2.5rem !important;
  }
  .mt-xxl-n40pt,
  .my-xxl-n40pt {
    margin-top: -2.5rem !important;
  }
  .mr-xxl-n40pt,
  .mx-xxl-n40pt {
    margin-right: -2.5rem !important;
  }
  .mb-xxl-n40pt,
  .my-xxl-n40pt {
    margin-bottom: -2.5rem !important;
  }
  .ml-xxl-n40pt,
  .mx-xxl-n40pt {
    margin-left: -2.5rem !important;
  }
  .m-xxl-n44pt {
    margin: -2.75rem !important;
  }
  .mt-xxl-n44pt,
  .my-xxl-n44pt {
    margin-top: -2.75rem !important;
  }
  .mr-xxl-n44pt,
  .mx-xxl-n44pt {
    margin-right: -2.75rem !important;
  }
  .mb-xxl-n44pt,
  .my-xxl-n44pt {
    margin-bottom: -2.75rem !important;
  }
  .ml-xxl-n44pt,
  .mx-xxl-n44pt {
    margin-left: -2.75rem !important;
  }
  .m-xxl-n48pt {
    margin: -3rem !important;
  }
  .mt-xxl-n48pt,
  .my-xxl-n48pt {
    margin-top: -3rem !important;
  }
  .mr-xxl-n48pt,
  .mx-xxl-n48pt {
    margin-right: -3rem !important;
  }
  .mb-xxl-n48pt,
  .my-xxl-n48pt {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n48pt,
  .mx-xxl-n48pt {
    margin-left: -3rem !important;
  }
  .m-xxl-n64pt {
    margin: -4rem !important;
  }
  .mt-xxl-n64pt,
  .my-xxl-n64pt {
    margin-top: -4rem !important;
  }
  .mr-xxl-n64pt,
  .mx-xxl-n64pt {
    margin-right: -4rem !important;
  }
  .mb-xxl-n64pt,
  .my-xxl-n64pt {
    margin-bottom: -4rem !important;
  }
  .ml-xxl-n64pt,
  .mx-xxl-n64pt {
    margin-left: -4rem !important;
  }
  .m-xxl-n112pt {
    margin: -7rem !important;
  }
  .mt-xxl-n112pt,
  .my-xxl-n112pt {
    margin-top: -7rem !important;
  }
  .mr-xxl-n112pt,
  .mx-xxl-n112pt {
    margin-right: -7rem !important;
  }
  .mb-xxl-n112pt,
  .my-xxl-n112pt {
    margin-bottom: -7rem !important;
  }
  .ml-xxl-n112pt,
  .mx-xxl-n112pt {
    margin-left: -7rem !important;
  }
  .m-xxl-n128pt {
    margin: -8rem !important;
  }
  .mt-xxl-n128pt,
  .my-xxl-n128pt {
    margin-top: -8rem !important;
  }
  .mr-xxl-n128pt,
  .mx-xxl-n128pt {
    margin-right: -8rem !important;
  }
  .mb-xxl-n128pt,
  .my-xxl-n128pt {
    margin-bottom: -8rem !important;
  }
  .ml-xxl-n128pt,
  .mx-xxl-n128pt {
    margin-left: -8rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 374px) {
  .text-i8-left {
    text-align: left !important;
  }
  .text-i8-right {
    text-align: right !important;
  }
  .text-i8-center {
    text-align: center !important;
  }
}

@media (min-width: 413px) {
  .text-i8-plus-left {
    text-align: left !important;
  }
  .text-i8-plus-right {
    text-align: right !important;
  }
  .text-i8-plus-center {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1366px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #5567FF !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0923ff !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #60686f !important;
}

.text-success {
  color: #77c13a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #538628 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #E4A93C !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba821a !important;
}

.text-danger {
  color: #d9534f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #303956 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #151825 !important;
}

.text-black {
  color: #272C33 !important;
}

a.text-black:hover, a.text-black:focus {
  color: #060708 !important;
}

.text-accent {
  color: #ED0B4C !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #a40835 !important;
}

.text-accent-red {
  color: #ED0B4C !important;
}

a.text-accent-red:hover, a.text-accent-red:focus {
  color: #a40835 !important;
}

.text-accent-yellow {
  color: #E4A93C !important;
}

a.text-accent-yellow:hover, a.text-accent-yellow:focus {
  color: #ba821a !important;
}

.text-accent-dodger-blue {
  color: #5567FF !important;
}

a.text-accent-dodger-blue:hover, a.text-accent-dodger-blue:focus {
  color: #0923ff !important;
}

.text-accent-pickled-bluewood {
  color: #303956 !important;
}

a.text-accent-pickled-bluewood:hover, a.text-accent-pickled-bluewood:focus {
  color: #151825 !important;
}

.text-accent-electric-violet {
  color: #9C42FF !important;
}

a.text-accent-electric-violet:hover, a.text-accent-electric-violet:focus {
  color: #7400f5 !important;
}

.text-primary-purple {
  color: #824EE1 !important;
}

a.text-primary-purple:hover, a.text-primary-purple:focus {
  color: #5a21c2 !important;
}

.text-primary-red {
  color: #ED0B4C !important;
}

a.text-primary-red:hover, a.text-primary-red:focus {
  color: #a40835 !important;
}

.text-primary-yellow {
  color: #E4A93C !important;
}

a.text-primary-yellow:hover, a.text-primary-yellow:focus {
  color: #ba821a !important;
}

.text-primary-light {
  color: #99ccff !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #4da6ff !important;
}

.text-primary-dodger-blue {
  color: #5567FF !important;
}

a.text-primary-dodger-blue:hover, a.text-primary-dodger-blue:focus {
  color: #0923ff !important;
}

.text-primary-pickled-bluewood {
  color: #303956 !important;
}

a.text-primary-pickled-bluewood:hover, a.text-primary-pickled-bluewood:focus {
  color: #151825 !important;
}

.text-body {
  color: #272C33 !important;
}

.text-muted {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-black-50 {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #272C33;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #E9EDF2;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #E9EDF2;
  }
}

.custom-checkbox-toggle {
  width: 3rem;
  height: 1.5rem;
  padding-left: 0;
}

.custom-checkbox-toggle .custom-control-label {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-checkbox-toggle .custom-control-label::after, .custom-checkbox-toggle .custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transition: all .2s ease;
  border-radius: 1.5rem;
}

.custom-checkbox-toggle .custom-control-label:before {
  width: 100%;
  height: 100%;
  background-color: #e3ebf6;
}

.custom-checkbox-toggle .custom-control-label::after {
  width: 1.5rem;
  height: 100%;
  transform: scale(0.8);
  background-color: #fff;
}

.custom-checkbox-toggle .custom-control-input:checked ~ .custom-control-label::after {
  right: 0;
  left: 1.5rem;
  background-color: #fff;
}

.form-image-group {
  position: relative;
}

.form-image-group .custom-control {
  margin: 0;
  padding: 0;
}

.form-image-group .custom-control-inline .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-image-group label {
  cursor: pointer;
  margin: 0;
  padding: 4px;
  border: 1px solid #E9EDF2;
  border-radius: 0.25rem;
}

.form-image-group label::before, .form-image-group label::after {
  display: none;
}

.form-image-group :checked ~ label {
  padding: 2px;
  border: 3px solid #5567FF;
}

.is-valid .form-image-group :checked ~ label {
  border-color: #77c13a;
}

.is-invalid .form-image-group label {
  border-color: #d9534f;
}

#settings .form-image-group {
  flex-wrap: wrap !important;
}

#settings .form-image-group:focus {
  outline: none;
}

#settings .form-image-group .col {
  margin-left: 0 !important;
}

#settings .form-image-group .col:nth-child(even) {
  margin-left: .5rem !important;
}

#settings .form-image-group .col:nth-child(1n+3) {
  margin-top: .5rem !important;
}

#settings .form-image-group img {
  width: 110px;
}

.flatpickr-input[readonly] {
  background-color: white;
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-hidden-input {
  visibility: hidden;
  height: 0;
}

.flatpickr-calendar-right .flatpickr-calendar {
  right: -1.5rem;
}

@media (min-width: 576px) {
  .flatpickr-calendar-right .flatpickr-calendar {
    right: 0;
  }
}

.flatpickr-calendar.inline {
  box-shadow: none;
  border: none;
}

.flatpickr-calendar.inline:before, .flatpickr-calendar.inline:after {
  display: none;
}

.flatpickr-calendar.inline,
.flatpickr-calendar.inline .flatpickr-days,
.flatpickr-calendar.inline .dayContainer {
  width: 100%;
  max-width: none;
}

.flatpickr-wrapper .flatpickr-input {
  height: 0;
  visibility: hidden;
  padding: 0;
}

.chart {
  position: relative;
  height: 300px;
}

.chart-legend {
  display: flex;
  margin-top: 2.5rem;
  white-space: nowrap;
  justify-content: center;
}

.chart-legend-item {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 0.707rem;
  color: rgba(39, 44, 51, 0.5);
  padding: .25rem .5rem;
  border: 1px solid #E9EDF2;
  border-radius: 0.2rem;
}

.chart-legend-indicator {
  display: block;
  width: .5rem;
  height: .5rem;
  margin-right: .25rem;
  border-radius: 50%;
}

.chart-legend:not(.chart-legend--vertical) .chart-legend-item + .chart-legend-item {
  margin-left: .5rem;
}

.chart-legend--vertical {
  flex-direction: column;
}

.chart-legend--vertical .chart-legend-item + .chart-legend-item {
  margin-top: .5rem;
}

#chart-tooltip {
  z-index: 0;
  pointer-events: none;
}

#chart-tooltip .popover-header {
  border-bottom: 0;
}

#chart-tooltip .popover-body {
  text-transform: uppercase;
  font-size: 0.707rem;
  color: rgba(39, 44, 51, 0.7);
}

#chart-tooltip .popover-body + .popover-body {
  padding-top: 0;
}

#chart-tooltip .popover-body-label,
#chart-tooltip .popover-body-value {
  margin-left: .25rem;
}

#chart-tooltip .popover-body-indicator {
  margin-right: .25rem;
}

#chart-tooltip .arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateX(-0.5rem);
}

#chart-tooltip .popover-body-indicator {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}

.avatar {
  font-size: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 0;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-online::before, .avatar-online::after, .avatar-offline::before, .avatar-offline::after {
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 20%;
  height: 20%;
  content: "";
  border-radius: 50%;
}

.avatar-online::before, .avatar-offline::before {
  width: 22%;
  height: 22%;
  right: 4%;
  bottom: 4%;
  background-color: white;
}

.avatar-online::after {
  background-color: #77c13a;
}

.avatar-offline::after {
  background-color: #818A91;
}

.avatar-title {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #818A91;
  align-items: center;
  justify-content: center;
}

.avatar-xl, .avatar-xxl {
  font-size: 1.70833rem;
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-lg {
  font-size: 1.33333rem;
  width: 4rem;
  height: 4rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-xs {
  font-size: .54167rem;
  width: 1.625rem;
  height: 1.625rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    font-size: 2.66667rem;
    width: 8rem;
    height: 8rem;
  }
}

.avatar.avatar-4by3 {
  width: 4rem;
}

.avatar-xxl.avatar-4by3 {
  width: 10.66667rem;
}

.avatar-xl.avatar-4by3 {
  width: 6.83333rem;
}

.avatar-lg.avatar-4by3 {
  width: 5.33333rem;
}

.avatar-group {
  display: inline-flex;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-group .avatar-img,
.avatar-group .avatar-title {
  border: 2px solid white;
}

.avatar-group .avatar + .avatar {
  margin-left: -.75rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -.625rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.40625rem;
}

.avatar-list > .avatar,
.avatar-list > .avatar-group {
  margin-bottom: .75rem;
  margin-left: .5rem;
}

.is-loading {
  position: relative;
  color: transparent !important;
}

.is-loading:after,
.loader {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  animation: 1s is-loading linear infinite;
  border: 3px solid #272C33;
  border-bottom-color: transparent;
  border-radius: 50%;
  background: transparent;
}

.is-loading-sm:after, .loader-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.is-loading-lg:after, .loader-lg {
  width: 2rem;
  height: 2rem;
  border-width: 5px;
}

.is-loading > * {
  opacity: 0 !important;
}

.is-loading:after {
  position: absolute;
  top: calc(50% - 1.5rem / 2);
  left: calc(50% - 1.5rem / 2);
  content: "";
}

.is-loading-sm:after {
  top: calc(50% - 1rem / 2);
  left: calc(50% - 1rem / 2);
}

.is-loading-lg:after {
  top: calc(50% - 2rem / 2);
  left: calc(50% - 2rem / 2);
}

.is-loading-primary:after,
.btn-outline-primary.is-loading:after,
.loader-primary {
  border-color: #5567FF;
  border-bottom-color: transparent;
}

.btn-primary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-secondary:after,
.btn-outline-secondary.is-loading:after,
.loader-secondary {
  border-color: #868e96;
  border-bottom-color: transparent;
}

.btn-secondary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-success:after,
.btn-outline-success.is-loading:after,
.loader-success {
  border-color: #77c13a;
  border-bottom-color: transparent;
}

.btn-success.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-info:after,
.btn-outline-info.is-loading:after,
.loader-info {
  border-color: #17a2b8;
  border-bottom-color: transparent;
}

.btn-info.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-warning:after,
.btn-outline-warning.is-loading:after,
.loader-warning {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-warning.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-danger:after,
.btn-outline-danger.is-loading:after,
.loader-danger {
  border-color: #d9534f;
  border-bottom-color: transparent;
}

.btn-danger.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-light:after,
.btn-outline-light.is-loading:after,
.loader-light {
  border-color: #f8f9fa;
  border-bottom-color: transparent;
}

.btn-light.is-loading:after, .navbar-light .is-loading.navbar-btn:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-dark:after,
.btn-outline-dark.is-loading:after,
.loader-dark {
  border-color: #303956;
  border-bottom-color: transparent;
}

.btn-dark.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-black:after,
.btn-outline-black.is-loading:after,
.loader-black {
  border-color: #272C33;
  border-bottom-color: transparent;
}

.btn-black.is-loading:after, .navbar-dark .is-loading.navbar-btn:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent:after,
.btn-outline-accent.is-loading:after,
.loader-accent {
  border-color: #ED0B4C;
  border-bottom-color: transparent;
}

.btn-accent.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-red:after,
.btn-outline-accent-red.is-loading:after,
.loader-accent-red {
  border-color: #ED0B4C;
  border-bottom-color: transparent;
}

.btn-accent-red.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-yellow:after,
.btn-outline-accent-yellow.is-loading:after,
.loader-accent-yellow {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-accent-yellow.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-accent-dodger-blue:after,
.btn-outline-accent-dodger-blue.is-loading:after,
.loader-accent-dodger-blue {
  border-color: #5567FF;
  border-bottom-color: transparent;
}

.btn-accent-dodger-blue.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-pickled-bluewood:after,
.btn-outline-accent-pickled-bluewood.is-loading:after,
.loader-accent-pickled-bluewood {
  border-color: #303956;
  border-bottom-color: transparent;
}

.btn-accent-pickled-bluewood.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-electric-violet:after,
.btn-outline-accent-electric-violet.is-loading:after,
.loader-accent-electric-violet {
  border-color: #9C42FF;
  border-bottom-color: transparent;
}

.btn-accent-electric-violet.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-purple:after,
.btn-outline-primary-purple.is-loading:after,
.loader-primary-purple {
  border-color: #824EE1;
  border-bottom-color: transparent;
}

.btn-primary-purple.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-red:after,
.btn-outline-primary-red.is-loading:after,
.loader-primary-red {
  border-color: #ED0B4C;
  border-bottom-color: transparent;
}

.btn-primary-red.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-yellow:after,
.btn-outline-primary-yellow.is-loading:after,
.loader-primary-yellow {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-primary-yellow.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-primary-light:after,
.btn-outline-primary-light.is-loading:after,
.loader-primary-light {
  border-color: #99ccff;
  border-bottom-color: transparent;
}

.btn-primary-light.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-primary-dodger-blue:after,
.btn-outline-primary-dodger-blue.is-loading:after,
.loader-primary-dodger-blue {
  border-color: #5567FF;
  border-bottom-color: transparent;
}

.btn-primary-dodger-blue.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-pickled-bluewood:after,
.btn-outline-primary-pickled-bluewood.is-loading:after,
.loader-primary-pickled-bluewood {
  border-color: #303956;
  border-bottom-color: transparent;
}

.btn-primary-pickled-bluewood.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

@keyframes is-loading {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.loader-list {
  display: flex;
  align-items: center;
}

.loader-list > .loader {
  margin-bottom: .75rem;
  margin-left: .5rem;
}

.corner-ribbon {
  margin: 0;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  font-weight: 500;
  box-sizing: border-box;
}

.corner-ribbon--default-left-top {
  transform-origin: 100% 100%;
  height: 30px;
  width: 100px;
  transform: rotateZ(-45deg);
  padding-right: -30px;
  padding-left: -29px;
  line-height: 30px;
  left: -30px;
  top: -30px;
}

.corner-ribbon--default-left-bottom {
  transform-origin: 100% 0;
  height: 30px;
  width: 100px;
  transform: rotateZ(45deg);
  padding-right: 31px;
  padding-left: 30px;
  line-height: 30px;
  left: -30px;
  bottom: -30px;
}

.corner-ribbon--default-right-top {
  transform-origin: 0 100%;
  height: 30px;
  width: 100px;
  transform: rotateZ(45deg);
  padding-right: 31px;
  padding-left: 30px;
  line-height: 30px;
  right: -30px;
  top: -30px;
}

.corner-ribbon--default-right-bottom {
  transform-origin: 0 0;
  height: 30px;
  width: 100px;
  transform: rotateZ(-45deg);
  padding-right: -30px;
  padding-left: -29px;
  line-height: 30px;
  right: -30px;
  bottom: -30px;
}

.corner-ribbon--fixed {
  position: fixed;
}

.corner-ribbon--shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.corner-ribbon--primary {
  color: #fff;
  background-color: #2c84dd;
}

.corner-ribbon--success {
  color: #fff;
  background-color: #77c13a;
}

.mdk-header {
  position: relative;
  z-index: 1;
  margin-bottom: 1.25rem;
  height: 200px;
}

.mdk-header :last-child {
  margin-bottom: 0;
}

.mdk-header[data-condenses], .mdk-header[data-reveals] {
  will-change: transform;
}

.mdk-header [data-fx-condenses] {
  transform-origin: left top;
  opacity: 0;
}

.mdk-header [data-fx-condenses][data-fx-id] {
  opacity: 1;
}

.mdk-header [data-primary] {
  z-index: 1;
}

.mdk-header__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

[data-effects*="parallax-background"] .mdk-header__bg {
  visibility: hidden;
}

.mdk-header__bg-rear,
.mdk-header__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-header__bg-rear {
  opacity: 0;
}

.mdk-header__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-header[data-condenses] .mdk-header__content {
  z-index: 0;
}

.mdk-header[data-condenses] [data-primary] {
  z-index: 1;
}

.mdk-header--shadow::after {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  pointer-events: none;
  opacity: 0;
  box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  will-change: opacity;
  z-index: -1;
}

.mdk-header--shadow-show::after {
  opacity: 1;
}

.mdk-header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
}

.mdk-header[disabled], .mdk-header[disabled]::after,
.mdk-header[disabled] .mdk-header__bg-rear,
.mdk-header[disabled] .mdk-header__bg-front,
.mdk-header[disabled] .mdk-header__content > [data-primary] {
  transition: none !important;
}

.mdk-header-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.mdk-header-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mdk-header-layout > .mdk-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.mdk-header-layout[data-fullbleed] > .mdk-header, .mdk-header-layout[data-has-scrolling-region] > .mdk-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mdk-header-layout__content {
  position: relative;
  z-index: 0;
}

.mdk-header-layout__content--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mdk-header-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-box {
  position: relative;
  margin-bottom: 1.25rem;
}

.mdk-box__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
}

[data-effects*="parallax-background"] .mdk-box__bg {
  visibility: hidden;
}

.mdk-box__bg-rear,
.mdk-box__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-box__bg-rear {
  opacity: 0;
}

.mdk-box__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-box[disabled], .mdk-box[disabled]::after,
.mdk-box[disabled] .mdk-box__bg-rear,
.mdk-box[disabled] .mdk-box__bg-front,
.mdk-box[disabled] .mdk-box__content > [data-primary] {
  transition: none !important;
}

.mdk-drawer {
  position: relative;
  visibility: hidden;
}

.mdk-drawer[data-opened], .mdk-drawer[data-closing] {
  visibility: visible;
}

.mdk-drawer[data-persistent] {
  width: 256px;
}

.mdk-drawer[data-persistent][data-position=left] {
  right: auto/* rtl:ignore */;
}

.mdk-drawer[data-persistent][data-position=right] {
  left: auto/* rtl:ignore */;
}

.mdk-drawer__content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  transition: transform 0.2s ease;
  background-color: #fff;
  left: 0/* rtl:ignore */;
  transform: translate3d(-100%, 0, 0)/* rtl:ignore */;
}

[data-position=right] > .mdk-drawer__content {
  right: 0/* rtl:ignore */;
  left: auto/* rtl:ignore */;
  transform: translate3d(100%, 0, 0)/* rtl:ignore */;
}

[data-opened] > .mdk-drawer__content {
  transform: translate3d(0, 0, 0);
}

.mdk-drawer__scrim {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease;
  transform: translateZ(0);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

[data-opened] > .mdk-drawer__scrim {
  opacity: 1;
}

[data-persistent] > .mdk-drawer__scrim {
  visibility: hidden;
  opacity: 0;
}

.mdk-drawer-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.mdk-drawer-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mdk-drawer-layout .mdk-drawer {
  position: relative;
}

.mdk-drawer-layout .mdk-drawer[data-persistent] {
  width: 256px;
}

.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=left] {
  order: 0/* rtl:2 */;
}

.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=right] {
  order: 2/* rtl:0 */;
}

.mdk-drawer-layout__content {
  position: relative;
  height: 100%;
  transition: transform 0.2s;
  min-width: 320px;
  z-index: 0;
  order: 1;
  flex: 1 1 0%;
}

.mdk-drawer-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-reveal {
  z-index: 0;
  position: relative;
  overflow: hidden;
  display: block;
}

.mdk-reveal[data-opened] .mdk-reveal__partial, .mdk-reveal--hover:hover .mdk-reveal__partial {
  opacity: 0;
}

.mdk-reveal__content {
  transition: transform .2s;
  background-color: #ffffff;
  position: relative;
  z-index: 0;
}

.mdk-reveal__partial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: '';
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.95) 80%, white 100%);
  opacity: 1;
  z-index: 1;
  transition: .2s opacity;
  pointer-events: none;
}

.mdk-carousel__content {
  transition: transform .4s;
  transform: translate3d(0, 0, 0);
  touch-action: pan-x;
}

.mdk-carousel__item {
  float: left;
  user-select: none;
  cursor: move;
  cursor: grab;
  -webkit-touch-callout: none;
  width: 100%;
}

.mdk-tooltip {
  transform: scale(0);
  transition: transform .2s;
  transform-origin: top center;
  will-change: transform;
  display: inline-block;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 13px;
  z-index: 999;
  font-weight: 500;
  text-align: center;
  position: fixed;
  line-height: 1;
  max-width: 200px;
  padding: .4rem;
  border-radius: .25rem;
  pointer-events: none;
}

.mdk-tooltip[data-opened] {
  transform: scale(1);
}

.flex {
  flex: 1 1 0% !important;
}

.flex-none,
.flex-0 {
  flex: none !important;
}

.flex-grow {
  flex: 1 1 auto !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

@media (min-width: 374px) {
  .flex-i8 {
    flex: 1 1 0% !important;
  }
  .flex-i8-none,
  .flex-i8-0 {
    flex: none !important;
  }
  .flex-i8-grow {
    flex: 1 1 auto !important;
  }
  .flex-i8-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 413px) {
  .flex-i8-plus {
    flex: 1 1 0% !important;
  }
  .flex-i8-plus-none,
  .flex-i8-plus-0 {
    flex: none !important;
  }
  .flex-i8-plus-grow {
    flex: 1 1 auto !important;
  }
  .flex-i8-plus-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 576px) {
  .flex-sm {
    flex: 1 1 0% !important;
  }
  .flex-sm-none,
  .flex-sm-0 {
    flex: none !important;
  }
  .flex-sm-grow {
    flex: 1 1 auto !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 768px) {
  .flex-md {
    flex: 1 1 0% !important;
  }
  .flex-md-none,
  .flex-md-0 {
    flex: none !important;
  }
  .flex-md-grow {
    flex: 1 1 auto !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 992px) {
  .flex-lg {
    flex: 1 1 0% !important;
  }
  .flex-lg-none,
  .flex-lg-0 {
    flex: none !important;
  }
  .flex-lg-grow {
    flex: 1 1 auto !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl {
    flex: 1 1 0% !important;
  }
  .flex-xl-none,
  .flex-xl-0 {
    flex: none !important;
  }
  .flex-xl-grow {
    flex: 1 1 auto !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
}

@media (min-width: 1366px) {
  .flex-xxl {
    flex: 1 1 0% !important;
  }
  .flex-xxl-none,
  .flex-xxl-0 {
    flex: none !important;
  }
  .flex-xxl-grow {
    flex: 1 1 auto !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
}

.w-100px {
  width: !important;
}

.w-30px {
  width: !important;
}

.h-100px {
  height: !important;
}

.h-30px {
  height: !important;
}

@media (min-width: 374px) {
  .w-i8-100px {
    width: !important;
  }
  .w-i8-30px {
    width: !important;
  }
  .h-i8-100px {
    height: !important;
  }
  .h-i8-30px {
    height: !important;
  }
}

@media (min-width: 413px) {
  .w-i8-plus-100px {
    width: !important;
  }
  .w-i8-plus-30px {
    width: !important;
  }
  .h-i8-plus-100px {
    height: !important;
  }
  .h-i8-plus-30px {
    height: !important;
  }
}

@media (min-width: 576px) {
  .w-sm-100px {
    width: !important;
  }
  .w-sm-30px {
    width: !important;
  }
  .h-sm-100px {
    height: !important;
  }
  .h-sm-30px {
    height: !important;
  }
}

@media (min-width: 768px) {
  .w-md-100px {
    width: !important;
  }
  .w-md-30px {
    width: !important;
  }
  .h-md-100px {
    height: !important;
  }
  .h-md-30px {
    height: !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100px {
    width: !important;
  }
  .w-lg-30px {
    width: !important;
  }
  .h-lg-100px {
    height: !important;
  }
  .h-lg-30px {
    height: !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100px {
    width: !important;
  }
  .w-xl-30px {
    width: !important;
  }
  .h-xl-100px {
    height: !important;
  }
  .h-xl-30px {
    height: !important;
  }
}

@media (min-width: 1366px) {
  .w-xxl-100px {
    width: !important;
  }
  .w-xxl-30px {
    width: !important;
  }
  .h-xxl-100px {
    height: !important;
  }
  .h-xxl-30px {
    height: !important;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-facebook {
  color: #3b5998;
}

.text-twitter {
  color: #0084b4;
}

.text-dribbble {
  color: #ea4c89;
}

.hover-text-decoration-0:hover, .hover-text-decoration-0:focus {
  text-decoration: none;
}

.lh-1 {
  line-height: 1rem;
}

.text-20, .text-black-20 {
  color: rgba(39, 44, 51, 0.2) !important;
}

.text-50, .text-black-50 {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-70, .text-black-70 {
  color: rgba(39, 44, 51, 0.7) !important;
}

.text-100, .text-black-100 {
  color: #272C33 !important;
}

.text-white-20 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.mb-heading {
  margin-bottom: 1rem !important;
}

.mb-grid-gutter {
  margin-bottom: 24px !important;
}

.mb-card-y {
  margin-bottom: 1rem;
}

@media (max-width: 373.98px) {
  .p-0-xs {
    padding: 0 !important;
  }
}

.col {
  padding-left: 12px;
  padding-right: 12px;
}

.icon--left {
  margin-right: .5rem;
}

.icon--right {
  margin-left: .5rem;
}

.fullbleed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.position-absolute-top,
.absolute-top {
  position: absolute;
  left: 0;
  top: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.o-hidden {
  overflow: hidden !important;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none;
  }
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.bg-primary {
  background-color: #5567FF !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

.bg-success {
  background-color: #77c13a !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-warning {
  background-color: #E4A93C !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #303956 !important;
}

.bg-black {
  background-color: #272C33 !important;
}

.bg-accent {
  background-color: #ED0B4C !important;
}

.bg-accent-red {
  background-color: #ED0B4C !important;
}

.bg-accent-yellow {
  background-color: #E4A93C !important;
}

.bg-accent-dodger-blue {
  background-color: #5567FF !important;
}

.bg-accent-pickled-bluewood {
  background-color: #303956 !important;
}

.bg-accent-electric-violet {
  background-color: #9C42FF !important;
}

.bg-primary-purple {
  background-color: #824EE1 !important;
}

.bg-primary-red {
  background-color: #ED0B4C !important;
}

.bg-primary-yellow {
  background-color: #E4A93C !important;
}

.bg-primary-light {
  background-color: #99ccff !important;
}

.bg-primary-dodger-blue {
  background-color: #5567FF !important;
}

.bg-primary-pickled-bluewood {
  background-color: #303956 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-alt {
  background: white;
}

.bg-body {
  background-color: #F5F7FA !important;
}

.bg-transparent {
  background: transparent !important;
}

.bg-darker {
  background-color: #2b324c !important;
}

.bg-gradient-purple {
  background-image: linear-gradient(-128deg, #8A4182 0%, #3289DC 100%);
}

.bg-gradient-primary {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
}

.bg-dark-blue {
  background-color: #0C2440 !important;
}

.bg-dark-purple {
  background: #824EE1 !important;
}

.border-1 {
  border: 1px solid #E9EDF2 !important;
}

.border-left-1 {
  border-left: 1px solid #E9EDF2 !important;
}

.border-left-1 {
  border-left: 1px solid #E9EDF2 !important;
}

.border-right-1 {
  border-right: 1px solid #E9EDF2 !important;
}

.border-right-1 {
  border-right: 1px solid #E9EDF2 !important;
}

.border-top-1 {
  border-top: 1px solid #E9EDF2 !important;
}

.border-top-1 {
  border-top: 1px solid #E9EDF2 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-2 {
  border: 2px solid #E9EDF2 !important;
}

.border-left-2 {
  border-left: 2px solid #E9EDF2 !important;
}

.border-left-2 {
  border-left: 2px solid #E9EDF2 !important;
}

.border-right-2 {
  border-right: 2px solid #E9EDF2 !important;
}

.border-right-2 {
  border-right: 2px solid #E9EDF2 !important;
}

.border-top-2 {
  border-top: 2px solid #E9EDF2 !important;
}

.border-top-2 {
  border-top: 2px solid #E9EDF2 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #E9EDF2 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #E9EDF2 !important;
}

.border-3 {
  border: 3px solid #E9EDF2 !important;
}

.border-left-3 {
  border-left: 3px solid #E9EDF2 !important;
}

.border-left-3 {
  border-left: 3px solid #E9EDF2 !important;
}

.border-right-3 {
  border-right: 3px solid #E9EDF2 !important;
}

.border-right-3 {
  border-right: 3px solid #E9EDF2 !important;
}

.border-top-3 {
  border-top: 3px solid #E9EDF2 !important;
}

.border-top-3 {
  border-top: 3px solid #E9EDF2 !important;
}

.border-bottom-3 {
  border-bottom: 3px solid #E9EDF2 !important;
}

.border-bottom-3 {
  border-bottom: 3px solid #E9EDF2 !important;
}

.border-4 {
  border: 4px solid #E9EDF2 !important;
}

.border-left-4 {
  border-left: 4px solid #E9EDF2 !important;
}

.border-left-4 {
  border-left: 4px solid #E9EDF2 !important;
}

.border-right-4 {
  border-right: 4px solid #E9EDF2 !important;
}

.border-right-4 {
  border-right: 4px solid #E9EDF2 !important;
}

.border-top-4 {
  border-top: 4px solid #E9EDF2 !important;
}

.border-top-4 {
  border-top: 4px solid #E9EDF2 !important;
}

.border-bottom-4 {
  border-bottom: 4px solid #E9EDF2 !important;
}

.border-bottom-4 {
  border-bottom: 4px solid #E9EDF2 !important;
}

.border {
  border: 1px solid #E9EDF2 !important;
}

.border-top {
  border-top: 1px solid #E9EDF2 !important;
}

.border-right {
  border-right: 1px solid #E9EDF2 !important;
}

.border-bottom {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-left {
  border-left: 1px solid #E9EDF2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

@media (min-width: 374px) {
  .border-i8 {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-i8 {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-i8 {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-i8 {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-i8 {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-i8-0 {
    border: 0 !important;
  }
  .border-top-i8-0 {
    border-top: 0 !important;
  }
  .border-right-i8-0 {
    border-right: 0 !important;
  }
  .border-bottom-i8-0 {
    border-bottom: 0 !important;
  }
  .border-left-i8-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 413px) {
  .border-i8-plus {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-i8-plus {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-i8-plus {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-i8-plus {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-i8-plus {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-i8-plus-0 {
    border: 0 !important;
  }
  .border-top-i8-plus-0 {
    border-top: 0 !important;
  }
  .border-right-i8-plus-0 {
    border-right: 0 !important;
  }
  .border-bottom-i8-plus-0 {
    border-bottom: 0 !important;
  }
  .border-left-i8-plus-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-sm {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-sm {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-sm {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-sm {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-right-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-left-sm-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-md {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-md {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-md {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-md {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-right-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-left-md-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-lg {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-lg {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-lg {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-right-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-left-lg-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-xl {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-xl {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-xl {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-xl {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-right-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-left-xl-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1366px) {
  .border-xxl {
    border: 1px solid #E9EDF2 !important;
  }
  .border-top-xxl {
    border-top: 1px solid #E9EDF2 !important;
  }
  .border-right-xxl {
    border-right: 1px solid #E9EDF2 !important;
  }
  .border-bottom-xxl {
    border-bottom: 1px solid #E9EDF2 !important;
  }
  .border-left-xxl {
    border-left: 1px solid #E9EDF2 !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-right-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-left-xxl-0 {
    border-left: 0 !important;
  }
}

.border-primary {
  border-color: #5567FF !important;
}

.border-left-primary {
  border-left-color: #5567FF !important;
}

.border-right-primary {
  border-right-color: #5567FF !important;
}

.border-top-primary {
  border-top-color: #5567FF !important;
}

.border-bottom-primary {
  border-bottom-color: #5567FF !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-left-secondary {
  border-left-color: #868e96 !important;
}

.border-right-secondary {
  border-right-color: #868e96 !important;
}

.border-top-secondary {
  border-top-color: #868e96 !important;
}

.border-bottom-secondary {
  border-bottom-color: #868e96 !important;
}

.border-success {
  border-color: #77c13a !important;
}

.border-left-success {
  border-left-color: #77c13a !important;
}

.border-right-success {
  border-right-color: #77c13a !important;
}

.border-top-success {
  border-top-color: #77c13a !important;
}

.border-bottom-success {
  border-bottom-color: #77c13a !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-left-info {
  border-left-color: #17a2b8 !important;
}

.border-right-info {
  border-right-color: #17a2b8 !important;
}

.border-top-info {
  border-top-color: #17a2b8 !important;
}

.border-bottom-info {
  border-bottom-color: #17a2b8 !important;
}

.border-warning {
  border-color: #E4A93C !important;
}

.border-left-warning {
  border-left-color: #E4A93C !important;
}

.border-right-warning {
  border-right-color: #E4A93C !important;
}

.border-top-warning {
  border-top-color: #E4A93C !important;
}

.border-bottom-warning {
  border-bottom-color: #E4A93C !important;
}

.border-danger {
  border-color: #d9534f !important;
}

.border-left-danger {
  border-left-color: #d9534f !important;
}

.border-right-danger {
  border-right-color: #d9534f !important;
}

.border-top-danger {
  border-top-color: #d9534f !important;
}

.border-bottom-danger {
  border-bottom-color: #d9534f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-left-light {
  border-left-color: #f8f9fa !important;
}

.border-right-light {
  border-right-color: #f8f9fa !important;
}

.border-top-light {
  border-top-color: #f8f9fa !important;
}

.border-bottom-light {
  border-bottom-color: #f8f9fa !important;
}

.border-dark {
  border-color: #303956 !important;
}

.border-left-dark {
  border-left-color: #303956 !important;
}

.border-right-dark {
  border-right-color: #303956 !important;
}

.border-top-dark {
  border-top-color: #303956 !important;
}

.border-bottom-dark {
  border-bottom-color: #303956 !important;
}

.border-black {
  border-color: #272C33 !important;
}

.border-left-black {
  border-left-color: #272C33 !important;
}

.border-right-black {
  border-right-color: #272C33 !important;
}

.border-top-black {
  border-top-color: #272C33 !important;
}

.border-bottom-black {
  border-bottom-color: #272C33 !important;
}

.border-accent {
  border-color: #ED0B4C !important;
}

.border-left-accent {
  border-left-color: #ED0B4C !important;
}

.border-right-accent {
  border-right-color: #ED0B4C !important;
}

.border-top-accent {
  border-top-color: #ED0B4C !important;
}

.border-bottom-accent {
  border-bottom-color: #ED0B4C !important;
}

.border-accent-red {
  border-color: #ED0B4C !important;
}

.border-left-accent-red {
  border-left-color: #ED0B4C !important;
}

.border-right-accent-red {
  border-right-color: #ED0B4C !important;
}

.border-top-accent-red {
  border-top-color: #ED0B4C !important;
}

.border-bottom-accent-red {
  border-bottom-color: #ED0B4C !important;
}

.border-accent-yellow {
  border-color: #E4A93C !important;
}

.border-left-accent-yellow {
  border-left-color: #E4A93C !important;
}

.border-right-accent-yellow {
  border-right-color: #E4A93C !important;
}

.border-top-accent-yellow {
  border-top-color: #E4A93C !important;
}

.border-bottom-accent-yellow {
  border-bottom-color: #E4A93C !important;
}

.border-accent-dodger-blue {
  border-color: #5567FF !important;
}

.border-left-accent-dodger-blue {
  border-left-color: #5567FF !important;
}

.border-right-accent-dodger-blue {
  border-right-color: #5567FF !important;
}

.border-top-accent-dodger-blue {
  border-top-color: #5567FF !important;
}

.border-bottom-accent-dodger-blue {
  border-bottom-color: #5567FF !important;
}

.border-accent-pickled-bluewood {
  border-color: #303956 !important;
}

.border-left-accent-pickled-bluewood {
  border-left-color: #303956 !important;
}

.border-right-accent-pickled-bluewood {
  border-right-color: #303956 !important;
}

.border-top-accent-pickled-bluewood {
  border-top-color: #303956 !important;
}

.border-bottom-accent-pickled-bluewood {
  border-bottom-color: #303956 !important;
}

.border-accent-electric-violet {
  border-color: #9C42FF !important;
}

.border-left-accent-electric-violet {
  border-left-color: #9C42FF !important;
}

.border-right-accent-electric-violet {
  border-right-color: #9C42FF !important;
}

.border-top-accent-electric-violet {
  border-top-color: #9C42FF !important;
}

.border-bottom-accent-electric-violet {
  border-bottom-color: #9C42FF !important;
}

.border-primary-purple {
  border-color: #824EE1 !important;
}

.border-left-primary-purple {
  border-left-color: #824EE1 !important;
}

.border-right-primary-purple {
  border-right-color: #824EE1 !important;
}

.border-top-primary-purple {
  border-top-color: #824EE1 !important;
}

.border-bottom-primary-purple {
  border-bottom-color: #824EE1 !important;
}

.border-primary-red {
  border-color: #ED0B4C !important;
}

.border-left-primary-red {
  border-left-color: #ED0B4C !important;
}

.border-right-primary-red {
  border-right-color: #ED0B4C !important;
}

.border-top-primary-red {
  border-top-color: #ED0B4C !important;
}

.border-bottom-primary-red {
  border-bottom-color: #ED0B4C !important;
}

.border-primary-yellow {
  border-color: #E4A93C !important;
}

.border-left-primary-yellow {
  border-left-color: #E4A93C !important;
}

.border-right-primary-yellow {
  border-right-color: #E4A93C !important;
}

.border-top-primary-yellow {
  border-top-color: #E4A93C !important;
}

.border-bottom-primary-yellow {
  border-bottom-color: #E4A93C !important;
}

.border-primary-light {
  border-color: #99ccff !important;
}

.border-left-primary-light {
  border-left-color: #99ccff !important;
}

.border-right-primary-light {
  border-right-color: #99ccff !important;
}

.border-top-primary-light {
  border-top-color: #99ccff !important;
}

.border-bottom-primary-light {
  border-bottom-color: #99ccff !important;
}

.border-primary-dodger-blue {
  border-color: #5567FF !important;
}

.border-left-primary-dodger-blue {
  border-left-color: #5567FF !important;
}

.border-right-primary-dodger-blue {
  border-right-color: #5567FF !important;
}

.border-top-primary-dodger-blue {
  border-top-color: #5567FF !important;
}

.border-bottom-primary-dodger-blue {
  border-bottom-color: #5567FF !important;
}

.border-primary-pickled-bluewood {
  border-color: #303956 !important;
}

.border-left-primary-pickled-bluewood {
  border-left-color: #303956 !important;
}

.border-right-primary-pickled-bluewood {
  border-right-color: #303956 !important;
}

.border-top-primary-pickled-bluewood {
  border-top-color: #303956 !important;
}

.border-bottom-primary-pickled-bluewood {
  border-bottom-color: #303956 !important;
}

.border-bottom-white {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.order-0 {
  order: 0 !important;
}

@media (min-width: 374px) {
  .order-i8-0 {
    order: 0 !important;
  }
}

@media (min-width: 413px) {
  .order-i8-plus-0 {
    order: 0 !important;
  }
}

@media (min-width: 576px) {
  .order-sm-0 {
    order: 0 !important;
  }
}

@media (min-width: 768px) {
  .order-md-0 {
    order: 0 !important;
  }
}

@media (min-width: 992px) {
  .order-lg-0 {
    order: 0 !important;
  }
}

@media (min-width: 1200px) {
  .order-xl-0 {
    order: 0 !important;
  }
}

@media (min-width: 1366px) {
  .order-xxl-0 {
    order: 0 !important;
  }
}

.order-1 {
  order: 1 !important;
}

@media (min-width: 374px) {
  .order-i8-1 {
    order: 1 !important;
  }
}

@media (min-width: 413px) {
  .order-i8-plus-1 {
    order: 1 !important;
  }
}

@media (min-width: 576px) {
  .order-sm-1 {
    order: 1 !important;
  }
}

@media (min-width: 768px) {
  .order-md-1 {
    order: 1 !important;
  }
}

@media (min-width: 992px) {
  .order-lg-1 {
    order: 1 !important;
  }
}

@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1 !important;
  }
}

@media (min-width: 1366px) {
  .order-xxl-1 {
    order: 1 !important;
  }
}

.order-2 {
  order: 2 !important;
}

@media (min-width: 374px) {
  .order-i8-2 {
    order: 2 !important;
  }
}

@media (min-width: 413px) {
  .order-i8-plus-2 {
    order: 2 !important;
  }
}

@media (min-width: 576px) {
  .order-sm-2 {
    order: 2 !important;
  }
}

@media (min-width: 768px) {
  .order-md-2 {
    order: 2 !important;
  }
}

@media (min-width: 992px) {
  .order-lg-2 {
    order: 2 !important;
  }
}

@media (min-width: 1200px) {
  .order-xl-2 {
    order: 2 !important;
  }
}

@media (min-width: 1366px) {
  .order-xxl-2 {
    order: 2 !important;
  }
}

.position-close-4pt {
  position: absolute;
  right: 4px;
  top: 4px;
  display: flex;
}

.text-white-100 {
  color: white !important;
}

.text-shadow {
  text-shadow: rgba(39, 44, 51, 0.2) 0px 9px 10px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
}

.text-headings {
  font-family: "Source Sans Pro", Arial, sans-serif !important;
}

.lh-24pt {
  line-height: 1.5rem;
}

.bg-dark-blue {
  background-image: #0C2440;
}

.bg-purple-gradient {
  background-image: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}

.bg-black,
.bg-black-100 {
  background-color: #272C33 !important;
}

.bg-black-50 {
  background-color: rgba(39, 44, 51, 0.5) !important;
}

.bg-black-20 {
  background-color: rgba(39, 44, 51, 0.2) !important;
}

.p-card-header {
  padding: 1rem 1rem;
}

.link-date {
  color: rgba(39, 44, 51, 0.7);
  position: relative;
  overflow: hidden;
  background: rgba(85, 103, 255, 0.05);
  padding: 2px 4px;
  border-radius: 2px;
}

.link-date::after {
  content: "................................................................";
  color: rgba(39, 44, 51, 0.4);
  font-size: .75rem;
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  top: .525rem;
  white-space: nowrap;
}

.link-date:hover {
  text-decoration: none;
}

.material-icons {
  vertical-align: middle;
}

.icon-16pt {
  font-size: 1rem !important;
}

.icon-24pt {
  font-size: 1.5rem !important;
}

.icon-32pt {
  font-size: 2rem !important;
}

.icon-40pt {
  font-size: 2.5rem !important;
}

.icon-48pt {
  font-size: 3rem !important;
}

.icon-64pt {
  font-size: 4rem !important;
}

.icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  padding: 0;
}

.icon-holder[href]:hover {
  text-decoration: none;
}

.icon-holder--small {
  width: 30px;
  height: 30px;
}

.icon-holder--default {
  background-color: #e9ecef;
}

.icon-holder--primary {
  color: #fff;
  background-image: none;
  background-color: #5567FF;
  border: 1px solid #5567FF;
}

.icon-holder--primary:hover {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--primary:hover:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--outline-primary {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--outline-primary:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--secondary {
  color: #fff;
  background-image: none;
  background-color: #868e96;
  border: 1px solid #868e96;
}

.icon-holder--secondary:hover {
  color: #868e96;
  background-image: none;
  background-color: transparent;
  border: 1px solid #868e96;
}

.icon-holder--secondary:hover:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.icon-holder--outline-secondary {
  color: #868e96;
  background-image: none;
  background-color: transparent;
  border: 1px solid #868e96;
}

.icon-holder--outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.icon-holder--success {
  color: #fff;
  background-image: none;
  background-color: #77c13a;
  border: 1px solid #77c13a;
}

.icon-holder--success:hover {
  color: #77c13a;
  background-image: none;
  background-color: transparent;
  border: 1px solid #77c13a;
}

.icon-holder--success:hover:hover {
  color: #fff;
  background-color: #77c13a;
  border-color: #77c13a;
}

.icon-holder--outline-success {
  color: #77c13a;
  background-image: none;
  background-color: transparent;
  border: 1px solid #77c13a;
}

.icon-holder--outline-success:hover {
  color: #fff;
  background-color: #77c13a;
  border-color: #77c13a;
}

.icon-holder--info {
  color: #fff;
  background-image: none;
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
}

.icon-holder--info:hover {
  color: #17a2b8;
  background-image: none;
  background-color: transparent;
  border: 1px solid #17a2b8;
}

.icon-holder--info:hover:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.icon-holder--outline-info {
  color: #17a2b8;
  background-image: none;
  background-color: transparent;
  border: 1px solid #17a2b8;
}

.icon-holder--outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.icon-holder--warning {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}

.icon-holder--warning:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--warning:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--outline-warning {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--outline-warning:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--danger {
  color: #fff;
  background-image: none;
  background-color: #d9534f;
  border: 1px solid #d9534f;
}

.icon-holder--danger:hover {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border: 1px solid #d9534f;
}

.icon-holder--danger:hover:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.icon-holder--outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border: 1px solid #d9534f;
}

.icon-holder--outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.icon-holder--light {
  color: #fff;
  background-image: none;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
}

.icon-holder--light:hover {
  color: #f8f9fa;
  background-image: none;
  background-color: transparent;
  border: 1px solid #f8f9fa;
}

.icon-holder--light:hover:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.icon-holder--outline-light {
  color: #f8f9fa;
  background-image: none;
  background-color: transparent;
  border: 1px solid #f8f9fa;
}

.icon-holder--outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.icon-holder--dark {
  color: #fff;
  background-image: none;
  background-color: #303956;
  border: 1px solid #303956;
}

.icon-holder--dark:hover {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--dark:hover:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--outline-dark {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--outline-dark:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--black {
  color: #fff;
  background-image: none;
  background-color: #272C33;
  border: 1px solid #272C33;
}

.icon-holder--black:hover {
  color: #272C33;
  background-image: none;
  background-color: transparent;
  border: 1px solid #272C33;
}

.icon-holder--black:hover:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.icon-holder--outline-black {
  color: #272C33;
  background-image: none;
  background-color: transparent;
  border: 1px solid #272C33;
}

.icon-holder--outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.icon-holder--accent {
  color: #fff;
  background-image: none;
  background-color: #ED0B4C;
  border: 1px solid #ED0B4C;
}

.icon-holder--accent:hover {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--accent:hover:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--outline-accent {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--outline-accent:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--accent-red {
  color: #fff;
  background-image: none;
  background-color: #ED0B4C;
  border: 1px solid #ED0B4C;
}

.icon-holder--accent-red:hover {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--accent-red:hover:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--outline-accent-red {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--accent-yellow {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}

.icon-holder--accent-yellow:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--accent-yellow:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--outline-accent-yellow {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--outline-accent-yellow:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--accent-dodger-blue {
  color: #fff;
  background-image: none;
  background-color: #5567FF;
  border: 1px solid #5567FF;
}

.icon-holder--accent-dodger-blue:hover {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--accent-dodger-blue:hover:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--outline-accent-dodger-blue {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--accent-pickled-bluewood {
  color: #fff;
  background-image: none;
  background-color: #303956;
  border: 1px solid #303956;
}

.icon-holder--accent-pickled-bluewood:hover {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--accent-pickled-bluewood:hover:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--outline-accent-pickled-bluewood {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--accent-electric-violet {
  color: #fff;
  background-image: none;
  background-color: #9C42FF;
  border: 1px solid #9C42FF;
}

.icon-holder--accent-electric-violet:hover {
  color: #9C42FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #9C42FF;
}

.icon-holder--accent-electric-violet:hover:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.icon-holder--outline-accent-electric-violet {
  color: #9C42FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #9C42FF;
}

.icon-holder--outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.icon-holder--primary-purple {
  color: #fff;
  background-image: none;
  background-color: #824EE1;
  border: 1px solid #824EE1;
}

.icon-holder--primary-purple:hover {
  color: #824EE1;
  background-image: none;
  background-color: transparent;
  border: 1px solid #824EE1;
}

.icon-holder--primary-purple:hover:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.icon-holder--outline-primary-purple {
  color: #824EE1;
  background-image: none;
  background-color: transparent;
  border: 1px solid #824EE1;
}

.icon-holder--outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.icon-holder--primary-red {
  color: #fff;
  background-image: none;
  background-color: #ED0B4C;
  border: 1px solid #ED0B4C;
}

.icon-holder--primary-red:hover {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--primary-red:hover:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--outline-primary-red {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}

.icon-holder--outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.icon-holder--primary-yellow {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}

.icon-holder--primary-yellow:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--primary-yellow:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--outline-primary-yellow {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}

.icon-holder--outline-primary-yellow:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.icon-holder--primary-light {
  color: #fff;
  background-image: none;
  background-color: #99ccff;
  border: 1px solid #99ccff;
}

.icon-holder--primary-light:hover {
  color: #99ccff;
  background-image: none;
  background-color: transparent;
  border: 1px solid #99ccff;
}

.icon-holder--primary-light:hover:hover {
  color: #fff;
  background-color: #99ccff;
  border-color: #99ccff;
}

.icon-holder--outline-primary-light {
  color: #99ccff;
  background-image: none;
  background-color: transparent;
  border: 1px solid #99ccff;
}

.icon-holder--outline-primary-light:hover {
  color: #fff;
  background-color: #99ccff;
  border-color: #99ccff;
}

.icon-holder--primary-dodger-blue {
  color: #fff;
  background-image: none;
  background-color: #5567FF;
  border: 1px solid #5567FF;
}

.icon-holder--primary-dodger-blue:hover {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--primary-dodger-blue:hover:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--outline-primary-dodger-blue {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}

.icon-holder--outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.icon-holder--primary-pickled-bluewood {
  color: #fff;
  background-image: none;
  background-color: #303956;
  border: 1px solid #303956;
}

.icon-holder--primary-pickled-bluewood:hover {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--primary-pickled-bluewood:hover:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--outline-primary-pickled-bluewood {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}

.icon-holder--outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.icon-holder--outline-muted {
  color: rgba(39, 44, 51, 0.5);
  background-image: none;
  background-color: transparent;
  border: 1px solid rgba(39, 44, 51, 0.5);
}

.icon-holder--outline-muted:hover {
  color: #fff;
  background-color: rgba(39, 44, 51, 0.5);
  border-color: rgba(39, 44, 51, 0.5);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
strong {
  font-weight: 500;
}

.display-1 {
  line-height: 1.25;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

h1, .h1 {
  line-height: 1.5;
}

h2, .h2 {
  line-height: 1.5;
}

h3, .h3 {
  line-height: 1.414;
}

h4, .h4 {
  line-height: 1.414;
}

h5, .h5 {
  line-height: 1.5;
  text-transform: uppercase;
  color: #303956;
}

.headings-family-exo2 h1, .headings-family-exo2 h2, .headings-family-exo2 h3, .headings-family-exo2 h4, .headings-family-exo2 h5, .headings-family-exo2 h6,
.headings-family-exo2 .h1, .headings-family-exo2 .h2, .headings-family-exo2 .h3, .headings-family-exo2 .h4, .headings-family-exo2 .h5, .headings-family-exo2 .h6 {
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
}

.headings-family-lato h1, .headings-family-lato h2, .headings-family-lato h3, .headings-family-lato h4, .headings-family-lato h5, .headings-family-lato h6,
.headings-family-lato .h1, .headings-family-lato .h2, .headings-family-lato .h3, .headings-family-lato .h4, .headings-family-lato .h5, .headings-family-lato .h6 {
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
}

.headings-family-oswald h1, .headings-family-oswald h2, .headings-family-oswald h3, .headings-family-oswald h4, .headings-family-oswald h5, .headings-family-oswald h6,
.headings-family-oswald .h1, .headings-family-oswald .h2, .headings-family-oswald .h3, .headings-family-oswald .h4, .headings-family-oswald .h5, .headings-family-oswald .h6 {
  font-family: "Oswald", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.lead {
  line-height: 1.556;
}

.lead:last-child {
  margin-bottom: 0;
}

small,
.small {
  line-height: 1rem;
}

a:hover {
  text-decoration: none;
}

.font-size-16pt {
  font-size: 1rem !important;
}

.font-size-20pt {
  font-size: 1.25rem !important;
}

.font-size-24pt {
  font-size: 1.5rem !important;
}

.font-size-32pt {
  font-size: 2rem !important;
}

.font-size-48pt {
  font-size: 3rem !important;
}

.font-size-56pt {
  font-size: 3.5rem !important;
}

.font-size-64pt {
  font-size: 4rem !important;
}

.measure-paragraph {
  max-width: 456px;
}

.measure-paragraph-max {
  max-width: 536px;
}

.measure-lead {
  max-width: 536px;
}

.measure-lead-max {
  max-width: 616px;
}

.measure-hero-lead {
  max-width: 696px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (max-width: 767.98px) {
  .has-drawer-opened .mdk-header-layout__content {
    z-index: 2;
  }
}

.mdk-header-layout {
  overflow: visible;
}

.sub-layout .mdk-header-layout__content {
  height: 100%;
}

.sub-layout .page-content {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .sub-layout-drawer .mdk-drawer__content {
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .layout-app .container-fluid, .layout-app .container-sm, .layout-app .container-md, .layout-app .container-lg, .layout-app .container-xl, .layout-app .container-xxl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.mdk-drawer[data-persistent].layout-mini__drawer,
.layout-mini__drawer .mdk-drawer__content {
  width: 64px;
}

.layout-mini .sidebar-secondary {
  transition: transform 0.2s ease;
  transform: translate3d(-100%, 0, 0);
  box-shadow: none;
}

.layout-mini-secondary .sidebar-secondary,
.layout-mini--open .sidebar-secondary {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.layout-mini__drawer[data-position="right"] .sidebar-secondary {
  transform: translate3d(100%, 0, 0);
}

@media (min-width: 1200px) {
  .layout-mini .page__container,
  .layout-mini-secondary .page__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.layout-mini .mdk-drawer[data-persistent].layout-mini-secondary__drawer,
.layout-mini .layout-mini-secondary__drawer .mdk-drawer__content,
.layout-mini-secondary .mdk-drawer[data-persistent].layout-mini-secondary__drawer,
.layout-mini-secondary .layout-mini-secondary__drawer .mdk-drawer__content {
  width: 320px;
}

.layout-mini .sidebar-mini,
.layout-mini-secondary .sidebar-mini {
  width: 64px;
  position: relative;
  z-index: 1;
}

.layout-mini .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon {
  color: #303956;
}

.layout-mini .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon {
  color: white;
}

.layout-mini .sidebar-mini .sidebar-menu-text,
.layout-mini .sidebar-mini .sidebar-badge,
.layout-mini .sidebar-mini .sidebar-menu-toggle-icon,
.layout-mini-secondary .sidebar-mini .sidebar-menu-text,
.layout-mini-secondary .sidebar-mini .sidebar-badge,
.layout-mini-secondary .sidebar-mini .sidebar-menu-toggle-icon {
  display: none;
}

.layout-mini .sidebar-mini .sidebar-menu-item,
.layout-mini-secondary .sidebar-mini .sidebar-menu-item {
  margin: 0;
  border-radius: 0;
}

.layout-mini .sidebar-mini .sidebar-menu-button,
.layout-mini-secondary .sidebar-mini .sidebar-menu-button {
  flex-direction: column;
  padding: .5rem 0;
}

.layout-mini .sidebar-mini .sidebar-menu-initials,
.layout-mini-secondary .sidebar-mini .sidebar-menu-initials {
  content: attr(data-initials);
  display: block;
  visibility: visible;
}

.layout-mini .sidebar-mini .sidebar-menu-button,
.layout-mini-secondary .sidebar-mini .sidebar-menu-button {
  justify-content: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.layout-mini .sidebar-mini .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini .sidebar-menu-icon {
  margin: 0;
}

.layout-mini .sidebar-secondary,
.layout-mini-secondary .sidebar-secondary {
  width: 256px;
  position: absolute;
  top: 0;
  left: 64px;
}

.layout-mini__drawer[data-position="right"] .sidebar-secondary,
.layout-mini-secondary__drawer[data-position="right"] .sidebar-secondary {
  left: auto;
  right: 64px;
}

.layout-mini__drawer .mdk-drawer__content,
.layout-mini-secondary__drawer .mdk-drawer__content {
  display: flex;
  z-index: 0;
}

@media (min-width: 576px) {
  .layout-mini .layout-mini__d-none,
  .layout-mini-secondary .layout-mini__d-none, .layout-mini
  .layout-mini-secondary__d-none,
  .layout-mini-secondary
  .layout-mini-secondary__d-none {
    display: none;
  }
}

.layout-mini--open .sidebar-secondary,
.layout-mini--open .layout-mini__drawer[data-position="right"] .sidebar-secondary,
.layout-mini-secondary--open .sidebar-secondary,
.layout-mini-secondary--open .layout-mini__drawer[data-position="right"] .sidebar-secondary {
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1200px) {
  .layout-mini-secondary .container {
    max-width: 936px;
  }
}

@media (min-width: 1200px) {
  .layout-compact .container-fluid, .layout-compact .container-sm, .layout-compact .container-md, .layout-compact .container-lg, .layout-compact .container-xl, .layout-compact .container-xxl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.layout-compact .mdk-drawer[data-persistent].layout-compact__drawer,
.layout-compact__drawer .mdk-drawer__content {
  width: 100px;
}

.layout-compact .sidebar-secondary {
  transition: transform 0.2s ease;
  transform: translate3d(-100%, 0, 0);
  width: 256px;
  position: absolute;
  top: 0;
  left: 100px;
  box-shadow: none;
}

.layout-compact__drawer[data-position="right"] .sidebar-secondary {
  transform: translate3d(100%, 0, 0);
  left: auto;
  right: 100px;
}

.layout-compact__drawer .mdk-drawer__content {
  display: flex;
  z-index: 0;
}

.layout-compact .sidebar-mini {
  width: 100px;
  position: relative;
  z-index: 1;
}

.layout-compact .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon {
  color: #303956;
}

.layout-compact .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon {
  color: white;
}

.layout-compact .sidebar-mini .sidebar-menu-toggle-icon {
  display: none;
}

.layout-compact .sidebar-mini .sidebar-menu-item {
  margin: 0;
  border-radius: 0;
}

.layout-compact .sidebar-mini .sidebar-menu-initials {
  content: attr(data-initials);
  display: block;
  visibility: visible;
}

.layout-compact .sidebar-mini .sidebar-menu-button {
  flex-direction: column;
  padding: 1.25rem 0;
  justify-content: center;
  font-size: .8125rem;
  line-height: 1rem;
}

.layout-compact .sidebar-mini .sidebar-menu-icon {
  margin: 0 0 .25rem;
  font-size: 2rem;
}

@media (min-width: 576px) {
  .layout-compact .layout-compact__d-none {
    display: none;
  }
}

.layout-compact--open .sidebar-secondary,
.layout-compact--open .layout-compact__drawer[data-position="right"] .sidebar-secondary {
  transform: translate3d(0, 0, 0);
}

.layout-compact--open .sidebar-secondary {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

@media (min-width: 992px) {
  .layout-boxed > .mdk-drawer-layout {
    padding: 1rem 0;
  }
}

.layout-boxed .page-content {
  overflow: hidden;
}

@media (min-width: 992px) {
  .layout-boxed .page-content {
    box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
    margin: 0 1rem 0 0;
    border-radius: 10px;
  }
}

@media (min-width: 992px) {
  .layout-boxed #default-drawer .mdk-drawer__content {
    padding-top: 1rem;
  }
  .layout-boxed #default-drawer .sidebar {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
    border-radius: 10px;
    padding: 0 1rem 0 0;
  }
  .layout-boxed #default-drawer .sidebar .open {
    background: transparent !important;
  }
}

.layout-boxed #default-drawer .sidebar-brand {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  margin-bottom: 2rem;
  height: 64px;
  flex-direction: row;
  align-items: center;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

@media (min-width: 992px) {
  .layout-boxed #default-drawer .sidebar-brand {
    border-radius: 10px;
    margin-left: 1rem;
  }
}

.layout-boxed #default-drawer .sidebar-brand-icon {
  margin-left: 1rem;
  margin-right: .5rem;
  margin-bottom: 0;
  width: 2.5rem;
}

.layout-boxed #default-drawer .sidebar-brand-dark {
  color: white;
}

@media (min-width: 992px) {
  .layout-boxed #default-drawer .sidebar-menu > .sidebar-menu-item {
    margin-right: 0;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
}

.badge .material-icons {
  font-size: inherit;
}

.badge > .material-icons {
  margin-right: 5px;
}

.badge-notifications {
  border-radius: 100px;
  padding: 0 .5rem;
  line-height: 1rem;
  font-weight: 700;
}

.badge-filter a {
  color: inherit;
  padding: 0 2px 0 0;
  opacity: .7;
}

.badge-filter a:hover {
  opacity: 1;
}

.badge-filter_name {
  opacity: .7;
  padding-right: 5px;
}

.badge-lg {
  padding: 6px 12px;
}

.badge-success,
.badge-warning {
  color: #fff;
}

.breadcrumb {
  align-items: center;
  font-size: 0.707rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Source Sans Pro", Arial, sans-serif;
  letter-spacing: 2px;
}

.container > .breadcrumb:first-of-type {
  padding-top: 1rem;
}

.text-breadcrumb {
  font-size: 0.707rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Source Sans Pro", Arial, sans-serif;
  letter-spacing: 2px;
  color: rgba(39, 44, 51, 0.5);
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  color: rgba(39, 44, 51, 0.5);
}

.breadcrumb-item > a {
  color: inherit;
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  content: "keyboard_arrow_right";
}

.alert-soft-primary {
  color: #5567FF;
  background-color: rgba(85, 103, 255, 0.05);
  border-color: #cfd4ff;
}

.alert-soft-primary hr {
  border-top-color: #b6bdff;
}

.alert-soft-primary .alert-link {
  color: #2239ff;
}

.alert-soft-secondary {
  color: #868e96;
  background-color: rgba(134, 142, 150, 0.05);
  border-color: #dddfe2;
}

.alert-soft-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-soft-secondary .alert-link {
  color: #6c757d;
}

.alert-soft-success {
  color: #77c13a;
  background-color: rgba(119, 193, 58, 0.05);
  border-color: #d9eec8;
}

.alert-soft-success hr {
  border-top-color: #cce8b5;
}

.alert-soft-success .alert-link {
  color: #5f9a2e;
}

.alert-soft-info {
  color: #17a2b8;
  background-color: rgba(23, 162, 184, 0.05);
  border-color: #bee5eb;
}

.alert-soft-info hr {
  border-top-color: #abdde5;
}

.alert-soft-info .alert-link {
  color: #117a8b;
}

.alert-soft-warning {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}

.alert-soft-warning hr {
  border-top-color: #f4ddb2;
}

.alert-soft-warning .alert-link {
  color: #d0911d;
}

.alert-soft-danger {
  color: #d9534f;
  background-color: rgba(217, 83, 79, 0.05);
  border-color: #f4cfce;
}

.alert-soft-danger hr {
  border-top-color: #efbbb9;
}

.alert-soft-danger .alert-link {
  color: #c9302c;
}

.alert-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.05);
  border-color: #fdfdfe;
}

.alert-soft-light hr {
  border-top-color: #ececf6;
}

.alert-soft-light .alert-link {
  color: #dae0e5;
}

.alert-soft-dark {
  color: #303956;
  background-color: rgba(48, 57, 86, 0.05);
  border-color: #c5c8d0;
}

.alert-soft-dark hr {
  border-top-color: #b7bbc5;
}

.alert-soft-dark .alert-link {
  color: #1e2335;
}

.alert-soft-black {
  color: #272C33;
  background-color: rgba(39, 44, 51, 0.05);
  border-color: #c3c4c6;
}

.alert-soft-black hr {
  border-top-color: #b6b7ba;
}

.alert-soft-black .alert-link {
  color: #111316;
}

.alert-soft-accent {
  color: #ED0B4C;
  background-color: rgba(237, 11, 76, 0.05);
  border-color: #fabbcd;
}

.alert-soft-accent hr {
  border-top-color: #f8a3bc;
}

.alert-soft-accent .alert-link {
  color: #bc093c;
}

.alert-soft-accent-red {
  color: #ED0B4C;
  background-color: rgba(237, 11, 76, 0.05);
  border-color: #fabbcd;
}

.alert-soft-accent-red hr {
  border-top-color: #f8a3bc;
}

.alert-soft-accent-red .alert-link {
  color: #bc093c;
}

.alert-soft-accent-yellow {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}

.alert-soft-accent-yellow hr {
  border-top-color: #f4ddb2;
}

.alert-soft-accent-yellow .alert-link {
  color: #d0911d;
}

.alert-soft-accent-dodger-blue {
  color: #5567FF;
  background-color: rgba(85, 103, 255, 0.05);
  border-color: #cfd4ff;
}

.alert-soft-accent-dodger-blue hr {
  border-top-color: #b6bdff;
}

.alert-soft-accent-dodger-blue .alert-link {
  color: #2239ff;
}

.alert-soft-accent-pickled-bluewood {
  color: #303956;
  background-color: rgba(48, 57, 86, 0.05);
  border-color: #c5c8d0;
}

.alert-soft-accent-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}

.alert-soft-accent-pickled-bluewood .alert-link {
  color: #1e2335;
}

.alert-soft-accent-electric-violet {
  color: #9C42FF;
  background-color: rgba(156, 66, 255, 0.05);
  border-color: #e3caff;
}

.alert-soft-accent-electric-violet hr {
  border-top-color: #d6b1ff;
}

.alert-soft-accent-electric-violet .alert-link {
  color: #810fff;
}

.alert-soft-primary-purple {
  color: #824EE1;
  background-color: rgba(130, 78, 225, 0.05);
  border-color: #dccdf7;
}

.alert-soft-primary-purple hr {
  border-top-color: #cdb7f3;
}

.alert-soft-primary-purple .alert-link {
  color: #6425d7;
}

.alert-soft-primary-red {
  color: #ED0B4C;
  background-color: rgba(237, 11, 76, 0.05);
  border-color: #fabbcd;
}

.alert-soft-primary-red hr {
  border-top-color: #f8a3bc;
}

.alert-soft-primary-red .alert-link {
  color: #bc093c;
}

.alert-soft-primary-yellow {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}

.alert-soft-primary-yellow hr {
  border-top-color: #f4ddb2;
}

.alert-soft-primary-yellow .alert-link {
  color: #d0911d;
}

.alert-soft-primary-light {
  color: #99ccff;
  background-color: rgba(153, 204, 255, 0.05);
  border-color: #e2f1ff;
}

.alert-soft-primary-light hr {
  border-top-color: #c9e5ff;
}

.alert-soft-primary-light .alert-link {
  color: #66b3ff;
}

.alert-soft-primary-dodger-blue {
  color: #5567FF;
  background-color: rgba(85, 103, 255, 0.05);
  border-color: #cfd4ff;
}

.alert-soft-primary-dodger-blue hr {
  border-top-color: #b6bdff;
}

.alert-soft-primary-dodger-blue .alert-link {
  color: #2239ff;
}

.alert-soft-primary-pickled-bluewood {
  color: #303956;
  background-color: rgba(48, 57, 86, 0.05);
  border-color: #c5c8d0;
}

.alert-soft-primary-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}

.alert-soft-primary-pickled-bluewood .alert-link {
  color: #1e2335;
}

.alert-light,
.alert-soft-light {
  color: #94979a;
  background-color: #f8f9fa;
  border-color: #e9ecef;
}

.alert-light hr,
.alert-soft-light hr {
  border-top-color: #e9ecef;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn:not(.btn-lg):not(.btn-sm) {
  font-size: 1.063rem;
}

.btn--raised {
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.button-list > .btn,
.button-list > .btn-group {
  margin-bottom: .75rem;
  margin-left: .5rem;
}

.btn-flush {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  line-height: 1;
  color: inherit;
}

.dropdown-toggle::after {
  align-self: center;
}

.btn .material-icons {
  font-size: inherit;
  line-height: inherit;
}

.btn-rounded {
  border-radius: 100px;
}

.dropdown-toggle[data-caret="false"]::after {
  display: none;
}

.btn__secondary-text {
  display: block;
  margin-top: -2px;
  font-size: .925rem;
}

.btn-success:not(:hover),
.btn-warning:not(:hover) {
  color: #fff;
}

.btn-outline-light {
  color: #212529;
  border-color: #212529;
}

.btn-outline-success:hover,
.btn-outline-warning:hover {
  color: #fff;
}

.btn-primary--dark {
  color: #fff;
  background-color: #2239ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
  color: #fff;
}

.btn-primary--dark:hover {
  color: #fff;
  background-color: #001bfb;
  border-color: #0019ee;
}

.btn-primary--dark:focus, .btn-primary--dark.focus {
  color: #fff;
  background-color: #001bfb;
  border-color: #0019ee;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(67, 87, 255, 0.5);
}

.btn-primary--dark.disabled, .btn-primary--dark:disabled {
  color: #fff;
  background-color: #2239ff;
  border-color: #2239ff;
}

.btn-primary--dark:not(:disabled):not(.disabled):active, .btn-primary--dark:not(:disabled):not(.disabled).active,
.show > .btn-primary--dark.dropdown-toggle {
  color: #fff;
  background-color: #0019ee;
  border-color: #0018e1;
}

.btn-primary--dark:not(:disabled):not(.disabled):active:focus, .btn-primary--dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary--dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(67, 87, 255, 0.5);
}

.btn-primary .btn__secondary-text,
.btn-primary--dark .btn__secondary-text {
  color: rgba(255, 255, 255, 0.5);
}

.btn-black, .navbar-dark .navbar-btn {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-black:hover, .navbar-dark .navbar-btn:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}

.btn-black:focus, .navbar-dark .navbar-btn:focus, .btn-black.focus, .navbar-dark .focus.navbar-btn {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-black.disabled, .navbar-dark .disabled.navbar-btn, .btn-black:disabled, .navbar-dark .navbar-btn:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.btn-black:not(:disabled):not(.disabled):active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle,
.navbar-dark .show > .dropdown-toggle.navbar-btn {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus,
.navbar-dark .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(222, 222, 223, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(222, 222, 223, 0.5);
}

.btn-white .btn__secondary-text {
  color: rgba(0, 0, 0, 0.3);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #5567FF;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.btn-outline-white .btn__secondary-text {
  color: rgba(255, 255, 255, 0.54);
}

.btn-outline-white:hover .btn__secondary-text, .btn-outline-white:focus .btn__secondary-text {
  color: rgba(39, 44, 51, 0.5);
}

@media (max-width: 373.98px) {
  .btn-block-xs {
    display: block;
    width: 100%;
  }
  .btn-block-xs:not(:last-child) {
    margin-bottom: 5px;
  }
}

@media (max-width: 373.98px) {
  .btn-group-xs > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.btn-light, .navbar-light .navbar-btn {
  color: #212529;
  background-color: #EDF0F2;
  border-color: #EDF0F2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.btn-light:hover, .navbar-light .navbar-btn:hover {
  color: #212529;
  background-color: #d7dde2;
  border-color: #cfd7dd;
}

.btn-light:focus, .navbar-light .navbar-btn:focus, .btn-light.focus, .navbar-light .focus.navbar-btn {
  color: #212529;
  background-color: #d7dde2;
  border-color: #cfd7dd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(206, 210, 212, 0.5);
}

.btn-light.disabled, .navbar-light .disabled.navbar-btn, .btn-light:disabled, .navbar-light .navbar-btn:disabled {
  color: #212529;
  background-color: #EDF0F2;
  border-color: #EDF0F2;
}

.btn-light:not(:disabled):not(.disabled):active, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle,
.navbar-light .show > .dropdown-toggle.navbar-btn {
  color: #212529;
  background-color: #cfd7dd;
  border-color: #c8d1d7;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus,
.navbar-light .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(206, 210, 212, 0.5);
}

.btn-accent-yellow, .btn-accent-yellow:hover, .btn-accent-yellow:focus, .btn-accent-yellow:active {
  color: white;
}

.badge-accent-yellow {
  color: white;
}

.card, .card-nav .tab-content,
.card-group {
  margin-bottom: 1.5rem;
}

.card, .card-nav .tab-content {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.card--elevated:hover {
  box-shadow: 0px 3px 5px -1px rgba(39, 44, 51, 0.1), 0px 5px 8px 0px rgba(39, 44, 51, 0.04), 0px 1px 14px 0px rgba(39, 44, 51, 0.02);
}

.card--raised {
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.card--connect {
  position: relative;
}

.card--connect::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #E9EDF2;
  left: 50%;
  margin-left: -2px;
  z-index: -1;
}

.card.card-flush, .card-nav .card-flush.tab-content {
  box-shadow: none;
  border: none;
}

.card.card-flush, .card-nav .card-flush.tab-content,
.card.card-flush .card-img,
.card-nav .card-flush.tab-content .card-img,
.card.card-flush .card-img-top,
.card-nav .card-flush.tab-content .card-img-top {
  border-radius: 0;
}

.card.card-flush-y, .card-nav .card-flush-y.tab-content {
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.card-2by1 .card-body {
  padding: 0.5rem 1rem;
}

.card-sm .card-body,
.card-sm .card-footer {
  padding: 0.625rem 0.75rem;
}

.card-lg .card-body,
.card-lg .card-footer {
  padding: 1.25rem;
}

.card-title {
  line-height: 1.25;
  margin-bottom: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  color: #303956;
}

.card-subtitle {
  font-size: 0.707rem;
  font-weight: 400;
  margin-top: 0;
}

@media (min-width: 576px) {
  .card-group > .card, .card-nav .card-group > .tab-content {
    margin-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .card-group--lg-up .card, .card-group--lg-up .card-nav .tab-content, .card-nav .card-group--lg-up .tab-content {
    flex: 0 0 100% !important;
    margin-bottom: 1rem;
  }
}

.card-group-row {
  display: flex;
  flex-flow: row wrap;
}

.card-group-row__col {
  display: flex;
}

.card-group-row__card {
  flex: 1 0 0;
}

.card-group-flush > .card, .card-nav .card-group-flush > .tab-content {
  box-shadow: none;
  border-bottom: 0;
  margin-bottom: 0;
  background: transparent;
}

.card-group-flush > .card, .card-nav .card-group-flush > .tab-content,
.card-group-flush > .card .card-img,
.card-nav .card-group-flush > .tab-content .card-img,
.card-group-flush > .card .card-img-top,
.card-nav .card-group-flush > .tab-content .card-img-top {
  border-radius: 0;
}

.card-group-flush > .card:first-child, .card-nav .card-group-flush > .tab-content:first-child {
  border-left: none;
}

.card-group-flush > .card:last-child, .card-nav .card-group-flush > .tab-content:last-child {
  border-right: none;
}

@media (max-width: 373.98px) {
  .card-group-flush > .card, .card-nav .card-group-flush > .tab-content {
    border-left: none;
    border-right: none;
  }
}

@media (min-width: 576px) {
  .card-columns--2 {
    column-count: 2;
  }
}

.card-column {
  display: inline-block;
  width: 100%;
}

.card-body + .card-body {
  border-top: 1px solid #DFE2E6;
}

.card-deck-row {
  display: flex;
  flex-flow: row wrap;
}

.card-deck-row > [class*="col-"] {
  display: flex;
}

.card-deck-row .card, .card-deck-row .card-nav .tab-content, .card-nav .card-deck-row .tab-content {
  flex: 1 1 0%;
}

.card-title {
  font-weight: 500;
}

.card-title a, .card-title[href] {
  color: inherit;
  display: flex;
  text-decoration: none;
}

.card-title:last-child {
  margin-bottom: 0;
}

.card-header {
  position: relative;
  border-bottom: 1px solid #DFE2E6;
}

.card-footer {
  border-top: 1px solid #DFE2E6;
  padding: .75rem;
}

.card-footer:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card > .fullbleed, .card-nav .tab-content > .fullbleed {
  border-radius: 0.5rem;
}

.card-img-cover {
  position: relative;
  height: 200px;
  object-fit: cover;
}

.card-header-tabs-basic {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.card-header-tabs-basic > div {
  display: flex;
}

.card-header-tabs-basic a {
  padding: .5rem .75rem;
  color: rgba(39, 44, 51, 0.54);
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 400;
}

.card-header-tabs-basic a:hover {
  text-decoration: none;
  color: rgba(39, 44, 51, 0.84);
}

.card-header-tabs-basic .active {
  color: #303956;
  font-weight: 600;
  border-bottom: 2px solid #5567FF;
}

.popover-lg {
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
  border: none;
}

.popover-lg .popover-body {
  padding: 1.5rem;
}

.popover-lg .bs-popover-top .arrow::before, .popover-lg .bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: transparent;
}

.popover-lg .bs-popover-right .arrow::before, .popover-lg .bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: transparent;
}

.popover-lg .bs-popover-bottom .arrow::before, .popover-lg .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: transparent;
}

.popover-lg .bs-popover-left .arrow::before, .popover-lg .bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: transparent;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 1rem;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 1rem;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 1rem;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 1rem;
}

.img-fluid {
  width: 100%;
  max-width: none;
}

.img-fluid-max {
  max-width: 100%;
  height: auto;
}

.page-link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  border-left: none;
  border-top: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  line-height: 1.25 !important;
}

.page-link:hover, .page-link:focus {
  text-decoration: none;
}

.page-item:last-of-type .page-link {
  border-right: none;
}

.page-link .material-icons {
  line-height: 1rem;
}

.pagination {
  position: relative;
  z-index: 0;
}

.pagination:last-child {
  margin-bottom: 0;
}

.pagination-xsm .page-link {
  padding: 0 0.5rem;
  font-size: 0.707rem;
  line-height: 24px;
}

.pagination-xsm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-xsm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination-xsm .page-link {
  line-height: 24px !important;
}

.pagination-xsm .material-icons {
  font-size: 1rem;
}

.rating {
  display: flex;
  align-items: center;
}

.rating__item {
  color: #f9c32c;
  display: flex;
  align-items: center;
}

.rating .material-icons {
  font-size: 1rem;
}

.rating-24 .material-icons {
  font-size: 1.5rem;
}

.rating-32 .material-icons {
  font-size: 2rem;
}

.table p {
  margin: 0;
  font-size: 14px;
}

.table ol li,
.table ul li {
  font-size: 14px;
}

.table thead th,
.table tbody td {
  vertical-align: middle;
  line-height: 1.2;
}

.table-nowrap th {
  white-space: nowrap;
}

.table .sort {
  color: inherit;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.table .sort::after {
  margin-left: 0.5rem;
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='rgba(39, 44, 51, 0.54)'/></svg>");
}

.table .sort.asc, .table .sort.desc {
  text-decoration: underline;
  font-weight: 600;
}

.table .sort + .sort {
  margin-left: 1rem;
}

.table--elevated {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.table-flush {
  margin-bottom: 0;
}

.table-flush thead th {
  border-top: 0;
}

.table-responsive {
  margin-bottom: 0;
}

.table thead th {
  color: #303956;
  text-transform: uppercase;
  font-size: 0.707rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.table tbody .selected > td {
  background-color: rgba(245, 247, 250, 0.5);
}

.thead-border-top-0 thead th {
  border-top: 0;
}

.table thead th {
  font-size: 0.707rem;
  padding: 0.5rem 0.75rem;
}

.table tbody td {
  line-height: 1.5;
  padding: 0.75rem;
}

.table-flush {
  border-bottom: 0;
}

.table-flush thead th:first-of-type,
.table-flush tbody td:first-of-type {
  border-left: 0;
}

.table-flush thead th:last-of-type,
.table-flush tbody td:last-of-type {
  border-right: 0;
}

.list-group {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.list-group-flush {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.list-group-flush > .list-group-item:not(.active) {
  background: none;
}

.list-group-flush > .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item.active a {
  color: inherit;
}

.media-left,
.media-right {
  display: flex;
  flex-direction: column;
}

.media-left {
  margin-right: 0.5rem;
}

.media-right {
  margin-left: 0.5rem;
}

.media-body {
  flex: 1 1 0%;
}

.media {
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 373.98px) {
  .media-stack-xs {
    flex-direction: column;
  }
  .media-stack-xs .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 412.98px) {
  .media-stack-i8 {
    flex-direction: column;
  }
  .media-stack-i8 .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 575.98px) {
  .media-stack-i8-plus {
    flex-direction: column;
  }
  .media-stack-i8-plus .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 767.98px) {
  .media-stack-sm {
    flex-direction: column;
  }
  .media-stack-sm .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 991.98px) {
  .media-stack-md {
    flex-direction: column;
  }
  .media-stack-md .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 1199.98px) {
  .media-stack-lg {
    flex-direction: column;
  }
  .media-stack-lg .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 1365.98px) {
  .media-stack-xl {
    flex-direction: column;
  }
  .media-stack-xl .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}

.media-stack-xxl {
  flex-direction: column;
}

.media-stack-xxl .media-left {
  width: 100% !important;
  margin: 0 0 1rem 0;
}

@media (min-width: 576px) {
  .media__thumbnail {
    width: 150px;
  }
}

.nav-link__secondary-text {
  display: block;
  margin-top: -2px;
  font-size: .825rem;
}

.nav-link {
  color: rgba(39, 44, 51, 0.5);
  display: flex;
}

.nav--flush .nav-link {
  padding: 0;
}

.nav-link.active,
.active .nav-link {
  font-weight: 500;
  color: #272C33;
}

.nav-tabs--flush .nav-link {
  border: none;
}

.nav-tabs--flush .nav-link:hover, .nav-tabs--flush .nav-link:focus {
  border-color: transparent;
}

.nav-tabs--flush .nav-link.active,
.nav-tabs--flush .nav-item.show .nav-link {
  background-color: transparent;
}

.nav-tabs--active-bg .nav-link.active,
.nav-tabs--active-bg .nav-item.show .nav-link {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.nav-tabs--active-underline .nav-link.active,
.nav-tabs--active-underline .nav-item.show .nav-link {
  border: none;
  border-bottom: 4px solid #5567FF;
}

.card-nav {
  z-index: 0;
}

.card-nav .nav-tabs {
  border-bottom: none;
  z-index: 2;
  position: relative;
}

.card-nav .nav-tabs .nav-item {
  margin-bottom: 0;
}

.card-nav .nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
}

@media (min-width: 576px) {
  .card-nav:not(.card-nav--vertical) .nav-tabs {
    padding: 0 1rem;
  }
  .card-nav:not(.card-nav--vertical) .tab-content {
    margin-top: -1px;
  }
}

.card-nav--vertical .nav-tabs {
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .card-nav--vertical .nav-tabs .active {
    border-color: #DFE2E6;
  }
  .card-nav--vertical .nav-tabs {
    margin-bottom: 1rem;
  }
  .card-nav--vertical .nav-tabs .nav-link {
    border-radius: 0.25rem;
  }
}

@media (min-width: 992px) {
  .card-nav--vertical {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .card-nav--vertical .tab-content {
    overflow: hidden;
    margin-bottom: 0;
    flex: 1;
  }
  .card-nav--vertical .tab-content:last-child {
    margin-left: -1px;
  }
  .card-nav--vertical .tab-content:first-child {
    margin-right: -1px;
  }
  .card-nav--vertical .nav-tabs {
    padding: 1rem 0;
  }
  .card-nav--vertical .nav-tabs:last-child .nav-link.active,
  .card-nav--vertical .nav-tabs:last-child .nav-item.show .nav-link {
    border-color: transparent transparent #F5F7FA transparent transparent #F5F7FA transparent transparent #F5F7FA #F5F7FA;
  }
  .card-nav--vertical .nav-tabs:last-child .nav-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-nav--vertical .nav-tabs:first-child .nav-link.active,
  .card-nav--vertical .nav-tabs:first-child .nav-item.show .nav-link {
    border-color: transparent transparent #F5F7FA #F5F7FA transparent transparent #F5F7FA transparent transparent #F5F7FA;
  }
  .card-nav--vertical .nav-tabs:first-child .nav-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.nav-tabs--pill {
  border-bottom: none;
}

.nav-tabs--pill .nav-link {
  border-radius: 0.25rem;
}

.nav-tabs--pill .nav-item {
  margin-bottom: 0;
}

.nav-tabs--pill .nav-item:not(:last-child) {
  margin-bottom: 5px;
}

@media (min-width: 576px) {
  .nav-tabs--pill .nav-item {
    margin-bottom: 0 !important;
  }
  .nav-tabs--pill .nav-item:not(:last-child) {
    margin-right: 5px;
  }
}

.nav-tabs--inactive-dark .nav-link {
  color: #fff;
}

.nav-tabs--inactive-dark .nav-link__secondary-text {
  color: rgba(255, 255, 255, 0.5);
}

.nav-tabs--inactive-dark .nav-link.disabled {
  color: rgba(255, 255, 255, 0.7);
}

.nav-tabs--inactive-primary .nav-link {
  background-color: #5567FF;
  border-color: #5567FF;
}

.nav-tabs--inactive-primary-dark .nav-link {
  background-color: #2239ff;
  border-color: #2239ff;
}

.nav-tabs--active-white .nav-link.active,
.nav-tabs--active-white .nav-item.show .nav-link {
  color: #272C33;
  background-color: #fff;
  border-color: #5567FF;
}

.nav-tabs--active-white .nav-link.active .nav-link__secondary-text,
.nav-tabs--active-white .nav-item.show .nav-link .nav-link__secondary-text {
  color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) {
  .nav-tabs--rounded-top-md-0 .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 768px) {
  .nav-tabs--rounded-bottom-md-0 .nav-link {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.nav-tabs--rounded-0 .nav-link {
  border-radius: 0;
}

.nav-tabs-card .nav-link {
  border-radius: 0;
}

.nav-tabs-card .nav-link:hover {
  color: #272C33;
}

.nav-tabs-card + .card-footer {
  border-top: none;
}

.card > .nav-tabs-card:not(:first-child), .card-nav .tab-content > .nav-tabs-card:not(:first-child) {
  border-top: 1px solid #DFE2E6;
}

.layout-sticky-subnav .mdk-header-layout {
  overflow: initial;
}

.layout-sticky-subnav .page__subnav {
  position: sticky;
  top: 64px;
  z-index: 2;
}

.layout-sticky-subnav .page__container {
  z-index: 0;
  position: relative;
}

.mdk-header-layout__content {
  z-index: auto !important;
}

@media screen and (max-width: 767px) {
  .mdk-header-layout {
    padding-bottom: 100px;
  }
}

.mdk-header-layout > .mdk-header {
  z-index: 2 !important;
}

.avatar-group {
  flex-direction: row;
}

.avatar-title {
  font-size: 0.707rem;
  font-weight: 500;
}

.avatar-32pt {
  width: 2rem;
  height: 2rem;
}

.page-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.page-heading h4,
.page-heading .h4 {
  margin-bottom: .25rem;
}

@media (min-width: 576px) {
  .page-heading h4,
  .page-heading .h4 {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .page-heading {
    flex-direction: row;
    align-items: flex-end;
  }
}

.page-headline {
  position: relative;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .page-headline {
    padding-bottom: 3rem;
  }
}

.page-headline :last-child {
  margin-bottom: 0;
}

.page-headline > :first-child {
  position: relative;
  display: inline-flex;
  margin-bottom: calc(1rem + 12px);
}

.page-headline > :first-child::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #5567FF;
  content: " ";
  bottom: -12px;
}

.page-headline--title > :first-child {
  margin-bottom: 12px;
}

.page-headline--white > :first-child::after {
  background-color: #fff;
}

.page-separator {
  position: relative;
  color: #868e96;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 0;
  margin-bottom: 1rem;
}

.page-separator::before {
  content: '';
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
}

.page-separator__text {
  display: inline-flex;
  padding-right: .5rem;
  border-radius: 0.25rem;
  background-color: #F5F7FA;
  max-width: 90%;
  text-transform: uppercase;
  font-size: .9375rem;
  color: #303956;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

.justify-content-center .page-separator__text {
  padding-left: .5rem;
}

.page-separator__text:hover {
  text-decoration: none;
}

.page-separator__bg-bottom {
  content: '';
  height: 50%;
  background-color: #F5F7FA;
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -2;
}

.page-separator__bg-top {
  content: '';
  height: 50%;
  background-color: #F5F7FA;
  width: 100%;
  bottom: 50%;
  left: 0;
  position: absolute;
  z-index: -2;
}

.page-separator-mask {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 356px;
}

.page-separator-mask .page-separator-mask__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 84px;
}

.page-separator-mask .page-separator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

.page-separator-mask .page-separator-mask__top {
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.8) 17%, rgba(255, 255, 255, 0.9) 32%, #ffffff 70%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 64px;
}

.page-separator-mask .page-separator-mask__bottom {
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
}

.page-section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .page-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .layout-default .page-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.page-nav {
  z-index: 0;
  position: relative;
  position: sticky;
  top: 4rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  display: block;
}

.page-nav .page-nav__content {
  margin-left: 21px;
  padding-left: 17px;
}

.page-nav::before {
  position: absolute;
  width: 2px;
  left: 33px;
  top: 0;
  bottom: 0;
  background-color: #E9EDF2;
  content: '';
  z-index: -1;
}

.page-nav .page-nav__menu {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}

.page-nav .page-nav__menu a {
  color: rgba(39, 44, 51, 0.7);
  position: relative;
  padding: 0 0 0 17px;
  margin-bottom: .5rem;
}

.page-nav .page-nav__menu a::before {
  background-color: #D2D5D9;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  content: '';
  left: -5px;
  top: 6px;
  position: absolute;
}

.page-nav .page-nav__menu a.active {
  font-weight: bold;
}

.page-nav .page-nav__menu a.active::before {
  background-color: #5567FF;
  border-color: white;
}

.page-num {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid #E9EDF2;
  background-color: #F5F7FA;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.414rem;
  line-height: 1.414;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303956;
}

[href] .page-num {
  color: #303956;
}

.page-num-container {
  margin-left: -3.25rem;
  margin-bottom: 1rem;
}

.page-num-container .page-num {
  margin-right: .75rem;
  flex-shrink: 0;
}

.page-num-container :last-child {
  margin-bottom: 0;
}

.page-num-timeline {
  position: relative;
  z-index: 0;
}

.page-num-timeline::after {
  content: '';
  width: .3125rem;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  background-color: #E9EDF2;
  z-index: -1;
  margin-left: -.15625rem;
}

.page-num-timeline__item {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.page-num-timeline__item-tip {
  width: .75rem;
  height: .75rem;
  display: block;
  border-radius: 100%;
  background-color: #D2D5D9;
  border: 2px solid #F5F7FA;
  margin-bottom: 5px !important;
}

.page-num-timeline__item-current {
  margin-top: .75rem !important;
}

.page-num-timeline__item-current .page-num {
  background-color: #5567FF;
  color: #99ccff;
  width: 4rem;
  height: 4rem;
  border: none;
}

.page-num-timeline__item-current .page-num-timeline__item-tip {
  position: absolute;
  top: -.375rem;
  left: 50%;
  margin-left: -.375rem;
  border-color: white;
  background-color: #5567FF;
}

.page-num-timeline__item.active .page-num-timeline__item-tip {
  border-color: #99ccff;
  background-color: #5567FF;
}

@media (min-width: 576px) {
  .page-num-timeline {
    margin-top: -.375rem;
  }
  .page-num-timeline__item {
    margin: 0 1rem;
  }
  .page-num-timeline::after {
    width: 100%;
    height: .3125rem;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: .375rem;
    margin-left: 0;
  }
}

.progression-bar {
  display: flex;
  color: rgba(39, 44, 51, 0.5);
  flex-direction: column;
  align-items: center;
}

.progression-bar__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
  text-align: center;
  position: relative;
  z-index: 0;
  color: inherit;
}

.progression-bar__item-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
}

.progression-bar__item:not(:last-child) {
  padding-bottom: 15px;
}

.progression-bar__item[href]:hover {
  text-decoration: none;
  color: inherit;
}

.progression-bar__item::before, .progression-bar__item::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #f2f2f2;
  width: 2px;
  top: 0;
  left: 50%;
  height: 100%;
  margin-left: -1px;
}

.progression-bar__item::before {
  display: none;
}

.progression-bar__item-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
  color: inherit;
  text-align: center;
  line-height: 22px;
  background-color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}

.progression-bar__item-text {
  color: inherit;
  background-color: #ffffff;
}

.progression-bar__item-icon:not(.progression-bar__item--disabled), .progression-bar__item-text:not(.progression-bar__item--disabled) {
  cursor: pointer;
}

.progression-bar__item--complete::after {
  background-color: rgba(39, 44, 51, 0.7);
}

.progression-bar__item--complete {
  color: rgba(39, 44, 51, 0.7);
}

.progression-bar__item--complete .progression-bar__item-icon {
  border-color: rgba(39, 44, 51, 0.7);
}

.progression-bar__item--active {
  color: #272C33;
}

.progression-bar__item--active:not(:last-child)::after {
  height: 50%;
}

.progression-bar__item--active:not(:last-child)::before {
  display: block;
}

.progression-bar--active-primary .progression-bar__item--active .progression-bar__item-icon {
  background-color: #5567FF;
  border-color: #5567FF;
  color: #fff;
}

.progression-bar--active-secondary .progression-bar__item--active .progression-bar__item-icon {
  background-color: #868e96;
  border-color: #868e96;
  color: #fff;
}

.progression-bar--active-success .progression-bar__item--active .progression-bar__item-icon {
  background-color: #77c13a;
  border-color: #77c13a;
  color: #fff;
}

.progression-bar--active-info .progression-bar__item--active .progression-bar__item-icon {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}

.progression-bar--active-warning .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}

.progression-bar--active-danger .progression-bar__item--active .progression-bar__item-icon {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #fff;
}

.progression-bar--active-light .progression-bar__item--active .progression-bar__item-icon {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #fff;
}

.progression-bar--active-dark .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303956;
  border-color: #303956;
  color: #fff;
}

.progression-bar--active-black .progression-bar__item--active .progression-bar__item-icon {
  background-color: #272C33;
  border-color: #272C33;
  color: #fff;
}

.progression-bar--active-accent .progression-bar__item--active .progression-bar__item-icon {
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  color: #fff;
}

.progression-bar--active-accent-red .progression-bar__item--active .progression-bar__item-icon {
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  color: #fff;
}

.progression-bar--active-accent-yellow .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}

.progression-bar--active-accent-dodger-blue .progression-bar__item--active .progression-bar__item-icon {
  background-color: #5567FF;
  border-color: #5567FF;
  color: #fff;
}

.progression-bar--active-accent-pickled-bluewood .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303956;
  border-color: #303956;
  color: #fff;
}

.progression-bar--active-accent-electric-violet .progression-bar__item--active .progression-bar__item-icon {
  background-color: #9C42FF;
  border-color: #9C42FF;
  color: #fff;
}

.progression-bar--active-primary-purple .progression-bar__item--active .progression-bar__item-icon {
  background-color: #824EE1;
  border-color: #824EE1;
  color: #fff;
}

.progression-bar--active-primary-red .progression-bar__item--active .progression-bar__item-icon {
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  color: #fff;
}

.progression-bar--active-primary-yellow .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}

.progression-bar--active-primary-light .progression-bar__item--active .progression-bar__item-icon {
  background-color: #99ccff;
  border-color: #99ccff;
  color: #fff;
}

.progression-bar--active-primary-dodger-blue .progression-bar__item--active .progression-bar__item-icon {
  background-color: #5567FF;
  border-color: #5567FF;
  color: #fff;
}

.progression-bar--active-primary-pickled-bluewood .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303956;
  border-color: #303956;
  color: #fff;
}

@media (min-width: 576px) {
  .progression-bar {
    flex-direction: row;
    align-items: start;
  }
  .progression-bar__item {
    padding-bottom: 0 !important;
  }
  .progression-bar__item:first-child {
    align-items: flex-start;
  }
  .progression-bar__item:last-child {
    align-items: flex-end;
  }
  .progression-bar__item::before, .progression-bar__item::after {
    left: 0;
    top: 12px;
    height: 2px !important;
    width: 100%;
    margin-left: 0;
  }
  .progression-bar__item--active:not(:last-child)::after {
    width: 50%;
  }
  .progression-bar__item--active:not(:last-child)::before {
    display: block;
  }
}

code.django .hljs-template-tag {
  color: #ED0B4C;
}

.bg-dark code {
  color: white;
}

.tooltip-inner {
  box-shadow: 0px 2px 1px -1px rgba(39, 44, 51, 0.1), 0px 1px 1px 0px rgba(39, 44, 51, 0.04), 0px 1px 3px 0px rgba(39, 44, 51, 0.02);
  font-size: .9375rem;
  font-weight: 600;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

.chip {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;
}

.chip + .chip {
  margin-left: .5rem;
}

.chip-primary {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.chip-primary:focus, .chip-primary.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-primary.disabled, .chip-primary:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-primary:not(:disabled):not(.disabled):active, .chip-primary:not(:disabled):not(.disabled).active,
.show > .chip-primary.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.chip-primary:not(:disabled):not(.disabled):active:focus, .chip-primary:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-outline-primary {
  color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary:focus, .chip-outline-primary.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-outline-primary.disabled, .chip-outline-primary:disabled {
  color: #5567FF;
  background-color: transparent;
}

.chip-outline-primary:not(:disabled):not(.disabled):active, .chip-outline-primary:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary:not(:disabled):not(.disabled):active:focus, .chip-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.chip-secondary:focus, .chip-secondary.focus {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.chip-secondary.disabled, .chip-secondary:disabled {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.chip-secondary:not(:disabled):not(.disabled):active, .chip-secondary:not(:disabled):not(.disabled).active,
.show > .chip-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
}

.chip-secondary:not(:disabled):not(.disabled):active:focus, .chip-secondary:not(:disabled):not(.disabled).active:focus,
.show > .chip-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.chip-outline-secondary {
  color: #868e96;
  border-color: #868e96;
}

.chip-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.chip-outline-secondary:focus, .chip-outline-secondary.focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.chip-outline-secondary.disabled, .chip-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.chip-outline-secondary:not(:disabled):not(.disabled):active, .chip-outline-secondary:not(:disabled):not(.disabled).active,
.show > .chip-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.chip-outline-secondary:not(:disabled):not(.disabled):active:focus, .chip-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.chip-success {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-success:hover {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
}

.chip-success:focus, .chip-success.focus {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.chip-success.disabled, .chip-success:disabled {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.chip-success:not(:disabled):not(.disabled):active, .chip-success:not(:disabled):not(.disabled).active,
.show > .chip-success.dropdown-toggle {
  color: #fff;
  background-color: #5f9a2e;
  border-color: #59902b;
}

.chip-success:not(:disabled):not(.disabled):active:focus, .chip-success:not(:disabled):not(.disabled).active:focus,
.show > .chip-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.chip-outline-success {
  color: #77c13a;
  border-color: #77c13a;
}

.chip-outline-success:hover {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.chip-outline-success:focus, .chip-outline-success.focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.chip-outline-success.disabled, .chip-outline-success:disabled {
  color: #77c13a;
  background-color: transparent;
}

.chip-outline-success:not(:disabled):not(.disabled):active, .chip-outline-success:not(:disabled):not(.disabled).active,
.show > .chip-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}

.chip-outline-success:not(:disabled):not(.disabled):active:focus, .chip-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.chip-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.chip-info:focus, .chip-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.chip-info.disabled, .chip-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.chip-info:not(:disabled):not(.disabled):active, .chip-info:not(:disabled):not(.disabled).active,
.show > .chip-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.chip-info:not(:disabled):not(.disabled):active:focus, .chip-info:not(:disabled):not(.disabled).active:focus,
.show > .chip-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.chip-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.chip-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.chip-outline-info:focus, .chip-outline-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.chip-outline-info.disabled, .chip-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.chip-outline-info:not(:disabled):not(.disabled):active, .chip-outline-info:not(:disabled):not(.disabled).active,
.show > .chip-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.chip-outline-info:not(:disabled):not(.disabled):active:focus, .chip-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.chip-warning {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-warning:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.chip-warning:focus, .chip-warning.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-warning.disabled, .chip-warning:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-warning:not(:disabled):not(.disabled):active, .chip-warning:not(:disabled):not(.disabled).active,
.show > .chip-warning.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.chip-warning:not(:disabled):not(.disabled):active:focus, .chip-warning:not(:disabled):not(.disabled).active:focus,
.show > .chip-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-warning {
  color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-warning:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-warning:focus, .chip-outline-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-outline-warning.disabled, .chip-outline-warning:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.chip-outline-warning:not(:disabled):not(.disabled):active, .chip-outline-warning:not(:disabled):not(.disabled).active,
.show > .chip-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-warning:not(:disabled):not(.disabled):active:focus, .chip-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}

.chip-danger:focus, .chip-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.chip-danger.disabled, .chip-danger:disabled {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.chip-danger:not(:disabled):not(.disabled):active, .chip-danger:not(:disabled):not(.disabled).active,
.show > .chip-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}

.chip-danger:not(:disabled):not(.disabled):active:focus, .chip-danger:not(:disabled):not(.disabled).active:focus,
.show > .chip-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.chip-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}

.chip-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.chip-outline-danger:focus, .chip-outline-danger.focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.chip-outline-danger.disabled, .chip-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}

.chip-outline-danger:not(:disabled):not(.disabled):active, .chip-outline-danger:not(:disabled):not(.disabled).active,
.show > .chip-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.chip-outline-danger:not(:disabled):not(.disabled):active:focus, .chip-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.chip-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.chip-light:focus, .chip-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.chip-light.disabled, .chip-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.chip-light:not(:disabled):not(.disabled):active, .chip-light:not(:disabled):not(.disabled).active,
.show > .chip-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.chip-light:not(:disabled):not(.disabled):active:focus, .chip-light:not(:disabled):not(.disabled).active:focus,
.show > .chip-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.chip-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.chip-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.chip-outline-light:focus, .chip-outline-light.focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.chip-outline-light.disabled, .chip-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.chip-outline-light:not(:disabled):not(.disabled):active, .chip-outline-light:not(:disabled):not(.disabled).active,
.show > .chip-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.chip-outline-light:not(:disabled):not(.disabled):active:focus, .chip-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.chip-dark {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-dark:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.chip-dark:focus, .chip-dark.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-dark.disabled, .chip-dark:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-dark:not(:disabled):not(.disabled):active, .chip-dark:not(:disabled):not(.disabled).active,
.show > .chip-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.chip-dark:not(:disabled):not(.disabled):active:focus, .chip-dark:not(:disabled):not(.disabled).active:focus,
.show > .chip-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-outline-dark {
  color: #303956;
  border-color: #303956;
}

.chip-outline-dark:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-dark:focus, .chip-outline-dark.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-outline-dark.disabled, .chip-outline-dark:disabled {
  color: #303956;
  background-color: transparent;
}

.chip-outline-dark:not(:disabled):not(.disabled):active, .chip-outline-dark:not(:disabled):not(.disabled).active,
.show > .chip-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-dark:not(:disabled):not(.disabled):active:focus, .chip-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-black {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-black:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}

.chip-black:focus, .chip-black.focus {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.chip-black.disabled, .chip-black:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.chip-black:not(:disabled):not(.disabled):active, .chip-black:not(:disabled):not(.disabled).active,
.show > .chip-black.dropdown-toggle {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}

.chip-black:not(:disabled):not(.disabled):active:focus, .chip-black:not(:disabled):not(.disabled).active:focus,
.show > .chip-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.chip-outline-black {
  color: #272C33;
  border-color: #272C33;
}

.chip-outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.chip-outline-black:focus, .chip-outline-black.focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.chip-outline-black.disabled, .chip-outline-black:disabled {
  color: #272C33;
  background-color: transparent;
}

.chip-outline-black:not(:disabled):not(.disabled):active, .chip-outline-black:not(:disabled):not(.disabled).active,
.show > .chip-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}

.chip-outline-black:not(:disabled):not(.disabled):active:focus, .chip-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.chip-accent {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.chip-accent:focus, .chip-accent.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-accent.disabled, .chip-accent:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-accent:not(:disabled):not(.disabled):active, .chip-accent:not(:disabled):not(.disabled).active,
.show > .chip-accent.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.chip-accent:not(:disabled):not(.disabled):active:focus, .chip-accent:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-outline-accent {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent:focus, .chip-outline-accent.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-outline-accent.disabled, .chip-outline-accent:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.chip-outline-accent:not(:disabled):not(.disabled):active, .chip-outline-accent:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent:not(:disabled):not(.disabled):active:focus, .chip-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-accent-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.chip-accent-red:focus, .chip-accent-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-accent-red.disabled, .chip-accent-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-accent-red:not(:disabled):not(.disabled):active, .chip-accent-red:not(:disabled):not(.disabled).active,
.show > .chip-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.chip-accent-red:not(:disabled):not(.disabled):active:focus, .chip-accent-red:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-outline-accent-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent-red:focus, .chip-outline-accent-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-outline-accent-red.disabled, .chip-outline-accent-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.chip-outline-accent-red:not(:disabled):not(.disabled):active, .chip-outline-accent-red:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-accent-red:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-red:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.chip-accent-yellow:focus, .chip-accent-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-accent-yellow.disabled, .chip-accent-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-accent-yellow:not(:disabled):not(.disabled):active, .chip-accent-yellow:not(:disabled):not(.disabled).active,
.show > .chip-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.chip-accent-yellow:not(:disabled):not(.disabled):active:focus, .chip-accent-yellow:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-accent-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-accent-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-accent-yellow:focus, .chip-outline-accent-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-outline-accent-yellow.disabled, .chip-outline-accent-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.chip-outline-accent-yellow:not(:disabled):not(.disabled):active, .chip-outline-accent-yellow:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-accent-yellow:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-yellow:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.chip-accent-dodger-blue:focus, .chip-accent-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-accent-dodger-blue.disabled, .chip-accent-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-accent-dodger-blue:not(:disabled):not(.disabled):active, .chip-accent-dodger-blue:not(:disabled):not(.disabled).active,
.show > .chip-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.chip-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-accent-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-outline-accent-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-accent-dodger-blue:focus, .chip-outline-accent-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-outline-accent-dodger-blue.disabled, .chip-outline-accent-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}

.chip-outline-accent-dodger-blue:not(:disabled):not(.disabled):active, .chip-outline-accent-dodger-blue:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.chip-accent-pickled-bluewood:focus, .chip-accent-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-accent-pickled-bluewood.disabled, .chip-accent-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-accent-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .chip-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.chip-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-outline-accent-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}

.chip-outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-accent-pickled-bluewood:focus, .chip-outline-accent-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-outline-accent-pickled-bluewood.disabled, .chip-outline-accent-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}

.chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-accent-electric-violet:hover {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
}

.chip-accent-electric-violet:focus, .chip-accent-electric-violet.focus {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.chip-accent-electric-violet.disabled, .chip-accent-electric-violet:disabled {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.chip-accent-electric-violet:not(:disabled):not(.disabled):active, .chip-accent-electric-violet:not(:disabled):not(.disabled).active,
.show > .chip-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #810fff;
  border-color: #7b02ff;
}

.chip-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .chip-accent-electric-violet:not(:disabled):not(.disabled).active:focus,
.show > .chip-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.chip-outline-accent-electric-violet {
  color: #9C42FF;
  border-color: #9C42FF;
}

.chip-outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.chip-outline-accent-electric-violet:focus, .chip-outline-accent-electric-violet.focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.chip-outline-accent-electric-violet.disabled, .chip-outline-accent-electric-violet:disabled {
  color: #9C42FF;
  background-color: transparent;
}

.chip-outline-accent-electric-violet:not(:disabled):not(.disabled):active, .chip-outline-accent-electric-violet:not(:disabled):not(.disabled).active,
.show > .chip-outline-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}

.chip-outline-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-electric-violet:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.chip-primary-purple {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-purple:hover {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
}

.chip-primary-purple:focus, .chip-primary-purple.focus {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.chip-primary-purple.disabled, .chip-primary-purple:disabled {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.chip-primary-purple:not(:disabled):not(.disabled):active, .chip-primary-purple:not(:disabled):not(.disabled).active,
.show > .chip-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #6425d7;
  border-color: #5f23cd;
}

.chip-primary-purple:not(:disabled):not(.disabled):active:focus, .chip-primary-purple:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.chip-outline-primary-purple {
  color: #824EE1;
  border-color: #824EE1;
}

.chip-outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.chip-outline-primary-purple:focus, .chip-outline-primary-purple.focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.chip-outline-primary-purple.disabled, .chip-outline-primary-purple:disabled {
  color: #824EE1;
  background-color: transparent;
}

.chip-outline-primary-purple:not(:disabled):not(.disabled):active, .chip-outline-primary-purple:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}

.chip-outline-primary-purple:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-purple:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.chip-primary-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}

.chip-primary-red:focus, .chip-primary-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-primary-red.disabled, .chip-primary-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-primary-red:not(:disabled):not(.disabled):active, .chip-primary-red:not(:disabled):not(.disabled).active,
.show > .chip-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}

.chip-primary-red:not(:disabled):not(.disabled):active:focus, .chip-primary-red:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-outline-primary-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-primary-red:focus, .chip-outline-primary-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-outline-primary-red.disabled, .chip-outline-primary-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}

.chip-outline-primary-red:not(:disabled):not(.disabled):active, .chip-outline-primary-red:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}

.chip-outline-primary-red:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-red:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}

.chip-primary-yellow:focus, .chip-primary-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-primary-yellow.disabled, .chip-primary-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-primary-yellow:not(:disabled):not(.disabled):active, .chip-primary-yellow:not(:disabled):not(.disabled).active,
.show > .chip-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}

.chip-primary-yellow:not(:disabled):not(.disabled):active:focus, .chip-primary-yellow:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-primary-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-primary-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-primary-yellow:focus, .chip-outline-primary-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-outline-primary-yellow.disabled, .chip-outline-primary-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}

.chip-outline-primary-yellow:not(:disabled):not(.disabled):active, .chip-outline-primary-yellow:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}

.chip-outline-primary-yellow:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-yellow:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-primary-light {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-light:hover {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
}

.chip-primary-light:focus, .chip-primary-light.focus {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.chip-primary-light.disabled, .chip-primary-light:disabled {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.chip-primary-light:not(:disabled):not(.disabled):active, .chip-primary-light:not(:disabled):not(.disabled).active,
.show > .chip-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #66b3ff;
  border-color: #59acff;
}

.chip-primary-light:not(:disabled):not(.disabled):active:focus, .chip-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.chip-outline-primary-light {
  color: #99ccff;
  border-color: #99ccff;
}

.chip-outline-primary-light:hover {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.chip-outline-primary-light:focus, .chip-outline-primary-light.focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.chip-outline-primary-light.disabled, .chip-outline-primary-light:disabled {
  color: #99ccff;
  background-color: transparent;
}

.chip-outline-primary-light:not(:disabled):not(.disabled):active, .chip-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}

.chip-outline-primary-light:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.chip-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}

.chip-primary-dodger-blue:focus, .chip-primary-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-primary-dodger-blue.disabled, .chip-primary-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-primary-dodger-blue:not(:disabled):not(.disabled):active, .chip-primary-dodger-blue:not(:disabled):not(.disabled).active,
.show > .chip-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}

.chip-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-primary-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-outline-primary-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary-dodger-blue:focus, .chip-outline-primary-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-outline-primary-dodger-blue.disabled, .chip-outline-primary-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}

.chip-outline-primary-dodger-blue:not(:disabled):not(.disabled):active, .chip-outline-primary-dodger-blue:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}

.chip-outline-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-dodger-blue:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.chip-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}

.chip-primary-pickled-bluewood:focus, .chip-primary-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-primary-pickled-bluewood.disabled, .chip-primary-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-primary-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .chip-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}

.chip-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .chip-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-outline-primary-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}

.chip-outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-primary-pickled-bluewood:focus, .chip-outline-primary-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-outline-primary-pickled-bluewood.disabled, .chip-outline-primary-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}

.chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active,
.show > .chip-outline-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}

.chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus,
.show > .chip-outline-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.brand {
  font-size: 1.625rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 1.231;
}

.brand-icon {
  margin-right: .5rem;
  display: inline-block;
  vertical-align: sub;
}

.carousel-card .carousel-control-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(39, 44, 51, 0.2), 0px 2px 2px 0px rgba(39, 44, 51, 0.14), 0px 1px 5px 0px rgba(39, 44, 51, 0.12);
  color: #272C33;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-card .carousel-control-prev,
.carousel-card .carousel-control-next {
  width: 2.5rem;
  opacity: 1;
}

.carousel-card .carousel-control-prev {
  left: calc(-2.5rem / 2);
}

.carousel-card .carousel-control-next {
  right: calc(-2.5rem / 2);
}

body .mdk-drawer-layout {
  overflow: visible !important;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent] {
  width: 200px !important;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].admin-left-panel6 {
  width: 256px !important;
}

body .mdk-header {
  position: sticky !important;
  margin-bottom: 1rem;
  height: auto;
}

body .mdk-header .material-icons {
  display: none;
}

body .mdk-header--fixed {
  position: static !important;
}

body .mdk-header--shadow::after {
  box-shadow: inset 0px 5px 6px -3px rgba(39, 44, 51, 0.4);
}

body .mdk-header__bg-front {
  background-position: center;
}

body .mdk-header__bg-front::after {
  display: block;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

body .mdk-header__content {
  display: flex;
  flex-direction: column;
}

body .mdk-header--bg-gradient-primary .mdk-header__bg-front::after {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
  opacity: 0.9;
}

body .mdk-header--bg-gradient-purple .mdk-header__bg-front::after {
  background-image: linear-gradient(-128deg, #8a4182 0%, #3289dc 100%);
  opacity: 0.9;
}

body .mdk-header--bg-white .mdk-header__bg-front::after {
  background-color: rgba(255, 255, 255, 0.9);
}

body .mdk-header--bg-primary .mdk-header__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

body .mdk-header--bg-secondary .mdk-header__bg-front::after {
  background-color: rgba(134, 142, 150, 0.9);
}

body .mdk-header--bg-success .mdk-header__bg-front::after {
  background-color: rgba(119, 193, 58, 0.9);
}

body .mdk-header--bg-info .mdk-header__bg-front::after {
  background-color: rgba(23, 162, 184, 0.9);
}

body .mdk-header--bg-warning .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

body .mdk-header--bg-danger .mdk-header__bg-front::after {
  background-color: rgba(217, 83, 79, 0.9);
}

body .mdk-header--bg-light .mdk-header__bg-front::after {
  background-color: rgba(248, 249, 250, 0.9);
}

body .mdk-header--bg-dark .mdk-header__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

body .mdk-header--bg-black .mdk-header__bg-front::after {
  background-color: rgba(39, 44, 51, 0.9);
}

body .mdk-header--bg-accent .mdk-header__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

body .mdk-header--bg-accent-red .mdk-header__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

body .mdk-header--bg-accent-yellow .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

body .mdk-header--bg-accent-dodger-blue .mdk-header__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

body .mdk-header--bg-accent-pickled-bluewood .mdk-header__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

body .mdk-header--bg-accent-electric-violet .mdk-header__bg-front::after {
  background-color: rgba(156, 66, 255, 0.9);
}

body .mdk-header--bg-primary-purple .mdk-header__bg-front::after {
  background-color: rgba(130, 78, 225, 0.9);
}

body .mdk-header--bg-primary-red .mdk-header__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

body .mdk-header--bg-primary-yellow .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

body .mdk-header--bg-primary-light .mdk-header__bg-front::after {
  background-color: rgba(153, 204, 255, 0.9);
}

body .mdk-header--bg-primary-dodger-blue .mdk-header__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

body .mdk-header--bg-primary-pickled-bluewood .mdk-header__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

@media (min-width: 992px) {
  .hero h1, .hero .h1 {
    font-size: 3.998rem;
    font-weight: 700;
    line-height: 1.25;
  }
  .hero .lead, .hero__lead {
    font-size: 1.414rem;
    line-height: 1.414;
  }
  .hero .lead:not(:last-child), .hero__lead:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.mdk-box__bg-front {
  background-position: center;
}

.mdk-box__bg-front::after {
  display: block;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mdk-box--bg-gradient-primary .mdk-box__bg-front::after {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
  opacity: .9;
}

.mdk-box--bg-primary .mdk-box__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-box--bg-secondary .mdk-box__bg-front::after {
  background-color: rgba(134, 142, 150, 0.9);
}

.mdk-box--bg-success .mdk-box__bg-front::after {
  background-color: rgba(119, 193, 58, 0.9);
}

.mdk-box--bg-info .mdk-box__bg-front::after {
  background-color: rgba(23, 162, 184, 0.9);
}

.mdk-box--bg-warning .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-danger .mdk-box__bg-front::after {
  background-color: rgba(217, 83, 79, 0.9);
}

.mdk-box--bg-light .mdk-box__bg-front::after {
  background-color: rgba(248, 249, 250, 0.9);
}

.mdk-box--bg-dark .mdk-box__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.mdk-box--bg-black .mdk-box__bg-front::after {
  background-color: rgba(39, 44, 51, 0.9);
}

.mdk-box--bg-accent .mdk-box__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-box--bg-accent-red .mdk-box__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-box--bg-accent-yellow .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-accent-dodger-blue .mdk-box__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-box--bg-accent-pickled-bluewood .mdk-box__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.mdk-box--bg-accent-electric-violet .mdk-box__bg-front::after {
  background-color: rgba(156, 66, 255, 0.9);
}

.mdk-box--bg-primary-purple .mdk-box__bg-front::after {
  background-color: rgba(130, 78, 225, 0.9);
}

.mdk-box--bg-primary-red .mdk-box__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-box--bg-primary-yellow .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-primary-light .mdk-box__bg-front::after {
  background-color: rgba(153, 204, 255, 0.9);
}

.mdk-box--bg-primary-dodger-blue .mdk-box__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-box--bg-primary-pickled-bluewood .mdk-box__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.will-shrink-to-hidden {
  will-change: transform;
  transition: 0.2s transform;
}

.shrink-to-hidden {
  transform: scale3d(0, 0, 0);
}

.will-fade-background {
  transition: 0.2s background-color;
}

.search-form {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075);
}

.search-form .form-control {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.search-form .form-control:focus {
  border-color: transparent;
  box-shadow: none;
}

.search-form .btn {
  padding: 0 .75rem;
  box-shadow: none;
  background-color: transparent;
}

.search-form .btn + .form-control {
  padding-left: 0;
}

.search-form--black, .navbar-dark .navbar-search {
  background-color: #272C33;
  border-color: #272C33;
}

.search-form--black .form-control, .navbar-dark .navbar-search .form-control {
  color: #fff;
}

.search-form--black .form-control:focus, .navbar-dark .navbar-search .form-control:focus {
  background-color: #272C33 !important;
}

.search-form--black .btn, .navbar-dark .navbar-search .btn {
  background: transparent;
  color: #868e96;
}

.search-form--light, .navbar-light .navbar-search {
  background-color: #EDF0F2;
  border-color: #EDF0F2;
}

.search-form--light .form-control, .navbar-light .navbar-search .form-control {
  color: #272C33;
}

.search-form--light .form-control::placeholder, .navbar-light .navbar-search .form-control::placeholder {
  color: #949595;
}

.search-form--light .btn, .navbar-light .navbar-search .btn {
  color: rgba(39, 44, 51, 0.4);
}

.stack {
  margin-bottom: calc(1rem + 10px);
  position: relative;
  overflow: initial;
  z-index: 0;
}

.stack::before, .stack::after {
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  transition: .2s transform ease-in-out;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07), 0px 2px 1px -1px #dfe2e6 inset, 0px 1px 2px 0px rgba(223, 226, 230, 0.14) inset, 0px 1px 3px 0px rgba(48, 56, 64, 0.12) inset;
  display: block;
  content: '';
  background: #ffffff;
  height: 5px;
  z-index: -1;
  pointer-events: none;
}

.stack::before {
  height: 10px;
  left: 20px;
  right: 20px;
}

.stack--hidden::before, .stack--hidden::after, .stack--hidden-hover:hover::before, .stack--hidden-hover:hover::after {
  transform: translate3d(0, -12px, 0);
}

.stack--primary-dark:before, .stack--primary-dark:after {
  background: #2239ff;
  box-shadow: 0 2px 0 #1830ff inset, 0 2px 0 #1830ff;
}

.stack--1::before {
  display: none;
}

.footer {
  background-color: white;
}

label {
  font-weight: 500;
}

.form-control-rounded {
  border-radius: 20rem;
}

.form-control-flush {
  padding: 0;
  border-width: 0;
  box-shadow: none;
  background-color: transparent;
}

.form-control-flush:focus {
  background-color: transparent;
  box-shadow: none;
}

.list-group-form .list-group-item {
  padding: 1rem 1.5rem;
}

.custom-controls-stacked .custom-control:last-child {
  margin-bottom: 0;
}

.form-inline label {
  margin-right: .25rem;
}

.form-label {
  color: #303956;
  text-transform: uppercase;
  font-size: 0.707rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.card-form .card-body {
  padding: 1.6875rem;
}

.card-form .card-body-form-group {
  padding: 1rem;
  padding-bottom: 0rem;
}

.card-form__body {
  background-color: #f8fafc;
  border-radius: 0.5rem 0.5rem 0 0;
}

@media (min-width: 576px) {
  .card-form__body {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

.card-form__body label:not([class]) {
  color: #303956;
  text-transform: uppercase;
  font-size: 0.707rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.form-image-group [disabled] ~ label::after,
.form-image-group :disabled ~ label::after {
  display: block;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  background: #e9ecef;
  width: initial;
  height: initial;
  border-radius: 0;
  content: " ";
  opacity: .4;
}

.input-group-text .material-icons {
  font-size: inherit;
}

.input-group.input-group-merge .form-control {
  box-shadow: none;
}

.input-group.input-group-merge .form-control:focus ~ [class*="input-group"] .input-group-text {
  border-color: white;
  color: #272C33;
  background-color: white;
}

.input-group.input-group-merge .form-control.is-valid ~ [class*="input-group"] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:valid ~ [class*="input-group"] .input-group-text {
  border-color: #77c13a;
  color: #77c13a;
}

.input-group.input-group-merge .form-control.is-invalid ~ [class*="input-group"] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:invalid ~ [class*="input-group"] .input-group-text {
  border-color: #d9534f;
  color: #d9534f;
}

.input-group.input-group-merge .form-control-prepended {
  padding-left: 0;
  border-left-width: 1px !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.input-group.input-group-merge .form-control-appended {
  padding-right: 0;
  border-right-width: 1px;
}

.input-group.input-group-merge .input-group-prepend {
  order: -1;
}

.input-group.input-group-merge .input-group-prepend > .input-group-text {
  border-right-width: 0 !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-group.input-group-merge .input-group-append > .input-group-text {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.custom-file-naked {
  cursor: pointer;
  width: auto;
  height: auto;
}

.custom-file-naked .custom-file-input {
  width: auto;
  height: auto;
  cursor: pointer;
  line-height: 1;
}

.custom-file-naked .custom-file-label {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: auto;
  cursor: pointer;
  line-height: 1;
  box-shadow: none;
}

.custom-file-naked .custom-file-label::after {
  display: none;
}

.custom-radio-icon {
  padding-left: 1rem;
}

.custom-radio-icon .custom-control-indicator {
  font-size: 1rem;
  color: white;
  background-color: transparent;
  background-image: none;
}

.custom-radio-icon .custom-control-input:checked ~ .custom-control-indicator {
  background-color: transparent;
  background-image: none;
  color: #5567FF;
}

.custom-radio-icon .custom-control-input:active ~ .custom-control-indicator {
  background-color: transparent;
  background-image: none;
  color: #eef0ff;
}

.custom-select-icon {
  position: relative;
  z-index: 0;
}

.custom-select-icon__select {
  padding-left: 1.75rem;
}

.custom-select-icon__select.form-control-lg {
  padding-left: 2rem;
}

.custom-select-icon__icon {
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.75rem;
  padding-top: 0.5rem;
  line-height: inherit !important;
  font-size: 1rem;
  z-index: 2;
  pointer-events: none;
}

.form-control-sm + .custom-select-icon__icon,
.custom-select-sm + .custom-select-icon__icon {
  font-size: 0.875rem;
}

.form-control-lg + .custom-select-icon__icon {
  padding-top: 0.5rem;
  font-size: 1.25rem;
}

.sidebar-p-a {
  padding: 1.5rem 1.5rem;
}

.sidebar-p-x {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.sidebar-p-y {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.sidebar-p-t {
  padding-top: 1.5rem;
}

.sidebar-p-b {
  padding-bottom: 1.5rem;
}

.sidebar-p-l {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.sidebar-p-r {
  padding-right: 1.5rem;
}

.sidebar-m-a {
  margin: 1.5rem 1.5rem;
}

.sidebar-m-x {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.sidebar-m-y {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-m-t {
  margin-top: 1.5rem;
}

.sidebar-m-b {
  margin-bottom: 1.5rem;
}

.sidebar-m-l {
  margin-left: 1.5rem;
}

.sidebar-m-r {
  margin-right: 1.5rem;
}

.sidebar-b-a {
  border: 1px solid transparent;
}

.sidebar-b-x {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.sidebar-b-y {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.sidebar-b-t {
  border-top: 1px solid transparent;
}

.sidebar-b-b {
  border-bottom: 1px solid transparent;
}

.sidebar-block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar > p,
.sidebar > .sidebar-text {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-brand {
  font-weight: 500;
  font-size: 1.625rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
}

.sidebar-brand:hover {
  text-decoration: none;
}

.sidebar-brand-icon {
  margin-right: 0.75rem;
}

.sidebar-brand-header {
  height: 56px;
  line-height: 56px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.sidebar-brand-border {
  border-bottom: 1px solid transparent;
}

.sidebar-heading {
  font-weight: 600;
  font-size: 0.9rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: normal;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.sidebar-light {
  color: rgba(0, 0, 0, 0.54);
  background: #ffffff;
}

.sidebar-light.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #e5e5e5;
}

.sidebar-light.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #e5e5e5;
}

.sidebar-light .sidebar-link {
  color: inherit;
}

.sidebar-light [class*="sidebar-b-"] {
  border-color: #e5e5e5;
}

.sidebar-light .sidebar-text, .sidebar-light p {
  color: rgba(0, 0, 0, 0.54);
}

.sidebar-light .sidebar-heading, .sidebar-light .sidebar-heading > a {
  color: #949799;
}

.sidebar-light hr {
  border-color: #e5e5e5;
}

.sidebar-light .sidebar-brand {
  color: #272C33;
}

.sidebar-light .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.07);
}

.sidebar-light .sidebar-brand-border {
  border-color: #e5e5e5;
}

.sidebar-light .sidebar-menu-button {
  color: rgba(47, 55, 64, 0.4);
}

.sidebar-light .sidebar-menu-toggle-icon {
  color: rgba(0, 0, 0, 0.24);
}

.sidebar-light .sidebar-menu-icon {
  color: rgba(47, 55, 64, 0.2);
}

.sidebar-light .sidebar-menu-label {
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.sidebar-light .sidebar-menu-button:hover {
  color: #8A9199;
  background: transparent;
}

.sidebar-light .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-light .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-light .open {
  background: #F5F7FA;
}

.sidebar-light .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-light .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-light .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-light .active > .sidebar-menu-button {
  color: #8A9199;
}

.sidebar-light .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-light .sidebar-submenu .sidebar-menu-button {
  color: rgba(47, 55, 64, 0.4);
}

.sidebar-light .sidebar-submenu .sidebar-menu-icon {
  color: rgba(47, 55, 64, 0.2);
}

.sidebar-light .sidebar-submenu .sidebar-menu-button:hover {
  color: #8A9199;
}

.sidebar-light .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-light .sidebar-submenu .active > .sidebar-menu-button {
  color: #8A9199;
}

.sidebar-light .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-light .sm-bordered {
  border-color: #e5e5e5;
}

.sidebar-light .sm-bordered.sidebar-submenu {
  border-color: #e5e5e5;
}

.sidebar-light .sm-item-bordered > .sidebar-menu-item {
  border-color: #E9EDF2;
}

.sidebar-light .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #E9EDF2;
}

.sidebar-light .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(47, 55, 64, 0.2);
}

.sidebar-light .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-light .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: #5567FF;
  color: #fff;
}

.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #5567FF;
  color: #fff;
}

.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}

.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}

.sidebar-light .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: #5567FF;
}

.sidebar-dark {
  color: rgba(255, 255, 255, 0.54);
  background: #303956;
}

.sidebar-dark.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark .sidebar-link {
  color: #fff;
}

.sidebar-dark [class*="sidebar-b-"] {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-text, .sidebar-dark p {
  color: rgba(255, 255, 255, 0.54);
}

.sidebar-dark .sidebar-heading, .sidebar-dark .sidebar-heading > a {
  color: #949799;
}

.sidebar-dark hr {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-brand {
  color: #ffffff;
}

.sidebar-dark .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-dark .open {
  background: #272C33;
}

.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-dark .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-dark .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark .sm-bordered {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sm-bordered.sidebar-submenu {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}

.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}

.sidebar-dark .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-menu {
  margin-bottom: 2rem;
  padding: 0 0;
  list-style: none;
}

.sidebar-menu-item {
  position: relative;
  overflow: hidden;
}

.sidebar-menu-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 40px;
  padding: 0 1.5rem;
  font-size: 15px;
}

.sidebar-menu-button, .sidebar-menu-button:hover, .sidebar-menu-button:focus {
  text-decoration: none;
}

.active > .sidebar-menu-button {
  font-weight: 500;
}

.sidebar-menu-toggle-icon {
  position: relative;
  font-size: 1.25rem;
}

.sidebar-menu-toggle-icon:before {
  font-family: "Material Icons";
  content: "";
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;
}

.open > .sidebar-menu-button .sidebar-menu-toggle-icon:before {
  content: "";
}

.sidebar-menu-icon {
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  line-height: normal;
  position: relative;
}

.sidebar-menu-icon--left {
  margin-right: 0.5rem;
}

.sidebar-menu-icon--right {
  margin-left: 0.5rem;
}

.sidebar-menu-badge {
  padding: 0.2rem 0.3rem;
  font-size: 0.75rem;
}

.sidebar-menu-badge,
.sidebar-menu-toggle-icon {
  margin-left: 8px;
}

.sidebar-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.open > .sidebar-submenu {
  display: block;
}

.sidebar-submenu .sidebar-menu-button {
  line-height: 32px;
  font-size: 15px;
}

.sidebar-submenu .sidebar-menu-icon {
  font-size: 24px;
}

.sm-icons-1 .sidebar-menu-icon {
  font-size: 1rem;
}

.sm-icons-small .sidebar-menu-icon {
  font-size: .875rem;
}

.sm-condensed > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 30px;
}

.sm-condensed.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 24px;
}

.sm-bordered {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sm-item-bordered > .sidebar-menu-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sm-item-bordered > .sidebar-menu-item:last-of-type {
  border-bottom: none;
}

.sm-item-bordered .sidebar-submenu {
  border-bottom: none;
}

.sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  text-align: center;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-icons-block.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-indent > .sidebar-menu-item .sidebar-menu-button {
  padding-left: 3rem;
}

.mdk-drawer {
  z-index: 3;
}

.mdk-drawer__content {
  background: transparent;
}

.mdk-drawer__content.scroll {
  overflow: scroll;
}

.sidebar {
  position: relative;
  height: auto;
  min-height: 100vh;
  text-align: initial;
  overscroll-behavior: none;
}

.sidebar-brand {
  margin-bottom: 0;
  height: 64px;
  flex-direction: column;
  padding: 2.5rem 0;
}

.sidebar-brand-icon {
  display: flex;
  width: 5.125rem;
  height: 5.125rem;
}

.sidebar-menu-toggle-icon {
  transition: transform .15s;
  transform: translate(0, 0);
}

.open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  transform: rotate(90deg);
}

.sidebar-submenu .sidebar-menu-item:first-child {
  padding-top: 0.75rem;
}

.sidebar-submenu .sidebar-menu-item:last-child {
  padding-bottom: 0.75rem;
}

.sidebar-menu-item.active .sidebar-menu-button::after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  background-color: #5567FF;
  content: "";
}

.sidebar-menu-item.active .sidebar-menu-button::after, .sidebar-menu-item.open .sidebar-menu-button::after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  content: "";
}

.sidebar-menu-item.open > .sidebar-menu-button::after {
  background-color: rgba(172, 182, 191, 0.2);
}

.sidebar-menu-item.active > .sidebar-menu-button::after {
  background-color: #5567FF;
}

.sidebar-menu:last-child {
  margin-bottom: 0;
}

.sidebar-brand {
  height: auto;
  line-height: 2rem;
  margin-right: 0;
}

.sidebar-brand-icon {
  margin-right: 0;
  margin-bottom: .5rem;
}

.sidebar-heading {
  line-height: 1.5rem;
  font-size: .875rem;
  margin-bottom: .5rem;
}

.sidebar-menu > .sidebar-menu-item {
  margin-right: 1rem;
  border-radius: 0 10px 10px 0;
}

.sidebar-menu-button {
  padding-right: .5rem;
}

.sidebar-menu-button.disabled, .sidebar-menu-button:disabled {
  opacity: .5;
  pointer-events: none;
}

.sidebar-menu-badge {
  padding: 0 0.5rem;
}

.sm-indent > .sidebar-menu-item .sidebar-menu-button {
  padding-left: 2.1875rem;
}

.sidebar-submenu .sidebar-menu-item:first-child {
  padding-top: 0;
}

.sidebar-menu-item.active .sidebar-menu-button::after, .sidebar-menu-item.open .sidebar-menu-button::after {
  display: none;
}

.sidebar-submenu .sidebar-menu-text {
  padding-left: 1.25rem;
  position: relative;
}

.sidebar-submenu .sidebar-menu-text::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #ED0B4C;
  display: none;
  left: -4px;
  top: 12px;
  border-radius: 100%;
}

.active > .sidebar-menu-button .sidebar-menu-text::after {
  display: block;
}

.sidebar-account {
  border-radius: 100px;
  box-shadow: 0px 2px 1px -1px rgba(39, 44, 51, 0.1), 0px 1px 1px 0px rgba(39, 44, 51, 0.04), 0px 1px 3px 0px rgba(39, 44, 51, 0.02);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-account {
  background: white;
}

.sidebar-dark .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark .navbar-toggler:hover {
  color: #8A9199;
}

.sidebar-dark {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
}

.sidebar-dark .text-100 {
  color: rgba(255, 255, 255, 0.54) !important;
}

.sidebar-dark .text-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.sidebar-black {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #1D2126;
}

.sidebar-black.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-black.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-black .sidebar-link {
  color: #fff;
}

.sidebar-black [class*="sidebar-b-"] {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-black .sidebar-text, .sidebar-black p {
  color: rgba(255, 255, 255, 0.54);
}

.sidebar-black .text-100 {
  color: rgba(255, 255, 255, 0.54);
}

.sidebar-black .text-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.sidebar-black .sidebar-heading, .sidebar-black .sidebar-heading > a {
  color: #949799;
}

.sidebar-black hr {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-black .sidebar-brand {
  color: #ffffff;
}

.sidebar-black .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-black .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-black .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-black .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-black .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-black .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-black .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-black .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-black .open {
  background: rgba(0, 0, 0, 0.2);
}

.sidebar-black .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}

.sidebar-black .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-black .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}

.sidebar-black .active > .sidebar-menu-button {
  color: white;
}

.sidebar-black .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-black .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(46, 49, 51, 0.9);
}

.sidebar-black .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-black .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-black .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-black .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-black .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-black .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-black .sm-bordered {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-black .sm-bordered.sidebar-submenu {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-black .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}

.sidebar-black .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}

.sidebar-black .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-black .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-black .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}

.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}

.sidebar-black .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-black .sidebar-account {
  background: white;
}

.sidebar-black .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}

.sidebar-black .search-form--black, .sidebar-black .navbar-dark .navbar-search, .navbar-dark .sidebar-black .navbar-search {
  background-color: #303840;
  border-color: #303840;
}

.sidebar-black .search-form--black .form-control, .sidebar-black .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-black .navbar-search .form-control {
  color: #fff;
}

.sidebar-black .search-form--black .form-control::placeholder, .sidebar-black .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-black .navbar-search .form-control::placeholder {
  color: #8A9199;
}

.sidebar-black .search-form--black .form-control:focus, .sidebar-black .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-black .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.sidebar-black .search-form--black .btn, .sidebar-black .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-black .navbar-search .btn {
  background: transparent;
  color: #8A9199;
}

.sidebar-black .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-black .navbar-toggler:hover {
  color: #8A9199;
}

.sidebar-dark-purple {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}

.sidebar-dark-purple.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-purple.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-purple .sidebar-link {
  color: #fff;
}

.sidebar-dark-purple [class*="sidebar-b-"] {
  border-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark-purple .sidebar-text,
.sidebar-dark-purple .text-100,
.sidebar-dark-purple p {
  color: #BF9DF2 !important;
}

.sidebar-dark-purple .text-50 {
  color: rgba(191, 157, 242, 0.5) !important;
}

.sidebar-dark-purple .sidebar-heading, .sidebar-dark-purple .sidebar-heading > a {
  color: #C8B8E6;
}

.sidebar-dark-purple hr {
  border-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark-purple .sidebar-brand {
  color: #ffffff;
}

.sidebar-dark-purple .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-purple .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark-purple .sidebar-menu-button {
  color: #BF9DF2;
}

.sidebar-dark-purple .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-purple .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-dark-purple .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-purple .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}

.sidebar-dark-purple .open {
  background: rgba(50, 50, 51, 0.05);
}

.sidebar-dark-purple .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .open > .sidebar-menu-button {
  background: transparent;
  color: #DECCFF;
}

.sidebar-dark-purple .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .active > .sidebar-menu-button {
  color: #DECCFF;
}

.sidebar-dark-purple .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button {
  color: #BF9DF2;
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-dark-purple .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark-purple .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-purple .sm-bordered {
  border-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark-purple .sm-bordered.sidebar-submenu {
  border-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark-purple .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-purple .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button {
  background: white;
  color: #824EE1;
}

.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}

.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}

.sidebar-dark-purple .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-dark-purple .sidebar-account {
  background: white;
}

.sidebar-dark-purple .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}

.sidebar-dark-purple .search-form--black, .sidebar-dark-purple .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-purple .navbar-search {
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}

.sidebar-dark-purple .search-form--black .form-control, .sidebar-dark-purple .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-purple .navbar-search .form-control {
  color: #fff;
}

.sidebar-dark-purple .search-form--black .form-control::placeholder, .sidebar-dark-purple .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-purple .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .search-form--black .form-control:focus, .sidebar-dark-purple .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-purple .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.sidebar-dark-purple .search-form--black .btn, .sidebar-dark-purple .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-purple .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-purple .navbar-toggler:hover {
  color: white;
}

.sidebar-dark-blue {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #0C2440;
}

.sidebar-dark-blue.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-blue.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-blue .sidebar-link {
  color: #fff;
}

.sidebar-dark-blue [class*="sidebar-b-"] {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sidebar-text,
.sidebar-dark-blue .text-100,
.sidebar-dark-blue p {
  color: rgba(172, 182, 191, 0.5) !important;
}

.sidebar-dark-blue .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}

.sidebar-dark-blue .sidebar-heading, .sidebar-dark-blue .sidebar-heading > a {
  color: #8A9199;
}

.sidebar-dark-blue hr {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sidebar-brand {
  color: #ffffff;
}

.sidebar-dark-blue .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-blue .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark-blue .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-blue .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-blue .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-blue .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-dark-blue .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-blue .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}

.sidebar-dark-blue .open {
  background: #0A1D33;
}

.sidebar-dark-blue .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-blue .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-dark-blue .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-blue .active > .sidebar-menu-button {
  color: #8A9199;
}

.sidebar-dark-blue .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-dark-blue .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark-blue .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-blue .sm-bordered {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sm-bordered.sidebar-submenu {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(15, 43, 77, 0.9);
}

.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}

.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}

.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}

.sidebar-dark-blue .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-dark-blue .sidebar-account {
  background: white;
}

.sidebar-dark-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}

.sidebar-dark-blue .search-form--black, .sidebar-dark-blue .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-blue .navbar-search {
  background-color: #0A1D33;
  border-color: #0A1D33;
}

.sidebar-dark-blue .search-form--black .form-control, .sidebar-dark-blue .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-blue .navbar-search .form-control {
  color: #fff;
}

.sidebar-dark-blue .search-form--black .form-control::placeholder, .sidebar-dark-blue .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-blue .search-form--black .form-control:focus, .sidebar-dark-blue .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.sidebar-dark-blue .search-form--black .btn, .sidebar-dark-blue .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-blue .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-blue .navbar-toggler:hover {
  color: white;
}

.sidebar-dark-dodger-blue {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #303956;
}

.sidebar-dark-dodger-blue.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-dodger-blue.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark-dodger-blue .sidebar-link {
  color: #fff;
}

.sidebar-dark-dodger-blue [class*="sidebar-b-"] {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sidebar-text,
.sidebar-dark-dodger-blue .text-100,
.sidebar-dark-dodger-blue p {
  color: white !important;
}

.sidebar-dark-dodger-blue .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}

.sidebar-dark-dodger-blue .sidebar-heading, .sidebar-dark-dodger-blue .sidebar-heading > a {
  color: #8A9199;
}

.sidebar-dark-dodger-blue hr {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sidebar-brand {
  color: #ffffff;
}

.sidebar-dark-dodger-blue .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-dodger-blue .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark-dodger-blue .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-dodger-blue .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-dodger-blue .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-dodger-blue .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-dark-dodger-blue .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-dodger-blue .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}

.sidebar-dark-dodger-blue .open {
  background: #074A3A;
}

.sidebar-dark-dodger-blue .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-dodger-blue .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-dark-dodger-blue .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-dodger-blue .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark-dodger-blue .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #303956;
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-dark-dodger-blue .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-dark-dodger-blue .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-dark-dodger-blue .sm-bordered {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sm-bordered.sidebar-submenu {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sm-item-bordered > .sidebar-menu-item {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-dodger-blue .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark-dodger-blue .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-dark-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}

.sidebar-dark-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}

.sidebar-dark-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}

.sidebar-dark-dodger-blue .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-dark-dodger-blue .sidebar-account {
  background: white;
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}

.sidebar-dark-dodger-blue .search-form--black, .sidebar-dark-dodger-blue .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-dodger-blue .navbar-search {
  background-color: #074A3A;
  border-color: #074A3A;
}

.sidebar-dark-dodger-blue .search-form--black .form-control, .sidebar-dark-dodger-blue .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-dodger-blue .navbar-search .form-control {
  color: #fff;
}

.sidebar-dark-dodger-blue .search-form--black .form-control::placeholder, .sidebar-dark-dodger-blue .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-dodger-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-dodger-blue .search-form--black .form-control:focus, .sidebar-dark-dodger-blue .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-dodger-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.sidebar-dark-dodger-blue .search-form--black .btn, .sidebar-dark-dodger-blue .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-dodger-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark-dodger-blue .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-dark-dodger-blue .navbar-toggler:hover {
  color: white;
}

.sidebar-green {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: #ACB0B5;
  background: #095846;
}

.sidebar-green.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.sidebar-green.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}

.sidebar-green .sidebar-link {
  color: #fff;
}

.sidebar-green [class*="sidebar-b-"] {
  border-color: #074A3A;
}

.sidebar-green .sidebar-text,
.sidebar-green .text-100,
.sidebar-green p {
  color: white !important;
}

.sidebar-green .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}

.sidebar-green .sidebar-heading, .sidebar-green .sidebar-heading > a {
  color: #8A9199;
}

.sidebar-green hr {
  border-color: #074A3A;
}

.sidebar-green .sidebar-brand {
  color: #ffffff;
}

.sidebar-green .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-green .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-green .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-green .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-green .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-green .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}

.sidebar-green .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}

.sidebar-green .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}

.sidebar-green .open {
  background: #074A3A;
}

.sidebar-green .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-green .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}

.sidebar-green .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-green .active > .sidebar-menu-button {
  color: white;
}

.sidebar-green .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-green .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #303956;
}

.sidebar-green .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}

.sidebar-green .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-green .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}

.sidebar-green .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}

.sidebar-green .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}

.sidebar-green .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}

.sidebar-green .sm-bordered {
  border-color: #074A3A;
}

.sidebar-green .sm-bordered.sidebar-submenu {
  border-color: #074A3A;
}

.sidebar-green .sm-item-bordered > .sidebar-menu-item {
  border-color: #074A3A;
}

.sidebar-green .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #074A3A;
}

.sidebar-green .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-green .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-green .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.sidebar-green .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}

.sidebar-green .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}

.sidebar-green .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}

.sidebar-green .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-green .sidebar-account {
  background: white;
}

.sidebar-green .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}

.sidebar-green .search-form--black, .sidebar-green .navbar-dark .navbar-search, .navbar-dark .sidebar-green .navbar-search {
  background-color: #074A3A;
  border-color: #074A3A;
}

.sidebar-green .search-form--black .form-control, .sidebar-green .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-green .navbar-search .form-control {
  color: #fff;
}

.sidebar-green .search-form--black .form-control::placeholder, .sidebar-green .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-green .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-green .search-form--black .form-control:focus, .sidebar-green .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-green .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.sidebar-green .search-form--black .btn, .sidebar-green .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-green .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-green .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}

.sidebar-green .navbar-toggler:hover {
  color: white;
}

.sidebar-light {
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.sidebar-light .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #E9EDF2;
}

.sidebar-light-yellow .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}

.sidebar-light-red .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}

.sidebar-light-purple .sidebar-submenu .sidebar-menu-text::after {
  background-color: #824EE1;
}

.sidebar-light-dodger-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #5567FF;
}

.navbar {
  min-height: 64px;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-shadow {
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.top-navbar {
  top: 64px;
}

@media (min-width: 374px) {
  .top-i8-navbar {
    top: 64px;
  }
}

@media (min-width: 413px) {
  .top-i8-plus-navbar {
    top: 64px;
  }
}

@media (min-width: 576px) {
  .top-sm-navbar {
    top: 64px;
  }
}

@media (min-width: 768px) {
  .top-md-navbar {
    top: 64px;
  }
}

@media (min-width: 992px) {
  .top-lg-navbar {
    top: 64px;
  }
}

@media (min-width: 1200px) {
  .top-xl-navbar {
    top: 64px;
  }
}

@media (min-width: 1366px) {
  .top-xxl-navbar {
    top: 64px;
  }
}

.navbar-height {
  min-height: 64px;
}

.navbar-brand {
  font-size: 1.625rem;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.navbar-brand-icon {
  margin-right: 0.5rem;
}

.navbar-divider {
  width: 1px;
  height: 64px;
  background: rgba(255, 255, 255, 0.24);
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  align-items: center;
  font-weight: 500;
  position: relative;
  padding: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-toggler {
  border: none;
  width: 64px;
  height: 64px;
  padding: 0;
  text-align: center;
  display: inline-block;
}

.navbar-toggler-right {
  order: 1;
}

@media (max-width: 767.98px) {
  .navbar .container {
    max-width: none;
  }
}

.navbar-expand-i8 .nav-link {
  height: 64px;
}

@media (max-width: 373.98px) {
  .navbar-expand-i8 > .container .navbar-brand,
  .navbar-expand-i8 > .container-fluid .navbar-brand,
  .navbar-expand-i8 > .container-sm .navbar-brand,
  .navbar-expand-i8 > .container-md .navbar-brand,
  .navbar-expand-i8 > .container-lg .navbar-brand,
  .navbar-expand-i8 > .container-xl .navbar-brand,
  .navbar-expand-i8 > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-i8.navbar-list {
    height: auto;
  }
  .navbar-expand-i8.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-i8.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 374px) {
  .navbar-expand-i8 .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-i8 .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-i8 .navbar-nav .nav-link + .nav-link,
  .navbar-expand-i8 .navbar-nav .nav-item + .nav-item,
  .navbar-expand-i8 .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-i8 .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-i8 .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-i8 .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-i8-plus .nav-link {
  height: 64px;
}

@media (max-width: 412.98px) {
  .navbar-expand-i8-plus > .container .navbar-brand,
  .navbar-expand-i8-plus > .container-fluid .navbar-brand,
  .navbar-expand-i8-plus > .container-sm .navbar-brand,
  .navbar-expand-i8-plus > .container-md .navbar-brand,
  .navbar-expand-i8-plus > .container-lg .navbar-brand,
  .navbar-expand-i8-plus > .container-xl .navbar-brand,
  .navbar-expand-i8-plus > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-i8-plus.navbar-list {
    height: auto;
  }
  .navbar-expand-i8-plus.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-i8-plus.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 413px) {
  .navbar-expand-i8-plus .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-i8-plus .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-i8-plus .navbar-nav .nav-link + .nav-link,
  .navbar-expand-i8-plus .navbar-nav .nav-item + .nav-item,
  .navbar-expand-i8-plus .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-i8-plus .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-i8-plus .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-i8-plus .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-sm .nav-link {
  height: 64px;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container .navbar-brand,
  .navbar-expand-sm > .container-fluid .navbar-brand,
  .navbar-expand-sm > .container-sm .navbar-brand,
  .navbar-expand-sm > .container-md .navbar-brand,
  .navbar-expand-sm > .container-lg .navbar-brand,
  .navbar-expand-sm > .container-xl .navbar-brand,
  .navbar-expand-sm > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-sm.navbar-list {
    height: auto;
  }
  .navbar-expand-sm.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-sm.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link + .nav-link,
  .navbar-expand-sm .navbar-nav .nav-item + .nav-item,
  .navbar-expand-sm .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-sm .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-sm .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-md .nav-link {
  height: 64px;
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container .navbar-brand,
  .navbar-expand-md > .container-fluid .navbar-brand,
  .navbar-expand-md > .container-sm .navbar-brand,
  .navbar-expand-md > .container-md .navbar-brand,
  .navbar-expand-md > .container-lg .navbar-brand,
  .navbar-expand-md > .container-xl .navbar-brand,
  .navbar-expand-md > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-md.navbar-list {
    height: auto;
  }
  .navbar-expand-md.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-md.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link + .nav-link,
  .navbar-expand-md .navbar-nav .nav-item + .nav-item,
  .navbar-expand-md .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-md .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-md .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-lg .nav-link {
  height: 64px;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container .navbar-brand,
  .navbar-expand-lg > .container-fluid .navbar-brand,
  .navbar-expand-lg > .container-sm .navbar-brand,
  .navbar-expand-lg > .container-md .navbar-brand,
  .navbar-expand-lg > .container-lg .navbar-brand,
  .navbar-expand-lg > .container-xl .navbar-brand,
  .navbar-expand-lg > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-lg.navbar-list {
    height: auto;
  }
  .navbar-expand-lg.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-lg.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link + .nav-link,
  .navbar-expand-lg .navbar-nav .nav-item + .nav-item,
  .navbar-expand-lg .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-lg .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-lg .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-xl .nav-link {
  height: 64px;
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container .navbar-brand,
  .navbar-expand-xl > .container-fluid .navbar-brand,
  .navbar-expand-xl > .container-sm .navbar-brand,
  .navbar-expand-xl > .container-md .navbar-brand,
  .navbar-expand-xl > .container-lg .navbar-brand,
  .navbar-expand-xl > .container-xl .navbar-brand,
  .navbar-expand-xl > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-xl.navbar-list {
    height: auto;
  }
  .navbar-expand-xl.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-xl.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link + .nav-link,
  .navbar-expand-xl .navbar-nav .nav-item + .nav-item,
  .navbar-expand-xl .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-xl .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-xl .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand-xxl .nav-link {
  height: 64px;
}

@media (max-width: 1365.98px) {
  .navbar-expand-xxl > .container .navbar-brand,
  .navbar-expand-xxl > .container-fluid .navbar-brand,
  .navbar-expand-xxl > .container-sm .navbar-brand,
  .navbar-expand-xxl > .container-md .navbar-brand,
  .navbar-expand-xxl > .container-lg .navbar-brand,
  .navbar-expand-xxl > .container-xl .navbar-brand,
  .navbar-expand-xxl > .container-xxl .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-xxl.navbar-list {
    height: auto;
  }
  .navbar-expand-xxl.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-xxl.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}

@media (min-width: 1366px) {
  .navbar-expand-xxl .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-link + .nav-link,
  .navbar-expand-xxl .navbar-nav .nav-item + .nav-item,
  .navbar-expand-xxl .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-xxl .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-xxl .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}

.navbar-expand .nav-link {
  height: 64px;
}

.navbar-expand > .container .navbar-brand,
.navbar-expand > .container-fluid .navbar-brand,
.navbar-expand > .container-sm .navbar-brand,
.navbar-expand > .container-md .navbar-brand,
.navbar-expand > .container-lg .navbar-brand,
.navbar-expand > .container-xl .navbar-brand,
.navbar-expand > .container-xxl .navbar-brand {
  padding-left: 1rem;
}

.navbar-expand.navbar-list {
  height: auto;
}

.navbar-expand.navbar-list .navbar-list__item {
  padding: 1rem;
  width: 100%;
}

.navbar-expand.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
  border-bottom: 1px solid #E9EDF2;
}

.navbar-expand .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav .nav-link + .nav-link,
.navbar-expand .navbar-nav .nav-item + .nav-item,
.navbar-expand .navbar-nav + .navbar-nav {
  margin-left: 1rem;
}

.navbar-expand .navbar-list__content {
  flex-direction: row;
}

.navbar-expand .navbar-collapse__content {
  display: flex;
  width: 100%;
}

.navbar-expand .navbar-collapse__content .navbar-nav {
  align-items: center;
}

@media (min-width: 576px) {
  .navbar-nav > .active > .nav-link::before,
  .navbar-nav .nav-link.active::before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #5567FF;
    content: " ";
    bottom: -3px;
  }
}

@media (max-width: 373.98px) {
  .navbar-collapse__content {
    padding: 1rem;
  }
  .navbar-collapse .navbar-nav + .navbar-nav {
    margin-top: 1rem;
  }
}

.navbar-list {
  padding-left: 0;
  padding-right: 0;
}

.navbar-list__content {
  display: flex;
  flex-direction: column;
}

.navbar-list__item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-submenu {
  background: #ffffff;
  border-bottom: 2px solid #E9EDF2;
}

@media (max-width: 373.98px) {
  .navbar-submenu .navbar-collapse {
    border-bottom: 2px solid #E9EDF2;
  }
}

.navbar-mini {
  min-height: 40px;
}

.navbar-mini .navbar-nav .nav-link {
  font-size: .8rem;
  font-weight: 400;
}

.navbar-mini .navbar-nav > .active > .nav-link::before {
  display: none;
}

.mr-navbar-x {
  margin-right: 1rem;
}

.bg-primary .navbar-dark .navbar-toggler {
  color: #fff;
}

.navbar .ps {
  padding-top: 3px;
  padding-bottom: 3px;
}

.navbar .ps .nav {
  flex-wrap: nowrap;
}

.navbar-nav > .active > .nav-link::before,
.navbar-nav .nav-link.active::before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #5567FF;
  content: " ";
  bottom: 0;
}

.navbar-light .badge-notifications {
  border: 1px solid #fff;
}

.navbar-light .navbar-text-50 {
  color: rgba(39, 44, 51, 0.5);
}

.navbar-light .navbar-text-70 {
  color: rgba(39, 44, 51, 0.7);
}

.navbar-light .navbar-text-100 {
  color: #272C33;
}

.navbar-light .navbar-avatar {
  background-color: #EDF0F2;
  border: 1px solid #EDF0F2;
  color: #303956;
}

.navbar-dark .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.navbar-dark .badge-notifications {
  border: 1px solid #303956;
}

.navbar-dark .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-text-100 {
  color: #fff;
}

.navbar-dark-white .navbar-brand {
  color: #fff;
}

.navbar-dark-white .navbar-brand:hover, .navbar-dark-white .navbar-brand:focus {
  color: #fff;
}

.navbar-dark-white .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-white .navbar-nav .nav-link:hover, .navbar-dark-white .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-dark-white .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark-white .navbar-nav .show > .nav-link,
.navbar-dark-white .navbar-nav .active > .nav-link,
.navbar-dark-white .navbar-nav .nav-link.show,
.navbar-dark-white .navbar-nav .nav-link.active {
  color: #fff;
}

@media (min-width: 576px) {
  .navbar-dark-white .navbar-nav .show > .nav-link::before,
  .navbar-dark-white .navbar-nav .active > .nav-link::before,
  .navbar-dark-white .navbar-nav .nav-link.show::before,
  .navbar-dark-white .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}

.navbar-dark-white .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-white .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-white .navbar-text a {
  color: #fff;
}

.navbar-dark-white .navbar-text a:hover, .navbar-dark-white .navbar-text a:focus {
  color: #fff;
}

.navbar-black {
  background: #1D2126;
}

.navbar-black .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-black .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-black .navbar-text-100 {
  color: #fff;
}

.navbar-black .navbar-search {
  background-color: #303840;
  border-color: #303840;
}

.navbar-black .navbar-search .form-control {
  color: #fff;
}

.navbar-black .navbar-search .form-control::placeholder {
  color: #8A9199;
}

.navbar-black .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.navbar-black .navbar-search .btn {
  background: transparent;
  color: #8A9199;
}

.navbar-black .navbar-btn {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.navbar-black .navbar-btn:hover {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
}

.navbar-black .navbar-btn:focus, .navbar-black .navbar-btn.focus {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(79, 86, 93, 0.5);
}

.navbar-black .navbar-btn.disabled, .navbar-black .navbar-btn:disabled {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}

.navbar-black .navbar-btn:not(:disabled):not(.disabled):active, .navbar-black .navbar-btn:not(:disabled):not(.disabled).active,
.show > .navbar-black .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #1a1f23;
  border-color: #15181c;
}

.navbar-black .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-black .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .navbar-black .navbar-btn.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(79, 86, 93, 0.5);
}

.navbar-black .navbar-brand {
  color: #fff;
}

.navbar-black .navbar-brand:hover, .navbar-black .navbar-brand:focus {
  color: #fff;
}

.navbar-black .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-black .navbar-nav .nav-link:hover, .navbar-black .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-black .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-black .navbar-nav .show > .nav-link,
.navbar-black .navbar-nav .active > .nav-link,
.navbar-black .navbar-nav .nav-link.show,
.navbar-black .navbar-nav .nav-link.active {
  color: #fff;
}

@media (min-width: 576px) {
  .navbar-black .navbar-nav .show > .nav-link::before,
  .navbar-black .navbar-nav .active > .nav-link::before,
  .navbar-black .navbar-nav .nav-link.show::before,
  .navbar-black .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}

.navbar-black .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-black .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-blue {
  background: #0C2440;
}

.navbar-dark-blue .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-blue .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-blue .navbar-text-100 {
  color: #fff;
}

.navbar-dark-blue .navbar-search {
  background-color: #0A1D33;
  border-color: #0A1D33;
}

.navbar-dark-blue .navbar-search .form-control {
  color: #fff;
}

.navbar-dark-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.navbar-dark-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-blue .navbar-btn {
  color: #fff;
  background-color: #0A1D33;
  border-color: #0A1D33;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.navbar-dark-blue .navbar-btn:hover {
  color: #fff;
  background-color: #040b13;
  border-color: #020508;
}

.navbar-dark-blue .navbar-btn:focus, .navbar-dark-blue .navbar-btn.focus {
  color: #fff;
  background-color: #040b13;
  border-color: #020508;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(47, 63, 82, 0.5);
}

.navbar-dark-blue .navbar-btn.disabled, .navbar-dark-blue .navbar-btn:disabled {
  color: #fff;
  background-color: #0A1D33;
  border-color: #0A1D33;
}

.navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled).active,
.show > .navbar-dark-blue .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #020508;
  border-color: black;
}

.navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .navbar-dark-blue .navbar-btn.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(47, 63, 82, 0.5);
}

.navbar-dark-blue .navbar-brand {
  color: #fff;
}

.navbar-dark-blue .navbar-brand:hover, .navbar-dark-blue .navbar-brand:focus {
  color: #fff;
}

.navbar-dark-blue .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-blue .navbar-nav .nav-link:hover, .navbar-dark-blue .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-dark-blue .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark-blue .navbar-nav .show > .nav-link,
.navbar-dark-blue .navbar-nav .active > .nav-link,
.navbar-dark-blue .navbar-nav .nav-link.show,
.navbar-dark-blue .navbar-nav .nav-link.active {
  color: #fff;
}

@media (min-width: 576px) {
  .navbar-dark-blue .navbar-nav .show > .nav-link::before,
  .navbar-dark-blue .navbar-nav .active > .nav-link::before,
  .navbar-dark-blue .navbar-nav .nav-link.show::before,
  .navbar-dark-blue .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}

.navbar-dark-blue .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark-blue .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-blue .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.navbar-dark-blue .badge-notifications {
  border: 1px solid #303956;
}

.navbar-dark-dodger-blue {
  background: #303956;
}

.navbar-dark-dodger-blue .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-dodger-blue .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-dodger-blue .navbar-text-100 {
  color: #fff;
}

.navbar-dark-dodger-blue .navbar-search {
  background-color: #074A3A;
  border-color: #074A3A;
}

.navbar-dark-dodger-blue .navbar-search .form-control {
  color: #fff;
}

.navbar-dark-dodger-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-dodger-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.navbar-dark-dodger-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-dodger-blue .navbar-avatar {
  background-color: #074A3A;
}

.navbar-dark-dodger-blue .navbar-btn {
  color: #fff;
  background-color: #074A3A;
  border-color: #074A3A;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.navbar-dark-dodger-blue .navbar-btn:hover {
  color: #fff;
  background-color: #04271f;
  border-color: #031b15;
}

.navbar-dark-dodger-blue .navbar-btn:focus, .navbar-dark-dodger-blue .navbar-btn.focus {
  color: #fff;
  background-color: #04271f;
  border-color: #031b15;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(44, 101, 88, 0.5);
}

.navbar-dark-dodger-blue .navbar-btn.disabled, .navbar-dark-dodger-blue .navbar-btn:disabled {
  color: #fff;
  background-color: #074A3A;
  border-color: #074A3A;
}

.navbar-dark-dodger-blue .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-dodger-blue .navbar-btn:not(:disabled):not(.disabled).active,
.show > .navbar-dark-dodger-blue .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #031b15;
  border-color: #01100c;
}

.navbar-dark-dodger-blue .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-dodger-blue .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .navbar-dark-dodger-blue .navbar-btn.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(44, 101, 88, 0.5);
}

.navbar-dark-dodger-blue .navbar-brand {
  color: #fff;
}

.navbar-dark-dodger-blue .navbar-brand:hover, .navbar-dark-dodger-blue .navbar-brand:focus {
  color: #fff;
}

.navbar-dark-dodger-blue .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-dodger-blue .navbar-nav .nav-link:hover, .navbar-dark-dodger-blue .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-dark-dodger-blue .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark-dodger-blue .navbar-nav .show > .nav-link,
.navbar-dark-dodger-blue .navbar-nav .active > .nav-link,
.navbar-dark-dodger-blue .navbar-nav .nav-link.show,
.navbar-dark-dodger-blue .navbar-nav .nav-link.active {
  color: #fff;
}

@media (min-width: 576px) {
  .navbar-dark-dodger-blue .navbar-nav .show > .nav-link::before,
  .navbar-dark-dodger-blue .navbar-nav .active > .nav-link::before,
  .navbar-dark-dodger-blue .navbar-nav .nav-link.show::before,
  .navbar-dark-dodger-blue .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}

.navbar-dark-dodger-blue .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark-dodger-blue .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-dodger-blue .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.navbar-dark-dodger-blue .badge-notifications {
  border: 1px solid #303956;
}

.navbar-dark-purple {
  background: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}

.navbar-dark-purple .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-purple .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-purple .navbar-text-100 {
  color: #fff;
}

.navbar-dark-purple .navbar-search {
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}

.navbar-dark-purple .navbar-search .form-control {
  color: #fff;
}

.navbar-dark-purple .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-purple .navbar-search .form-control:focus {
  background-color: transparent !important;
}

.navbar-dark-purple .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark-purple .navbar-btn {
  color: #fff;
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075);
}

.navbar-dark-purple .navbar-btn:hover {
  color: #fff;
  background-color: rgba(31, 31, 32, 0.05);
  border-color: rgba(25, 25, 25, 0.05);
}

.navbar-dark-purple .navbar-btn:focus, .navbar-dark-purple .navbar-btn.focus {
  color: #fff;
  background-color: rgba(31, 31, 32, 0.05);
  border-color: rgba(25, 25, 25, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 44, 51, 0.075), 0 0 0 1px rgba(229, 229, 229, 0.5);
}

.navbar-dark-purple .navbar-btn.disabled, .navbar-dark-purple .navbar-btn:disabled {
  color: #fff;
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}

.navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled).active,
.show > .navbar-dark-purple .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 25, 25, 0.05);
  border-color: rgba(18, 18, 19, 0.05);
}

.navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled).active:focus,
.show > .navbar-dark-purple .navbar-btn.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(39, 44, 51, 0.125), 0 0 0 1px rgba(229, 229, 229, 0.5);
}

.navbar-dark-purple .navbar-brand {
  color: #fff;
}

.navbar-dark-purple .navbar-brand:hover, .navbar-dark-purple .navbar-brand:focus {
  color: #fff;
}

.navbar-dark-purple .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark-purple .navbar-nav .nav-link:hover, .navbar-dark-purple .navbar-nav .nav-link:focus {
  color: #ffffff;
}

.navbar-dark-purple .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark-purple .navbar-nav .show > .nav-link,
.navbar-dark-purple .navbar-nav .active > .nav-link,
.navbar-dark-purple .navbar-nav .nav-link.show,
.navbar-dark-purple .navbar-nav .nav-link.active {
  color: #fff;
}

@media (min-width: 576px) {
  .navbar-dark-purple .navbar-nav .show > .nav-link::before,
  .navbar-dark-purple .navbar-nav .active > .nav-link::before,
  .navbar-dark-purple .navbar-nav .nav-link.show::before,
  .navbar-dark-purple .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}

.navbar-dark-purple .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark-purple .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-purple .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.navbar-dark-purple .badge-notifications {
  border: 1px solid #824EE1;
}

.dropdown-toggle:focus {
  outline: 0;
}

[data-caret="false"]::before, [data-caret="false"]::after {
  display: none !important;
}

.dropdown-menu {
  box-shadow: 0px 5px 5px -3px rgba(39, 44, 51, 0.1), 0px 8px 10px 1px rgba(39, 44, 51, 0.04), 0px 3px 14px 2px rgba(39, 44, 51, 0.02);
  display: block;
  visibility: hidden;
  opacity: 0;
  margin-top: 0 !important;
  transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1), margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
  background-clip: initial;
}

.dropdown-menu::before, .dropdown-menu::after {
  border: 8px solid transparent;
  border-bottom-color: #fff;
  content: "";
  height: 0;
  left: 10px;
  opacity: 0;
  transition: 0.1s opacity cubic-bezier(0.3, 0.5, 0.5, 1);
  position: absolute;
  top: calc((8px + 0.5rem) * -1);
  width: 1px;
}

.dropdown-menu::before {
  top: calc((9px + 0.5rem) * -1);
  border-bottom-color: transparent;
}

.dropdown-menu-right::before, .dropdown-menu-right::after {
  left: initial;
  right: 10px;
}

.dropup .dropdown-menu {
  margin-bottom: 0 !important;
}

.dropup .dropdown-menu::before, .dropup .dropdown-menu::after {
  top: auto;
  bottom: calc((8px + 0.5rem) * -1);
  border-bottom-color: transparent;
  border-top-color: #fff;
}

.dropup .dropdown-menu::before {
  bottom: calc((9px + 0.5rem) * -1);
  border-top-color: transparent;
}

.show > .dropdown-menu,
.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  margin-top: 0.5rem !important;
}

.dropup .show > .dropdown-menu, .dropup
.dropdown-menu.show {
  margin-bottom: 0.5rem !important;
}

.show > .dropdown-menu::before, .show > .dropdown-menu::after,
.dropdown-menu.show::before,
.dropdown-menu.show::after {
  opacity: 1;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item > .material-icons {
  font-size: 18px;
  margin-right: 5px;
}

.dropdown-item.active, .dropdown-item:active {
  color: #272C33;
  font-weight: bold;
  background-color: transparent;
  position: relative;
}

.dropdown-item.active::after, .dropdown-item:active::after {
  position: absolute;
  left: -1px;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  background-color: #5567FF;
  content: "";
}

.dropdown-menu .close {
  padding: 0.5rem;
  line-height: 0;
}

.dropdown-menu-full {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-menu-caret-center {
  transform-origin: 50% top;
}

.dropup .dropdown-menu-caret-center {
  transform-origin: 50% bottom;
}

.dropdown-menu-caret-center::before, .dropdown-menu-caret-center::after {
  left: 50%;
  margin-left: -4px;
}

@media (max-width: 767.98px) {
  .dropdown-xs-down-full {
    position: initial !important;
  }
  .dropdown-xs-down-full .dropdown-menu {
    left: 0;
    right: 0;
    width: 100% !important;
    border-radius: 0;
  }
  .dropdown-xs-down-full .dropdown-menu::before, .dropdown-xs-down-full .dropdown-menu::after {
    display: none;
  }
}

.dropdown-header {
  color: #272C33;
  font-weight: 500;
}

.user-dropdown .dropdown-menu:not(.show) {
  display: none;
}

.dropdown-notifications .dropdown-menu {
  min-width: 300px;
  padding: 0;
}

.dropdown-notifications .dropdown-menu .list-group {
  position: relative;
  overflow: hidden;
}

.dropdown-notifications .dropdown-menu .list-group-item {
  display: flex;
  flex-direction: column;
  border-color: rgba(39, 44, 51, 0.05);
}

.dropdown-notifications .dropdown-menu .unread-indicator {
  display: inline-block;
  content: '';
  width: .5rem;
  height: .5rem;
  border-radius: 100%;
}

.dropdown-notifications .dropdown-toggle {
  display: flex;
}

.dropdown-notifications .dropdown-toggle .material-icons {
  font-size: 2rem;
}

.dropdown-notifications .badge-notifications {
  position: relative;
  display: block;
  margin-left: -.75rem;
}

.dropdown-notifications .dropdown-menu {
  width: 300px;
}

.indicator-line {
  display: block;
  width: 18px;
  height: 4px;
  background-color: #E9EDF2;
}

.map {
  width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.map-pin {
  width: 64px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: -32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: top center;
  color: #888;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.map-pin span {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  box-sizing: content-box;
  background: #5567FF;
  padding: .25rem;
}

.jqvmap-label {
  z-index: 1;
  border: 1px solid #E9EDF2;
  border-radius: 0.25rem;
  background: white;
  padding: .25rem .5rem;
  color: #272C33;
}

.dashboard-area-tabs__tab {
  display: flex;
  flex-direction: column;
}

.dashboard-area-tabs__tab:hover {
  text-decoration: none;
}

.dashboard-area-tabs__tab, .dashboard-area-tabs__tab * {
  color: #272C33;
}

.dashboard-area-tabs__tab:not(.active) {
  background-color: #F5F7FA;
}

.dashboard-area-tabs__tab:not(.active), .dashboard-area-tabs__tab:not(.active) * {
  color: rgba(39, 44, 51, 0.5);
}

.dashboard-area-tabs__tab.active {
  position: relative;
}

.dashboard-area-tabs__tab.active::before {
  content: " ";
  height: 4px;
  background-color: #5567FF;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
}

.dashboard-location-tabs__tab {
  margin-bottom: 8px;
  cursor: pointer;
}

.dashboard-location-tabs__tab:not(.active) .progress-bar {
  background: rgba(39, 44, 51, 0.2) !important;
}

.list-todo:last-child {
  margin-bottom: 0;
}

.list-todo li:not(:last-child) {
  margin-bottom: 1rem;
}

.list-todo .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}

.list-todo .custom-control {
  padding-left: 2rem;
}

.list-todo .custom-control-label::before, .list-todo .custom-control-label::after {
  left: -2rem;
}

.list-skills li {
  display: flex;
  align-items: center;
}

.list-skills li:not(:last-child) {
  margin-bottom: 1rem;
}

.list-skills li > div + div {
  padding-left: 1rem;
}

.list-skills li > div:first-child {
  width: 110px;
}

.list-skills:last-child {
  margin-bottom: 0;
}

.posts-card {
  margin-bottom: 12px;
}

.posts-card:last-child {
  margin-bottom: 0;
}

.posts-card__content {
  padding: 0.5rem;
}

.posts-card__title {
  white-space: nowrap;
}

.posts-card__title, .posts-card__tag,
.posts-card .card-title,
.posts-card .card-title > a {
  max-width: 180px;
}

.posts-card__tag {
  font-size: 0.707rem;
}

.posts-card__tag,
.posts-card .card-title {
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1199.98px) {
  .posts-card__meta {
    margin-top: .4375rem;
    padding-top: .4375rem;
    border-top: 1px solid #E9EDF2;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .posts-card__tag {
    width: 100px;
  }
  .posts-card__date {
    width: 140px;
  }
  .posts-card__title,
  .posts-card .card-title,
  .posts-card .card-title > a {
    max-width: 300px;
  }
}

.posts-cards {
  margin-bottom: 12px;
}

.posts-card-popular {
  position: relative;
  overflow: hidden;
}

.posts-card-popular__content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.posts-card-popular .card-img {
  height: 196px;
  object-fit: cover;
}

.posts-card-popular .card-title,
.posts-card-popular a {
  color: white;
}

.posts-card-popular .card-body {
  border: none;
}

.posts-card-popular__title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.posts-card-popular__title .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}

.menu-modal .modal-content {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.menu-modal .modal-body {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.menu-modal .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.menu-modal .tab-content .nav-link {
  color: rgba(39, 44, 51, 0.7);
}

.menu-modal .tab-content .nav-link:hover {
  color: #272C33;
}

.menu-modal .nav-link.active {
  color: #272C33;
}

.menu-modal .menu .nav-link {
  font-weight: 500;
  padding: .5rem 1.25rem;
  color: rgba(39, 44, 51, 0.7);
}

.menu-modal .menu .active {
  color: rgba(39, 44, 51, 0.7);
  background-color: #fff;
  position: relative;
}

.menu-modal .menu .active::after {
  content: ' ';
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #5567FF;
  display: block;
  position: absolute;
}

@media (min-width: 576px) {
  .menu-modal .modal-dialog {
    max-width: calc(800px - (1rem * 2));
    margin-top: 64px;
  }
}

.app-messages {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.app-messages .mdk-drawer-layout,
.app-messages .mdk-header-layout,
.app-messages .mdk-header-layout__content,
.app-messages .mdk-drawer-layout__content {
  height: 100%;
}

.app-messages .page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
}

.app-messages__container {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.app-messages__fullbleed {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.message__aside {
  margin-right: 1rem;
}

.message:nth-child(even) .message__aside {
  order: 1;
  margin-right: 0;
  margin-left: 1rem;
}

.message:nth-child(even) .message__body {
  margin-left: auto;
}

.messages-toggle {
  position: absolute;
  right: -1px;
  top: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui .page__container {
  max-width: 944px;
}

.overlay {
  position: relative;
}

.overlay__content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: opacity .4s, background-color .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  pointer-events: none;
}

.overlay__action {
  transition: opacity .4s, transform .4s;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}

.overlay--show .overlay__content {
  opacity: 1;
}

.overlay--show .overlay__action {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.overlay--duserselect {
  user-select: none;
}

.overlay--primary .overlay__content {
  background-color: rgba(85, 103, 255, 0.35);
}

.overlay--primary.overlay--show .overlay__content {
  background-color: rgba(85, 103, 255, 0.95);
}

.overlay--secondary .overlay__content {
  background-color: rgba(134, 142, 150, 0.35);
}

.overlay--secondary.overlay--show .overlay__content {
  background-color: rgba(134, 142, 150, 0.95);
}

.overlay--success .overlay__content {
  background-color: rgba(119, 193, 58, 0.35);
}

.overlay--success.overlay--show .overlay__content {
  background-color: rgba(119, 193, 58, 0.95);
}

.overlay--info .overlay__content {
  background-color: rgba(23, 162, 184, 0.35);
}

.overlay--info.overlay--show .overlay__content {
  background-color: rgba(23, 162, 184, 0.95);
}

.overlay--warning .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}

.overlay--warning.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--danger .overlay__content {
  background-color: rgba(217, 83, 79, 0.35);
}

.overlay--danger.overlay--show .overlay__content {
  background-color: rgba(217, 83, 79, 0.95);
}

.overlay--light .overlay__content {
  background-color: rgba(248, 249, 250, 0.35);
}

.overlay--light.overlay--show .overlay__content {
  background-color: rgba(248, 249, 250, 0.95);
}

.overlay--dark .overlay__content {
  background-color: rgba(48, 57, 86, 0.35);
}

.overlay--dark.overlay--show .overlay__content {
  background-color: rgba(48, 57, 86, 0.95);
}

.overlay--black .overlay__content {
  background-color: rgba(39, 44, 51, 0.35);
}

.overlay--black.overlay--show .overlay__content {
  background-color: rgba(39, 44, 51, 0.95);
}

.overlay--accent .overlay__content {
  background-color: rgba(237, 11, 76, 0.35);
}

.overlay--accent.overlay--show .overlay__content {
  background-color: rgba(237, 11, 76, 0.95);
}

.overlay--accent-red .overlay__content {
  background-color: rgba(237, 11, 76, 0.35);
}

.overlay--accent-red.overlay--show .overlay__content {
  background-color: rgba(237, 11, 76, 0.95);
}

.overlay--accent-yellow .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}

.overlay--accent-yellow.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--accent-dodger-blue .overlay__content {
  background-color: rgba(85, 103, 255, 0.35);
}

.overlay--accent-dodger-blue.overlay--show .overlay__content {
  background-color: rgba(85, 103, 255, 0.95);
}

.overlay--accent-pickled-bluewood .overlay__content {
  background-color: rgba(48, 57, 86, 0.35);
}

.overlay--accent-pickled-bluewood.overlay--show .overlay__content {
  background-color: rgba(48, 57, 86, 0.95);
}

.overlay--accent-electric-violet .overlay__content {
  background-color: rgba(156, 66, 255, 0.35);
}

.overlay--accent-electric-violet.overlay--show .overlay__content {
  background-color: rgba(156, 66, 255, 0.95);
}

.overlay--primary-purple .overlay__content {
  background-color: rgba(130, 78, 225, 0.35);
}

.overlay--primary-purple.overlay--show .overlay__content {
  background-color: rgba(130, 78, 225, 0.95);
}

.overlay--primary-red .overlay__content {
  background-color: rgba(237, 11, 76, 0.35);
}

.overlay--primary-red.overlay--show .overlay__content {
  background-color: rgba(237, 11, 76, 0.95);
}

.overlay--primary-yellow .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}

.overlay--primary-yellow.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--primary-light .overlay__content {
  background-color: rgba(153, 204, 255, 0.35);
}

.overlay--primary-light.overlay--show .overlay__content {
  background-color: rgba(153, 204, 255, 0.95);
}

.overlay--primary-dodger-blue .overlay__content {
  background-color: rgba(85, 103, 255, 0.35);
}

.overlay--primary-dodger-blue.overlay--show .overlay__content {
  background-color: rgba(85, 103, 255, 0.95);
}

.overlay--primary-pickled-bluewood .overlay__content {
  background-color: rgba(48, 57, 86, 0.35);
}

.overlay--primary-pickled-bluewood.overlay--show .overlay__content {
  background-color: rgba(48, 57, 86, 0.95);
}

.course-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  z-index: 0;
}

.course-nav::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 1px);
  height: 2px;
  background-color: white;
  opacity: .2;
  z-index: -1;
}

.course-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100px;
  background-color: #F5F7FA;
}

.course-nav a .material-icons {
  font-size: 1rem;
  color: rgba(39, 44, 51, 0.7);
}

.course-nav .active {
  position: relative;
  z-index: 0;
}

.course-nav .active::before {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
  position: absolute;
  content: '';
  border-radius: 100px;
}

.course-nav .active .material-icons {
  color: #ED0B4C;
}

.player {
  background-color: rgba(39, 44, 51, 0.8);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  z-index: 0;
}

.player__image {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: var(--player-image);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@media (min-width: 768px) {
  .player__image {
    left: 2rem;
    top: 2rem;
    bottom: 2rem;
    right: 2rem;
  }
}

.player__play {
  background-color: #ED0B4C;
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .25rem;
}

.player__play .material-icons {
  font-size: 2rem;
  color: white;
}

.accordion {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 1.5rem;
}

.accordion__item {
  position: relative;
  display: block;
}

.accordion__item:not(:last-child) {
  margin-bottom: -1px;
  border-bottom: 1px solid #E9EDF2;
}

.accordion__item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion__item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion__item:hover, .accordion__item:focus {
  z-index: 1;
  text-decoration: none;
}

.accordion__toggle {
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  color: #272C33;
  font-weight: 500;
}

.accordion__toggle-icon {
  transform: rotate(0);
}

.accordion__item.open .accordion__toggle-icon {
  transform: rotate(-180deg);
}

.accordion__menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(39, 44, 51, 0.7);
  padding: 0.5rem 0;
  font-size: 13px;
  line-height: 1.5rem;
}

.accordion__menu-link:not(:last-child) {
  margin-bottom: -1px;
  border-bottom: 1px solid #E9EDF2;
}

.accordion__menu-link:hover, .accordion__menu-link:focus {
  z-index: 1;
}

.accordion__menu-link.active {
  color: #272C33;
  font-weight: 500;
}

.accordion__menu-link a {
  color: inherit;
}

.accordion__submenu {
  padding: 0.5rem 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion__submenu a {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding: 0 1rem;
  line-height: 1.5rem;
}

.accordion__submenu a.active {
  font-weight: 500;
  color: #272C33;
}

.accordion--boxed .accordion__toggle {
  padding: 0.75rem 1rem 0.75rem 1.5rem;
}

.accordion--boxed .accordion__menu-link {
  padding: 0.5rem 1rem 0.5rem 1.5rem;
}

.custom-pills {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.list-quiz {
  list-style: none;
  margin-bottom: 24px;
}

@media (max-width: 767.98px) {
  .list-quiz {
    padding: 0;
  }
}

.list-quiz .list-quiz-item {
  margin-bottom: 10px;
}

.list-quiz .list-quiz-badge {
  border-radius: 50% !important;
  padding: 0;
  display: inline-block;
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  border: 1px solid #E9EDF2;
  background: #F6F6F6;
  color: #272C33;
}

.list-quiz .list-quiz-badge .material-icons {
  font-size: 1.25rem;
}

.list-quiz .list-quiz-text {
  margin-left: .625rem;
  font-size: 18px;
  padding: 3px 6px;
  display: inline-block;
}

.list-quiz .list-quiz-text.active {
  background: #868e96;
}

.list-quiz .list-quiz-badge-success {
  background: #77c13a;
  border-color: #77c13a;
  color: #fff;
}

.list-quiz .list-quiz-badge-error {
  background: #d9534f;
  border-color: #d9534f;
  color: #fff;
}

.wall-banner {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  padding: 30px 20px 30px 28px;
  display: none;
}

.wall-banner img {
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .wall-banner {
    flex-direction: column;
    padding: 20px 20px 0;
  }
}

.wall-banner .wrap {
  flex-grow: 1;
}

.wall-banner .wrap h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  margin: 0 0 40px;
  color: #239f87;
}

@media screen and (max-width: 767px) {
  .wall-banner .wrap h3 {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 20px;
  }
}

.wall-banner .wrap p {
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .wall-banner .wrap p {
    font-size: 16px;
    line-height: normal;
  }
}

.wall-banner .wrap span {
  display: block;
  padding-top: 23px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 18px;
  color: #2a2a2a;
}

@media screen and (max-width: 767px) {
  .wall-banner .wrap span {
    line-height: normal;
    font-size: 16px;
    padding-top: 20px;
  }
}

.wall-banner .wrap span strong {
  font-weight: 700;
}

.rewards-banner {
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 38px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.rewards-banner .wrap {
  display: flex;
  align-items: center;
}

.rewards-banner .wrap .points {
  margin-right: 30px;
}

.rewards-banner .wrap p {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  letter-spacing: -0.24px;
  color: #2a2a2a;
}

.rewards-banner .wrap span {
  width: 124px;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  background: rgba(35, 159, 135, 0.1);
  font-size: 20px;
  line-height: 1;
  letter-spacing: -0.24px;
  margin-bottom: 5px;
  color: #2a2a2a;
}

.rewards-banner .wrap span strong {
  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  margin-bottom: -5px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #2a2a2a;
}

.transaction-list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.transaction-list + .primary-page-header {
  margin-top: 30px;
}

.transaction-list li {
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  display: flex;
  padding: 14px 32px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.transaction-list li + li {
  margin-top: 10px;
}

.transaction-list li em {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #2a2a2a;
}

.transaction-list li em span {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: 1px;
  color: #2a2a2a;
}

.transaction-list li strong {
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */
  text-align: center;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  color: #239f87;
}

.transaction-list li p {
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  color: #2a2a2a;
}

.transaction-list li p span {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #6f6f6f;
  margin-top: 2px;
}

.referral-code-box {
  margin-top: 90px;
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  text-align: center;
  padding: 30px 20px;
}

.referral-code-box p {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  color: #666666;
}

.referral-code-box p em {
  cursor: pointer;
  font-style: normal;
}

.referral-code-box p em svg {
  font-style: 16px;
  margin-right: 4px;
}

.referral-code-box span {
  font-weight: bold;
  font-size: 16px;
  padding: 0 20px;
  min-width: 220px;
  line-height: 24px;
  display: inline-flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px dashed #00a689;
  color: #00a689;
}

.referral-code-box ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

.referral-code-box ul li a {
  display: block;
  font-size: 24px;
}

.referral-code-box ul li + li {
  margin-left: 25px;
}

.referral-code-box h4 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #111111;
}

.redeem-modal {
  max-width: 380px;
  padding: 10px;
}

.redeem-modal h4 {
  font-family: Rokkitt, serif;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #2a2a2a;
  margin-bottom: 30px;
}

.redeem-modal .resend-otp {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 15px;
  color: #6f6f6f;
}

.redeem-modal .resend-otp a {
  font-weight: 700;
  color: #239f87;
}

.redeem-modal .resend-otp a:hover {
  text-decoration: underline;
}

.redeem-modal ul {
  list-style: none;
  margin: 0 0 40px;
  justify-content: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.redeem-modal ul li + li {
  margin-left: 20px;
}

.redeem-modal ul li .primary-form-control {
  width: 40px;
  padding: 0;
  text-align: center;
}

.redeem-modal h3 {
  font-weight: bold;
  font-size: 34px;
  font-family: Rokkitt, serif;
  text-align: center;
  line-height: 44px;
  color: #2a2a2a;
  margin-bottom: 25px;
}

.redeem-modal img {
  text-align: center;
  display: block;
  margin: 0 auto 8px;
}

.redeem-modal p {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6f6f6f;
  margin-bottom: 25px;
}

.applicable-on-courses > p {
  font-weight: 600;
  color: #393e41;
}

.applicable-on-courses ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.applicable-on-courses ul li {
  margin-right: 20px;
  margin-bottom: 20px;
}

.applicable-for .wrap_foot {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
}

.applicable-for .wrap_foot.detach {
  background: #666666;
}

.applicable-for .wrap_foot.attach {
  background: #00b8be;
}

.applicable-for .wrap_body {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  height: 470px;
  overflow: auto;
}

.applicable-for .wrap_body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.applicable-for .wrap_body ul li {
  margin-bottom: 20px;
}

.applicable-for > p {
  font-weight: 600;
  color: #393e41;
}

.btn {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  text-align: center;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  transition: all ease-in-out 0.3s;
}

.btn.edu-btn {
  background-color: #239f87;
  border-radius: 4px;
  border-color: #239f87;
  height: 38px;
  font-size: 15px !important;
}

.btn.edu-btn:focus {
  box-shadow: none;
}

.btn.edu-btn:active {
  box-shadow: none !important;
  background-color: #239f87 !important;
  border-color: #239f87 !important;
}

@media (min-width: 1200px) {
  .btn {
    min-width: 180px;
  }
}

.btn:hover {
  transition: all ease-in-out 0.3s;
}

.btn.btn-link {
  padding: 0;
  min-width: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  height: auto;
  line-height: normal;
  color: #6f6f6f;
}

.btn.btn-link.primary {
  color: #239f87;
}

.btn.btn-link:hover {
  text-decoration: none;
}

.btn.btn-link:disabled {
  opacity: 0.5;
}

.btn.btn-primary {
  background-color: #239f87 !important;
  border-color: #239f87 !important;
  color: #ffffff !important;
}

.btn.btn-primary:hover {
  color: #ffffff !important;
}

.btn.btn-primary:hover {
  color: white;
}

.btn.btn-primary:disabled {
  background-color: #9f9f9f;
  border-color: #9f9f9f;
  color: #dddddd;
  opacity: 1;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  background-color: #1e8a75;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #239f87;
  border-color: #239f87;
}

.btn.btn-default {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #6f6f6f;
}

.btn.btn-default:not(:disabled):not(.disabled):hover {
  background-color: #d9d9d9;
}

.btn.btn-default:not(:disabled):not(.disabled):active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.button-group {
  display: flex;
  align-items: center;
}

.button-group button + button {
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .button-group button + button {
    margin-left: 5px;
  }
}

.round-btn {
  min-width: 128px;
  height: 44px;
  background-color: #239F87;
  border-color: #239F87;
  border-radius: 25px;
  font-size: 15px !important;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.7px;
  padding: 0 10px;
}

.round-btn:hover, .round-btn:focus, .round-btn:active {
  background-color: #239F87 !important;
  border-color: #239F87 !important;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #393e41;
}

.primary-input {
  display: block;
  width: 100%;
}

.primary-input:focus {
  outline: none;
}

.form-control {
  background: #f3f3f3;
  border-color: #f3f3f3;
  height: 50px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  box-shadow: none;
  border: none;
  font-size: 18px;
  resize: none;
  line-height: 23px;
  color: #596d8c;
  padding: 0 14px;
}

.form-control:focus {
  background: #f3f3f3;
  box-shadow: none;
  text-shadow: none;
  border-color: #f3f3f3;
  color: #596d8c;
}

.form-control--white {
  background-color: #ffffff;
  color: rgba(111, 111, 111, 0.85);
  border: 1px solid #b7b7b7;
}

.form-control--white:focus {
  color: rgba(111, 111, 111, 0.85);
  background-color: #ffffff;
  border-color: #b7b7b7;
}

textarea.form-control {
  line-height: 32px;
  padding: 14px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/**
* MARGINS AND PADDINGS
*/
.m-t-0 {
  margin-top: 0rem !important;
}

.p-t-0 {
  padding-top: 0rem !important;
}

.m-b-0 {
  margin-bottom: 0rem !important;
}

.p-b-0 {
  padding-bottom: 0rem !important;
}

.m-l-0 {
  margin-left: 0rem !important;
}

.p-l-0 {
  padding-left: 0rem !important;
}

.m-r-0 {
  margin-right: 0rem !important;
}

.p-r-0 {
  padding-right: 0rem !important;
}

.m-t-3 {
  margin-top: 0.1875rem !important;
}

.p-t-3 {
  padding-top: 0.1875rem !important;
}

.m-b-3 {
  margin-bottom: 0.1875rem !important;
}

.p-b-3 {
  padding-bottom: 0.1875rem !important;
}

.m-l-3 {
  margin-left: 0.1875rem !important;
}

.p-l-3 {
  padding-left: 0.1875rem !important;
}

.m-r-3 {
  margin-right: 0.1875rem !important;
}

.p-r-3 {
  padding-right: 0.1875rem !important;
}

.m-t-4 {
  margin-top: 0.25rem !important;
}

.p-t-4 {
  padding-top: 0.25rem !important;
}

.m-b-4 {
  margin-bottom: 0.25rem !important;
}

.p-b-4 {
  padding-bottom: 0.25rem !important;
}

.m-l-4 {
  margin-left: 0.25rem !important;
}

.p-l-4 {
  padding-left: 0.25rem !important;
}

.m-r-4 {
  margin-right: 0.25rem !important;
}

.p-r-4 {
  padding-right: 0.25rem !important;
}

.m-t-5 {
  margin-top: 0.3125rem !important;
}

.p-t-5 {
  padding-top: 0.3125rem !important;
}

.m-b-5 {
  margin-bottom: 0.3125rem !important;
}

.p-b-5 {
  padding-bottom: 0.3125rem !important;
}

.m-l-5 {
  margin-left: 0.3125rem !important;
}

.p-l-5 {
  padding-left: 0.3125rem !important;
}

.m-r-5 {
  margin-right: 0.3125rem !important;
}

.p-r-5 {
  padding-right: 0.3125rem !important;
}

.m-t-8 {
  margin-top: 0.5rem !important;
}

.p-t-8 {
  padding-top: 0.5rem !important;
}

.m-b-8 {
  margin-bottom: 0.5rem !important;
}

.p-b-8 {
  padding-bottom: 0.5rem !important;
}

.m-l-8 {
  margin-left: 0.5rem !important;
}

.p-l-8 {
  padding-left: 0.5rem !important;
}

.m-r-8 {
  margin-right: 0.5rem !important;
}

.p-r-8 {
  padding-right: 0.5rem !important;
}

.m-t-10 {
  margin-top: 0.625rem !important;
}

.p-t-10 {
  padding-top: 0.625rem !important;
}

.m-b-10 {
  margin-bottom: 0.625rem !important;
}

.p-b-10 {
  padding-bottom: 0.625rem !important;
}

.m-l-10 {
  margin-left: 0.625rem !important;
}

.p-l-10 {
  padding-left: 0.625rem !important;
}

.m-r-10 {
  margin-right: 0.625rem !important;
}

.p-r-10 {
  padding-right: 0.625rem !important;
}

.m-t-12 {
  margin-top: 0.75rem !important;
}

.p-t-12 {
  padding-top: 0.75rem !important;
}

.m-b-12 {
  margin-bottom: 0.75rem !important;
}

.p-b-12 {
  padding-bottom: 0.75rem !important;
}

.m-l-12 {
  margin-left: 0.75rem !important;
}

.p-l-12 {
  padding-left: 0.75rem !important;
}

.m-r-12 {
  margin-right: 0.75rem !important;
}

.p-r-12 {
  padding-right: 0.75rem !important;
}

.m-t-14 {
  margin-top: 0.875rem !important;
}

.p-t-14 {
  padding-top: 0.875rem !important;
}

.m-b-14 {
  margin-bottom: 0.875rem !important;
}

.p-b-14 {
  padding-bottom: 0.875rem !important;
}

.m-l-14 {
  margin-left: 0.875rem !important;
}

.p-l-14 {
  padding-left: 0.875rem !important;
}

.m-r-14 {
  margin-right: 0.875rem !important;
}

.p-r-14 {
  padding-right: 0.875rem !important;
}

.m-t-15 {
  margin-top: 0.9375rem !important;
}

.p-t-15 {
  padding-top: 0.9375rem !important;
}

.m-b-15 {
  margin-bottom: 0.9375rem !important;
}

.p-b-15 {
  padding-bottom: 0.9375rem !important;
}

.m-l-15 {
  margin-left: 0.9375rem !important;
}

.p-l-15 {
  padding-left: 0.9375rem !important;
}

.m-r-15 {
  margin-right: 0.9375rem !important;
}

.p-r-15 {
  padding-right: 0.9375rem !important;
}

.m-t-16 {
  margin-top: 1rem !important;
}

.p-t-16 {
  padding-top: 1rem !important;
}

.m-b-16 {
  margin-bottom: 1rem !important;
}

.p-b-16 {
  padding-bottom: 1rem !important;
}

.m-l-16 {
  margin-left: 1rem !important;
}

.p-l-16 {
  padding-left: 1rem !important;
}

.m-r-16 {
  margin-right: 1rem !important;
}

.p-r-16 {
  padding-right: 1rem !important;
}

.m-t-20 {
  margin-top: 1.25rem !important;
}

.p-t-20 {
  padding-top: 1.25rem !important;
}

.m-b-20 {
  margin-bottom: 1.25rem !important;
}

.p-b-20 {
  padding-bottom: 1.25rem !important;
}

.m-l-20 {
  margin-left: 1.25rem !important;
}

.p-l-20 {
  padding-left: 1.25rem !important;
}

.m-r-20 {
  margin-right: 1.25rem !important;
}

.p-r-20 {
  padding-right: 1.25rem !important;
}

.m-t-24 {
  margin-top: 1.5rem !important;
}

.p-t-24 {
  padding-top: 1.5rem !important;
}

.m-b-24 {
  margin-bottom: 1.5rem !important;
}

.p-b-24 {
  padding-bottom: 1.5rem !important;
}

.m-l-24 {
  margin-left: 1.5rem !important;
}

.p-l-24 {
  padding-left: 1.5rem !important;
}

.m-r-24 {
  margin-right: 1.5rem !important;
}

.p-r-24 {
  padding-right: 1.5rem !important;
}

.m-t-25 {
  margin-top: 1.5625rem !important;
}

.p-t-25 {
  padding-top: 1.5625rem !important;
}

.m-b-25 {
  margin-bottom: 1.5625rem !important;
}

.p-b-25 {
  padding-bottom: 1.5625rem !important;
}

.m-l-25 {
  margin-left: 1.5625rem !important;
}

.p-l-25 {
  padding-left: 1.5625rem !important;
}

.m-r-25 {
  margin-right: 1.5625rem !important;
}

.p-r-25 {
  padding-right: 1.5625rem !important;
}

.m-t-27 {
  margin-top: 1.6875rem !important;
}

.p-t-27 {
  padding-top: 1.6875rem !important;
}

.m-b-27 {
  margin-bottom: 1.6875rem !important;
}

.p-b-27 {
  padding-bottom: 1.6875rem !important;
}

.m-l-27 {
  margin-left: 1.6875rem !important;
}

.p-l-27 {
  padding-left: 1.6875rem !important;
}

.m-r-27 {
  margin-right: 1.6875rem !important;
}

.p-r-27 {
  padding-right: 1.6875rem !important;
}

.m-t-28 {
  margin-top: 1.75rem !important;
}

.p-t-28 {
  padding-top: 1.75rem !important;
}

.m-b-28 {
  margin-bottom: 1.75rem !important;
}

.p-b-28 {
  padding-bottom: 1.75rem !important;
}

.m-l-28 {
  margin-left: 1.75rem !important;
}

.p-l-28 {
  padding-left: 1.75rem !important;
}

.m-r-28 {
  margin-right: 1.75rem !important;
}

.p-r-28 {
  padding-right: 1.75rem !important;
}

.m-t-30 {
  margin-top: 1.875rem !important;
}

.p-t-30 {
  padding-top: 1.875rem !important;
}

.m-b-30 {
  margin-bottom: 1.875rem !important;
}

.p-b-30 {
  padding-bottom: 1.875rem !important;
}

.m-l-30 {
  margin-left: 1.875rem !important;
}

.p-l-30 {
  padding-left: 1.875rem !important;
}

.m-r-30 {
  margin-right: 1.875rem !important;
}

.p-r-30 {
  padding-right: 1.875rem !important;
}

.m-t-32 {
  margin-top: 2rem !important;
}

.p-t-32 {
  padding-top: 2rem !important;
}

.m-b-32 {
  margin-bottom: 2rem !important;
}

.p-b-32 {
  padding-bottom: 2rem !important;
}

.m-l-32 {
  margin-left: 2rem !important;
}

.p-l-32 {
  padding-left: 2rem !important;
}

.m-r-32 {
  margin-right: 2rem !important;
}

.p-r-32 {
  padding-right: 2rem !important;
}

.m-t-35 {
  margin-top: 2.1875rem !important;
}

.p-t-35 {
  padding-top: 2.1875rem !important;
}

.m-b-35 {
  margin-bottom: 2.1875rem !important;
}

.p-b-35 {
  padding-bottom: 2.1875rem !important;
}

.m-l-35 {
  margin-left: 2.1875rem !important;
}

.p-l-35 {
  padding-left: 2.1875rem !important;
}

.m-r-35 {
  margin-right: 2.1875rem !important;
}

.p-r-35 {
  padding-right: 2.1875rem !important;
}

.m-t-36 {
  margin-top: 2.25rem !important;
}

.p-t-36 {
  padding-top: 2.25rem !important;
}

.m-b-36 {
  margin-bottom: 2.25rem !important;
}

.p-b-36 {
  padding-bottom: 2.25rem !important;
}

.m-l-36 {
  margin-left: 2.25rem !important;
}

.p-l-36 {
  padding-left: 2.25rem !important;
}

.m-r-36 {
  margin-right: 2.25rem !important;
}

.p-r-36 {
  padding-right: 2.25rem !important;
}

.m-t-40 {
  margin-top: 2.5rem !important;
}

.p-t-40 {
  padding-top: 2.5rem !important;
}

.m-b-40 {
  margin-bottom: 2.5rem !important;
}

.p-b-40 {
  padding-bottom: 2.5rem !important;
}

.m-l-40 {
  margin-left: 2.5rem !important;
}

.p-l-40 {
  padding-left: 2.5rem !important;
}

.m-r-40 {
  margin-right: 2.5rem !important;
}

.p-r-40 {
  padding-right: 2.5rem !important;
}

.m-t-45 {
  margin-top: 2.8125rem !important;
}

.p-t-45 {
  padding-top: 2.8125rem !important;
}

.m-b-45 {
  margin-bottom: 2.8125rem !important;
}

.p-b-45 {
  padding-bottom: 2.8125rem !important;
}

.m-l-45 {
  margin-left: 2.8125rem !important;
}

.p-l-45 {
  padding-left: 2.8125rem !important;
}

.m-r-45 {
  margin-right: 2.8125rem !important;
}

.p-r-45 {
  padding-right: 2.8125rem !important;
}

.m-t-50 {
  margin-top: 3.125rem !important;
}

.p-t-50 {
  padding-top: 3.125rem !important;
}

.m-b-50 {
  margin-bottom: 3.125rem !important;
}

.p-b-50 {
  padding-bottom: 3.125rem !important;
}

.m-l-50 {
  margin-left: 3.125rem !important;
}

.p-l-50 {
  padding-left: 3.125rem !important;
}

.m-r-50 {
  margin-right: 3.125rem !important;
}

.p-r-50 {
  padding-right: 3.125rem !important;
}

.m-t-55 {
  margin-top: 3.4375rem !important;
}

.p-t-55 {
  padding-top: 3.4375rem !important;
}

.m-b-55 {
  margin-bottom: 3.4375rem !important;
}

.p-b-55 {
  padding-bottom: 3.4375rem !important;
}

.m-l-55 {
  margin-left: 3.4375rem !important;
}

.p-l-55 {
  padding-left: 3.4375rem !important;
}

.m-r-55 {
  margin-right: 3.4375rem !important;
}

.p-r-55 {
  padding-right: 3.4375rem !important;
}

.m-t-60 {
  margin-top: 3.75rem !important;
}

.p-t-60 {
  padding-top: 3.75rem !important;
}

.m-b-60 {
  margin-bottom: 3.75rem !important;
}

.p-b-60 {
  padding-bottom: 3.75rem !important;
}

.m-l-60 {
  margin-left: 3.75rem !important;
}

.p-l-60 {
  padding-left: 3.75rem !important;
}

.m-r-60 {
  margin-right: 3.75rem !important;
}

.p-r-60 {
  padding-right: 3.75rem !important;
}

.m-t-65 {
  margin-top: 4.0625rem !important;
}

.p-t-65 {
  padding-top: 4.0625rem !important;
}

.m-b-65 {
  margin-bottom: 4.0625rem !important;
}

.p-b-65 {
  padding-bottom: 4.0625rem !important;
}

.m-l-65 {
  margin-left: 4.0625rem !important;
}

.p-l-65 {
  padding-left: 4.0625rem !important;
}

.m-r-65 {
  margin-right: 4.0625rem !important;
}

.p-r-65 {
  padding-right: 4.0625rem !important;
}

.m-t-70 {
  margin-top: 4.375rem !important;
}

.p-t-70 {
  padding-top: 4.375rem !important;
}

.m-b-70 {
  margin-bottom: 4.375rem !important;
}

.p-b-70 {
  padding-bottom: 4.375rem !important;
}

.m-l-70 {
  margin-left: 4.375rem !important;
}

.p-l-70 {
  padding-left: 4.375rem !important;
}

.m-r-70 {
  margin-right: 4.375rem !important;
}

.p-r-70 {
  padding-right: 4.375rem !important;
}

.m-t-75 {
  margin-top: 4.6875rem !important;
}

.p-t-75 {
  padding-top: 4.6875rem !important;
}

.m-b-75 {
  margin-bottom: 4.6875rem !important;
}

.p-b-75 {
  padding-bottom: 4.6875rem !important;
}

.m-l-75 {
  margin-left: 4.6875rem !important;
}

.p-l-75 {
  padding-left: 4.6875rem !important;
}

.m-r-75 {
  margin-right: 4.6875rem !important;
}

.p-r-75 {
  padding-right: 4.6875rem !important;
}

.m-t-80 {
  margin-top: 5rem !important;
}

.p-t-80 {
  padding-top: 5rem !important;
}

.m-b-80 {
  margin-bottom: 5rem !important;
}

.p-b-80 {
  padding-bottom: 5rem !important;
}

.m-l-80 {
  margin-left: 5rem !important;
}

.p-l-80 {
  padding-left: 5rem !important;
}

.m-r-80 {
  margin-right: 5rem !important;
}

.p-r-80 {
  padding-right: 5rem !important;
}

.m-t-100 {
  margin-top: 6.25rem !important;
}

.p-t-100 {
  padding-top: 6.25rem !important;
}

.m-b-100 {
  margin-bottom: 6.25rem !important;
}

.p-b-100 {
  padding-bottom: 6.25rem !important;
}

.m-l-100 {
  margin-left: 6.25rem !important;
}

.p-l-100 {
  padding-left: 6.25rem !important;
}

.m-r-100 {
  margin-right: 6.25rem !important;
}

.p-r-100 {
  padding-right: 6.25rem !important;
}

.m-t-120 {
  margin-top: 7.5rem !important;
}

.p-t-120 {
  padding-top: 7.5rem !important;
}

.m-b-120 {
  margin-bottom: 7.5rem !important;
}

.p-b-120 {
  padding-bottom: 7.5rem !important;
}

.m-l-120 {
  margin-left: 7.5rem !important;
}

.p-l-120 {
  padding-left: 7.5rem !important;
}

.m-r-120 {
  margin-right: 7.5rem !important;
}

.p-r-120 {
  padding-right: 7.5rem !important;
}

.width-full {
  width: 100% !important;
}

.aligm-items-start {
  align-items: flex-start !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.lh-1 {
  line-height: 1 !important;
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-tabs-with-btn .wrap p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: 900;
}

.back-button {
  display: flex;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 14px;
  color: #2a2a2a;
}

.back-button span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-button img {
  margin-right: 8px;
}

.width-full {
  width: 100%;
}

@font-face {
  font-family: "icomoon";
  src: url("./../fonts/icons/icomoon.eot?1qvg8k");
  src: url("./../fonts/icons/icomoon.eot?1qvg8k#iefix") format("embedded-opentype"), url("./../fonts/icons/icomoon.ttf?1qvg8k") format("truetype"), url("./../fonts/icons/icomoon.woff?1qvg8k") format("woff"), url("./../fonts/icons/icomoon.svg?1qvg8k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-down-arrow:before {
  content: "\e900";
}

.icon-goal:before {
  content: "\e901";
}

.user-structure {
  min-height: 100vh;
  background: #ffffff;
  display: flex;
}

@media (max-width: 767px) {
  .user-structure {
    flex-wrap: wrap;
    min-height: 100%;
  }
}

@media (max-width: 767px) {
  .user-structure {
    width: 100%;
  }
}

.user-structure .info-header {
  padding: 10px 20px;
}

.user-structure .info-header img {
  max-width: 100%;
  height: 35px;
}

@media (max-width: 767px) {
  .user-structure .info-header img {
    height: auto;
  }
}

@media (min-width: 768px) {
  .user-structure .info-header {
    padding: 14px 20px 0;
  }
  .user-structure .info-header img {
    height: auto;
  }
}

.user-structure .info-body {
  display: none;
}

@media (min-width: 768px) {
  .user-structure .info-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 144px;
  }
}

.user-structure .info-body img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .user-structure .info-body {
    padding: 0 35px;
    height: calc(100% - (56px + 65px - 15px));
  }
}

@media (min-width: 992px) {
  .user-structure .info-body {
    padding: 0 35px;
    height: calc(100% - (56px + 65px));
  }
}

.user-structure .info-body h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  max-width: 517px;
  font-family: "Rokkitt", serif;
  color: #00a689;
  margin-bottom: 14px;
}

@media (max-width: 991px) {
  .user-structure .info-body h1 {
    font-size: 26px;
    line-height: normal;
  }
}

.user-structure .info-body p {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 32px;
  color: #666666;
  margin-bottom: 58px;
}

@media (max-width: 991px) {
  .user-structure .info-body p {
    font-size: 16px;
    line-height: normal;
  }
}

@media (min-width: 992px) {
  .user-structure .info-body p {
    font-size: 20px;
  }
}

.user-structure .info-footer {
  display: none;
}

@media (min-width: 768px) {
  .user-structure .info-footer {
    display: block;
    padding: 0 35px 20px;
  }
}

@media (min-width: 992px) {
  .user-structure .info-footer {
    padding: 0 94px 35px;
  }
}

.user-structure .info-footer ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.user-structure .info-footer ul li + li {
  margin-left: 50px;
}

.user-structure .info-footer ul li + li a {
  position: relative;
}

.user-structure .info-footer ul li + li a::before {
  content: "";
  width: 4.24px;
  height: 4.24px;
  left: -27.24px;
  top: 50%;
  position: absolute;
  background: #666666;
  opacity: 0.3;
  transform: rotate(45deg) translateY(-50%);
}

.user-structure .info-footer ul li a {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
}

.user-structure .info-footer ul li a.fb {
  color: #3b5998;
}

.user-structure .info-footer ul li a.lk {
  color: #0077b5;
}

.user-structure .info-footer ul li a.ins {
  color: #1da1f2;
}

.user-structure--info {
  background-color: #fff;
  width: 50%;
}

@media (max-width: 991px) {
  .user-structure--info {
    width: calc(100% - 350px);
  }
}

@media (max-width: 767px) {
  .user-structure--info {
    width: 100%;
    background: #f5f9ff;
  }
}

.user-structure--form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f9ff;
  width: 50%;
}

@media (max-width: 1200px) {
  .user-structure--form {
    width: 50%;
    padding: 50px 20px;
  }
}

@media (max-width: 991px) {
  .user-structure--form {
    width: 350px;
  }
}

@media (max-width: 767px) {
  .user-structure--form {
    width: 100%;
    padding: 30px 20px;
  }
}

.user-structure--form .wrap {
  width: 432px;
}

@media (max-width: 992px) {
  .user-structure--form .wrap {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .user-structure--form .wrap {
    width: 70%;
  }
}

@media (max-width: 575px) {
  .user-structure--form .wrap {
    width: 100%;
  }
}

.user-structure--form .wrap label + .form-control {
  border: none;
  padding: 0;
}

.user-structure--form .wrap .button-group {
  margin-top: 35px;
}

.user-structure--form .wrap .button-group .btn {
  width: 50%;
}

@media screen and (max-width: 480px) {
  .user-structure--form .wrap .button-group .btn {
    width: auto;
    flex-grow: 1;
  }
}

.user-structure--form .wrap .form-control {
  background-color: #f3f3f3;
}

.user-structure--form .wrap p {
  font-size: 16px;
  line-height: 28px;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(111, 111, 111, 0.85);
  margin: 0;
}

@media (min-width: 768px) {
  .user-structure--form .wrap p {
    font-size: 20px;
    line-height: 32px;
  }
}

.user-structure--form .wrap p + p {
  margin-top: 20px;
}

.user-structure--form .form-link {
  margin-top: 15px;
}

.user-structure--form .form-link a {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #6f6f6f;
}

.user-structure--form .or-seprator {
  position: relative;
  text-align: center;
  margin: 20px 0 14px;
}

.user-structure--form .or-seprator::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.user-structure--form .or-seprator span {
  font-size: 20px;
  line-height: 1;
  background-color: #f5f9ff;
  padding: 0 10px;
  position: relative;
  color: #173460;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

.user-structure--form h2 {
  font-size: 24px;
  line-height: 34px;
  font-family: "Rokkitt", serif;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .user-structure--form h2 {
    font-size: 34px;
    line-height: 44px;
  }
}

.user-structure--form h3 {
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0 0 4px;
  font-size: 15px;
  line-height: 33px;
  color: #2a2a2a;
}

@media (min-width: 768px) {
  .user-structure--form h3 {
    font-size: 18px;
    line-height: 36px;
  }
}

.user-structure--form .login-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 575px) {
  .user-structure--form .login-options {
    display: block;
  }
}

@media (max-width: 992px) {
  .user-structure--form .login-options li {
    width: 100%;
  }
}

.user-structure--form .login-options li + li {
  margin-left: 20px;
}

@media (max-width: 575px) {
  .user-structure--form .login-options li + li {
    margin-left: 0;
    margin-top: 10px;
  }
}

.user-structure--form .login-options li a {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4.8px;
  transition: all ease-in-out 0.3s;
  font-size: 0;
  line-height: 20px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
}

@media (max-width: 1280px) {
  .user-structure--form .login-options li a {
    font-size: 14px !important;
    padding: 0 10px !important;
  }
}

@media (max-width: 992px) {
  .user-structure--form .login-options li a {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .user-structure--form .login-options li a img {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .user-structure--form .login-options li a {
    font-size: 14px;
    width: auto;
    padding: 0 20px;
  }
  .user-structure--form .login-options li a img {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .user-structure--form .login-options li a {
    font-size: 16px;
  }
  .user-structure--form .login-options li a img {
    margin-right: 20px;
  }
}

.user-structure--form .login-options li a:hover {
  background-color: #e7e7e7;
  transition: all ease-in-out 0.3s;
}

.user-structure--form .otp-box {
  margin-bottom: 30px;
  display: block;
}

.user-structure--form .otp-box ul {
  margin: 2px 0 4px;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.user-structure--form .otp-box ul li {
  width: 50px;
}

.user-structure--form .otp-box ul li + li {
  margin-left: 20px;
}

.user-structure--form .otp-box ul li .form-control {
  text-align: center;
  padding: 0;
  background-color: #fff;
  font-weight: 600;
  font-size: 20px;
  color: #6f6f6f;
}

.user-structure--form .otp-box p {
  font-weight: 600;
  font-size: 13px;
  line-height: 36px;
  margin: 0;
  color: #6f6f6f;
  font-family: "Source Sans Pro", sans-serif;
}

.user-structure--form .otp-box .btn-link {
  font-size: 13px;
  color: #fe0000;
  margin: 11px 0;
  line-height: 1;
}

.user-structure .onboarding-info {
  margin-top: 30px;
}

.user-structure .onboarding-info p {
  font-size: 16px !important;
  color: #000;
}

.user-structure .onboarding-info p a {
  color: #000;
  text-decoration: underline;
}

.login-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  list-style: none;
  width: 60%;
  margin: 40px 0;
  border-radius: 50px;
  padding: 0px;
}

.login-tabs li {
  width: 50%;
}

.login-tabs li:first-child a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.login-tabs li:last-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.login-tabs li a {
  line-height: 1;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
}

.login-tabs li a.active {
  background-color: #00a689;
  color: #fff;
  pointer-events: none;
}

.password-field {
  position: relative;
}

.password-field .primary-form-control {
  padding-right: 40px;
}

.password-field svg {
  position: absolute;
  right: 12px;
  cursor: pointer;
  font-size: 18px;
  top: 31px;
}

.custom-tabs-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

/* custom tabs */
.custom-tabs {
  border-radius: 4px;
  display: flex;
}

.custom-tabs span {
  cursor: pointer;
  background-color: #e6e6e6;
  font-size: 15px;
  font-weight: 700;
  color: #6f6f6f;
  letter-spacing: 0.3px;
  height: 38px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.custom-tabs span.active {
  background-color: #239f87;
  color: white;
}

.custom-tabs span.active:before {
  display: none;
}

.custom-tabs span.active + span:before {
  display: none;
}

.custom-tabs span:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 1px;
  height: 30px;
  background-color: #bdb9b9;
}

.custom-tabs span:first-child {
  border-radius: 4px 0 0 4px;
}

.custom-tabs span:first-child:before {
  display: none;
}

.custom-tabs span:last-child {
  border-radius: 0 4px 4px 0;
}

/* custom-card */
.custom-card {
  background-color: white;
  border-radius: 8px;
  min-height: 190px;
  padding: 20px;
  margin-bottom: 20px;
}

.custom-card .custom-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.custom-card .custom-card-head .course-name {
  font-size: 16px;
  color: black;
  font-weight: bold;
}

.custom-card .custom-card-head .subscribe-text {
  font-size: 14px;
  color: #239f87;
  font-weight: 600;
}

/* course info */
.course-info {
  margin-bottom: 15px;
}

.course-info ul {
  list-style-type: none;
  margin-left: -40px;
  display: flex;
  margin-bottom: 7px;
}

.course-info ul li {
  font-size: 14px;
  color: #6f6f6f;
  position: relative;
}

.course-info ul li + li {
  padding-left: 14px;
  margin-left: 10px;
}

.course-info ul li + li:before {
  content: " ";
  background-image: url("https://educrack-dev.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
  width: 4px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 8px;
}

.course-info p {
  font-size: 14px;
  color: #6f6f6f;
  margin-bottom: 0;
}

/* from-to-wrapper */
.from-to-wrapper {
  display: flex;
}

.from-to-wrapper .from-to-box:first-child {
  margin-right: 75px;
}

.from-to-wrapper .from-to-box span {
  display: block;
  font-size: 14px;
  color: #6f6f6f;
  margin-bottom: 5px;
}

.from-to-wrapper .from-to-box strong {
  display: block;
  font-size: 14px;
  color: #2a2a2a;
  font-weight: bold;
}

/* course-detail-wrapper */
.overview-detail-wrapper {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 40px;
}

.overview-detail-wrapper .overview-image-box {
  width: 100%;
  height: 247px;
}

.overview-detail-wrapper .overview-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.overview-detail-wrapper .overview-detail-body {
  padding: 20px 25px;
}

.overview-detail-wrapper .overview-detail-body .overview-heading {
  display: block;
  font-size: 18px;
  color: black;
  margin-bottom: 12px;
  font-weight: bold;
}

.overview-detail-wrapper .overview-detail-body .text-body {
  margin-bottom: 20px;
}

.overview-detail-wrapper .overview-detail-body .text-body p {
  font-size: 14px;
  color: #6f6f6f;
  margin-bottom: 7px;
}

.overview-detail-wrapper .overview-detail-body .text-body p:last-child {
  margin-bottom: 0;
}

/* inner-heading */
.inner-heading {
  font-size: 16px;
  font-weight: bold;
  color: #2a2a2a;
  margin-bottom: 10px;
  display: block;
}

/* small-custom-card */
.small-custom-card {
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.small-custom-card strong {
  display: block;
  font-size: 16px;
  color: #2a2a2a;
  margin-bottom: 5px;
  font-weight: bold;
}

.small-custom-card span {
  display: block;
  font-size: 14px;
  color: #6f6f6f;
}

/* subject-detail-wrapper */
.subject-detail-wrapper {
  margin-bottom: 20px;
}

/* subject-detail-inner */
.subject-detail-inner {
  max-width: 740px;
}

/* teachers-detail-wrapper */
.teachers-detail-wrapper {
  margin-bottom: 20px;
}

/* custom-card-media */
.custom-card-media {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
}

.custom-card-media .image-box {
  min-width: 60px;
  height: 60px;
}

.custom-card-media .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.custom-card-media .custom-card-media-body {
  margin-left: 20px;
}

.custom-card-media .custom-card-media-body .heading {
  display: block;
  font-size: 18px;
  color: black;
  margin-bottom: 5px;
}

.custom-card-media .custom-card-media-body .text {
  display: block;
  font-size: 14px;
  color: #6f6f6f;
  margin-bottom: 12px;
}

.custom-card-media .custom-card-media-body ul {
  display: flex;
  list-style-type: none;
  margin-left: -42px;
}

.custom-card-media .custom-card-media-body ul li {
  margin-right: 50px;
  display: flex;
  align-items: center;
  color: #6f6f6f;
  font-size: 14px;
}

.custom-card-media .custom-card-media-body ul li img {
  margin-right: 7px;
}

.custom-card-media .custom-card-media-body ul li span {
  line-height: 1.2;
}

.custom-card-media .custom-card-media-body p {
  font-size: 14px;
  color: #6f6f6f;
  margin-bottom: 0;
}

.filter-row-wrapper {
  max-width: 694px;
  margin-bottom: 25px;
}

.filter-row-wrapper .filter-row-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-row-wrapper .filter-row-top p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: #2a2a2a;
}

.filter-row-wrapper .filter-row-top .filter-icon {
  cursor: pointer;
}

.filter-row-wrapper .filter-text {
  font-size: 14px;
  color: #6f6f6f;
  opacity: 0.85;
  margin-top: 12px;
  line-height: 28px;
  margin-bottom: 0;
}

.filter-icon {
  cursor: pointer;
}

.calendar-wrapper {
  max-width: 750px;
  margin-bottom: 20px;
}

.lesson-wrapper {
  max-width: 750px;
}

.small-accordion {
  margin-bottom: 0;
}

.small-accordion .card, .small-accordion .card-nav .tab-content, .card-nav .small-accordion .tab-content {
  box-shadow: none;
  border: none;
  margin-bottom: 20px;
  position: relative;
}

.small-accordion .card-header {
  height: 60px;
  border-radius: 4px !important;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px;
  border: none;
  margin-bottom: 0 !important;
}

.small-accordion .card-header .heading {
  font-size: 16px;
  font-weight: bold;
  color: #2a2a2a;
}

.small-accordion .card-header .icon-down-arrow {
  font-size: 24px;
  font-weight: bold;
  transform: rotate(270deg);
  margin-right: -7px;
}

.small-accordion .card-header.active .icon-down-arrow {
  transform: rotate(0deg);
}

.small-accordion .card-body {
  padding: 0 20px 20px 20px;
}

.small-accordion .card-body .small-acc-box {
  margin-bottom: 20px;
}

.small-accordion .card-body .small-acc-box .course-info {
  margin-bottom: 0;
}

.small-accordion .card-body .small-acc-box strong {
  display: block;
  font-weight: bold;
  font-size: 15px;
  color: #2a2a2a;
  margin-bottom: 5px;
}

.small-accordion .card-body .small-acc-box:last-child {
  margin-bottom: 0;
}

.small-accordion .card-body .small-acc-box:last-child ul:last-child {
  margin-bottom: 0;
}

.test-accordion .card-header {
  height: 84px;
}

.test-accordion .card-header .acc-left-side .text {
  display: block;
  font-size: 14px;
  color: #6f6f6f;
  margin-top: 5px;
}

.test-accordion .card-header .icon-down-arrow {
  font-size: 26px;
}

.test-custom-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-custom-card .right {
  display: flex;
}

.test-custom-card .right .start-text {
  font-size: 14px;
  color: #239f87;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.test-custom-card .right .start-text.with-complete {
  text-transform: capitalize;
  cursor: default;
}

.test-custom-card .right .retry-text {
  font-size: 14px;
  color: #6f6f6f;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.small-media-card {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.small-media-card .image-box {
  min-width: 114px;
  width: 114px;
  height: 114px;
}

.small-media-card .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.small-media-card .small-media-card-body {
  margin-left: 15px;
}

.small-media-card .small-media-card-body strong {
  display: block;
  font-size: 15px;
  color: #2a2a2a;
  font-weight: bold;
}

.small-media-card .small-media-card-body p {
  font-size: 14px;
  line-height: 22px;
  color: #6f6f6f;
  margin: 5px 0;
}

.small-media-card .small-media-card-body span {
  font-weight: bold;
  display: block;
  font-size: 14px;
  color: #2a2a2a;
}

.mentor-detail-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 520px;
}

.mentor-detail-box .mentor-detail-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.mentor-detail-box .mentor-detail-box-top .left {
  display: flex;
  align-items: center;
}

.mentor-detail-box .mentor-detail-box-top .left .image-box {
  width: 80px;
  height: 80px;
}

.mentor-detail-box .mentor-detail-box-top .left .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mentor-detail-box .mentor-detail-box-top .left .left-body {
  margin-left: 15px;
}

.mentor-detail-box .mentor-detail-box-top .left .left-body strong {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #2a2a2a;
}

.mentor-detail-box .mentor-detail-box-top .left .left-body span {
  display: block;
  font-size: 14px;
  color: #6f6f6f;
}

.mentor-detail-box .mentor-detail-box-top .follow-btn {
  min-width: 112px;
  height: 36px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #239f87;
  border: 1px solid #239f87;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.mentor-detail-box .mentor-detail-box-top .follow-btn:hover {
  background-color: #239f87;
  color: white;
  transition: 0.5s;
}

.mentor-detail-box .text {
  font-size: 14px;
  color: #6f6f6f;
  line-height: 24px;
  margin: 20px 0;
}

.mentor-detail-box .spe-text {
  font-size: 14px;
  color: #6f6f6f;
  margin: 0;
}

.mentor-detail-box .spe-text span {
  font-weight: bold;
}

.mentor-info-list {
  display: flex;
}

.mentor-info-list .mentor-info-box {
  width: 33.33%;
  text-align: center;
}

.mentor-info-list .mentor-info-box strong {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.mentor-info-list .mentor-info-box span {
  width: 118px;
  height: 34px;
  background-color: #e6e6e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 auto;
}

.mentor-detail-box-wrapper {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.assignment-type {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0;
  padding: 15px 0;
  list-style: none;
}

.assignment-type li {
  font-weight: 600;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  text-transform: uppercase;
  color: #35363b;
  display: flex;
  align-items: center;
}

.assignment-type li + li {
  margin-left: 15px;
}

.assignment-type li span {
  width: 11px;
  font-size: 0;
  border-radius: 50%;
  height: 11px;
  margin-right: 5px;
}

.custom-table + .custom-table {
  margin-top: 30px;
}

.custom-table.eight-col .custom-table--col {
  width: calc((100% - 105px) / 7);
}

.custom-table.eight-col .custom-table--col:last-child {
  width: 105px;
}

.custom-table.two-col .custom-table--col {
  width: calc(100% / 2);
}

.custom-table.three-col .custom-table--col {
  width: calc(100% / 3);
}

.custom-table.four-col .custom-table--col {
  width: calc(100% / 4);
}

.custom-table.five-col .custom-table--col {
  width: calc(100% / 5);
}

.custom-table.five-col.with-action .custom-table--col {
  width: calc((100% - 71px) / 4);
}

.custom-table.five-col.with-action .custom-table--col:last-child {
  width: 71px;
}

.custom-table--head .custom-table--row {
  height: auto;
  margin-bottom: 10px;
}

.custom-table--head .custom-table--col {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #35363b;
}

.custom-table--body .custom-table--row {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  min-height: 62px;
  transition: all ease-in-out 0.3s;
}

.custom-table--body .custom-table--row + .custom-table--row {
  margin-top: 10px;
}

.custom-table--row {
  display: flex;
  align-items: center;
}

.custom-table--col {
  padding: 0 20px;
}

.custom-table--col .table-options {
  position: relative;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}

.custom-table--col .table-options:hover .table-options--menu {
  transform: scale(1);
  transition: all ease-in-out 0.3s;
}

.custom-table--col .table-options--menu {
  position: absolute;
  top: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin: 0;
  min-width: 95px;
  padding: 12px 14px;
  list-style: none;
  right: 30px;
  transform: scale(0);
  transform-origin: top right;
  transition: all ease-in-out 0.3s;
}

.custom-table--col .table-options--menu li + li {
  margin-top: 10px;
}

.custom-table--col .table-options--menu li a {
  display: block;
  font-size: 12px;
  line-height: 17px;
  color: #35363b;
}

.custom-table--col .table-options--menu li a:hover {
  color: #00a689;
}

.custom-table--col .table-options:hover {
  transition: all ease 0.3s;
}

.custom-table--col .btn-danger {
  background: #e60f0f;
  border-radius: 4px;
  min-width: 1px;
  border: none;
  width: 65px;
  height: 34px;
}

.custom-table--col .btn-danger:hover {
  background-color: #ce0d0d;
}

.custom-table--col span {
  font-size: 10px;
  line-height: 14px;
  color: #666666;
  display: block;
}

.custom-table--col span + span {
  margin-top: 2px;
}

.custom-table--col p {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: #35363b;
}

.custom-table--col p a {
  color: #35363b;
  display: block;
}

.custom-table--col p a:hover {
  text-decoration: underline;
}

.custom-table--col p.expired {
  color: #b6b6b6;
  font-size: 14px;
}

.primary-pagination {
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
}

.primary-pagination li.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.primary-pagination li.active a {
  border-color: #1ea087;
  pointer-events: none;
}

.primary-pagination li + li {
  margin-left: 11px;
  position: relative;
}

.primary-pagination li:nth-child(2) {
  margin-left: 0;
}

.primary-pagination li:first-child {
  margin-right: 20px;
}

.primary-pagination li:first-child a {
  width: auto;
  height: auto;
}

.primary-pagination li:last-child {
  margin-left: 20px;
}

.primary-pagination li:last-child a {
  width: auto;
  height: auto;
}

.primary-pagination li a {
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35363b;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.primary-pagination li a svg {
  font-size: 16px;
  color: #aaaaaa;
}

.custom-table-info {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #35363b;
}

.custom-table-info strong {
  font-weight: 800;
}

.test-box--header {
  margin-bottom: 30px;
}

.test-box--header h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #2a2a2a;
  margin-bottom: 15px;
}

.test-box--body {
  background: #ffffff;
  padding: 25px;
  border-radius: 10px;
}

.test-box--body .button-group {
  justify-content: flex-end;
  margin-top: 30px;
}

.test-box--body .button-group .btn {
  min-width: 128px;
  height: 44px;
  font-size: 18px;
}

.test-box--body .button-group .btn + .btn {
  margin-left: 25px;
}

.test-box--body h4 {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  color: #6f6f6f;
}

.test-box--body p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 26px;
  color: #2a2a2a;
}

.my-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.my-progress:not(:last-child) {
  margin-bottom: 40px;
}

.my-progress--name {
  display: flex;
  width: calc(173px + 34px);
  align-items: center;
}

.my-progress--name span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  color: #00A689;
  margin-right: 10px;
}

.my-progress--name p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 16px;
  color: #2A2A2A;
}

.my-progress--bar {
  flex-grow: 1;
  padding: 12px 22px 0 0;
}

.my-progress--bar span {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #111111;
}

.my-progress--bar p {
  font-size: 12px;
  line-height: 18px;
  margin: 1px 0 0;
  color: #666666;
}

.my-progress--action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 12px;
  width: 100px;
}

.my-progress--action a {
  font-size: 13px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #666666;
}

.my-progress--action a + a {
  margin-top: 5px;
}

.primary-progress-bar {
  display: flex;
  align-items: center;
}

.primary-progress-bar span {
  margin-left: 5px;
}

.primary-progress-bar .wrap {
  width: 100%;
  height: 8px;
  background: #FFFFFF;
  border: 1px solid #239F87;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.primary-progress-bar .wrap .bar-line {
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: 10px;
  background: #00A689;
}

.live-box + .live-box {
  margin-top: 20px;
}

.live-box h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  margin: 0 0 15px;
  color: #000;
  font-size: 20px;
  line-height: 36px;
}

.doubt-box {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
}

.doubt-box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.doubt-box-head .left {
  display: flex;
  align-items: center;
}

.doubt-box-head .left .image-box {
  height: 52px;
  width: 52px;
}

.doubt-box-head .left .image-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.doubt-box-head .left .doubt-box-head-body {
  margin-left: 12px;
}

.doubt-box-head .left .doubt-box-head-body strong {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-bottom: 3px;
  line-height: 1;
}

.doubt-box-head .left .doubt-box-head-body span {
  display: block;
  font-size: 14px;
  color: #6F6F6F;
}

.doubt-feed-image {
  width: 100%;
  height: 220px;
  margin-bottom: 20px;
}

.doubt-feed-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.doubt-feed-desc {
  margin-bottom: 16px;
}

.doubt-feed-desc p {
  font-size: 14px;
  color: #6F6F6F;
  line-height: 22px;
}

.doubt-feed-info .doubt-feed-info-top {
  display: flex;
  justify-content: space-between;
}

.doubt-feed-info .doubt-feed-info-top span {
  font-size: 14px;
  color: #6F6F6F;
}

.doubt-feed-info .doubt-feed-action-list {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  margin-top: 5px;
  padding: 8px 0 4px 0;
}

.doubt-feed-info .doubt-feed-action-list span {
  cursor: pointer;
}

.doubt-feed-info .doubt-feed-action-list span {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #6F6F6F;
  font-weight: bold;
}

.doubt-feed-info .doubt-feed-action-list span img {
  margin-right: 5px;
}

.doubt-write-comment {
  position: relative;
}

.doubt-write-comment .form-control {
  font-size: 14px;
  color: black;
  border: none;
  box-shadow: none;
  height: 70px;
  padding-left: 60px;
}

.doubt-write-comment .image-box {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 10px;
}

.doubt-write-comment .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.doubt-comment-wrapper {
  border-bottom: 1px solid #F0F0F0;
  padding: 15px 0;
}

.doubt-comment-wrapper .doubt-comment-box {
  display: flex;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.doubt-comment-wrapper .doubt-comment-box:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.doubt-comment-wrapper .doubt-comment-box .image-box {
  width: 50px;
  height: 50px;
}

.doubt-comment-wrapper .doubt-comment-box .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body {
  margin-left: 12px;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body .user-time {
  margin-top: 13px;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body .user-time strong {
  font-size: 14px;
  color: #2A2A2A;
  font-weight: bold;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body .user-time span {
  font-size: 12px;
  color: #6F6F6F;
  margin-left: 25px;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body .doubt-comment-text {
  font-size: 14px;
  color: #6F6F6F;
  line-height: 28px;
  margin-bottom: 0;
}

.doubt-comment-wrapper .doubt-comment-box .doubt-comment-box-body .reply-btn {
  font-size: 14px;
  cursor: pointer;
  color: #239F87;
}

.doubt-dropdown {
  position: relative;
}

.doubt-dropdown .toggle {
  cursor: pointer;
}

.doubt-dropdown .menu {
  position: absolute;
  left: -85px;
  top: -8px;
  border-radius: 8px;
  box-shadow: 4px 6px 10px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 85px;
  display: none;
}

.doubt-dropdown .menu .item {
  padding: 10px;
  font-size: 14px;
  color: #212121;
  cursor: pointer;
  transition: 0.5s;
}

.doubt-dropdown .menu .item:hover {
  color: #a7a7a7;
  transition: 0.5s;
}

.doubt-dropdown .menu.active {
  display: block;
}

.doubt-post-box {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px 20px 15px 20px;
}

.doubt-post-box .doubt-post-box-top {
  display: flex;
  margin-bottom: 15px;
}

.doubt-post-box .doubt-post-box-top .doubt-post-write {
  width: 60%;
  margin-right: 20px;
  position: relative;
}

.doubt-post-box .doubt-post-box-top .doubt-post-write .form-control {
  border: none;
  border-bottom: 1px solid #F0F0F0;
  box-shadow: none;
  padding: 3px 0 0 35px;
  height: 50px;
  border-radius: 0;
  font-size: 14px;
  resize: none;
  font-weight: bold;
}

.doubt-post-box .doubt-post-box-top .doubt-post-write .ask-icon {
  position: absolute;
  left: 0;
  top: 0;
}

.doubt-post-box .doubt-post-box-top .right {
  display: flex;
}

.doubt-post-box .doubt-post-box-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doubt-post-box .doubt-post-box-bottom .edu-btn {
  min-width: 140px;
}

.doubt-file-upload input {
  display: none;
}

.doubt-file-upload label {
  cursor: pointer;
  font-size: 14px;
  color: #6F6F6F;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
}

.doubt-file-upload label img {
  margin-right: 5px;
}

.inner-main-heading {
  font-size: 18px;
  color: black;
}

.settings-wrapper {
  max-width: 580px;
}

.settings-box .setting-box-heading {
  font-size: 14px;
  display: block;
  color: #6F6F6F;
  margin-bottom: 8px;
}

.settings-box .settings-box-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6dede;
  padding-bottom: 23px;
  margin-bottom: 23px;
}

.settings-box .settings-box-row .settings-inner-heading {
  font-size: 14px;
  color: #2A2A2A;
  font-weight: bold;
  line-height: 1;
}

.settings-box .settings-box-row .right {
  line-height: 1;
}

.settings-box .settings-box-row .action-btn {
  font-size: 14px;
  cursor: pointer;
  color: #239F87;
  font-weight: 600;
  line-height: 1;
}

.settings-box.for-language .setting-box-heading {
  margin-bottom: 12px;
}

.settings-box.for-language .settings-inner-heading {
  color: #6F6F6F;
  font-weight: 500;
}

.settings-box.for-language .lang-heading {
  font-size: 14px;
  color: #2A2A2A;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

.settings-links ul {
  margin-bottom: 0;
  list-style-type: none;
  margin-left: -40px;
}

.settings-links li {
  margin-bottom: 8px;
}

.settings-links li a {
  font-size: 14px;
  color: #2A2A2A;
  font-weight: bold;
  line-height: 1;
}

.settings-links li:last-child {
  margin-bottom: 0;
}

.toggle-switch {
  height: 16px;
}

.toggle-switch .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 16px;
  margin-bottom: 0;
}

.toggle-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle-switch input:checked + .slider {
  background-color: #239F87;
}

.toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px #239F87;
}

.toggle-switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

.toggle-switch .slider.round {
  border-radius: 34px;
}

.toggle-switch .slider.round:before {
  border-radius: 25px;
}

/* Student Header */
.student-header {
  position: static !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  z-index: 999;
}

.student-header .navbar {
  height: 70px;
  background-color: white;
  padding: 0 22px;
  display: flex;
  align-items: center;
}

.student-header .navbar-brand {
  margin-right: 105px;
}

.student-header .navbar-nav {
  margin: 0 -20px !important;
}

.student-header .navbar-nav .nav-link {
  font-size: 15px;
  color: #6f6f6f;
  height: auto;
  margin: 0 20px !important;
}

.student-header .navbar-nav .nav-link:first-child {
  margin-left: 0;
}

.student-header .navbar-nav .nav-link:hover {
  color: #239f87;
}

.student-header .header-right {
  display: flex;
  align-items: center;
}

.student-header .button-dropdown {
  margin-right: 20px;
}

.student-header .user-dropdown {
  margin-left: 20px;
}

/* left-panel */
.left-panel {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 220px;
  padding: 22px 12px 22px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.left-panel ul {
  list-style-type: none;
  margin-left: -30px;
}

.left-panel ul li {
  margin-bottom: 22px;
}

.left-panel ul li a {
  display: flex;
  align-items: center;
}

.left-panel ul li a:hover .left-nav-link {
  color: #239f87;
}

.left-panel ul li .left-nav-link {
  font-size: 14px;
  color: #6f6f6f;
  margin-left: 14px;
  line-height: 1.3;
}

.left-panel ul li .left-nav-icon {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  display: block;
}

.left-panel ul li .left-nav-icon .my-resume {
  margin-bottom: 0;
}

.left-panel ul li .my-profile {
  background-image: url(../../dist/images/educator/profile.svg) !important;
}

.left-panel ul li .my-cms {
  background-image: url(../../dist/images/educator/cms.svg) !important;
}

.left-panel ul li .my-dashboard {
  background-image: url(../../dist/images/educator/dashboard.svg) !important;
}

.left-panel ul li .my-wall {
  background-image: url(../../dist/images/educator/wall.svg) !important;
}

.left-panel ul li .my-course {
  background-image: url(../../dist/images/educator/course.svg) !important;
}

.left-panel ul li .my-doubt {
  background-image: url(../../dist/images/educator/doubts.svg) !important;
}

.left-panel ul li .my-question-bank {
  background-image: url(../../dist/images/educator/question-bank.svg) !important;
}

.left-panel ul li .my-manange-test {
  background-image: url(../../dist/images/educator/manage-test.svg) !important;
}

.left-panel ul li .my-manange-test-bundle {
  background-image: url(../../dist/images/educator/manage-test-bundle.svg) !important;
}

.left-panel ul li .my-library {
  background-image: url(../../dist/images/educator/library.svg) !important;
}

.left-panel ul li .my-educator {
  background-image: url(../../dist/images/educator/educator.svg) !important;
}

.left-panel ul li .my-assignments {
  background-image: url(../../dist/images/educator/assignment.svg) !important;
}

.left-panel ul li .my-students {
  background-image: url(../../dist/images/educator/my-students.svg) !important;
}

.left-panel ul li .my-manage-batches {
  background-image: url(../../dist/images/educator/manage-batches.svg) !important;
}

.left-panel ul li .my-go-live {
  background-image: url(../../dist/images/educator/live.svg) !important;
}

.left-panel ul li .my-reports {
  background-image: url(../../dist/images/educator/reports.svg) !important;
}

.left-panel ul li .my-role {
  background-image: url(../../dist/images/educator/role.svg) !important;
}

.left-panel ul li .my-interview {
  background-image: url(../../dist/images/educator/interview.svg) !important;
}

.left-panel ul li .my-manage-payments {
  background-image: url(../../dist/images/educator/manage-payment.svg) !important;
}

.left-panel ul li .my-rewards {
  background-image: url(../../dist/images/educator/reward.svg) !important;
}

.left-panel ul li .my-support {
  background-image: url(../../dist/images/educator/info.svg) !important;
}

.left-panel ul li .my-logout {
  background-image: url(../../dist/images/educator/logout.svg) !important;
}

.left-panel ul li .my-resume {
  background-image: url(../../dist/images/educator/resume.svg) !important;
  margin-bottom: 0 !important;
}

.left-panel ul li .my-mock-interviews {
  background-image: url(../../dist/images/educator/mock.svg) !important;
}

.left-panel ul li .pro-image {
  background-image: url(../../dist/images/side-menu/edu-pro.svg) !important;
}

.left-panel ul li .goal-image {
  background-image: url(../../dist/images/side-menu/goals.svg) !important;
}

.left-panel ul li .live-image {
  background-image: url(../../dist/images/side-menu/live.svg) !important;
}

.left-panel ul li .assignment-image {
  background-image: url(../../dist/images/side-menu/assignment.svg) !important;
}

.left-panel ul li .progress-image {
  background-image: url(../../dist/images/side-menu/progress.svg) !important;
}

.left-panel ul li .reward-image {
  background-image: url(../../dist/images/side-menu/reward.svg) !important;
}

.left-panel ul li .settings {
  background-image: url(../../dist/images/side-menu/settings.svg) !important;
}

.left-panel ul li .support-image {
  background-image: url(../../dist/images/side-menu/support.svg) !important;
}

.left-panel ul li .ad-dashboard-image {
  background-image: url("../images/ad-dashboard-icon.png");
}

.left-panel ul li .ad-wall-image {
  background-image: url("../images/ad-wall-icon.png");
}

.left-panel ul li .ad-doubt-image {
  background-image: url("../images/ad-doubt-icon.png");
}

.left-panel ul li .ad-batch-image {
  background-image: url("../images/ad-batch-icon.png");
}

.left-panel ul li .ad-student-image {
  background-image: url("../images/ad-student-icon.png");
}

.left-panel ul li .ad-educator-image {
  background-image: url("../images/ad-educator-icon.png");
}

.left-panel ul li .ad-question-image {
  background-image: url("../images/ad-question-icon.png");
}

.left-panel ul li .ad-test-image {
  background-image: url("../images/ad-test-icon.png");
}

.left-panel ul li .ad-library-image {
  background-image: url("../images/ad-library-icon.png");
}

.left-panel ul li .ad-logout-image {
  background-image: url("../images/ad-logout-icon.png");
}

.left-panel .collapse-menu {
  margin-top: 25px;
  margin-left: -12px;
  display: none;
}

.left-panel .collapse-menu .left-nav-icon {
  display: none;
}

.left-panel .collapse-menu.active {
  display: block;
}

.rotate180 {
  transform: rotate(180deg);
}

.admin-left-panel6 .left-panel {
  width: 256px;
  padding-left: 0;
  padding-right: 0;
  background: linear-gradient(167.93deg, #1ea087 22.39%, #35838e 87.46%);
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  top: 64px;
}

.admin-left-panel6 .left-panel::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.admin-left-panel6 .left-panel .collapse-menu {
  margin: 0;
  padding: 0;
}

.admin-left-panel6 .left-panel .collapse-menu li {
  margin-bottom: 0;
  margin-top: 10px;
}

.admin-left-panel6 .left-panel .collapse-menu li.active > a {
  background: #00b8be;
}

.admin-left-panel6 .left-panel .collapse-menu li a {
  padding: 10px 25px 10px 60px;
  color: #fff;
}

.admin-left-panel6 .left-panel .collapse-menu li a:hover .left-nav-link {
  color: #fff;
}

.admin-left-panel6 .left-panel .collapse-menu li a .left-nav-link {
  margin: 0;
}

.admin-left-panel6 .left-panel .left-nav-link {
  color: white;
}

.admin-left-panel6 .left-panel img {
  display: none;
}

.admin-left-panel6 .left-panel > ul {
  padding: 0 0 22px;
  margin: 0;
}

.admin-left-panel6 .left-panel > ul > li {
  padding: 0;
  margin-bottom: 10px;
}

.admin-left-panel6 .left-panel > ul > li.active > a {
  background: #00b8be;
}

.admin-left-panel6 .left-panel > ul > li.active > a svg {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.3s ease-in-out all;
}

.admin-left-panel6 .left-panel > ul > li:last-child {
  margin-bottom: 0;
}

.admin-left-panel6 .left-panel > ul > li > a {
  position: relative;
  padding: 10px 25px;
}

.admin-left-panel6 .left-panel > ul > li > a.in svg {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.3s ease-in-out all;
}

.admin-left-panel6 .left-panel > ul > li > a:hover .left-nav-link {
  color: #fff;
}

.admin-left-panel6 .left-panel > ul > li > a svg {
  position: absolute;
  right: 22px;
  top: 50%;
  display: block;
  color: #fff;
  transition: 0.3s ease-in-out all;
  transform: translateY(-50%) rotate(0deg);
}

.admin-left-panel6 .left-panel > ul > li > a .nav-arrow-img {
  position: absolute;
  right: 6px;
  top: 50%;
  display: block;
  transform: translateY(-50%);
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel {
  width: 250px !important;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel {
  width: 250px !important;
  background: #f5f9ff;
  padding: 24px 8px;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul {
  flex-grow: 1;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li {
  margin-bottom: 8px;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li.active a {
  background: #ffe200;
  box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a {
  display: flex;
  padding: 12px 16px;
  border-radius: 50px;
  align-items: center;
  font-size: 16px;
  color: #111111;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a:hover {
  background: #ffe200;
  box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a .left-nav-link {
  color: #111111;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a:hover .left-nav-link {
  color: #111111;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a span {
  line-height: 1;
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel .left-panel ul li a img {
  margin-right: 12px;
}

.user-left-panel .left-panel ul li .left-nav-icon {
  width: 16px;
  height: 16px;
}

body {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: #f5f9ff !important;
}

a {
  transition: 0.5s;
}

a:hover {
  transition: 0.5s;
}

/* notification-wrapper */
.notification-wrapper {
  position: relative;
}

.notification-wrapper .notification-toggle {
  position: relative;
  margin: 0 20px;
}

.notification-wrapper .notification-toggle .noti-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

/* user-dropdown */
.user-dropdown .dropdown-toggle {
  background: none !important;
  padding: 0;
  border: none;
  box-shadow: none;
  display: flex;
}

.user-dropdown .dropdown-toggle:after {
  display: none;
}

.user-dropdown .dropdown-toggle .user-image {
  height: 32px;
  width: 32px;
}

.user-dropdown .dropdown-toggle .user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #eee;
  border-radius: 50%;
}

.user-dropdown .dropdown-toggle .user-name {
  font-size: 14px;
  font-weight: 500;
  color: #2a2a2a;
  margin: 0;
}

.user-dropdown .dropdown-toggle .icon-down-arrow {
  color: #2a2a2a;
  font-size: 24px;
}

.user-dropdown .dropdown-toggle:active {
  background: none !important;
  border: none;
  box-shadow: none !important;
}

.user-dropdown .dropdown-toggle:focus {
  box-shadow: none !important;
  background: none;
}

.user-dropdown .dropdown-menu {
  left: auto;
  right: 0;
  border-radius: 4px;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
  margin-top: 0.5rem !important;
}

.user-dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #6f6f6f;
  padding: 6px 15px;
}

.user-dropdown .dropdown-menu .dropdown-item:last-child {
  color: #e84f4f;
}

.user-dropdown .dropdown-menu .dropdown-item:last-child:hover {
  color: #e84f4f;
}

.user-dropdown .dropdown-menu .dropdown-item:hover {
  color: #239f87;
}

.user-dropdown .dropdown-menu .dropdown-item:active {
  font-weight: 500;
}

.user-dropdown .dropdown-menu .dropdown-item:after {
  display: none;
}

.user-dropdown .dropdown-menu:before {
  display: none;
}

.user-dropdown .dropdown-menu:after {
  display: none;
}

/* Inner wrapper */
.inner-wrapper {
  margin-top: 70px;
}

/* right-side-wrapper */
.right-side-wrapper {
  margin-left: 220px;
  padding: 20px 20px 0 20px;
  display: flex;
  position: relative;
}

/* right-ad-wrapper */
.right-ad-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
}

/* reward-point-box */
.reward-point-box {
  background: linear-gradient(360deg, #239f87 -250.95%, #ffffff 100%);
  border-radius: 8px;
  width: 200px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.reward-point-box img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.reward-point-box span {
  font-size: 16px;
  color: #2a2a2a;
  margin-bottom: 8px;
  display: block;
}

.reward-point-box strong {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 1;
  margin-bottom: 20px;
}

.reward-point-box a {
  min-width: 115px;
  height: 34px;
  background: #239f87;
  border-radius: 4px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.reward-point-box a:hover {
  color: white;
}

/* ad-banner */
.ad-banner {
  width: 200px;
  height: 378px;
  background-color: white;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ad-banner p {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: bold;
}

/* main-mid */
.main-mid {
  padding: 15px;
}

@media screen and (min-width: 1280px) {
  .main-mid {
    width: calc(100% - 280px);
    padding: 25px 40px;
  }
}

/* row-for-gap */
.row-for-gap {
  margin-left: -10px;
  margin-right: -10px;
}

.row-for-gap .column-for-gap {
  padding-left: 10px;
  padding-right: 10px;
}

/* Custom-radio */
.custom-radio {
  margin-bottom: 0;
  padding-left: 0;
}

.custom-radio input {
  display: none;
}

.custom-radio input:checked + label:before {
  background-color: #239f87;
}

.custom-radio input:checked + label:after {
  display: block;
}

.custom-radio label {
  cursor: pointer;
}

.custom-radio label:before {
  content: "";
  height: 21px;
  width: 21px;
  background-color: #f3f3f3;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.custom-radio label:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 4px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

/* custom-breadcrumb */
.custom-breadcrumb .breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 24px;
}

.custom-breadcrumb .breadcrumb .breadcrumb-item {
  font-size: 14px;
  color: #6f6f6f;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 500;
  position: relative;
}

.custom-breadcrumb .breadcrumb .breadcrumb-item:last-child {
  font-weight: bold;
}

.custom-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 14px;
  margin-left: 10px;
}

.custom-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: " ";
  background-image: url("https://educrack-dev.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
  width: 4px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 6px;
}

.custom-search {
  width: 350px;
  position: relative;
}

.custom-search .form-control {
  height: 38px;
  background-color: #e6e6e6;
  border-radius: 4px;
  border-color: #e6e6e6;
  box-shadow: none;
  font-size: 14px;
  color: #6f6f6f;
  padding-left: 40px;
}

.custom-search .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e6e6e6;
  height: 38px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
}

.search-subject-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-subject-row .form-group {
  margin-bottom: 0;
}

.search-subject-row .right {
  display: flex;
  align-items: center;
}

.search-subject-row select::-ms-expand {
  display: none;
}

.search-subject-row .custom-option {
  margin-right: 20px;
}

.custom-option {
  width: 230px;
  position: relative;
}

.custom-option .form-control {
  background-color: #e6e6e6;
  box-shadow: none;
  border-color: #e6e6e6;
  font-size: 14px;
  height: 38px;
  -webkit-appearance: none;
}

.custom-option .icon-down-arrow {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
}

.notification-page {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-page li {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 10px;
  line-height: 1;
  background-color: #fff;
}

.notification-page li.active {
  background: #FFFAF0;
  border-color: #ffe9bd;
}

.notification-page li + li {
  margin-top: 5px;
}

.notification-page li .wrap {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

.notification-page li .wrap strong {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}

.notification-page li .wrap span {
  font-size: 12px;
  line-height: 17px;
  color: #666666;
  margin-left: auto;
  flex-shrink: 0;
}

.notification-page li p {
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  color: #686868;
}

.menu-wrap {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  min-width: 300px;
  right: 0;
  top: 34px;
  padding: 10px 6px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1), margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
}

@media screen and (max-width: 767px) {
  .menu-wrap {
    min-width: 250px;
  }
}

.menu-wrap.active {
  opacity: 1;
  visibility: visible;
  margin-top: 14px !important;
  transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1), margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
}

.menu-wrap h3 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #6F6F6F;
  padding: 6px 11px 12px;
  margin: 0;
  text-transform: uppercase;
}

.menu-wrap .view-all {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  color: #00A689;
  margin-top: 10px;
}

.menu-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .menu-wrap ul {
    max-height: 150px;
  }
}

.menu-wrap ul li {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 10px;
  line-height: 1;
  background-color: #fff;
}

.menu-wrap ul li.active {
  cursor: pointer;
  background: #FFFAF0;
  border-color: #ffe9bd;
}

.menu-wrap ul li + li {
  margin-top: 5px;
}

.menu-wrap ul li .wrap {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

.menu-wrap ul li .wrap strong {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}

.menu-wrap ul li .wrap span {
  font-size: 12px;
  line-height: 17px;
  color: #666666;
  margin-left: auto;
  flex-shrink: 0;
}

.menu-wrap ul li p {
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  color: #686868;
}

.primary-card {
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 20px 27px;
}

.primary-card p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 23px;
  color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.primary-card p strong {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #239f87;
}

.primary-card .info {
  margin: 6px 0 20px;
}

.primary-card .info span {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
}

.primary-card .info-path {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.primary-card .info-path span + span {
  margin-left: 20px;
  position: relative;
}

.primary-card .info-path span + span:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 7px;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.primary-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.primary-card ul li + li {
  margin-left: 75px;
}

.primary-card ul li span {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
}

.primary-card ul li span + span {
  margin-top: 10px;
}

.primary-card ul li strong {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  font-family: "Source Sans Pro", sans-serif;
  color: #2a2a2a;
}

.secondary-card {
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.secondary-card .action-group .btn {
  font-size: 14px;
  font-weight: 700;
}

.secondary-card .action-group .btn + .btn {
  margin-left: 10px;
}

.secondary-card .wrap {
  flex-grow: 1;
}

.secondary-card p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #2a2a2a;
  margin: 0;
}

.secondary-card span {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 32px;
  display: block;
  color: #6f6f6f;
}

.progress {
  box-shadow: none;
  background: #e5e5e5;
  border-radius: 20px;
  height: 12px;
}

.progress-bar {
  border-radius: 20px;
}

.progress .bg-success {
  background-color: #239f87 !important;
}

.selectable-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.selectable-list.three-col .selectable-list--wrap {
  width: calc((100% - 60px) / 3);
}

.selectable-list--wrap {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 9px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.selectable-list--wrap p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  color: #2a2a2a;
}

.selectable-list--wrap span {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.selectable-list--wrap span img {
  opacity: 0;
}

.selectable-list--wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  height: 100%;
}

.selectable-list--wrap input:checked ~ span {
  background-color: #239f87;
  border-color: #239f87;
}

.selectable-list--wrap input:checked ~ span img {
  opacity: 1;
}

.list-with-tag + .list-with-tag {
  margin-top: 10px;
}

.list-with-tag .tag-header {
  position: relative;
  margin-bottom: 20px;
}

.list-with-tag .tag-header::before {
  background-color: #e6e6e6;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
}

.list-with-tag .tag-header ul {
  background: #e6e6e6;
  border-radius: 4px;
  list-style: none;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 10px;
  display: inline-flex;
  align-items: center;
}

.list-with-tag .tag-header ul li {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #2a2a2a;
}

.list-with-tag .tag-header ul li + li {
  margin-left: 10px;
}

.courses {
  margin: 20px 0 20px;
}

@media (min-width: 768px) {
  .courses {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .courses p {
    margin-bottom: 18px;
  }
}

.courses ul {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.courses ul li {
  position: relative;
  background: #f3f3f3;
  border-radius: 4px;
  padding: 0 20px;
  height: 120px;
  width: calc((100% - 40px) / 2);
  font-weight: bold;
  margin: 20px 10px 0;
  font-size: 16px;
  line-height: 18px;
  font-family: "Rokkitt", serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a2a2a;
  text-align: center;
}

@media (min-width: 992px) {
  .courses ul li {
    width: calc((100% - 60px) / 3);
    font-size: 20px;
    line-height: 20px;
  }
}

.courses ul li span {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ffffff;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.courses ul li span img {
  opacity: 0;
}

.courses ul li input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.courses ul li input:checked + span {
  background-color: #239f87;
}

.courses ul li input:checked + span img {
  opacity: 1;
}

.courses.secondary p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #2a2a2a;
  margin-bottom: 0;
}

.courses.secondary ul li {
  background-color: #ffffff;
}

.courses.secondary ul li span {
  background: #f0f0f0;
}

@media (min-width: 992px) {
  .courses.five-col ul li {
    width: calc((100% - 100px) / 4);
  }
}

.primary-list {
  border: 1px solid #c4c4c4;
  margin-bottom: 5px;
}

.primary-list .wrap {
  align-items: center;
}

.primary-list .wrap + div {
  display: none;
}

.primary-list .icon {
  width: 55px;
  min-width: 1px;
  border: none;
  flex-shrink: 0;
  border: 1px solid #C4C4C4;
  height: 55px;
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.action-box h3 {
  font-family: "Rokkitt", serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  color: #2a2a2a;
}

.action-box p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  line-height: 32px;
  max-width: 565px;
  color: #6f6f6f;
  opacity: 0.85;
}

.action-box textarea {
  height: 367px;
  padding: 20px;
  margin-bottom: 25px;
}

.action-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.action-box ul li {
  background: #f3f3f3;
  border-radius: 4px;
  height: 120px;
  font-family: "Rokkitt", serif;
  font-weight: 700;
  width: calc((100% - 40px) / 3);
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #2a2a2a;
}

.action-box ul li:hover {
  background-color: #239f87;
  color: #ffffff;
}

.success-box {
  text-align: center;
}

.success-box img {
  display: block;
  margin: 0 auto 60px;
}

.success-box p {
  margin: 0;
}

.success-box .btn {
  margin-top: 60px;
}

.primary-thumbnail {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
}

.primary-thumbnail--action {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
}

.primary-thumbnail--icon {
  width: 114px;
  height: 114px;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;
}

.primary-thumbnail--icon img {
  max-width: 100%;
}

.primary-thumbnail--content {
  width: calc(100% - 114px);
  padding-left: 14px;
  padding-right: 40px;
  padding-top: 16px;
}

.primary-thumbnail--content h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
  color: #2a2a2a;
}

.primary-thumbnail--content p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #6f6f6f;
}

.small-modal .modal-dialog {
  max-width: 365px;
}

.small-modal .modal-body {
  padding: 30px 30px 15px 30px;
}

.small-modal .modal-body .heading {
  font-size: 18px;
  color: #2A2A2A;
  font-weight: bold;
  margin-bottom: 20px;
}

.small-modal .modal-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.small-modal .modal-detail-row:last-child {
  margin-bottom: 0;
}

.small-modal .modal-detail-row strong {
  font-size: 14px;
  color: #2A2A2A;
  font-weight: bold;
}

.small-modal .modal-detail-row span {
  font-size: 14px;
  color: #6F6F6F;
}

.small-modal .round-btn {
  margin-top: 10px;
}

.small-modal .modal-content {
  border-radius: 10px;
}

.forgetform {
  height: 100vh;
}

.forget-sec .wrap .button-group {
  margin-top: 0px;
}

.forget-sec .wrap .button-group .btn {
  width: 100%;
}

.leftnavcard {
  position: relative;
  display: flex;
}

.leftnavmid {
  padding-bottom: 0px;
  padding-left: 1rem;
}

.addv {
  width: 20%;
  position: absolute;
  top: 0;
  right: 20px;
}

.para {
  font-size: 1rem;
  font-weight: bold;
}

.trans {
  width: 50rem;
}

.batchp {
  font-size: 1rem;
  font-weight: bold;
}

.tab {
  width: 70%;
}

.textchange {
  color: #239f86;
  font-size: 1rem;
}

.progressimg {
  height: 3rem;
  border: 2px solid #239f86;
  border-radius: 1rem;
  margin-right: 2rem;
}

.batch-accordion .accordion .card-header {
  padding: 1rem 1rem;
}

.progress-according .accordion .card-body .lesson-wrap .wrapper {
  width: calc(100% - 4px);
}

.prodetail {
  margin-top: -7px;
}

.prodetail a {
  margin-left: 3rem;
}

.progress {
  border: 1px solid #239f86;
}

.progress-bar {
  background-color: #239f86;
}

.livestreamtab {
  width: 100%;
}

.checkprice h4 {
  font-size: 15px;
  padding-left: 30px;
}

.checkprice p {
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
}

.checkdown .subscribed img {
  width: 100%;
}

.calendar-sec .e-day.e-title {
  font-size: 20px;
}

.calendar-sec .e-date-icon-prev.e-icons {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  background-color: #f3f3f3;
  border-radius: 5px;
}

.calendar-sec .e-date-icon-next.e-icons {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-left: 4px;
}

.calendar-sec .e-today .e-day {
  background-color: #00a689 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
}

.calender-event-sec {
  background-color: #fff;
  padding: 14px 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #ccc;
}

.calendar-sec .e-control.e-calendar {
  border: none;
  max-width: 100%;
}

.calendar-sec .e-header.e-month {
  border-bottom: 2px solid #ccc;
  padding-bottom: 7px;
}

.calender-btn .pdf-down {
  background-color: #f3f3f3;
  padding: 11px 11px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 48px;
}

.calender-btn .calendar-timeline-btn {
  display: inline-block;
  list-style: none;
  padding-left: 0px;
  background-color: #f3f3f3;
  margin-right: 12px;
  border-radius: 6px;
  margin-bottom: 6px;
}

.calendar-timeline-btn .active-bg {
  background-color: #000;
  color: #fff;
  border-radius: 5px;
}

.calendar-timeline-btn li {
  display: inline-block;
  padding: 10px 16px;
}

.calender-btn {
  text-align: right;
  margin-right: 20px;
  width: 100%;
  border-bottom: 2px solid #ccc;
  padding-bottom: 7px;
}

.calender-event-sec tr th {
  padding: 18px 0px;
}

.calender-event-sec tr td {
  padding: 11px 0px !important;
}

.calender-event-sec .pd-r {
  padding-right: 0px;
  max-width: 100%;
}

.calender-event-sec .pd-r {
  padding-right: 0px;
}

.calender-event-sec .pd-le {
  padding-left: 0px !important;
}

.calender-event-sec .e-footer-container {
  display: none;
}

.calendar-event-box {
  height: 90%;
  padding: 15px 15px;
  border-left: 2px solid #ccc;
}

.event-sec .cur-date {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

.calendar-event-box .event-box {
  background-color: #00a6892e;
  margin-bottom: 11px;
  border: 2px solid #00a689;
  border-radius: 5px;
  padding: 5px 10px;
}

.calendar-event-box .event-box ul {
  padding-left: 0px;
  list-style: none;
}

.calendar-event-box .event-box h5 {
  color: #00a689;
  font-size: 19px;
  margin-bottom: 0px;
}

.calendar-event-box .event-box .event-title {
  color: #000;
  font-size: 21px;
  font-weight: 600;
}

.calendar-event-box .event-box .event-time {
  color: #9e9e9e;
  font-size: 21px;
}

.calendar-color-list ul {
  list-style: none;
  padding-left: 0px;
  background-color: #efefef;
  padding: 12px 40px;
  margin: 0px 17px;
  border-radius: 10px;
}

.calendar-color-list li {
  display: inline-block;
  margin-right: 29px;
  height: initial;
  position: relative;
  padding-left: 4px;
}

.color-yellow::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #ffe200;
  left: -10px;
}

.color-green::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #00c50a;
  left: -10px;
}

.color-black::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #000000;
  left: -10px;
}

.calendar-color-list li:last-child {
  margin-right: 0px;
}

.calendar-event-box .event-box .event-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.assignment-tabelbg {
  background: #e3e3e3;
  border-radius: 8px 8px 0px 0px;
}

.assign_row ul li {
  list-style: none;
}

.assign_row h4 {
  color: #00a68d;
}

.assign_row {
  background-color: #ffffff;
}

.assign_table img {
  height: 18rem;
  width: 100%;
}

.row.assign_row h6 {
  line-height: 1.5rem;
  margin-bottom: 0rem;
}

.assign_row {
  padding: 1rem;
}

.assign_row p {
  color: #727171;
}

.assign_row .col-md-7 {
  border-right: 2px solid lightgray;
}

.stats_head {
  font-size: 1rem;
}

.box_notes {
  height: 100vh;
  width: 100%;
  background-color: white;
}

.assignment-tabelbg h6 {
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 1190px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0.5rem;
  }
  .livemid {
    width: calc(100% - 0px);
  }
  .livestreammid {
    width: 100%;
  }
  .statistics img {
    width: 60%;
  }
  .calender-btn .pdf-down {
    margin-right: 6px;
  }
  .calendar-timeline-btn li {
    padding: 11px 3px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 3px;
  }
}

@media only screen and (max-width: 991px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 50%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
  .card, .card-nav .tab-content {
    width: 100%;
  }
  .progressm {
    width: calc(100% - 0px);
  }
  .progress21 {
    width: calc(100% - 0px);
  }
  .progresscard {
    width: 100%;
  }
  .mentor .three-col li {
    width: calc(100% / 2 - 30px) !important;
  }
  .goalmid {
    width: calc(100% - 0px);
  }
  .assignmentmid {
    width: calc(100% - 0px);
  }
  .assignmentmid-edu {
    width: calc(100% / 2 - 0px) !important;
  }
  .advertisement-block {
    padding: 25px 40px;
    flex-grow: 1;
  }
  .course-schedule img {
    width: 100%;
  }
  .calender-btn .pdf-down {
    margin-right: 20px;
  }
  .calendar-timeline-btn li {
    padding: 11px 11px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 11px;
  }
  .calender_main {
    width: calc(100% - 0px) !important;
  }
}

@media only screen and (max-width: 830px) {
  .primary-table-ui .table-responsive-md table tbody tr td {
    height: 80px;
    padding: 0px 10px;
  }
  .assignment-tabelbg.row .col-md-3 h6 {
    font-size: 14px;
    font-weight: bold;
  }
  .assignment-tabelbg h6 {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 60%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmid {
    width: 100%;
  }
  .livestreammid {
    text-align: center;
  }
  .custom-nav-tabs ul li {
    height: 49px;
    display: flex;
    width: 50%;
    text-align: center;
    margin: auto;
    align-items: center;
    padding: 0 26px;
    font-size: 16px;
    margin: auto;
    line-height: 23px;
    display: table;
    padding-top: 15px;
    color: #111;
    position: relative;
    cursor: pointer;
  }
  .leftnavmid .col-md-4 {
    width: 50%;
  }
  .leftnavmain {
    width: calc(100% - 0px);
  }
  .checkprice h4 {
    padding-left: 60px;
  }
  .main-mid.rewardmid {
    width: calc(100% - 0px);
  }
  .mentor .three-col li {
    width: calc(100% / 1 - 30px) !important;
  }
  .mentor {
    width: calc(100% - 0px);
    padding: 25px 40px;
  }
  .referral-code img {
    width: 100%;
  }
  .link-paytm .wrap {
    width: 100%;
    margin: 0 auto;
  }
  .reward-banner {
    display: block;
    padding-right: 0px;
    padding: 20px 0px;
  }
  .reward-banner .image {
    width: 100%;
  }
  .reward-banner .wrap button {
    margin: auto;
    display: block;
  }
  .tabs-select {
    width: 100%;
  }
  .offset-sm-1 {
    margin-left: 0;
  }
  .statistics {
    margin-top: 12px;
  }
  .assign-head {
    width: 100%;
  }
  .custom-nav-tabs {
    width: 100%;
    padding: 0px 5px;
  }
  .custom-nav-tabs ul {
    display: initial;
  }
  .custom-nav-tabs ul {
    width: 100%;
  }
  .custom-nav-tabs ul li {
    padding: 12px 3px;
  }
  .assignmentmid {
    width: calc(100% - 0px);
  }
  .assignmentmid-edu {
    width: calc(100% / 2 - 0px) !important;
  }
  .advertisement-block {
    padding: 25px 40px;
    flex-grow: 1;
  }
  .course-schedule img {
    width: 100%;
  }
  .row_cal {
    margin-right: 0px;
  }
  .calender-btn {
    margin-top: 12px;
  }
}

/* @media only screen and (max-width: 660px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 40%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmid {
    width: 100%;
  }
} */
@media only screen and (max-width: 600px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 70%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmain {
    width: calc(100% - 0px);
  }
  .leftnavmain h3 {
    font-size: 19px;
  }
  .reward-banner .wrap h4 {
    font-size: 3rem;
  }
  .assignment--row ul li {
    width: calc(100% - 0px);
    margin-bottom: 10px;
    background: #f5f9ff;
    border: 0.5px solid #e0e6ee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding-left: 9px;
    padding-right: 16px;
    cursor: pointer;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .assignment--row ul {
    display: inherit;
  }
}

/*
@media only screen and (max-width: 560px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 40%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavmid {
    width: 100%;
  }
  /* .leftnavrow p{
      font-size: 1rem;
    } */
/* } */
@media only screen and (max-width: 520px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 70%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .leftnavmid .col-md-4 {
    width: 100%;
  }
  .leftnavmain .subscribed img {
    width: 100%;
  }
  .checkprice h4 {
    padding-left: 0px;
  }
  .headingcheck h1 {
    font-size: 25px;
  }
  td .d-flex {
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 90%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .progressimg {
    margin-right: 0.5rem;
  }
  .reward-banner {
    display: block;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 420px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 90%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .progressimg {
    height: 2rem;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 388px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .calender-btn .calendar-timeline-btn {
    margin-right: 0px;
  }
  .calender-btn .pdf-down {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
  .main-mid.rewardmid {
    width: calc(100% - 0px);
  }
  .calender-btn .pdf-down {
    margin-right: 6px;
  }
  .calendar-timeline-btn li {
    padding: 11px 3px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 3px;
  }
}

@media only screen and (max-width: 320px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
}

/*# sourceMappingURL=scss.css.map */
.checkout-page {
  width: calc(100% - 30px);
  max-width: 517px;
  margin: 15px auto;
  background: #ffffff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .checkout-page {
    padding: 57px 125px 34px;
    width: 100%;
    margin: 50px auto;
  }
}

.checkout-page button {
  margin: 20px auto 0;
  width: 160px;
  display: block;
}

@media screen and (min-width: 768px) {
  .checkout-page button {
    margin: 50px auto 0;
  }
}

.checkout-page .details h3 {
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  margin: 0 0 15px;
  line-height: 16px;
  color: #000000;
}

@media screen and (min-width: 768px) {
  .checkout-page .details h3 {
    font-size: 18px;
    margin: 0 0 18px;
    line-height: 18px;
  }
}

.checkout-page .details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkout-page .details ul li {
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .checkout-page .details ul li {
    font-size: 18px;
    line-height: 18px;
  }
}

.checkout-page .details ul li + li {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .checkout-page .details ul li + li {
    margin-top: 17px;
  }
}

.checkout-page .details ul li span strong {
  font-weight: 600;
}

.checkout-page .details ul li span strong em {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: block;
  font-style: normal;
}

.checkout-page .details ul li span:first-child {
  flex-grow: 1;
}

.checkout-page .details ul li span:last-child {
  min-width: 100px;
  flex-shrink: 0;
}

.checkout-page .details--footer {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .checkout-page .details--footer {
    margin-top: 55px;
  }
}

.checkout-page .details--footer ul li {
  align-items: flex-start;
}

.checkout-page .custom-thumbnail {
  margin: 0 auto 20px;
}

@media screen and (min-width: 768px) {
  .checkout-page .custom-thumbnail {
    width: calc(100% - 50px);
    margin: 0 auto 40px;
  }
}

.checkout-page h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #000000;
  margin: 0 0 20px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .checkout-page h1 {
    font-size: 30px;
    margin: 0 0 49px;
  }
}

.box_notes {
  padding: 15px;
  height: auto;
}

.box_notes embed {
  display: block;
  margin: 0 !important;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .custom-nav-tabs ul {
    padding: 0 10px;
  }
  .custom-nav-tabs ul li {
    padding: 0;
    justify-content: center;
    font-size: 13px;
  }
  .advertisement-block {
    width: 100%;
  }
  .box_notes {
    height: auto;
  }
  .box_notes embed {
    max-width: 100%;
    min-height: 1px;
  }
}

.student-navbar {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  height: 70px;
  background-color: #fff;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .student-navbar {
    padding: 0 15px;
  }
}

.student-navbar .left {
  display: flex;
  align-items: center;
}

.student-navbar .left .logo {
  margin-right: 105px;
}

@media screen and (max-width: 767px) {
  .student-navbar .left .logo {
    margin-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .student-navbar .left .nav {
    display: none;
  }
}

.student-navbar .left svg {
  margin-right: 10px;
  font-size: 30px;
  display: none;
}

@media screen and (max-width: 1199px) {
  .student-navbar .left svg {
    display: block;
  }
}

.student-navbar .left svg g {
  fill: #000;
}

.student-navbar .right {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .student-navbar .right .user-dropdown .dropdown-toggle {
    min-width: 1px;
  }
  .student-navbar .right .user-dropdown .dropdown-toggle .user-name {
    font-size: 0;
  }
  .student-navbar .right .user-dropdown .dropdown-toggle .icon-down-arrow {
    display: none;
  }
  .student-navbar .right .notification-wrapper .notification-toggle::before {
    display: none;
  }
  .student-navbar .right .notification-wrapper .notification-toggle::after {
    display: none;
  }
  .student-navbar .right .notification-wrapper .notification-toggle .noti-icon {
    margin: 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .student-navbar .right .button-dropdown {
    position: fixed;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    left: 0;
    bottom: 0;
    padding: 10px;
    display: none;
  }
  .student-navbar .right .button-dropdown .btn {
    width: 100%;
  }
  .button-dropdown .dropdown-menu {
    width: calc(100% - 20px) !important;
  }
  .student-navbar .left .logo img {
    width: 120px;
  }
}

@media screen and (max-width: 1199px) {
  .navbar {
    min-height: 70px;
  }
  body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel,
  body .mdk-drawer-layout .mdk-drawer[data-persistent].admin-left-panel6 {
    width: 100% !important;
  }
  body .mdk-drawer-layout .mdk-drawer[data-persistent] {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    overflow: auto;
    height: calc(100vh - 70px);
    top: 70px;
    left: -100%;
    transition: all ease-in-out 0.3s;
  }
  body .mdk-drawer-layout .mdk-drawer[data-persistent].show {
    left: 0;
    transition: all ease-in-out 0.3s;
  }
  body .mdk-drawer-layout .mdk-drawer[data-persistent] .left-panel {
    position: static;
    overflow: auto;
  }
}

.button-dropdown .dropdown-toggle {
  min-width: 212px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #239f87 !important;
  border-color: #239f87 !important;
  border-radius: 8px;
  font-size: 18px !important;
  font-weight: bold;
  padding: 0 16px;
}

.button-dropdown .dropdown-toggle:after {
  display: none;
}

.button-dropdown .dropdown-toggle svg {
  margin-left: 10px;
}

.button-dropdown .dropdown-toggle:focus {
  background-color: #239f87;
  border-color: #239f87;
  box-shadow: none !important;
}

.button-dropdown .dropdown-toggle:active {
  background-color: #239f87;
  border-color: #239f87;
  box-shadow: none !important;
}

.button-dropdown .dropdown-menu {
  left: auto;
  right: 0;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #E5E5E5;
  overflow: auto;
  max-height: 200px;
  min-width: 1px !important;
  width: 100%;
  padding: 12px 8px;
  margin-top: 0 !important;
}

.button-dropdown .dropdown-menu:after {
  display: none;
}

.button-dropdown .dropdown-menu .custom-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 6px 8px;
  position: relative;
}

.button-dropdown .dropdown-menu .custom-item .custom-radio {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.button-dropdown .dropdown-menu .custom-item .custom-radio input:not(:checked) + label::before {
  background-color: transparent;
}

.button-dropdown .dropdown-menu .custom-item .custom-radio .form-check {
  position: static;
  padding: 0;
}

.button-dropdown .dropdown-menu .custom-item .custom-radio .form-check label::before {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.button-dropdown .dropdown-menu .custom-item .custom-radio .form-check label:after {
  display: none;
}

.button-dropdown .dropdown-menu .custom-item-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #111111;
  position: relative;
  z-index: 9;
  text-transform: none;
}

.student-navbar .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #666666;
}

.student-navbar .nav-link.active {
  color: #239f87 !important;
}

.courser-selection.three-col ul li {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .courser-selection.three-col ul li {
    width: calc((100% / 3) - 24px);
  }
}

.courser-selection ul {
  list-style: none;
  margin: 0 -12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.courser-selection ul li {
  margin: 0 12px 24px;
  height: auto;
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal;
  padding: 10px;
  position: relative;
  color: #aaaaaa;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #00A689;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 6px;
}

@media screen and (max-width: 767px) {
  .courser-selection ul li {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: normal;
  }
}

.courser-selection ul li.selected {
  color: #fff;
  padding-left: 10px;
  background: #00A689;
  border: 1px solid #00A689;
}

.courser-selection ul li.selected span {
  border-color: transparent;
  background-color: transparent;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .courser-selection ul li.selected span {
    margin-right: 10px;
  }
}

.courser-selection ul li.selected svg {
  width: 24px;
  height: 24px;
  color: #fff;
  position: static;
  right: 0;
  margin-right: 0;
  top: 0;
}

@media screen and (max-width: 767px) {
  .courser-selection ul li.selected svg {
    width: 18px;
    height: 18px;
  }
}

.courser-selection ul li.selected::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: -1px;
  top: -1px;
  display: none;
}

.courser-selection ul li input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.courser-selection ul li span {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  transform: none;
  left: 0;
  position: static;
  font-size: 0;
  background: rgba(0, 166, 137, 0.1);
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 6px;
}

@media screen and (max-width: 767px) {
  .courser-selection ul li span {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }
}

.assignment-wrap + .assignment-wrap {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap + .assignment-wrap {
    margin-top: 15px;
  }
}

.assignment-wrap h3 {
  color: #00A689 !important;
}

@media screen and (max-width: 767px) {
  .assignment-wrap h3 {
    margin: 0;
  }
}

.assignment-wrap .assignment-table--head {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--head {
    display: none;
  }
}

.assignment-wrap .assignment-table--head .assignment-table--col {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #111111;
}

.assignment-wrap .assignment-table--body .assignment-table--row + .assignment-table--row {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--body .assignment-table--row + .assignment-table--row {
    margin-top: 0;
    border-top: 1px solid #eee;
  }
}

.assignment-wrap .assignment-table--row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--row {
    justify-content: space-between;
    padding: 15px 0;
  }
}

.assignment-wrap .assignment-table--col {
  padding: 0 15px;
}

.assignment-wrap .assignment-table--col:first-child {
  width: calc(100% - 500px);
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--col:first-child {
    width: 100%;
    margin-bottom: 10px;
  }
}

.assignment-wrap .assignment-table--col:nth-child(2) {
  width: 250px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--col:nth-child(2) {
    width: auto;
  }
}

.assignment-wrap .assignment-table--col:last-child {
  width: 250px;
}

@media screen and (max-width: 767px) {
  .assignment-wrap .assignment-table--col:last-child {
    width: auto;
  }
}

.assignment-wrap .assignment-table--col span {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  color: #666666;
}

.assignment-wrap .assignment-table--col span a {
  color: #00A689;
}

.assignment-wrap .assignment-table--col p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 18px;
  display: block;
  color: #666666;
}

.assignment-wrap .assignment-table--col p + span {
  margin-top: 4px;
}

.assignment-type-bullets {
  display: block;
  width: 10px;
  font-size: 0;
  height: 10px;
  border-radius: 50%;
}

.assignment-type-bullets.test {
  background-color: #beab00;
}

.assignment-type-bullets.group {
  background-color: #00b8be;
}

.assignment-type-bullets.batch {
  background-color: #00be4c;
}

.simple-table {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.simple-table.batch-reports li {
  width: calc(100% - (87px + 58px));
}

.simple-table.batch-reports li:first-child {
  width: calc(87px + 58px);
}

.simple-table:first-child {
  padding-top: 0;
}

.simple-table:last-child {
  padding-bottom: 0;
}

.simple-table + .simple-table {
  border-top: 1px solid #E9E9E9;
}

.simple-table li {
  font-size: 14px;
  line-height: 18px;
  color: #35363B;
}

.simple-table li strong {
  font-weight: 600;
}

.educator-info-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.educator-info-list li {
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  display: flex;
  cursor: pointer;
  margin-right: 50px;
  margin-top: 5px;
  color: #35363B;
}

.educator-info-list li span {
  width: 85px;
}

.profile-box {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 50px 50px;
}

.profile-box--header {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

.profile-box--header .image {
  margin-right: 50px;
}

.profile-box--header .image label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin: 4px 0 0;
  text-align: center;
  letter-spacing: -0.3px;
  color: #111111;
}

.profile-box--header .image label input {
  display: none;
}

.profile-box--header .image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #f5f9ff;
}

.profile-box--header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.profile-box--header ul li {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 24px;
  height: 52px;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 6px;
  letter-spacing: -0.3px;
  font-weight: 600;
  font-size: 12px;
  color: #666666;
}

.profile-box--header ul li + li {
  margin-left: 16px;
}

.profile-box--header ul li span {
  font-size: 16px;
  margin-right: 5px;
}

.tabbed-radio ul {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.tabbed-radio ul li {
  position: relative;
}

.tabbed-radio ul li + li {
  margin-left: 11px;
}

.tabbed-radio ul li input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tabbed-radio ul li input:checked + span {
  background: #00A689;
  border-color: #00A689;
  color: #fff;
}

.tabbed-radio ul li span {
  height: 40px;
  border-radius: 6px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #fff 0, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #111;
}

.tabbed-radio p.radio-heading {
  display: block;
  font-family: "Source Sans Pro",sans-serif;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #393e41;
}

.helper-link {
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  cursor: pointer;
  color: #00A689;
}

.helper-link svg {
  margin-left: 0;
  font-size: 16px;
}

.otp-box {
  display: flex;
}

.otp-box .form-group {
  margin: 0;
}

.otp-box .form-group:not(:last-child) {
  margin-right: 16px;
}

.otp-box .form-group .primary-form-control {
  width: 40px;
  text-align: center;
}

.resend-btn {
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  color: #EB4335;
}

/* add-question-container */
.add-question-container {
  margin-bottom: 120px;
}

.add-question-container .form-control {
  height: 38px;
}

.add-question-container .form-control:hover {
  border-color: #239f87;
}

.add-question-container .form-row {
  margin-right: -10px;
  margin-left: -10px;
}

.add-question-container .form-row > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.add-question-container .btn-form-group-row {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}

.add-question-container .btn-form-group-row .btn {
  height: 38px;
}

.add-question-container .right-side .form-group {
  width: 200px;
}

.add-question-container .custom-file .custom-file-label {
  height: 38px;
  cursor: pointer;
  line-height: 1.7;
}

.add-question-container .custom-file .custom-file-label:after {
  height: 36px;
}

.add-question-container .add-bottom-form-box {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
}

.add-question-container .add-bottom-form-box .form-group label {
  margin-bottom: 15px;
  line-height: 1;
  text-transform: uppercase;
}

.add-question-container .label-delete-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-question-container .label-delete-row .right-side {
  display: flex;
  align-items: center;
}

.add-question-container .label-delete-row .right-side .true-false-text {
  margin-right: 10px;
}

.add-question-container .label-delete-row label {
  color: #7f8381;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}

.add-question-container .label-delete-row .btn {
  min-width: 50px;
  height: 35px;
}

.add-question-container .add-bottom-form-box .for-custom-radio {
  display: flex;
  margin-bottom: 20px;
  margin-top: 38px;
}

.add-question-container .add-bottom-form-box .for-custom-radio label {
  text-transform: capitalize;
  margin: 0 0 0 8px !important;
  line-height: 1;
}

.add-question-container .add-bottom-form-box .for-custom-radio div {
  display: flex;
  position: relative;
}

.add-question-container .add-bottom-form-box .for-custom-radio div:first-child {
  margin-right: 25px;
}

.add-question-container .add-bottom-form-box .for-custom-radio input:after {
  cursor: pointer;
  top: -2px;
}

.add-question-container .add-bottom-form-box .for-custom-radio input:before {
  cursor: pointer;
}

.add-question-container .add-bottom-form-box .for-custom-radio input:checked:before {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  top: 4px;
  left: 3px;
  position: absolute;
  background-color: white;
  content: "";
  z-index: 9;
}

.add-question-container .add-bottom-form-box .custom-checkbox label {
  margin-bottom: 0;
  cursor: pointer;
}

.add-question-container .add-bottom-form-box .add-option-btn {
  height: 38px;
  background-color: #ffe200;
  font-size: 14px;
  border: 1px solid #e1e3e7;
  margin-bottom: 20px;
  margin-left: 5px;
}

.add-question-container .add-bottom-form-box .editor-2 {
  margin: 20px 0;
  position: relative;
}

.add-question-container .add-bottom-form-box .editor-2 .required-text {
  font-size: 14px;
  color: #239f87;
  margin-top: 3px;
  margin-bottom: 5px;
}

.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 36px;
}

.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom .editor-2-checkbox label {
  margin-bottom: 0;
  margin-left: 5px;
  cursor: pointer;
}

.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom .delete-icon {
  margin-left: 10px;
}

.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom .delete-icon label {
  display: none !important;
}

.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom .delete-icon .btn {
  min-width: 50px;
  height: 35px;
}

.add-question-container .add-bottom-form-box .form-group .tox-tinymce {
  margin-bottom: 28px;
  width: 100% !important;
}

.ques-count-text {
  text-align: right;
  margin-bottom: 18px;
  color: #272c33;
  font-size: 15px;
}

/* for-height */
.for-height .form-control {
  height: 38px;
  padding-left: 12px;
}

.for-height .form-control:hover {
  border-color: #239f87;
}

/* ques-row */
.ques-row {
  padding: 15px 0;
}

.ques-row .ques-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}

.ques-row .ques-left::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: -8px;
  bottom: 0;
  margin: auto;
  background-color: #acb0b5;
}

.ques-row .ques-left .ques-count {
  background-color: #47b3bb;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 25px;
}

.btn {
  min-width: 50px;
  height: 35px;
}

.ques-row .form-group {
  margin-bottom: 8px;
}

.ques-row .form-control {
  height: 38px;
  background-color: white;
}

.ques-row .form-control:hover {
  border-color: #47b3bb;
}

.ques-row label {
  font-size: 15px;
  margin-bottom: 5px;
}

/* add-question-wrapper */
.add-question-wrapper {
  text-align: center;
  margin: 50px 0;
}

.add-question-wrapper .icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.add-question-wrapper img {
  height: 45px;
}

.add-question-wrapper .text {
  font-size: 15px;
  margin: 20px 0;
}

/* Custom-upload */
.custom-upload input {
  display: none;
}

.custom-upload label {
  background-color: #1ea7fd;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  font-size: 15px;
}

/* question-list-container */
.question-list-container .card, .question-list-container .card-nav .tab-content, .card-nav .question-list-container .tab-content {
  border-radius: 0;
}

.question-list-container .table-responsive-md thead tr {
  height: 60px;
}

.question-list-container .table-responsive-md thead tr th {
  padding: 0 15px;
  font-size: 14px;
}

.question-list-container .table-responsive-md tbody tr {
  min-height: 50px;
}

.question-list-container .table-responsive-md tbody tr td {
  padding: 5px 15px;
}

.question-list-container .table-responsive-md tbody tr td:nth-child(2) {
  max-width: 350px;
  white-space: inherit;
}

.question-list-container .table-responsive-md tbody tr td p {
  margin-bottom: 0;
}

.tox-statusbar {
  display: none !important;
}

/* bulk-ques-box */
.bulk-ques-box {
  background-color: white;
  padding: 15px;
  display: flex;
  border: none;
  margin-bottom: 30px;
}

.bulk-ques-box.alert-danger {
  color: #844042;
  background-color: #f7dddc;
  border-color: #f4cfce;
}

.bulk-ques-box .left-side {
  width: 50px;
}

.bulk-ques-box .left-side .count {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #239f87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto 30px;
}

.bulk-ques-box .right-side {
  width: calc(100% - 50px);
}

.bulk-ques-box .right-side .row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 -8px;
}

.bulk-ques-box .right-side .form-control {
  box-shadow: none;
}

.bulk-ques-box .right-side .form-control:focus {
  border: 1px solid #edf0f2;
}

.bulk-ques-box .right-side .row .bulk-input-group {
  width: 20%;
  padding: 0 8px;
}

.bulk-ques-bottom-box .form-group-wrapper {
  width: 85%;
}

.bulk-ques-bottom-box .form-group-wrapper span {
  font-size: 15px;
  margin-bottom: 5px;
  display: block;
}

.bulk-ques-bottom-box .form-group-wrapper textarea.form-control {
  margin-bottom: 0;
}

.bulk-ques-bottom-box .form-group .form-control {
  height: 38px;
  background-color: white;
  border: 1px solid #edf0f2;
  font-size: 15px;
  color: #434544;
}

.bulk-ques-bottom-box .form-group span {
  font-size: 15px;
  color: #48506a;
  margin-bottom: 4px;
  display: block;
}

.bulk-ques-bottom-box .form-group textarea.form-control {
  height: 100px;
  padding-left: 15px;
  padding-top: 5px;
  resize: none;
}

.bulk-ques-bottom-box .input-with-side-text {
  display: flex;
  margin-bottom: 0;
}

.bulk-ques-bottom-box .input-with-side-text span {
  font-size: 15px;
  padding-top: 8px;
}

.bulk-ques-bottom-box .input-with-side-text .option-heading {
  font-size: 15px;
  min-width: 95px;
}

.bulk-ques-bottom-box .input-with-side-text .form-group {
  margin-bottom: 0;
  margin-right: 15px;
}

.bulk-ques-bottom-box .input-with-side-text .text {
  margin-top: 15px;
  margin-top: 1px;
  margin-right: 0;
}

.bulk-ques-bottom-box .input-with-side-text .form-group {
  width: 76%;
}

.bulk-ques-bottom-box .input-with-option-check {
  display: flex;
  margin-bottom: 16px;
}

.bulk-ques-bottom-box .input-with-option-check .form-group {
  margin-bottom: 0;
  width: 76%;
  margin-right: 15px;
}

.bulk-ques-bottom-box .input-with-option-check .option-heading {
  font-size: 15px;
  min-width: 95px;
}

.bulk-ques-bottom-box .input-with-option-check p {
  width: 77.5%;
  margin-bottom: 0;
}

.bulk-ques-bottom-box .input-with-option-check .invalid-feedback {
  display: none !important;
}

.ques-bank-box {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
}

.ques-bank-box .ques-bank-box-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 10px;
}

.ques-bank-box .ques-bank-box-top .ques-no {
  color: #095846;
  margin-right: 10px;
}

.ques-bank-box .ques-bank-box-top .left-side {
  display: flex;
}

.ques-bank-box .ques-bank-box-top .left-side ul {
  list-style-type: none;
  display: flex;
  margin-left: -30px;
  margin-bottom: 0;
}

.ques-bank-box .ques-bank-box-top .left-side ul li {
  padding-right: 5px;
  margin-right: 5px;
  position: relative;
  color: #acacac;
}

.ques-bank-box .ques-bank-box-top .left-side ul li:last-child:after {
  display: none;
}

.ques-bank-box .ques-bank-box-top .left-side ul li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  background-color: #b5b5b5;
  height: 12px;
  width: 1px;
}

.ques-bank-box .ques-bank-box-top .right-side .used-text {
  text-decoration: underline;
  color: #acacac;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.ques-bank-box .ques-bank-box-top .right-side .used-text img {
  height: 12px;
  margin-left: 10px;
}

.ques-bank-box .question {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ques-bank-box .question span {
  font-size: 16px;
  margin-right: 13px;
}

.ques-bank-box .question p {
  margin-bottom: 0;
  font-size: 13px;
}

.ques-bank-box .ques-edit-delete-row {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  color: #b5b5b5;
}

.ques-bank-box .ques-edit-delete-row .ques-edit {
  margin: 0 20px;
  cursor: pointer;
}

.ques-bank-box .ques-edit-delete-row .ques-delete {
  cursor: pointer;
}

.ques-bank-box .ques-edit-delete-row .ques-delete img {
  height: 16px;
}

.question-list-container .card, .question-list-container .card-nav .tab-content, .card-nav .question-list-container .tab-content {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

.question-list-container .card thead, .question-list-container .card-nav .tab-content thead, .card-nav .question-list-container .tab-content thead {
  display: none;
}

.question-list-container .card tbody tr, .question-list-container .card-nav .tab-content tbody tr, .card-nav .question-list-container .tab-content tbody tr {
  min-height: auto;
}

.question-list-container .card tbody tr td, .question-list-container .card-nav .tab-content tbody tr td, .card-nav .question-list-container .tab-content tbody tr td {
  padding: 0;
  white-space: inherit;
  border: none;
}

.question-list-container .card-footer {
  border-radius: 0;
  border: none;
}

.question-list-container .form-row .form-control {
  background-color: white;
  font-size: 16px;
}

.question-list-container .question-date-picker-wrapper {
  display: flex;
}

.question-list-container .question-date-picker-wrapper .react-datepicker-wrapper {
  margin: 0 5px;
}

.question-list-container .question-date-picker-wrapper .react-datepicker-wrapper input {
  height: 38px;
  font-size: 16px;
  padding-left: 12px;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #596d8c;
  border-radius: 4px;
}

.avatar-title img {
  padding: .25rem;
}

.avatar-title {
  color: #fff;
  background-color: #303956;
}

.custom-badge {
  font-size: 13px;
  border-radius: 10rem;
}

.custom-badge.contained {
  background: white;
  color: black;
}

.custom-badge.outlined {
  background: white;
  color: black;
}

.outlined-badge {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #239f87;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  color: #000000;
}

.outlined-badge.disabled {
  opacity: 0.22;
  pointer-events: none;
}

.outlined-badge:hover {
  background-color: #239f87;
  color: #fff;
  transition: all ease-in-out 0.3s;
}

.primary-button {
  background: transparent;
  border: 0px;
  padding: 0.5rem 1rem;
  font-size: 13px;
  border-radius: 0.25rem;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  color: #434544;
  transition: all ease-in-out 0.3s;
}

.primary-button:hover {
  transition: all ease-in-out 0.3s;
}

.primary-button[shape="popup"] {
  background: #239f87;
  color: white;
  border: 1px solid #e1e3e7;
}

.primary-button[shape="popup"]:hover {
  background: #20927c;
  color: white;
}

.primary-button[shape="finish"] {
  background: #00b8be;
  color: white;
  border: 1px solid #e1e3e7;
}

.primary-button[shape="finish"]:hover {
  background: #00a9af;
  color: white;
}

.primary-button[shape="primary"] {
  background: #ffe200;
  color: black;
  border: 1px solid #e1e3e7;
}

.primary-button[shape="primary"]:hover {
  background: #f0d400;
}

.primary-button[shape="secondary"] {
  background: #f5f7fa;
  color: black;
  border: 1px solid #acb0b5;
}

.primary-button[shape="secondary"]:hover {
  background: #ebeff5;
}

.btn-danger {
  border-radius: 4px;
  border: solid 1px #e1e3e7;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.15;
  color: #ffffff;
  background-color: #e60f0f;
}

.button-with-icon {
  background: #f5f7fa;
  border: 1px solid #acb0b5;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #303956;
  transition: all ease-in-out 0.3s;
}

.button-with-icon:hover {
  background: #47b3bb;
  color: #fff;
  transition: all ease-in-out 0.3s;
  border-color: #47b3bb;
}

.button-with-icon:focus {
  outline: none;
}

.button-with-icon svg {
  margin-right: 5px;
}

.secondary-input {
  position: relative;
}

.secondary-input i {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 32px;
  font-style: normal;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
}

.secondary-input .primary-form-control {
  padding-left: 178px;
}

.primary-modal {
  position: absolute;
  border: 0px none;
  background: white none repeat scroll 0% 0%;
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 20px;
  min-width: 420px;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: gray 0px 0px 5px;
}

.primary-modal .batch-accordion .accordion {
  border: 1px solid #eee;
}

.primary-modal.height-default {
  height: auto;
  max-height: calc(100% - 50px);
  min-height: 1px;
}

@media screen and (max-width: 767px) {
  .primary-modal.height-default {
    min-height: 1px;
  }
}

.primary-modal.p-0 {
  padding: 0 !important;
}

.primary-modal.lg {
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  .primary-modal.lg {
    width: 80% !important;
  }
}

.primary-modal.lg .resume-preview {
  width: 100% !important;
}

.primary-modal .scrollable-list {
  overflow: auto;
  height: calc(100% - (40px + 24px));
  margin: 0 -20px;
  padding: 0;
  list-style: none;
}

.primary-modal .scrollable-list li {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.primary-modal .scrollable-list li + li {
  margin-top: 20px;
}

.primary-modal .scrollable-list li p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: #35363b;
}

.primary-modal header p {
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
}

.primary-modal header h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 99;
}

.test-list-data {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-between;
  margin: 0;
  padding: 10px 0;
}

.test-list-data:first-child {
  padding-top: 0;
}

.test-list-data:last-child {
  padding-bottom: 0;
}

.test-list-data + .test-list-data {
  border-top: 1px solid #d9d9d9;
}

.test-list-data li {
  font-size: 13px;
  line-height: 19px;
  color: #666666;
  display: flex;
  align-items: center;
}

.test-list-data li span:first-child {
  width: 300px;
}

.test-list-data li span:nth-child(2) {
  width: 150px;
}

.test-list-data li strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #454746;
}

.test-list-data li a {
  text-decoration: underline;
}

body .editor-data * {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: inherit !important;
  margin: 0 0 1rem !important;
  text-indent: inherit !important;
  color: #000 !important;
  text-align: left !important;
  padding: 0 !important;
  font-size: 20px !important;
  line-height: 160% !important;
}

body .editor-data h1 * {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: inherit !important;
  font-size: 2.827rem !important;
  line-height: 1.5 !important;
  margin: 0 !important;
}

body .editor-data h2 * {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: inherit !important;
  line-height: 1.5 !important;
  font-size: 1.999rem !important;
  margin: 0 !important;
}

body .editor-data h3 *,
body .editor-data h4 * {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: inherit !important;
  font-size: 1.414rem !important;
  line-height: 1.414 !important;
  margin: 0 !important;
}

body .editor-data h5 *,
body .editor-data h6 * {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: inherit !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  font-size: 1rem !important;
  margin: 0 !important;
}

body .editor-data p {
  font-size: 20px !important;
  line-height: 160% !important;
  color: #000 !important;
  margin: 0 0 1rem !important;
}

body .editor-data p * {
  margin: 0 !important;
}

body .editor-data strong {
  color: #000 !important;
  font-weight: 700 !important;
}

body .editor-data strong * {
  margin: 0 !important;
}

body .editor-data table {
  max-width: 100% !important;
  width: 100% !important;
}

body .editor-data ul,
body .editor-data ol {
  display: block !important;
}

body .editor-data ul li,
body .editor-data ol li {
  white-space: normal !important;
  font-size: 20px !important;
  line-height: 160% !important;
  position: relative !important;
  color: #000 !important;
  text-align: left !important;
  margin-bottom: 1rem !important;
}

body .editor-data ul li *,
body .editor-data ol li * {
  margin: 0 !important;
}

body .editor-data img {
  display: block !important;
  margin-bottom: 1rem !important;
  max-width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
}

body .editor-data ul {
  list-style: none !important;
}

body .editor-data ul li {
  padding-left: 24px !important;
}

body .editor-data ul li::before {
  content: "";
  background-image: url("https://educrack.com/static/asset/svg/new/star.svg") !important;
  width: 13px !important;
  height: 14px !important;
  position: absolute !important;
  left: 0 !important;
  top: 9px !important;
  background-color: transparent !important;
  opacity: 1 !important;
  transform: none !important;
}

body .editor-data ol {
  list-style: decimal !important;
  padding-left: 1rem !important;
}

.primary-page-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.primary-page-header button {
  height: 40px;
  border-radius: 4px;
  padding: 0 20px;
  display: block;
  background: #ffe200;
  border: 1px solid #e1e3e7;
}

.primary-page-header button.primary-outine-button {
  border-color: #0B8F75;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  height: 30px;
  line-height: 15px;
  padding: 0 10px !important;
  color: #35363B;
}

.primary-page-header button.primary-outine-button svg {
  color: #0B8F75;
  transition: all ease-in-out .3s;
  font-size: 18px;
}

.primary-page-header button.primary-outine-button:hover {
  background-color: #0B8F75;
  color: #fff;
}

.primary-page-header button.primary-outine-button:hover svg {
  color: #fff;
  transition: all ease-in-out .3s;
}

.primary-page-header button:hover {
  background-color: #f0d400;
}

.primary-page-header + .primary-page-header {
  margin-top: 30px;
}

.primary-page-header h2 {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 32px;
  line-height: 31px;
  text-align: left;
  color: #464343;
}

.primary-page-header ol {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.primary-page-header ol li {
  margin-top: 10px;
}

.primary-page-header ol li svg {
  fill: #919191;
}

.primary-page-header ol li a {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  text-transform: uppercase;
  color: #919191;
}

.primary-page-header ol li a:hover {
  text-decoration: underline;
}

.primary-outine-button {
  border-color: #0B8F75;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  height: 30px;
  line-height: 15px;
  padding: 0 10px !important;
  color: #35363B;
}

.primary-outine-button svg {
  color: #0B8F75;
  transition: all ease-in-out .3s;
  font-size: 18px;
}

.primary-outine-button:hover {
  background-color: #0B8F75;
  color: #fff;
}

.primary-outine-button:hover svg {
  color: #fff;
  transition: all ease-in-out .3s;
}

.primary-tabs ul.tabs-list {
  margin: 0;
  padding: 0;
}

.primary-tabs ul.tabs-list li {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #262424;
}

.primary-tabs ul.tabs-list li + li {
  margin-left: 30px;
}

.primary-tabs ul.tabs-list li.active {
  font-weight: 700;
  border-color: #239f87;
}

.primary-tab-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.primary-table-ui {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfe2e6;
}

.primary-table-ui .pagination {
  padding: 0 30px;
  border-top: 1px solid #e9edf2;
  height: 70px;
}

.primary-table-ui .table-responsive-md table tbody tr td {
  height: 80px;
  padding: 15px 30px;
}

.primary-table-ui .table-responsive-md table tbody tr td .btn-danger {
  width: 90px;
  height: 40px;
}

.primary-table-ui .table-responsive-md table tbody tr td .primary-table-info {
  font-size: 13px;
  color: #239f87;
  margin: 0;
  line-height: 1.69;
}

.primary-table-ui .table-responsive-md table tbody tr td .action-button {
  padding: 0;
}

.primary-table-ui .table-responsive-md table tbody tr td .subject {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #434544;
}

.primary-table-ui .table-responsive-md table tbody tr td .status {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: left;
  color: #239f87;
}

.primary-table-ui .table-responsive-md table thead tr th {
  background: #eaeaea;
  height: 50px;
  font-weight: normal;
  padding: 0 30px;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #303956;
  letter-spacing: normal;
}

.primary-table-ui .table-responsive-md table thead tr th:first-child {
  border-top-left-radius: 8px;
}

.primary-table-ui .table-responsive-md table thead tr th:last-child {
  border-top-right-radius: 8px;
}

.primary-paginated-table--head {
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 17px;
  color: #35363b;
  font-weight: 600;
}

.primary-paginated-table--head .primary-paginated-table--col .Checkbox {
  position: static;
}

.primary-paginated-table--head .primary-paginated-table--col label {
  margin: 0;
}

.primary-paginated-table--body .btn-danger {
  width: 90px;
  height: 40px;
}

.primary-paginated-table--body .primary-table-info {
  font-size: 13px;
  color: #239f87;
  margin: 0;
  line-height: 1.69;
}

.primary-paginated-table--body .action-button {
  padding: 0;
}

.primary-paginated-table--body .primary-text {
  font-size: 12px;
  line-height: 17px;
  word-wrap: break-word;
  color: #35363b;
}

.primary-paginated-table--body .primary-text p {
  font-size: 12px;
  line-height: 17px;
  word-wrap: break-word;
  color: #35363b;
  margin: 0;
}

.primary-paginated-table--body .primary-text img {
  max-width: 100%;
  display: block;
}

.primary-paginated-table--body .primary-text a {
  text-decoration: underline;
}

.primary-paginated-table--body .subject {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #434544;
}

.primary-paginated-table--body .subject.ellipses p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.primary-paginated-table--body .primary-paginated-table--row {
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  min-height: 60px;
  padding: 10px;
}

.primary-paginated-table--body .primary-paginated-table--row + .primary-paginated-table--row {
  margin-top: 5px;
}

.primary-paginated-table--row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
}

.primary-paginated-table--col {
  padding: 0 20px;
}

.primary-paginated-table--col .btn-danger {
  background: #e60f0f;
  border-radius: 4px;
  min-width: 1px;
  border: none;
  width: 65px;
  height: 34px;
}

.primary-paginated-table--col .action-button {
  padding: 0;
  float: none;
}

.primary-paginated-table--col .status {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: left;
}

.primary-paginated-table--col .status.approved {
  color: #239f87;
}

.primary-paginated-table--col .status.open {
  color: #f20808;
}

.primary-paginated-table--col .status.pending {
  color: #d7c622;
}

.primary-paginated-table--col .status.unapproved {
  color: #666666;
}

.primary-pagination {
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
}

.primary-pagination li.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.primary-pagination li.active a {
  border-color: #1ea087;
  pointer-events: none;
}

.primary-pagination li + li {
  margin-left: 11px;
  position: relative;
}

.primary-pagination li:nth-child(2) {
  margin-left: 0;
}

.primary-pagination li:first-child {
  margin-right: 20px;
}

.primary-pagination li:first-child a {
  width: auto;
  height: auto;
}

.primary-pagination li:last-child {
  margin-left: 20px;
}

.primary-pagination li:last-child a {
  width: auto;
  height: auto;
}

.primary-pagination li a {
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35363b;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.primary-pagination li a svg {
  font-size: 16px;
  color: #aaaaaa;
}

.single-col-table.with-action .primary-paginated-table--col {
  width: calc(100% - 30px);
}

.single-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.single-col-table .primary-paginated-table--col {
  width: 100%;
}

.two-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 2);
}

.two-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.two-col-table .primary-paginated-table--col {
  width: calc(100% / 3);
}

.three-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 3);
}

.three-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.three-col-table .primary-paginated-table--col {
  width: calc(100% / 3);
}

.four-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 3);
}

.four-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.four-col-table .primary-paginated-table--col {
  width: calc(100% / 4);
}

.five-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 4);
}

.five-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.five-col-table .primary-paginated-table--col {
  width: calc(100% / 5);
}

.six-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 5);
}

.six-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.six-col-table .primary-paginated-table--col {
  width: calc(100% / 6);
}

.seven-col-table.with-action .primary-paginated-table--col {
  width: calc((100% - 30px) / 6);
}

.seven-col-table.with-action .primary-paginated-table--col:last-child {
  padding: 0;
  width: 30px;
}

.seven-col-table.with-button .primary-paginated-table--col {
  width: calc((100% - 75px) / 6);
}

.seven-col-table.with-button .primary-paginated-table--col:last-child {
  padding: 0 5px;
  width: 75px;
}

.seven-col-table .primary-paginated-table--col {
  width: calc(100% / 7);
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .support-table .primary-paginated-table--col:first-child {
    width: calc(100% - (250px + 170px + 110px + 110px + 60px)) !important;
  }
  .support-table .primary-paginated-table--col:nth-child(3) {
    width: 170px !important;
  }
  .support-table .primary-paginated-table--col:nth-child(4) {
    width: 110px !important;
  }
  .support-table .primary-paginated-table--col:nth-child(5) {
    width: 110px !important;
  }
}

@media screen and (max-width: 1023px) {
  .support-table .primary-paginated-table--row {
    padding: 0;
  }
  .support-table .primary-paginated-table--row + .primary-paginated-table--row {
    margin-top: 15px;
  }
  .support-table .primary-paginated-table--col {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
    padding: 10px;
  }
  .support-table .primary-paginated-table--col::before {
    font-size: 13px;
    font-weight: 600;
  }
  .support-table .primary-paginated-table--col:first-child::before {
    content: 'Ticket Id';
  }
  .support-table .primary-paginated-table--col:nth-child(2)::before {
    content: 'Description';
  }
  .support-table .primary-paginated-table--col:nth-child(3)::before {
    content: 'Type';
  }
  .support-table .primary-paginated-table--col:nth-child(4)::before {
    content: 'Reply';
  }
  .support-table .primary-paginated-table--col:nth-child(5)::before {
    content: 'Duration';
  }
  .support-table .primary-paginated-table--col:last-child::before {
    content: 'Action';
  }
  .support-table .primary-paginated-table--col + .primary-paginated-table--col {
    border-top: 1px solid #eee;
  }
}

.primary-avtar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #fff;
  flex-shrink: 0;
  border: 1px solid #e6e6e6;
  background-repeat: no-repeat;
  background-size: cover;
}

.avtar-with-text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.avtar-with-text .primary-avtar {
  margin-right: 20px;
}

.avtar-with-text .wrap p {
  margin: 0;
}

.avtar-with-text .wrap p.avtar-email {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  word-break: break-word;
  color: #acb0b5;
}

.avtar-with-text .wrap p.avtar-mobile {
  margin-top: 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  word-break: break-word;
  color: #434544;
}

.avtar-with-text .wrap p.avtar-name {
  word-break: break-word;
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #434544;
}

.datepicker-wrap {
  position: relative;
}

.datepicker-wrap .react-daterange-picker {
  display: block;
  width: 100%;
}

.datepicker-wrap .react-daterange-picker__button {
  display: none;
}

.datepicker-wrap .react-daterange-picker__inputGroup {
  flex-grow: 0;
  min-width: 1px;
}

.datepicker-wrap .react-daterange-picker__wrapper {
  height: 36px;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  padding: 4px 15px;
  text-align: left;
  color: #000;
  border: 1px solid #e6e6e6;
}

.datepicker-wrap .react-datepicker-wrapper {
  width: 100%;
  display: block;
}

.datepicker-wrap .react-datepicker-wrapper .primary-form-control {
  padding-right: 40px;
}

.datepicker-wrap svg {
  position: absolute;
  right: 10px;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  color: #239f87;
}

.primary-box-ui-wrap {
  display: flex;
  flex-wrap: wrap;
}

.primary-box-ui-wrap .primary-box-ui {
  margin-right: 20px;
}

.primary-box-ui {
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
}

.primary-box-ui .badge {
  border-radius: 2px;
  background-color: #c4ffd6;
  font-size: 10px;
  padding: 2px 8px;
}

.primary-box-ui > label {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 14px;
  display: block;
  line-height: 25px;
  text-align: left;
  color: #262424;
}

.primary-box-ui .dropzone-wrap {
  display: flex;
  align-items: center;
}

.primary-box-ui .dropzone-wrap .wrap {
  flex-shrink: 0;
}

.primary-box-ui .dropzone-wrap .wrap + .wrap {
  margin-left: 15px;
}

.primary-box-ui .dropzone-wrap .wrap > label {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 14px;
  display: block;
  line-height: 25px;
  width: 100%;
  text-align: left;
  color: #262424;
}

.primary-box-ui .show-img {
  border-radius: 6px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.primary-box-ui .show-img > img {
  max-width: 100%;
  max-height: 135px;
}

.primary-box-ui h4 {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
  color: #000;
}

.primary-box-ui .edit-icon {
  border: solid 1px #b9bcca;
  background-color: #ffffff;
  outline: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 19px;
  color: #b9bcca;
  transition: all ease-in-out 0.3s;
}

.primary-box-ui .edit-icon svg {
  transition: all ease-in-out 0.3s;
}

.primary-box-ui .edit-icon:hover {
  background-color: #b9bcca;
  transition: all ease-in-out 0.3s;
}

.primary-box-ui .edit-icon:hover svg {
  fill: #ffffff;
  transition: all ease-in-out 0.3s;
}

.primary-text-group {
  margin-bottom: 9px;
}

.primary-text-group p {
  margin: 0 0 4px;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: normal;
  text-align: left;
  color: #919191;
}

.primary-text-group span {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: normal;
  word-break: break-word;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  color: #000;
}

.primary-image-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.primary-image-list div {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #f4f4f4;
}

.primary-image-list div + div {
  margin-left: 20px;
}

.primary-image-list div img {
  max-width: 100%;
}

.inline-upload-box-ui .image-box {
  width: 100%;
  outline: none;
}

.inline-upload-box-ui .image-box label {
  margin: 0;
  outline: none;
}

.custom-file {
  display: block !important;
  height: 36px !important;
}

.custom-file .custom-file-label {
  margin: 0;
  box-shadow: none;
  height: 38px;
  border: 1px solid #e1e1e1;
  font-weight: normal;
  color: #000;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: none;
  display: flex;
  align-items: center;
}

.custom-file .custom-file-label:after {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 80px;
  background: transparent;
  border: none;
  color: #000;
  font-weight: 600;
}

.primary-helper-text {
  color: grey;
  margin-top: 3px;
  font-size: 12px;
}

.file-upload-with-helpertext {
  margin-bottom: 15px;
}

.file-upload-with-helpertext .form-group {
  margin-bottom: 0;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination li:first-child a {
  padding-left: 0;
}

.pagination li:last-child a {
  padding-right: 0;
}

.pagination li a {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  padding: 0 8px;
  color: #303956;
}

.pagination li a:hover {
  background-color: transparent;
}

.pagination li a span {
  display: block;
  line-height: 15px;
}

.custom-control {
  z-index: auto;
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #239f87;
  background-color: #239f87;
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before:focus-within .custom-control-label::before {
  box-shadow: 0 0 0 1px #239f87;
}

.custom-control.custom-checkbox label.custom-control-label {
  color: #7f8381;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-color: #239f87;
  line-height: 1.5rem;
}

.custom-control.custom-checkbox label.custom-control-label::before {
  box-shadow: 0 0 0 1px #239f87;
  border-radius: 3px;
}

.secondary-checkbox--wrap {
  display: flex;
  align-items: flex-start;
}

.secondary-checkbox--wrap .Checkbox {
  top: 0;
}

.secondary-checkbox--wrap label {
  font-size: 14px;
  line-height: 1.79;
  margin: 0 0 0 8px;
  color: #000000;
}

.primary-form-control {
  height: 40px;
  border-radius: 6px;
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  display: block;
  width: 100%;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 11px;
  text-align: left;
  color: #111111;
  border: 1px solid #c4cdd5 !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}

.primary-form-control:focus {
  border-color: #239f87 !important;
  box-shadow: none !important;
}

.primary-form-control:disabled {
  opacity: 0.55;
}

textarea.primary-form-control {
  height: auto;
  resize: none;
}

.input-group {
  position: relative;
  border: none;
}

.input-group .input-group-text {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: none;
}

.input-group .input-group-text svg {
  fill: #acb0b5;
}

.input-group .input-group-append {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input-group .input-group-prepend {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input-group .primary-form-control.form-control-prepended {
  padding-left: 40px !important;
  border-radius: 6px !important;
}

.input-group .primary-form-control.form-control-appended {
  padding-right: 40px !important;
  border-radius: 6px !important;
}

.form-group {
  margin-bottom: 15px;
}

.custom-ReactSelect > div > div,
.custom-AsyncSelect > div > div {
  min-height: 40px;
  border-radius: 6px;
  border: 1px solid #c4cdd5 !important;
  box-sizing: border-box;
  box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
  background: linear-gradient(180deg, #fff 0, #f9fafb 100%);
}

.custom-ReactSelect > div > div > div:first-child,
.custom-AsyncSelect > div > div > div:first-child {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
}

.custom-ReactSelect > div > div > div + div > div,
.custom-AsyncSelect > div > div > div + div > div {
  padding: 7px 8px;
}

.custom-ReactSelect > div > div > div + div > span,
.custom-AsyncSelect > div > div > div + div > span {
  display: none;
}

.simple-file-upload {
  height: 36px;
}

.simple-file-upload label {
  margin: 0;
  box-shadow: none;
  border: 1px solid #e1e1e1;
  height: 100%;
  padding: 0 0 0 0.75rem;
  border-radius: 0.25rem;
  /* cursor: pointer; */
  background-color: #fff;
  display: flex;
  align-items: center;
}

.simple-file-upload label p {
  width: calc(100% - 80px);
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #000;
  line-height: 25px;
}

.simple-file-upload label span {
  padding: 0;
  width: 80px;
  text-align: center;
  font-size: 14px;
  border: none;
  color: #000;
  font-weight: 600;
}

.simple-file-upload label input {
  display: none;
}

.file-uploading {
  margin-right: 5px;
  margin-top: 10px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  display: flex;
  line-height: 1;
  background-color: #fff;
  align-items: center;
  padding-right: 30px;
}

.file-uploading .remove-icon {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d7dd;
  cursor: pointer;
}

.file-uploading .icon {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}

.file-uploading p {
  margin: 0 0 5px;
  width: 100%;
  font-size: 14px;
}

.file-uploading small {
  display: block;
}

.days-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.days-info--row {
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  color: #48506a;
  display: flex;
  align-items: center;
}

.days-info--row:not(:first-child) {
  margin-left: 16px;
}

.days-info--row span {
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.slot-timings {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}

.slot-timings .days-slot {
  display: flex;
  align-items: center;
}

.slot-timings .days-slot .outlined-badge {
  width: 60px;
  height: 20px;
}

.slot-timings .days-slot label {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  min-width: calc(68px + 19px);
  color: #000000;
}

.batch-sections {
  padding: 8px 0;
}

.batch-sections + .batch-sections {
  border-top: solid 1px #e6e6e6;
}

.primary-page-header {
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 20px;
}

.primary-page-header h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  margin: 0;
  color: #111111;
}

.primary-page-header h3 + p {
  margin: 8px 0 0;
}

@media screen and (max-width: 767px) {
  .primary-page-header h3 + p {
    margin-top: 3px;
  }
}

@media screen and (max-width: 767px) {
  .primary-page-header h3 {
    font-size: 20px;
    line-height: normal;
  }
}

.primary-page-header p {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0;
  color: #666666;
}

.primary-page-header a {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #00a689;
}

.custom-thumbnail {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  min-height: 305px;
  padding: 8px 8px 20px;
}

.custom-thumbnail h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
  color: #111111;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.custom-thumbnail p {
  font-size: 12px;
  line-height: 18px;
  margin: 12px 0 0;
  display: flex;
  align-items: center;
  color: #666666;
}

.custom-thumbnail p svg {
  margin-right: 10px;
  width: 12px;
  stroke: #00a689;
  color: #00a689;
  height: 12px;
}

.custom-thumbnail ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.custom-thumbnail ul li {
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.custom-thumbnail ul li + li {
  margin-left: 20px;
  position: relative;
}

.custom-thumbnail ul li + li::before {
  content: "";
  background-image: url("https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
  width: 4px;
  height: 7px;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-thumbnail > span {
  display: flex;
  height: 165px;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border-radius: 16px;
  overflow: hidden;
}

.custom-thumbnail > span.subscribed::before {
  content: "";
  background-image: url(../../dist/images/batch/subscribed.svg);
  width: 64px;
  height: 64px;
  position: absolute;
  left: 0;
  top: 0;
}

.custom-thumbnail > span img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.primary-breacrumbs {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

.primary-breacrumbs li:last-child {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #00a689;
}

.primary-breacrumbs li + li {
  margin-left: 20px;
  position: relative;
}

.primary-breacrumbs li + li:nth-child(2) {
  margin-left: 10px;
}

.primary-breacrumbs li + li:not(:nth-child(2)):before {
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  height: 7px;
  width: 4px;
  background-image: url("https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
  content: "";
}

.primary-breacrumbs li a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #6f6f6f;
  display: flex;
  align-items: center;
}

.primary-breacrumbs li a.active {
  color: #00a689;
  font-weight: bold;
}

.primary-breacrumbs li a .back {
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
}

.custom-nav-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-nav-tabs p {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin: 0;
  color: #00a689;
}

.custom-nav-tabs button {
  width: 104px;
  height: 40px;
  min-width: 1px;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00a689;
  box-shadow: 0px 8px 16px rgba(0, 166, 137, 0.32);
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.custom-nav-tabs .form-group {
  margin-bottom: 0;
  width: 152px;
}

.custom-nav-tabs ul {
  display: flex;
  margin: 0;
  padding: 0 16px;
  list-style: none;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.custom-nav-tabs ul li {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 26px;
  font-size: 16px;
  line-height: 23px;
  color: #111111;
  position: relative;
  cursor: pointer;
}

.custom-nav-tabs ul li.active {
  font-weight: bold;
  color: #00a689;
  pointer-events: none;
}

.custom-nav-tabs ul li.active::before {
  content: "";
  width: 100%;
  background: #00a689;
  border-radius: 2px 2px 0px 0px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.custom-nav-tabs ul li.filter {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #00a689, #00a689);
  border-radius: 8px;
}

.custom-nav-tabs ul li.filter svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

.batch-detail-wrap .wrap-content .subject {
  background: #ffffff;
  margin-top: 20px;
  padding: 20px 28px 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-bottom: 108px;
}

.batch-detail-wrap .wrap-content .subject .view-all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}

.batch-detail-wrap .wrap-content .subject .view-all a {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #00a689;
}

.batch-detail-wrap .wrap-content .subject h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #111111;
  margin: 0 0 11px;
}

.batch-detail-wrap .wrap-content .subject p {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0;
  color: #2a2a2a;
}

.batch-detail-wrap .wrap-content .subject p + p {
  margin-top: 20px;
}

.batch-detail-wrap .wrap-content .subject p span {
  margin-left: 10px;
  font-size: 14px;
  line-height: 14px;
  color: #6f6f6f;
}

.batch-detail-wrap .wrap-content .overview {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.batch-detail-wrap .wrap-content .overview .wrap {
  display: flex;
  padding: 16px;
}

.batch-detail-wrap .wrap-content .overview .wrap .img img {
  border-radius: 4px;
}

.batch-detail-wrap .wrap-content .overview .wrap p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  color: #666666;
}

.batch-detail-wrap .wrap-content .overview .wrap .course-intro-view {
  display: flex;
  margin-top: 18px;
}

.batch-detail-wrap .wrap-content .overview .wrap .course-intro-view p {
  display: flex;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  color: #00a689;
  align-items: center;
}

.batch-detail-wrap .wrap-content .overview .wrap .course-intro-view p img {
  margin-right: 16px;
}

.batch-detail-wrap .wrap-content .overview .wrap .desc {
  padding-left: 32px;
}

.batch-detail-wrap .wrap-content .overview .wrap .desc h3 {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  line-height: 32px;
  color: #111111;
}

.batch-detail-wrap .wrap-content .overview .wrap .desc h3 + span {
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: #aaaaaa;
}

.batch-detail-wrap .wrap-content .overview--footer {
  border-top: 1px solid #e2e8f0;
  padding: 15px 40px;
}

.batch-detail-wrap .wrap-content .overview--footer ul {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.batch-detail-wrap .wrap-content .overview--footer ul li {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #111111;
}

.batch-detail-wrap .wrap-content .overview--footer ul li img {
  margin-right: 4px;
}

.our-mentors-ui .sub-heading {
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #263238;
  margin-bottom: 59px;
}

.our-mentors-ui .desc {
  margin: 0;
  padding: 26px 23px 38px 23px;
  font-size: 14px;
  line-height: 20px;
  color: #6f6f6f;
}

.our-mentors-ui .bar {
  background: #f2f6f9;
  display: flex;
}

.our-mentors-ui .bar .section {
  width: 50%;
  padding: 2px 0 3px 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #02c6a2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-mentors-ui .bar .section svg {
  margin-right: 8.5px;
}

.our-mentors-ui ul {
  display: flex;
  margin: 0 -15px;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}

.our-mentors-ui ul.three-col li {
  width: calc(100% / 3 - 30px);
}

.our-mentors-ui ul li {
  width: calc(100% / 4 - 30px);
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #e2e9f0;
  box-sizing: border-box;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  text-align: center;
}

.our-mentors-ui ul li .info {
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin: 0 0 16px;
}

.our-mentors-ui ul li .info .name {
  display: block;
  font-weight: bold;
}

.our-mentors-ui ul li .img {
  padding-top: 30px;
  padding-bottom: 11px;
}

.our-mentors-ui ul li .img img {
  background-color: #e8e8e8;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: contain;
}

.course-schedule ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 0 15px;
  padding: 14px 0 0;
}

.course-schedule ul li {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #2a2a2a;
}

.course-schedule ul li + li {
  margin-left: 23px;
}

.course-schedule img {
  margin: 0 auto;
  display: block;
}

.batch-accordion + .batch-accordion {
  margin-top: 10px;
}

.batch-accordion h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 12px;
  color: #2a2a2a;
}

.batch-accordion .accordion {
  margin-bottom: 0;
}

.batch-accordion .accordion + .accordion {
  margin-top: 10px;
}

.batch-accordion .accordion .card, .batch-accordion .accordion .card-nav .tab-content, .card-nav .batch-accordion .accordion .tab-content {
  margin: 0;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: none;
  border: none;
}

.batch-accordion .accordion .card-body {
  background-color: #ffffff;
  padding: 0 9px 0;
}

.batch-accordion .accordion .card-body .test-wrap {
  background: #f5f9ff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 16px 0;
}

.batch-accordion .accordion .card-body .test-wrap .test-card {
  width: calc((100% - 48px) / 4);
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 12px;
  text-align: center;
}

.batch-accordion .accordion .card-body .test-wrap .test-card a {
  background: #a9eadf;
  border-radius: 0px 0px 12px 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #097461;
}

.batch-accordion .accordion .card-body .test-wrap .test-card a.view-report {
  background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
  color: #000000;
  text-decoration: underline;
  font-weight: normal;
}

.batch-accordion .accordion .card-body .test-wrap .test-card .wrap {
  padding: 23px 10px 16px;
}

.batch-accordion .accordion .card-body .test-wrap .test-card .wrap h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 50px;
  color: #111111;
}

.batch-accordion .accordion .card-body .test-wrap .test-card .wrap p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.batch-accordion .accordion .card-body .test-wrap .test-card .wrap p span {
  color: #00a689;
}

.batch-accordion .accordion .card-body .lesson-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper {
  width: calc(50% - 4px);
  margin-bottom: 26px;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper h4 {
  height: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  background: #e2e9f0;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #2a2a2a;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper ul {
  margin: 0;
  list-style: none;
  padding: 0 20px;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper ul li {
  display: flex;
  align-items: center;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper ul li + li {
  margin-top: 10px;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper ul li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f9ff;
  width: 53px;
  height: 37px;
}

.batch-accordion .accordion .card-body .lesson-wrap .wrapper ul li p {
  margin: 0;
  padding-left: 12px;
  width: calc(100% - 53px);
  display: flex;
  align-items: center;
}

.batch-accordion .accordion .card-header {
  display: block;
  padding: 17px 17px;
  margin: 0;
  border: none;
  cursor: pointer;
  position: relative;
}

.batch-accordion .accordion .card-header.active p {
  color: #111111;
}

.batch-accordion .accordion .card-header.active::after {
  transform: translateY(-50%) rotate(90deg);
}

.batch-accordion .accordion .card-header.active ul li {
  font-weight: 600;
  color: #00a689;
}

.batch-accordion .accordion .card-header.active::before {
  content: "";
  width: 3px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: #00a689;
  border-radius: 0px 2px 2px 0px;
}

.batch-accordion .accordion .card-header p {
  font-size: 16px;
  line-height: 23px;
  margin: 0;
  color: #838383;
}

@media screen and (max-width: 767px) {
  .batch-accordion .accordion .card-header p {
    font-size: 12px;
    line-height: 1.5;
  }
}

.batch-accordion .accordion .card-header > ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.batch-accordion .accordion .card-header > ul li {
  font-size: 16px;
  line-height: 1;
  color: #707070;
}

.batch-accordion .accordion .card-header > ul li + li {
  margin-left: 23px;
  position: relative;
}

.batch-accordion .accordion .card-header > ul li + li:before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #000;
  position: absolute;
  left: -11px;
}

.batch-accordion .accordion .card-header::after {
  content: "";
  background-image: url("../../dist/images/batch/right.svg");
  width: 8px;
  height: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.resource {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
}

.resource--row {
  display: flex;
  flex-wrap: wrap;
}

.resource--row .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f9ff;
  width: 53px;
  height: 37px;
}

.resource--row p {
  margin: 0;
  padding-left: 12px;
  width: calc(100% - 53px);
  display: flex;
  align-items: center;
}

.resource--row p a {
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}

.resource--row p a:hover {
  color: #00a689;
}

.resource--row + .resource--row {
  margin-top: 10px;
}

.advertisement-block {
  padding: 25px;
  flex-grow: 0 !important;
  width: 280px;
  display: none;
}

.advertisement-block .advertisement-banner {
  height: 378px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
}

.advertisement-block .advertisement-banner + .advertisement-banner {
  margin-top: 16px;
}

.advertisement-block .advertisement-banner p {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #1458ea;
  margin: 0;
}

.rewards-points {
  height: 200px;
  background-size: cover;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  background-image: linear-gradient(137.27deg, #1feec6 12.53%, #22cfae 28.83%, #10bc9b 47.03%, #239f87 65.13%, #108770 81.44%, #076040 100%);
  border-radius: 8px;
}

.rewards-points .wrap {
  position: relative;
  z-index: 2;
}

.rewards-points .wrap p {
  font-size: 18px;
  line-height: 18px;
  margin: 0 0 5px;
  color: #ffffff;
}

.rewards-points .wrap span {
  display: block;
  font-weight: bold;
  font-size: 40px;
  line-height: 36px;
  letter-spacing: 0.16em;
  color: #ffffff;
}

.rewards-points .wrap button {
  width: 88px;
  height: 32px;
  border: none;
  background: #ffffff;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 18px;
  text-align: center;
  color: #239f87;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.rewards-points::before {
  content: "";
  background-image: url("../../dist/images/batch/rewards-bg.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.primary-filter {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.primary-filter form {
  display: flex;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .primary-filter form {
    flex-wrap: wrap;
  }
}

.primary-filter .form-group {
  margin: 0;
  margin-right: 0;
  width: 100%;
}

.primary-filter .form-group + .form-group {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .primary-filter .form-group + .form-group {
    margin-top: 10px;
    margin-left: 0;
  }
}

.primary-filter .filter-icon {
  width: 37px;
  height: 37px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 8px;
}

.primary-filter .filter-icon svg {
  width: 19px;
  fill: #6f6f6f;
  height: 19px;
}

.mentor-details {
  margin: 25px 0;
}

.mentor-details p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #6f6f6f;
}

.mentor-details p + p {
  margin-top: 1rem;
}

.mentor-details--header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.mentor-details--header .mentor-icon {
  padding: 0 40px;
}

.mentor-details--header .mentor-icon img {
  width: 155px;
  object-fit: contain;
  height: 155px;
  border-radius: 50%;
  background-color: #eee;
}

.mentor-details--header .mentor-data h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  margin: 0 0 6px;
  color: #000000;
}

.mentor-details--header .mentor-data ul {
  list-style: none;
  margin: 48px 0 0;
  padding: 0;
  display: flex;
}

.mentor-details--header .mentor-data ul li {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #2a2a2a;
}

.mentor-details--header .mentor-data ul li + li {
  margin-left: 20px;
}

.mentor-details--header .mentor-data ul li.video-link {
  font-weight: 600;
  font-size: 14px;
  margin-left: 45px;
  line-height: 20px;
  text-decoration: underline;
  color: #263238;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  background: #e2e9f0;
  border-radius: 8px;
  transition: all ease-in-out 0.3s;
}

.mentor-details--header .mentor-data ul li.video-link:hover {
  transition: all ease-in-out 0.3s;
  background-color: #d1dce7;
}

.mentor-details--header .mentor-data ul li.video-link img {
  margin-right: 20px;
}

.mentor-details--header .mentor-data ul li strong {
  width: 118px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6e6e6;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #6f6f6f;
}

.mentor-details--header .mentor-data span {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #6f6f6f;
}

.mentor-details--header .mentor-data span + span {
  margin-top: 10px;
}

.primary-sidebar {
  width: 248px;
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.primary-sidebar ul {
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.primary-sidebar ul li {
  margin-bottom: 8px;
}

.primary-sidebar ul li.active a {
  background: #ffe200;
  box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
}

.primary-sidebar ul li a {
  display: flex;
  padding: 12px 16px;
  border-radius: 50px;
  align-items: center;
}

.primary-sidebar ul li a img {
  margin-right: 12px;
}

.transactions {
  margin-bottom: 30px;
}

.transactions h3 {
  font-size: 20px;
  line-height: 18px;
  margin: 0 0 17px;
  color: #2a2a2a;
}

.transactions--row {
  display: flex;
  padding: 14px 14px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.transactions--row + .transactions--row {
  margin-top: 10px;
}

.transactions--row .wrap p {
  font-size: 16px;
  line-height: 16px;
  color: #2a2a2a;
}

.transactions--row .wrap span {
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: #6f6f6f;
}

.transactions--row p {
  margin: 0;
  display: flex;
  align-items: center;
}

.transactions--row > p {
  font-size: 16px;
  line-height: 18px;
  color: #2a2a2a;
}

.transactions--row > p.paytm-price {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #2a2a2a;
}

.transactions--row > p.price {
  font-size: 26px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #239f87;
}

.transactions--row > p span {
  margin-left: 2px;
  font-size: 12px;
}

.transactions--row > p img {
  margin-right: 3px;
}

.referral-code {
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  padding: 25px 20px 0;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.referral-code img {
  display: block;
  margin: 0 auto;
}

.reward-banner {
  background: #ffffff;
  margin-bottom: 30px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.reward-banner .wrap button {
  width: 149px;
  height: 35px;
  margin-top: 6px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  background: #239f87;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
}

.reward-banner .wrap button:hover {
  background-color: #1e8a75;
  transition: all ease-in-out 0.3s;
}

.reward-banner .wrap p {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #2a2a2a;
  margin: 10px 0 0;
}

.reward-banner .wrap h4 {
  font-size: 50px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #2a2a2a;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reward-banner .wrap h4 span {
  margin-left: 2px;
  font-weight: normal;
  font-size: 20px;
}

.reward-banner .image {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 186.67px;
  position: relative;
}

.reward-banner .image img {
  position: relative;
}

.reward-banner .image::before {
  content: "";
  background: url("../../dist/images/batch/reward-bg.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.link-paytm {
  margin-top: 40px;
}

.link-paytm .wrap {
  width: 342px;
  margin: 0 auto;
}

.link-paytm .wrap p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
  text-align: center;
  color: #6f6f6f;
}

.link-paytm .wrap .link-box {
  width: 342px;
  height: 61px;
  padding: 0 20px;
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.link-paytm .wrap .link-box button {
  width: 69px;
  height: 35px;
  border: none;
  background: #239f87;
  border-radius: 4px;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  transition: all ease-in-out 0.3s;
}

.link-paytm .wrap .link-box button:hover {
  background-color: #1e8a75;
  transition: all ease-in-out 0.3s;
}

.link-paytm .wrap .link-box span {
  font-size: 16px;
  line-height: 20px;
  color: #6f6f6f;
}

.link-paytm .wrap .link-box input {
  padding: 0 16px;
  width: calc(100% - (69px + 24px));
  border: none;
  font-size: 20px;
  line-height: 20px;
  color: #6f6f6f;
}

.link-paytm .wrap .link-box input::placeholder {
  color: #b6b6b6;
}

.link-paytm .wrap img {
  display: block;
  margin: 0 auto 13px;
}

.redeem-button {
  display: flex;
  justify-content: flex-end;
}

.redeem-button button {
  width: 179px;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  height: 35px;
  background: #239f87;
  border-radius: 4px;
  border: none;
  transition: all ease-in-out 0.3s;
}

.redeem-button button:hover {
  background-color: #1e8a75;
  transition: all ease-in-out 0.3s;
}

.statistics {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px 24px;
}

.statistics h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  line-height: 36px;
  color: #2a2a2a;
}

.statistics img {
  display: block;
  margin-top: 10px;
}

.assignment {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px;
}

.assignment--row + .assignment--row {
  margin-top: 24px;
}

.assignment--row > p {
  font-size: 12px;
  line-height: 32px;
  margin: 0;
  color: #6f6f6f;
  opacity: 0.85;
}

.assignment--row ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.assignment--row ul li {
  width: calc(50% - 4px);
  background: #f5f9ff;
  border: 0.5px solid #e0e6ee;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding-left: 9px;
  padding-right: 16px;
  cursor: pointer;
  padding-top: 14px;
  padding-bottom: 14px;
}

.assignment--row ul li .wrap {
  flex-grow: 1;
  padding-left: 25px;
  position: relative;
}

.assignment--row ul li .wrap svg {
  width: 18px;
  height: 18px;
  color: #00a689;
  position: absolute;
  left: 0;
  top: 0;
}

.assignment--row ul li .wrap h3 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

.assignment--row ul li .wrap > span {
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  display: block;
  color: #000000;
}

.assignment--row ul li .wrap p {
  display: flex;
  margin: 0;
  align-items: center;
}

.assignment--row ul li .wrap p span {
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}

.assignment--row ul li .wrap p span + span {
  margin-left: 20px;
  position: relative;
}

.assignment--row ul li .wrap p span + span::before {
  content: "•";
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  left: -11px;
  color: #666666;
}

.assignment--row ul li > svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: #aaaaaa;
}

.assigned-test-box {
  margin-top: 40px;
}

.assigned-test-box h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #00a689;
  margin: 0 0 17px;
}

.primary-batch-card {
  border-radius: 10px;
  background: #fff;
  margin-bottom: 30px;
  transition: all ease-in-out 0.3s;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  padding: 8px;
}

.primary-batch-card:hover {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 0.3s;
  border-color: transparent;
}

.primary-batch-card--header {
  height: 136px;
  position: relative;
}

.primary-batch-card--header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.primary-batch-card--header p {
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  padding: 4px 5px;
  color: #343434;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.primary-batch-card--info {
  border-top: 1px solid #e9e9e9;
  margin: 0 -8px;
  padding: 8px 0 0 12px;
}

.primary-batch-card--info > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  line-height: 1;
  min-height: 47px;
}

.primary-batch-card--info > ul > li {
  padding: 4px 6px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: #000000;
}

.primary-batch-card--info > ul > li.ml-auto > div {
  padding: 0;
}

.primary-batch-card--info > ul > li.edit-icon {
  margin-left: auto;
  cursor: pointer;
  padding: 0;
  background: #fff;
  color: #239f87;
  font-size: 16px;
}

.primary-batch-card--info > ul > li + li {
  margin-left: 10px;
}

.primary-batch-card--body {
  padding: 0;
}

.primary-batch-card--body h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #111111;
}

.primary-batch-card--body ul {
  margin: 0;
  padding: 8px 0 14px;
  list-style: none;
}

.primary-batch-card--body ul li {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #666666;
}

.primary-batch-card--body ul li + li {
  margin-top: 6px;
}

.primary-batch-card--body ul li svg {
  color: #00a689;
  margin-right: 8px;
}

.primary-batch-card--body p {
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.primary-batch-card--body p span {
  margin: 0 7px;
}

.primary-batch-card--body p + p {
  margin-top: 2px;
}

.result-container .primary-batch-tabs {
  justify-content: center;
  position: sticky;
  top: 71px;
  z-index: 1;
  background: white;
  padding: 10px 0;
}

.primary-batch-tabs {
  list-style: none;
  margin: 0 0 23px;
  padding: 0;
  display: flex;
  align-items: center;
}

.primary-batch-tabs--content .free-resource .primary-button {
  height: 36px;
  min-width: 1px;
  margin-top: 19px;
  padding: 0;
  width: 73px;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap .date {
  width: 60px;
  display: flex;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap .date > p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap .date > p span {
  font-size: 14px;
  color: #48506a;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap ul {
  list-style: none;
  margin: 0 -10px;
  width: calc(100% - 50px);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap ul li {
  background: #fff;
  width: calc((100% - 40px) / 4);
  margin: 0 5px 10px;
  padding: 10px 20px;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap ul li p {
  margin: 0;
  font-size: 12px;
  color: #000;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap ul li p a {
  text-decoration: underline;
  color: #01b96b;
}

.primary-batch-tabs--content .assign-teacher-subject .wrap ul li p + p {
  margin-top: 8px;
}

.primary-batch-tabs--content .day-wise-slot {
  margin-top: 25px;
}

.primary-batch-tabs--content .day-wise-slot .wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.primary-batch-tabs--content .day-wise-slot .wrap .day {
  width: 120px;
}

.primary-batch-tabs--content .day-wise-slot .wrap .custom-control.custom-checkbox label.custom-control-label {
  font-size: 14px;
  text-transform: none;
  color: #000;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul {
  list-style: none;
  margin: 0 0 0 30px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul li {
  display: flex;
  align-items: center;
  padding: 15px 13px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #e6e6e6;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul li + li {
  margin-left: 10px;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul li svg {
  cursor: pointer;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul li p {
  margin: 0;
  font-size: 14px;
  color: #000;
}

.primary-batch-tabs--content .day-wise-slot .wrap ul li svg {
  margin-left: 20px;
}

.primary-batch-tabs--content .day-wise-slot .wrap .add-slot {
  width: 60px;
  cursor: pointer;
  height: 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #239f87;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.primary-batch-tabs--content .day-wise-slot .wrap .add-slot:hover {
  background-color: #239f87;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.primary-batch-tabs--content .day-wise-slot .wrap .add-slot.disbaled {
  opacity: 0.22;
  pointer-events: none;
}

.primary-batch-tabs--content .batch-total-info {
  display: flex;
  align-items: center;
  margin: 33px 0 0;
  padding: 0;
  list-style: none;
}

.primary-batch-tabs--content .batch-total-info li {
  font-size: 13px;
  line-height: 1;
  color: #48506a;
}

.primary-batch-tabs--content .batch-total-info li + li {
  margin-left: 15px;
}

.primary-batch-tabs--content .batch-total-info li strong {
  font-weight: 600;
  font-size: 16px;
}

.primary-batch-tabs--content .topic-box {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
  padding: 0;
}

.primary-batch-tabs--content .topic-box li {
  width: calc((100% - 60px) / 3);
  display: flex;
  flex-direction: column;
  min-height: 115px;
  border-radius: 6px;
  background: #fff;
  padding: 10px 20px;
  margin: 0 10px 20px;
  border: 1px solid #e6e6e6;
}

.primary-batch-tabs--content .topic-box li svg {
  margin-left: auto;
  display: block;
  cursor: pointer;
  margin-top: 5px;
}

.primary-batch-tabs--content .topic-box li h3 {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  flex-grow: 1;
}

.primary-batch-tabs--content .topic-box li p {
  margin: 0 0 3px;
  font-size: 12px;
  line-height: 22px;
  color: #919191;
}

.primary-batch-tabs li {
  user-select: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  display: block;
  cursor: pointer;
  color: #35363b;
  height: 36px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0 15px;
}

.primary-batch-tabs li.active {
  background: #f5f7fa;
  border: 1px solid #1ea087;
  color: #1ea087;
  pointer-events: none;
  position: relative;
}

.primary-batch-tabs li a {
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: block;
  cursor: pointer;
  color: #6f6f6f;
}

.primary-batch-tabs li a::before {
  content: "";
  height: 2px;
  background: transparent;
  position: absolute;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  transform-origin: center center;
  left: 0;
  bottom: -4px;
  width: 100%;
}

.primary-batch-tabs li a.active {
  color: #00a689;
  pointer-events: none;
  position: relative;
}

.primary-batch-tabs li a.active::before {
  transition: all 0.3s ease-in-out;
  background: #00a689;
  transform: scale(1);
}

.batch-sections {
  margin-bottom: 30px;
  border-top: none !important;
}

.batch-sections h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
  color: #35363b;
}

.batch-sections--row {
  display: flex;
  align-items: center;
}

.batch-sections--col {
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #35363b;
}

.batch-sections--col p {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 1;
}

.batch-sections--col p svg {
  font-size: 20px;
  margin-left: 5px;
  color: #c4c4c4;
  cursor: pointer;
}

.batch-sections--col p a {
  text-decoration: underline;
  font-weight: normal;
  color: #35363b;
}

.batch-sections--col:first-child {
  width: 300px;
}

.batch-sections--col:last-child {
  width: calc(100% - 300px);
}

.batch-sections--head {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 7px;
}

.batch-sections--head .batch-sections--col {
  font-weight: bold;
  color: #35363b;
  font-size: 12px;
  line-height: 17px;
}

.batch-sections--body .batch-sections--row {
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.batch-sections--body .batch-sections--row .custom-control.custom-checkbox label.custom-control-label {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  color: #35363b;
}

.batch-sections--body .batch-sections--row .custom-control {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.batch-sections--body .batch-sections--row .custom-control .custom-control-input {
  display: none;
}

.resource-list {
  margin: 40px 0;
}

.resource-list--head .resource-list--col {
  font-size: 13px;
  color: #48506a;
}

.resource-list--body {
  padding-top: 14px;
}

.resource-list--row {
  display: flex;
  align-items: center;
}

.resource-list--row + .resource-list--row {
  margin-top: 35px;
}

.resource-list--col {
  font-size: 14px;
  color: #000;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.resource-list--col strong {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
}

.resource-list--col a {
  font-size: 14px;
  color: #239f87;
}

.resource-list--col img + svg {
  margin-left: 40px;
}

.resource-list--col svg + svg {
  margin-left: 40px;
}

.resource-list--col:first-child {
  width: calc(94px + 99px);
  flex-grow: 0;
}

.topic-list-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 265px;
  overflow: auto;
}

.topic-list-wrap li {
  padding: 10px 5px;
  border-bottom: 1px solid #eee;
}

.free-resources > p {
  font-size: 14px;
  line-height: 20px;
  color: #35363b;
}

.free-resources ul {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}

.free-resources ul li {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 20px;
}

.free-resources ul li + li {
  margin-top: 10px;
}

.free-resources ul li svg {
  font-size: 16px;
  cursor: pointer;
  color: #c4c4c4;
}

.free-resources ul li p {
  font-size: 16px;
  width: calc(100% - 16px);
  line-height: 23px;
  color: #111111;
  padding-left: 25px;
  padding-right: 15px;
  margin: 0;
  position: relative;
}

.free-resources ul li p svg {
  color: #1ea087;
  position: absolute;
  left: 0;
  top: 4px;
}

.primary-batch-tabs--content .course-box {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}

.primary-batch-tabs--content .course-box .course-detail {
  width: calc(100% - 200px);
  padding-left: 25px;
}

.primary-batch-tabs--content .course-box .course-detail .details {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.primary-batch-tabs--content .course-box .course-detail .details p {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #c2b5b5;
  margin-right: 20px;
}

.primary-batch-tabs--content .course-box .course-detail .details p svg {
  color: #00a689;
  margin-right: 8px;
}

.primary-batch-tabs--content .course-box .course-detail .details a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00a689;
}

.primary-batch-tabs--content .course-box .course-detail .details a .play-icon {
  width: 34px;
  height: 34px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50%;
  justify-content: center;
  background: #00a689;
}

.primary-batch-tabs--content .course-box .course-detail .details a .play-icon::before, .primary-batch-tabs--content .course-box .course-detail .details a .play-icon::after {
  content: "";
  position: absolute;
}

.primary-batch-tabs--content .course-box .course-detail .details a .play-icon::before {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #00a689;
  opacity: 0.4;
}

.primary-batch-tabs--content .course-box .course-detail .details a .play-icon:after {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: #00a689;
  opacity: 0.16;
}

.primary-batch-tabs--content .course-box .course-detail .details a .play-icon svg {
  color: #ffd100;
  position: relative;
}

.primary-batch-tabs--content .course-box .course-detail h3 {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #111111;
}

.primary-batch-tabs--content .course-box .course-detail ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}

.primary-batch-tabs--content .course-box .course-detail ul li + li {
  margin-left: 23px;
  position: relative;
}

.primary-batch-tabs--content .course-box .course-detail ul li + li::before {
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  height: 7px;
  width: 4px;
  background-image: url(https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png);
  content: "";
}

.primary-batch-tabs--content .course-box .course-detail ul li a {
  font-size: 14px;
  line-height: 18px;
  display: block;
  color: #666666;
}

.primary-batch-tabs--content .course-box .course-image {
  width: 200px;
  background: #f5f9ff;
  border-radius: 6px;
  height: 136px;
  overflow: hidden;
}

.primary-batch-tabs--content .course-box .course-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.primary-batch-tabs--content .subject-topic > p {
  padding-left: 300px;
  display: flex;
}

.primary-batch-tabs--content .subject-topic > p a {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
  color: #1ea087;
}

.primary-batch-tabs--content .batch-sections--col ul {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.primary-batch-tabs--content .batch-sections--col ul li {
  margin: 0 10px 10px;
}

.primary-batch-tabs--content .batch-sections--col ul li:not(:last-child) {
  position: relative;
}

.primary-batch-tabs--content .batch-sections--col ul li:not(:last-child)::after {
  content: "";
  height: 7px;
  width: 1px;
  background-color: #35363b;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.primary-batch-tabs--content .batch-sections--col ul li p {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  display: block;
  color: #35363b;
}

.primary-batch-tabs--content .batch-sections--col ul li a {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: block;
  color: #35363b;
}

.primary-batch-tabs--content .day-wise-slot {
  margin-bottom: 40px;
}

.primary-batch-tabs--content .day-wise-slot h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #111111;
}

.primary-batch-tabs--content .description {
  margin-bottom: 40px;
}

.primary-batch-tabs--content .description h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #111111;
}

.primary-batch-tabs--content .description table {
  width: 100% !important;
  margin: 20px 0;
  border: #000 1px solid;
}

.primary-batch-tabs--content .description table td {
  padding: 10px;
}

.primary-batch-tabs--content .description p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #35363b;
}

.primary-batch-tabs--content .description p + p {
  margin-top: 15px;
}

.batch-accordion .accordion .card-header p span {
  font-size: 16px;
  line-height: 23px;
  color: #707070;
}

@media screen and (max-width: 767px) {
  .batch-accordion .accordion .card-header p span {
    font-size: 12px;
    line-height: 1.5;
    white-space: nowrap;
    padding-left: 10px;
    text-align: right;
  }
}

.accordion .card-body .lesson-notes-list {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
}

.accordion .card-body .lesson-notes-list li {
  display: flex;
}

.accordion .card-body .lesson-notes-list li + li {
  margin-top: 10px;
}

.accordion .card-body .lesson-notes-list li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #00a689;
}

.accordion .card-body .lesson-notes-list li a div:not(:first-child):last-child {
  padding-left: 12px;
}

.accordion .card-body .lesson-notes-list li a:hover {
  text-decoration: underline;
}

.accordion .card-body .lesson-notes-list li a span {
  width: 53px;
  height: 37px;
  display: flex;
  flex-shrink: 0;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  background: rgba(0, 166, 137, 0.1);
  border-radius: 4px;
}

.accordion .card-body .lesson-notes-list li a span svg {
  font-size: 24px;
  color: #454545;
}

.accordion .card-body .add-box {
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  background: #f0f0f0;
  border-radius: 8px;
}

.accordion .card-body .add-box p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #111111;
  margin: 0;
}

.accordion .card-body .add-box p svg {
  margin-right: 5px;
  color: #0b8f75;
  font-size: 18px;
}

.primary-timeline {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.primary-timeline.transparent {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}

.primary-timeline.transparent .primary-timeline--body {
  padding: 0;
}

.primary-timeline--header {
  border-bottom: #e2e8f0 1px solid;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.primary-timeline--header .days-dropdown > a {
  min-width: 144px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  justify-content: space-between;
  background: #f0f0f0;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  color: #111111;
}

.primary-timeline--header .days-dropdown > a svg {
  color: #111111;
  font-size: 24px;
}

.primary-timeline--body {
  padding: 15px;
}

.primary-timeline--body .timeline-row {
  display: flex;
  align-items: center;
}

.primary-timeline--body .timeline-row + .timeline-row {
  margin-top: 15px;
}

.primary-timeline--body .timeline-row .wrap {
  width: calc(100% - 60px);
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
}

.primary-timeline--body .timeline-row .wrap .timeline-card {
  margin-bottom: 15px;
  background: #f3fcfb;
  border: 1px solid #00a689;
  box-sizing: border-box;
  border-radius: 6px;
  width: calc(50% - 15px);
  padding: 8px 15px;
}

.primary-timeline--body .timeline-row .wrap .timeline-card + .timeline-card {
  margin-left: 30px;
}

.primary-timeline--body .timeline-row .wrap .timeline-card p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  color: #111111;
}

.primary-timeline--body .timeline-row .wrap .timeline-card p a {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  color: #ff0000;
}

.primary-timeline--body .timeline-row .wrap .timeline-card strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  color: #111111;
}

.primary-timeline--body .timeline-row .wrap .timeline-card span {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #239f87;
}

.primary-timeline--body .timeline-row .wrap .timeline-card i {
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  color: #666666;
  font-style: normal;
}

.primary-timeline--body .timeline-row h3 {
  width: calc(30px + 30px);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #35363b;
  margin: 0;
  text-align: center;
}

.primary-timeline--body .timeline-row h3 span {
  display: block;
}

.session-topic-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
}

.session-topic-header em {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */
  display: flex;
  align-items: center;
  color: #239f87;
  font-style: normal;
}

.session-topic-header p {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */
  display: flex;
  align-items: center;
  color: #35363b;
}

.session-topic-header p span {
  margin-left: 30px;
}

.add-session-data .add-box {
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  border: 1px solid #0b8f75;
  border-radius: 8px;
}

.add-session-data .add-box + .add-box {
  margin-top: 50px;
}

.add-session-data .add-box span {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-align: right;
  color: #aaaaaa;
}

.add-session-data .add-box p {
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #111111;
  text-transform: uppercase;
  margin: 0;
}

.add-session-data .add-box p svg {
  margin-right: 10px;
  color: #0b8f75;
  font-size: 18px;
}

.custom-table.eight-col .custom-table--col {
  width: calc((100% - 105px) / 7);
}

.custom-table.eight-col .custom-table--col:last-child {
  width: 105px;
}

.custom-table--head .custom-table--row {
  height: auto;
  margin-bottom: 7px;
}

.custom-table--head .custom-table--col {
  font-size: 14px;
  line-height: 18px;
  color: #6f6f6f;
}

.custom-table--body .custom-table--row {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
}

.custom-table--body .custom-table--row + .custom-table--row {
  margin-top: 10px;
}

.custom-table--row {
  display: flex;
  align-items: center;
  height: 62px;
}

.custom-table--col {
  padding: 0 20px;
}

.custom-table--col .btn-danger {
  background: #e60f0f;
  border-radius: 4px;
  min-width: 1px;
  border: none;
  width: 65px;
  height: 34px;
}

.custom-table--col .btn-danger:hover {
  background-color: #ce0d0d;
}

.custom-table--col p {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.custom-table--col p.expired {
  color: #b6b6b6;
  font-size: 14px;
}

.lesson-wrapping + .lesson-wrapping {
  margin-top: 34px;
}

.lesson-wrapping > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #111111;
  margin-bottom: 11px;
}

.student-tabs .primary-tabs .primary-batch-tabs {
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: inline-flex;
  padding: 0 40px;
}

.student-tabs .primary-tabs .primary-batch-tabs li {
  padding: 0;
  border-radius: none;
  font-size: 16px;
  line-height: 23px;
  color: #6f6f6f;
  position: relative;
  border: none;
}

.student-tabs .primary-tabs .primary-batch-tabs li::before {
  display: none;
}

.student-tabs .primary-tabs .primary-batch-tabs li::after {
  content: "";
  width: 100%;
  border-radius: 2px 2px 0px 0px;
  background: #00a689;
  position: absolute;
  bottom: -14px;
  left: 0;
  height: 3px;
  transform: scale(0);
  transform-origin: center center;
  transition: all ease-in-out 0.3s;
}

.student-tabs .primary-tabs .primary-batch-tabs li + li {
  margin-left: 45px;
}

.student-tabs .primary-tabs .primary-batch-tabs li.active {
  font-weight: bold;
  color: #00a689;
  background-color: transparent;
}

.student-tabs .primary-tabs .primary-batch-tabs li.active::after {
  transform: scale(1);
  transition: all ease-in-out 0.3s;
}

.primary-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #00a689;
}

.resource-detail-box {
  background: #ffffff;
  min-height: calc(100vh - (70px + 25px + 32px + 20px + 24px + 16px));
}

.resources-list {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
}

.resources-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.resources-list ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.resources-list ul li:hover {
  cursor: pointer;
}

.resources-list ul li:hover p {
  font-weight: 600;
  color: #00a689;
}

.resources-list ul li + li {
  margin-top: 12px;
}

.resources-list ul li span {
  width: 53px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 166, 137, 0.1);
  border-radius: 4px;
}

.resources-list ul li p {
  margin: 0;
  width: calc(100% - 53px);
  padding-left: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}

.priamry-test-card .test-card-footer.report {
  background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
  text-decoration: underline;
}

.primary-mentor-card {
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  transition: all ease-in-out 0.3s;
}

.primary-mentor-card:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 0.3s;
}

.primary-mentor-card:hover .primary-mentor-card--body {
  border-color: transparent;
  transition: all ease-in-out 0.3s;
}

.primary-mentor-card--body {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  padding: 60px 20px 28px;
  border-top: none;
  transition: all ease-in-out 0.3s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.primary-mentor-card--body h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #111111;
  margin-bottom: 8px;
}

.primary-mentor-card--body span {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #111111;
}

.primary-mentor-card--body p {
  font-size: 14px;
  margin: 0;
  line-height: 24px;
  color: #6f6f6f;
}

.primary-mentor-card--body ul {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  height: 44px;
  list-style: none;
  margin: 8px 0 17px;
}

.primary-mentor-card--body ul li {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #111111;
}

.primary-mentor-card--body ul li + li {
  margin-left: 35px;
}

.primary-mentor-card--body ul li svg {
  margin-right: 7px;
}

.primary-mentor-card--header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 80px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background: linear-gradient(180deg, #b3d4de 0%, #c4e0e9 100%);
}

.primary-mentor-card--header img {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -45px;
  border-radius: 50%;
  background-color: #f5f7fa;
  border: 5px solid #fff;
  object-fit: cover;
}

.simple-box--head {
  margin-bottom: 24px;
}

.simple-box--head h4 {
  font-weight: bold;
  margin: 0;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #111111;
}

@media screen and (min-width: 768px) {
  .simple-box--head h4 {
    font-size: 34px;
    line-height: 48px;
  }
}

.simple-box--head h3 {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
  line-height: normal;
  color: #111111;
}

@media screen and (min-width: 768px) {
  .simple-box--head h3 {
    font-size: 24px;
    line-height: 48px;
  }
}

.simple-box--head h3 + p {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.mentor-preview-box {
  background: #ffffff;
  padding: 20px;
}

.mentor-preview-box ul {
  list-style: none;
  display: flex;
  align-items: flex-end;
  margin: 16px 0 0;
  padding: 0;
}

.mentor-preview-box ul li {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #00a689;
}

.mentor-preview-box ul li + li {
  margin-left: 16px;
}

.mentor-preview-box ul li span {
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background: rgba(0, 166, 137, 0.1);
  border-radius: 8px;
  color: #00a689;
  width: 118px;
  height: 34px;
}

.mentor-preview-box ul li span svg {
  margin-right: 4px;
  width: 24px;
  height: 24px;
}

.mentor-preview-box .video-box {
  width: 250px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(50% 50% at 50% 50%, #6c6c6c 0%, #4e4e4e 100%);
  border-radius: 8px;
  border: 1px solid #eee;
  flex-shrink: 0;
}

.mentor-preview-box .video-box embed {
  margin: 0 !important;
}

.mentor-preview-box .video-box svg {
  cursor: pointer;
}

.mentor-preview-box .wrap {
  display: flex;
  flex-wrap: wrap;
}

.mentor-preview-box .wrap img {
  width: 155px;
  height: 155px;
  border: 5px solid #fff;
  border-radius: 50%;
}

.mentor-preview-box .wrap .content {
  width: calc(100% - 155px);
  padding-left: 12px;
}

.mentor-preview-box .wrap .content .user-info {
  margin: 0;
}

.mentor-preview-box .wrap .content .user-info li {
  font-size: 15px;
  line-height: 21px;
  font-weight: normal;
  width: auto;
  color: #6f6f6f;
}

@media screen and (max-width: 600px) {
  .mentor-preview-box .wrap .content .user-info li {
    font-size: 16px;
  }
}

.mentor-preview-box .wrap .content .user-info li + li {
  margin-left: 22px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .mentor-preview-box .wrap .content .user-info li + li {
    margin-left: 0;
    margin-top: 22px;
  }
}

.mentor-preview-box .wrap .content .user-info li + li::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background-color: #6f6f6f;
}

@media screen and (max-width: 600px) {
  .mentor-preview-box .wrap .content .user-info li + li::before {
    height: 1px;
    width: 10px;
    top: -8px;
    transform: translateX(-50%);
    left: 50%;
  }
}

.mentor-preview-box .wrap .content h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 8px;
  color: #111111;
}

.mentor-preview-box .wrap .content strong {
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 18px;
  color: #6f6f6f;
}

.mentor-preview-box .wrap .content p {
  font-size: 16px;
  line-height: 18px;
  color: #6f6f6f;
  margin: 0;
}

.mentor-preview-box .info {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.mentor-preview-box .desc {
  padding: 30px 0 0;
}

.mentor-preview-box .desc p {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: #6f6f6f;
}

.mentor-preview-box .desc p + p {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .mentor-preview-box ul {
    justify-content: center;
    margin-bottom: 20px;
  }
  .mentor-preview-box .desc {
    padding-top: 20px;
  }
  .mentor-preview-box .info {
    display: block;
    padding: 15px 15px 0;
  }
  .mentor-preview-box .info .video-box {
    width: 100%;
  }
  .mentor-preview-box .info .wrap {
    padding: 0;
    display: block;
  }
  .mentor-preview-box .info .wrap .content {
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 12px;
  }
  .mentor-preview-box .info .wrap img {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    display: block;
  }
  .accordion .card-body .lesson-notes-list {
    margin-bottom: 15px;
  }
  .primary-timeline--body .timeline-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .primary-timeline--body .timeline-row .wrap {
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
  .primary-timeline--body .timeline-row .wrap .timeline-card {
    width: 100%;
  }
  .primary-timeline--body .timeline-row .wrap .timeline-card + .timeline-card {
    margin-left: 0;
    margin-top: 15px;
  }
  .primary-timeline--body .timeline-row h3 {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .primary-timeline--body .timeline-row h3 span + span {
    margin-left: 5px;
  }
  .primary-timeline--header .days-dropdown > a {
    min-width: 120px;
    font-size: 16px;
    line-height: 18px;
  }
  .primary-timeline--header .days-dropdown > a svg {
    font-size: 18px;
  }
  .student-tabs .primary-tabs .primary-batch-tabs {
    overflow-x: auto;
    max-width: 100%;
    height: 50px;
    padding: 0 20px;
    overflow-y: hidden;
  }
  .student-tabs .primary-tabs .primary-batch-tabs li {
    white-space: nowrap;
    padding: 0 20px;
    font-size: 14px;
  }
  .student-tabs .primary-tabs .primary-batch-tabs li:after {
    width: calc(100% - 40px);
    right: 0;
    margin: 0 auto;
    bottom: -7px;
  }
  .student-tabs .primary-tabs .primary-batch-tabs li + li {
    margin: 0;
  }
}

@media screen and (max-width: 479px) {
  .primary-heading {
    font-size: 18px;
    line-height: normal;
  }
  .mentor-preview-box ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mentor-preview-box ul li {
    width: calc(50% - 5px);
  }
  .mentor-preview-box ul li span {
    width: 100%;
  }
  .mentor-preview-box ul li.video {
    width: 100%;
    margin-top: 10px;
  }
  .mentor-preview-box ul li.video span {
    margin-top: 0;
  }
  .mentor-preview-box ul li + li {
    margin-left: 0;
  }
}

.document-box {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 20px;
  border-radius: 4px;
}

.document-box h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #35363b;
}

.document-box ul {
  margin: 6px 0 0;
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
}

.document-box ul.docs {
  margin-top: 16px;
}

.document-box ul.docs li {
  width: 101px;
  height: 71px;
}

.document-box ul li {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 3px;
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-box ul li + li {
  margin-left: 24px;
}

.document-box ul li img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.document-box span {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: block;
  color: #a1a1a1;
}

.document-box strong {
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  display: block;
  margin-top: 6px;
  color: #535974;
}

.document-box strong i {
  font-style: normal;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  .batch-accordion.test-accordian.assignment-accordian .accordion .card-header p div {
    width: calc(500px - 17px);
  }
  .batch-accordion.test-accordian.assignment-accordian .accordion .card-header p div p {
    width: 50%;
    padding: 0 15px;
  }
}

.timeline-card .action-button {
  margin-left: auto;
}

.timeline-card .edit-icon {
  color: #239f87;
  flex-shrink: 0;
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 5px;
}

.primary-timeline .timeline-tabs {
  width: 176px;
  display: flex;
  list-style: none;
  background: #f0f0f0;
  margin: 0;
  padding: 4px;
  border-radius: 8px;
}

.primary-timeline .timeline-tabs li {
  width: 50%;
  position: relative;
}

.primary-timeline .timeline-tabs li input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.primary-timeline .timeline-tabs li input:checked + span {
  background: #111111;
  color: #ffffff;
}

.primary-timeline .timeline-tabs li span {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  /* identical to box height, or 143% */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
}

.primary-timeline--body .calender-view {
  margin-top: -15px;
  margin-bottom: -15px;
  display: flex;
}

.primary-timeline--body .calender-view .timeline-row .wrap {
  width: 100% !important;
}

.primary-timeline--body .calender-view .timeline-card {
  width: 100% !important;
}

.primary-timeline--body .calender-view .timeline-card + .timeline-card {
  margin-left: 0 !important;
}

.primary-timeline--body .calender-view .col {
  width: 50%;
  padding: 15px 0;
}

.primary-timeline--body .calender-view .col .no-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #111111;
}

.primary-timeline--body .calender-view .col .col-footer {
  height: 40px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 6px;
}

.primary-timeline--body .calender-view .col .col-footer p {
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  padding-left: 16px;
  position: relative;
  color: #666666;
}

.primary-timeline--body .calender-view .col .col-footer p.current::before {
  background: #30a58f;
}

.primary-timeline--body .calender-view .col .col-footer p.class::before {
  background: #111111;
}

.primary-timeline--body .calender-view .col .col-footer p::before {
  content: "";
  width: 8px;
  height: 8px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
}

.primary-timeline--body .calender-view .col .col-footer p + p {
  margin-left: 20px;
}

.primary-timeline--body .calender-view .col .col-header {
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.primary-timeline--body .calender-view .col .col-header .primary-outine-button {
  border-color: #0b8f75;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
  height: 36px;
  line-height: 15px;
  padding: 0 10px !important;
  color: #35363b;
}

.primary-timeline--body .calender-view .col .col-header .primary-outine-button svg {
  color: #0b8f75;
  transition: all ease-in-out 0.3s;
  font-size: 18px;
}

.primary-timeline--body .calender-view .col .col-header .primary-outine-button:hover {
  background-color: #0b8f75;
  color: #fff;
}

.primary-timeline--body .calender-view .col .col-header .primary-outine-button:hover svg {
  color: #fff;
  transition: all ease-in-out 0.3s;
}

.primary-timeline--body .calender-view .col .col-header h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  color: #111111;
}

.primary-timeline--body .calender-view .col:first-child {
  padding-right: 24px;
}

.primary-timeline--body .calender-view .col:last-child {
  padding-left: 24px;
}

.primary-timeline--body .calender-view .col + .col {
  border-left: 1px solid #e2e8f0;
}

.primary-timeline--header h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  color: #111111;
}

.primary-timeline--header h3 strong {
  font-weight: bold;
}

.primary-timeline--header .days-dropdown {
  position: relative;
}

.primary-timeline--header .days-dropdown ul {
  position: absolute;
  margin: 0;
  padding: 5px;
  display: none;
  list-style: none;
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.primary-timeline--header .days-dropdown ul li {
  cursor: pointer;
  font-size: 14px;
  padding: 6px 10px;
}

.primary-timeline--header .days-dropdown ul li + li {
  border-top: 1px solid #eee;
}

.course-overview .course {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-bottom: 21px;
}

.course-overview .course--body {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body {
    padding: 30px;
    flex-direction: row;
  }
}

.course-overview .course--body .course-card {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-card {
    width: 300px;
  }
}

.course-overview .course--body .course-card a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  justify-content: center;
  border: 1px solid #c4c4c4;
  line-height: 24px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: #00a689;
}

.course-overview .course--body .course-card a span {
  width: 20px;
  height: 20px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50%;
  justify-content: center;
  background: #00a689;
}

.course-overview .course--body .course-card a span svg {
  color: #ffd100;
  position: relative;
}

.course-overview .course--body .course-card a span::after, .course-overview .course--body .course-card a span::before {
  content: "";
  position: absolute;
}

.course-overview .course--body .course-card a span::after {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background: #00a689;
  opacity: 0.16;
}

.course-overview .course--body .course-card a span::before {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background: #00a689;
  opacity: 0.4;
}

.course-overview .course--body .course-card img {
  max-width: 100%;
  width: 100%;
}

.course-overview .course--body .course-info {
  margin: 0;
  width: 100%;
  padding-top: 15px;
  text-align: center;
}

.course-overview .course--body .course-info .description ul {
  margin: 0;
  padding: 0;
  display: block;
}

.course-overview .course--body .course-info .description ul li {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #666 !important;
  margin-bottom: 0 !important;
  line-height: 150% !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  font-size: 14px !important;
}

.course-overview .course--body .course-info .description ul li + li {
  padding-left: 0;
  margin-left: 0;
  margin-top: 10px !important;
}

.course-overview .course--body .course-info .description ul li span {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #666 !important;
  margin-bottom: 0 !important;
  line-height: 150% !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  font-size: 14px !important;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-info {
    width: calc(100% - 300px);
    padding-top: 0;
    padding-left: 25px;
    text-align: left;
  }
}

.course-overview .course--body .course-info h3 {
  font-weight: bold;
  line-height: 32px;
  margin: 0 0 4px;
  color: #111111;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-info h3 {
    font-size: 24px;
  }
}

.course-overview .course--body .course-info strong {
  display: block;
  font-weight: 600;
  line-height: 24px;
  color: #666666;
  margin-bottom: 8px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-info strong {
    font-size: 16px;
  }
}

.course-overview .course--body .course-info p {
  line-height: 24px;
  color: #666666;
  font-size: 14px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-info p {
    font-size: 16px;
    margin: 0 0 10px;
  }
}

.course-overview .course--body .course-info .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .course-overview .course--body .course-info .wrap {
    justify-content: flex-end;
  }
}

.course-overview .course--body .course-info .wrap span {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */
  margin-right: 20px;
  color: #111111;
}

.course-overview .course--footer {
  border-top: 1px solid #e2e8f0;
}

.course-overview .course--footer ul {
  list-style: none;
  margin: 0;
  padding: 0 30px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 479px) {
  .course-overview .course--footer ul {
    padding: 10px 15px 0;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
  }
}

.course-overview .course--footer ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
}

@media screen and (max-width: 479px) {
  .course-overview .course--footer ul li {
    font-size: 14px;
    padding: 0 0 10px;
  }
  .course-overview .course--footer ul li:first-child {
    order: 1;
  }
  .course-overview .course--footer ul li:nth-child(2) {
    order: 2;
    font-weight: 600;
    color: #00a689;
  }
}

.course-overview .course--footer ul li svg {
  margin-right: 10px;
  color: #1ea087;
}

.course-overview .subjects {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
}

.course-overview .subjects--body {
  padding: 0 15px;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--body {
    padding: 30px;
  }
}

.course-overview .subjects--body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.course-overview .subjects--body ul li {
  display: block;
  padding: 15px 0;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--body ul li {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.course-overview .subjects--body ul li:hover {
  cursor: pointer;
}

.course-overview .subjects--body ul li:hover p {
  text-decoration: underline;
}

.course-overview .subjects--body ul li small {
  font-size: 14px;
  line-height: 14px;
  color: #666666;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--body ul li small {
    margin-left: 10px;
    margin-top: 0;
  }
}

.course-overview .subjects--body ul li p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #111111;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--body ul li p {
    font-size: 18px;
  }
}

.course-overview .subjects--body ul li + li {
  border-top: 1px solid #eee;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--body ul li + li {
    margin-top: 24px;
    border-top: none;
  }
}

.course-overview .subjects--footer {
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
}

.course-overview .subjects--footer p {
  font-weight: bold;
  line-height: 18px;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  color: #00a689;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--footer p {
    font-size: 18px;
  }
}

.course-overview .subjects--footer p:hover {
  text-decoration: underline;
}

.course-overview .subjects--header {
  height: 62px;
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 0 15px;
}

@media screen and (min-width: 768px) {
  .course-overview .subjects--header {
    padding: 0 30px;
  }
}

.course-overview .subjects--header h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #111111;
  margin: 0;
}

.question-banks--footer {
  display: flex;
  padding-bottom: 14px;
  padding-top: 14px;
  align-items: center;
  justify-content: flex-end;
}

.question-banks--footer .last-edit {
  font-size: 13px;
  line-height: 1;
  margin-right: 20px;
  color: #acacac;
}

.question-banks--footer .edit {
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
  color: #303956;
}

.question-banks--footer svg {
  display: block;
}

.question-banks--body {
  margin-top: 10px;
}

.question-banks--body .wrap {
  position: relative;
  padding-left: 20px;
}

.question-banks--body .wrap ol, .question-banks--body .wrap ul {
  padding-left: 12px;
}

.question-banks--body .wrap * {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #303956 !important;
  line-height: normal !important;
}

.question-banks--body .wrap > span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 13px;
  line-height: 1.15;
  color: #303956;
}

.question-banks--body .wrap p {
  font-size: 14px;
  line-height: 1.15;
  color: #303956;
  white-space: normal;
}

.question-banks--header {
  display: flex;
  padding-top: 0;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
}

.question-banks--header .wrap {
  display: flex;
  align-items: center;
}

.question-banks--header .wrap span {
  font-size: 13px;
  color: #acacac;
  margin-right: 10px;
  line-height: 1;
}

.question-banks--header .wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.question-banks--header .wrap ul li {
  font-size: 13px;
  color: #acacac;
  line-height: 1;
  padding: 0 5px;
}

.question-banks--header .wrap ul li + li {
  border-left: 1px solid #acacac;
}

.add-question {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-question .form-group {
  margin: 0;
}

.add-question .form-group .custom-ReactSelect {
  width: 164px;
}

.add-question .form-group .custom-ReactSelect > div > span + div:nth-child(2) {
  background-color: #fff72e;
  border: solid 1px #e6e6e6;
}

.add-question .form-group .custom-ReactSelect > div > span + div:nth-child(2) > div:first-child > div:first-child {
  font-size: 13px;
  color: #48506a;
  text-transform: uppercase;
}

.add-question .form-group .custom-ReactSelect > div > div:nth-child(1) {
  background-color: #fff72e;
  border: solid 1px #e6e6e6;
}

.add-question .form-group .custom-ReactSelect > div > div:nth-child(1) > div:first-child > div:first-child {
  font-size: 13px;
  color: #48506a;
  text-transform: uppercase;
}

.add-question .form-group .custom-ReactSelect > div > div + div {
  background-color: #fff;
}

.add-question .form-group .custom-ReactSelect > div > div > div:last-child svg {
  color: #48506a;
}

.add-question-row {
  margin: 20px 0;
  background-color: #fff;
  padding: 15px;
}

.add-question-row .wrap {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-question-row .wrap p {
  margin: 0;
  font-size: 16px;
  color: #000000;
}

.add-question-row .wrap div {
  cursor: pointer;
}

body .react-datepicker {
  font-family: "Source Sans Pro", sans-serif !important;
  border-color: #eee;
}

body .react-datepicker .react-datepicker__header {
  border: 0;
  background-color: #fff;
}

body .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #fff;
}

body .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #eee;
}

body .react-datepicker__day--keyboard-selected,
body .react-datepicker__month-text--keyboard-selected,
body .react-datepicker__quarter-text--keyboard-selected {
  background-color: #239f87;
}

body .react-datepicker__day-name {
  font-weight: 600;
}

.ques-count-text .form-group {
  margin: 0;
  margin-left: 10px;
  text-align: left;
  width: 85px;
}

.primary-delete-btn {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.permission-box {
  margin: 40px 0;
}

.permission-box p {
  font-weight: 600;
}

.permission-box .wrap {
  background-color: #fff;
}

.permission-box .wrap .wrap-row {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.permission-box .wrap .wrap-row p {
  margin: 0;
  color: #7f8381;
  width: 200px;
}

.permission-box .wrap .wrap-row ul {
  margin: 0;
  padding: 0;
  width: calc(100% - 200px);
  list-style: none;
  display: flex;
  align-items: center;
}

.permission-box .wrap .wrap-row ul li {
  width: calc(100% / 3);
}

.primary-upload-ui {
  width: 46px;
  height: 38px;
  border-radius: 10px;
  border: solid 1px #efefef;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.primary-upload-ui label {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.primary-upload-ui label svg {
  color: #000;
}

.primary-upload-ui input {
  display: none;
}

.transparent-textarea {
  width: 100%;
  height: auto;
  display: block;
  border: none;
  resize: none;
  font-size: 18px;
  color: #434544;
}

.add-option-form {
  display: flex;
  margin: 15px 0;
  align-items: center;
}

.add-option-form > div {
  width: 36px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 36px;
  margin-left: 5px;
  cursor: pointer;
  flex-shrink: 0;
}

.add-option-form > div svg {
  font-size: 20px;
}

.add-option-form .clear {
  background-color: #f44d4d;
}

.add-option-form .add {
  background-color: #239f87;
}

.modal-image-box {
  position: relative;
}

.modal-attachment {
  position: relative;
}

.modal-attachment .cross {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 5px 10px;
}

.modal-attachment img {
  max-width: 100%;
  border-radius: 10px;
}

.option-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 20px;
}

.option-wrapper .quiz_list {
  display: flex;
  align-items: center;
}

.option-wrapper .quiz_list span {
  font-size: 14px;
  color: #262424;
  line-height: 1;
  margin-right: 7px;
}

.option-wrapper .quiz_list .list-icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #1a1a1a;
  height: 24px;
  margin-right: 12px;
  border: solid 1px #767676;
  border-radius: 50%;
  background-color: #ffffff;
}

.option-wrapper .quiz_list .list-icon.selected-icon {
  background-color: #239f87;
  border-color: #239f87;
  color: #ffffff;
}

.add-more-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-more-options div {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  color: #1a1a1a;
}

.add-more-options div svg {
  margin-left: 5px;
}

.quiz-list-options {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 10px 0;
}

.quiz-list-options:first-child {
  padding-top: 0;
}

.quiz-list-options:last-child {
  padding-bottom: 0;
}

.quiz-list-options + .quiz-list-options {
  border-top: solid 1px rgba(112, 112, 112, 0.1);
}

.quiz-list-options li:first-child {
  width: 24px;
  border-radius: 50%;
  height: 24px;
  border: solid 1px #767676;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-size: 12px;
  color: #1a1a1a;
}

.quiz-list-options li svg {
  cursor: pointer;
}

.quiz-list-options li:nth-child(2) {
  flex-grow: 1;
  padding: 0 10px;
  font-size: 14px;
  line-height: normal;
  color: #262424;
}

.educrack-wall .create-button {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.educrack-wall--score {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
  color: #000000;
}

.educrack-wall--filter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.educrack-wall--filter label {
  display: none;
}

.educrack-wall--filter .primary-form-control,
.educrack-wall--filter .form-group {
  width: calc((100% - 30px) / 3);
}

.wall-grid {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .wall-grid {
    flex-wrap: wrap;
  }
}

.wall-grid .content {
  flex-grow: 1;
}

@media screen and (max-width: 767px) {
  .wall-grid .content {
    order: 2;
    width: 100%;
  }
}

.wall-grid .filter {
  width: 280px;
  padding-left: 40px;
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .wall-grid .filter {
    order: 1;
    padding-left: 0;
    padding-bottom: 20px;
    width: 100%;
  }
}

.wall-grid .filter h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1EA087;
}

.simple-box.mt-5 {
  margin-top: 0 !important;
}

.primary-image-picker {
  text-align: center;
  width: 160px;
}

.primary-image-picker p {
  font-size: 14px;
  margin: 0 0 1px;
  color: #000000;
}

.primary-image-picker span {
  display: block;
  line-height: 1.42;
  opacity: 0.5;
  font-size: 12px;
  color: #000000;
}

.primary-image-picker .picker-ui {
  margin: 0 0 6px;
  display: block;
  background-size: cover;
  background-position: center center;
  height: 160px;
  border-radius: 6px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
}

.primary-image-picker .picker-ui input {
  display: none;
}

.primary-table-view {
  margin: 20px 0 30px;
}

.primary-table-view--head .primary-table-view--row {
  margin-bottom: 10px;
}

.primary-table-view--head .primary-table-view--row > div {
  display: flex;
  align-items: center;
}

.primary-table-view--head .primary-table-view--row > div .Checkbox {
  width: auto;
  height: auto;
  position: static;
}

.primary-table-view--head .primary-table-view--row > div .Checkbox svg {
  width: 16px;
  margin-right: 7px;
  transform: scale(1);
}

.primary-table-view--head .primary-table-view--row > div label {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  color: #48506a;
}

.primary-table-view--head .heading {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  color: #48506a;
}

.primary-table-view--body .heading {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  color: #000;
}

.primary-table-view--row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.primary-table-view--row + .primary-table-view--row {
  margin-top: 10px;
}

.primary-table-view--row .heading {
  width: calc(55px + 141px);
}

.primary-table-view--row .sectional-timing {
  width: calc(16px + 7px + 110px + 47px);
}

.primary-table-view--row .total-timing {
  width: calc(16px + 7px + 77px + 47px);
}

.primary-table-view--row .negative-marking {
  width: calc(16px + 7px + 107px + 47px);
}

.primary-table-view--row .not-attempted {
  width: calc(16px + 7px + 85px + 47px);
}

.secondary-input-group {
  display: flex;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  border-radius: 4px;
}

.secondary-input-group:hover {
  border-color: #239f87;
}

.secondary-input-group i {
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #000;
  background-color: #fff;
}

.secondary-input-group .primary-form-control {
  width: auto;
  border: none !important;
  padding-left: 0;
  flex-grow: 1;
}

.primary-input-group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.primary-input-group .form-group {
  width: 70px;
}

.primary-input-group input {
  font-family: "Source Sans Pro";
  font-weight: normal;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 25px;
  text-align: left;
  color: #48506a;
}

.primary-input-group p {
  margin: 8px 0 0 8px;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  text-align: left;
  color: #48506a;
}

.question-bank {
  width: 879px;
}

.question-bank--footer {
  margin-top: 20px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.question-bank--footer p {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  position: absolute;
  left: 0;
  line-height: 1;
  text-align: left;
  color: #000;
}

.question-bank--filter .form-group {
  margin: 0;
}

.question-bank--filter .wrap {
  display: flex;
  align-items: center;
}

.question-bank--filter .input-with-icon {
  flex-grow: 1;
}

.question-bank--filter h3 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin: 0 30px 0 0;
  color: #000;
  display: flex;
  align-items: center;
}

.question-bank--filter h3 img {
  margin-left: 8px;
}

.question-bank--filter ul {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.question-bank--filter ul li {
  flex-grow: 1;
}

.question-bank--filter ul li + li {
  margin-left: 10px;
}

.question-bank--filter ul li select {
  height: 30px;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: left;
  color: #a0a0a0;
}

.question-bank--filter ul li button {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #00b8be;
  font-family: "Source Sans Pro";
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #35363b;
  transition: all 0.3s ease-in-out;
}

.question-bank--filter ul li button:hover {
  transition: all 0.3s ease-in-out;
  background-color: #00b8be;
  color: #fff;
}

.question-bank--questions {
  margin-top: 20px;
}

.question-bank--questions ul {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

.question-bank--questions ul + ul {
  margin-top: 20px;
}

.question-bank--questions ul li {
  font-family: "Source Sans Pro" !important;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #303956;
}

.question-bank--questions ul li .Checkbox {
  width: auto;
  display: block;
  height: auto;
  position: static;
}

.question-bank--questions ul li p {
  margin: 0;
}

.question-bank--questions ul li label {
  display: none;
}

.question-bank--questions ul li svg {
  transform: scale(1);
  height: 16px;
  width: 16px;
}

.question-bank--questions ul li:not(:first-child) {
  width: 150px;
  padding-left: 10px;
}

.question-bank--questions ul li:nth-child(2) {
  flex-grow: 1;
}

.question-bank--questions ul li .primary-checkbox span {
  font-family: "Maven Pro";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #303956;
  text-transform: none;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-left: 40px;
}

.input-with-icon svg {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.add-questions--header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.add-questions--header .add-questions-btn {
  width: 147px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border: 1px solid #acb0b5;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #303956;
}

.add-questions--header .add-questions-btn img {
  margin-right: 6px;
}

.primary-table .form-group {
  margin: 0;
}

.primary-table .primary-form-control {
  width: 46px;
  padding: 0 8px;
  height: 27px;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  color: #262424;
}

.primary-table--head .primary-table--row {
  padding: 0 16px 8px;
}

.primary-table--head .primary-table--row .invalid-feedback {
  width: 100%;
  margin: 0;
}

.primary-table--head .primary-table--row .Checkbox {
  position: static;
  width: auto;
  height: auto;
  display: block;
}

.primary-table--head .primary-table--row .Checkbox + label {
  margin: 0 0 0 7px;
}

.primary-table--head .primary-table--row svg {
  display: block;
  transform: scale(1);
  width: 16px;
  height: 16px;
}

.primary-table--head .primary-table--col {
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: left;
  color: #303956;
}

.primary-table--body .primary-table--row {
  background-color: #fff;
}

.primary-table--body .primary-table--row.expand {
  align-items: flex-start;
}

.primary-table--body .primary-table--row.expand .expand-icon {
  transform: rotate(90deg);
}

.primary-table--body .primary-table--row + .primary-table--row {
  margin-top: 1px;
}

.primary-table--row {
  display: flex;
  overflow: hidden;
  padding: 14px 16px;
  flex-wrap: wrap;
  align-items: center;
}

.primary-table--row:not(.expand) .question,
.primary-table--row:not(.expand) .correct-answer,
.primary-table--row:not(.expand) .explanation,
.primary-table--row:not(.expand) .options {
  display: none;
}

.primary-table--col {
  padding: 0 12px;
}

.primary-table--col .question {
  margin-top: 9px;
}

.primary-table--col .options {
  margin-top: 6px;
}

.primary-table--col .options strong {
  color: #189f06;
}

.primary-table--col .correct-answer {
  margin-top: 10px;
}

.primary-table--col .correct-answer strong {
  color: #f00;
}

.primary-table--col .explanation {
  margin-top: 6px;
}

.primary-table--col:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.primary-table--col strong {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
}

.primary-table--col img {
  cursor: pointer;
}

.primary-table--col p {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: #303956;
}

.primary-table--col:first-child {
  width: 120px;
}

.primary-table--col:nth-child(2) {
  width: 120px;
}

.primary-table--col:nth-child(3) {
  width: calc(100% - (120px + 120px + 137px));
}

.primary-table--col:last-child {
  width: 137px;
}

.primary-field {
  width: 46px;
  padding: 0 8px;
  height: 27px;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  text-align: left;
  color: #262424;
}

.test-reult-explanation {
  padding: 28px;
}

.test-reult-explanation .explanation-tabs {
  margin-bottom: 30px;
}

.test-reult-explanation .explanation-tabs ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.test-reult-explanation .explanation-tabs ul li {
  font-size: 18px;
  line-height: 32px;
  color: #6f6f6f;
}

.test-reult-explanation .explanation-tabs ul li.active {
  font-weight: 600;
  color: #239f87;
  position: relative;
}

.test-reult-explanation .explanation-tabs ul li.active:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #ffd100;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.test-reult-explanation .explanation-tabs ul li + li {
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  .secondary-table {
    overflow: auto;
  }
}

.secondary-table--body .secondary-table--row + .secondary-table--row {
  margin-top: 5px;
}

.secondary-table--body .secondary-table--row h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  color: #6f6f6f;
}

.secondary-table--body .secondary-table--row .wrap {
  display: flex;
  align-items: flex-start;
}

.secondary-table--col {
  display: flex;
  align-items: center;
  padding: 9px 20px;
}

@media screen and (max-width: 767px) {
  .secondary-table--col {
    flex-shrink: 0;
  }
}

.secondary-table--col span {
  flex-grow: 1;
}

.secondary-table--col span img {
  margin: 0 12px;
  display: block;
}

.secondary-table--col a {
  font-size: 18px;
  line-height: 32px;
  text-decoration: underline;
  color: #6f6f6f;
}

@media screen and (max-width: 767px) {
  .secondary-table--col a {
    font-size: 16px;
    line-height: normal;
  }
}

@media screen and (min-width: 768px) {
  .secondary-table--col p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    margin: 0;
  }
}

.secondary-table--col:first-child {
  width: 73px;
}

.secondary-table--col:nth-child(2) {
  width: calc(100% - (73px + 139px + 80px + 117px + 250px));
}

@media screen and (max-width: 767px) {
  .secondary-table--col:nth-child(2) {
    width: 200px;
  }
}

.secondary-table--col:nth-child(3) {
  width: 139px;
}

.secondary-table--col:nth-child(4) {
  width: 80px;
}

.secondary-table--col:last-child {
  width: calc(250px + 117px);
}

@media screen and (max-width: 767px) {
  .secondary-table--col:last-child {
    width: 230px;
  }
}

.secondary-table--header {
  margin-bottom: 13px;
}

.secondary-table--header .secondary-table--col {
  padding: 4px 20px;
}

.secondary-table--header .secondary-table--row {
  height: 40px;
  display: flex;
  align-items: center;
  background: #f8f8f8;
}

.question-responses p {
  margin: 0;
}

.question-responses label.d-flex {
  border-bottom: 1px dashed #ccc;
  padding: 10px 0;
  margin: 0;
}

.priamry-test-card {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  text-align: center;
  margin-bottom: 28px;
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.priamry-test-card .test-card-footer {
  background: #a9eadf;
  border-radius: 0px 0px 8px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #097461;
  margin: 0 -20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.priamry-test-card .test-card-footer .custom-control-label {
  font-weight: 600 !important;
  color: #35363b !important;
}

.priamry-test-card .test-card-footer .custom-control-label::before {
  background-color: transparent;
  border-color: #239f87;
}

.priamry-test-card .test-card-footer.view-report {
  background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
  font-weight: normal;
  color: #000000;
  text-decoration: underline;
}

.priamry-test-card.subscribed::before {
  background-image: url(../../dist/images/batch/subscribed.svg);
  width: 64px;
  height: 64px;
  position: absolute;
  left: -8px;
  content: "";
  top: -8px;
}

.priamry-test-card img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin: 0 auto 17px;
  display: block;
  object-fit: cover;
  background-color: #eee;
}

.priamry-test-card p {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 7px;
  color: #252525;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.priamry-test-card ul {
  list-style: none;
  margin: 0;
  padding: 0 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priamry-test-card ul li {
  font-size: 16px;
  line-height: 160%;
  color: #636363;
  position: relative;
}

.priamry-test-card ul li.active {
  color: #00a689;
}

.priamry-test-card ul li + li {
  margin-left: 16px;
}

.priamry-test-card ul li + li::before {
  content: "";
  width: 4px;
  height: 4px;
  background: #c4c4c4;
  position: absolute;
  border-radius: 50%;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.batch-accordion.test-accordian .accordion .card-body .test-wrap .test-card .wrap img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 16px;
  border-radius: 50%;
  object-fit: cover;
}

.batch-accordion.test-accordian .accordion .card-body .test-wrap .test-card .wrap h3 {
  margin-bottom: 15px;
}

.batch-accordion.test-accordian .accordion .card-header {
  position: relative;
}

.batch-accordion.test-accordian .accordion .card-header::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #00a689;
  border-radius: 0px 2px 2px 0px;
}

.batch-accordion.test-accordian .accordion .card-header p {
  color: #111111;
}

.test-page {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.test-page--header .left-col {
  display: flex;
  align-items: center;
  padding: 10px 20px !important;
  border-bottom: 1px solid rgba(182, 182, 182, 0.6);
}

@media screen and (max-width: 767px) {
  .test-page--header .left-col {
    padding: 10px 10px !important;
  }
}

.test-page--header .left-col p {
  margin: 0;
}

.test-page--header .right-col {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(182, 182, 182, 0.6);
  border-left: none !important;
}

.test-page--header .right-col .timer p {
  line-height: normal;
  margin-bottom: 5px;
  text-transform: none !important;
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 13px;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
}

.test-page--header .right-col .timer span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
  color: #239f87;
}

.test-page--body {
  height: calc(100vh - (51px + 56px));
}

.test-page--body .section-wrap {
  overflow-y: auto;
  height: calc(100% - 42px);
  overflow-x: hidden;
}

.test-page--body .right-col {
  padding-bottom: 10px !important;
  transition: all ease-in-out 0.3s;
  position: relative;
  padding: 20px;
}

.test-page--body .right-col .toggle-icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9d54d;
  color: #000;
  position: absolute;
  left: -17.5px;
  top: 11px;
  border-radius: 50%;
}

.test-page--body .right-col.hide {
  margin-right: -260px;
  transition: all ease-in-out 0.3s;
}

.test-page--body .right-col .current-section {
  margin-bottom: 20px;
}

.test-page--body .right-col .current-section p {
  line-height: normal;
  margin-bottom: 5px;
  text-transform: none !important;
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 13px;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
}

.test-page--body .right-col .current-section span {
  display: flex;
  align-items: center;
  border: 1px solid rgba(182, 182, 182, 0.26);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  height: 42px;
  border-radius: 0.25rem;
  padding: 0 0.75rem;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: #000;
}

.test-page--body .right-col .q-pallete {
  height: 100%;
}

.test-page--body .right-col .q-pallete--body {
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
}

.test-page--body .right-col .q-pallete--body > .flex-grow-1 {
  overflow-y: auto;
  height: calc(100% - 42px);
  overflow-x: hidden;
}

.test-page--body .right-col .q-pallete--body .status-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.test-page--body .right-col .q-pallete--body .status-wrap > div {
  width: 50%;
}

.test-page--body .right-col .q-pallete--body ul {
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.test-page--body .right-col .q-pallete--body ul li {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px 15px;
  border: 1px solid;
  cursor: pointer;
}

.test-page--body .right-col .q-pallete--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.test-page--body .right-col .q-pallete--header p {
  line-height: normal;
  margin-bottom: 5px;
  text-transform: none !important;
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 13px;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  color: #6f6f6f;
}

.test-page--body .right-col .q-pallete--header span {
  font-size: 13px;
  text-decoration: underline;
  font-weight: 600;
  color: #239f87;
  line-height: 1;
  cursor: pointer;
}

.test-page--body .left-col img {
  max-width: 100%;
  display: block;
}

.test-page--body .left-col .position-sticky {
  top: 0;
}

.test-page--body .left-col .badge-pill {
  margin-bottom: 10px;
}

.test-page--footer .right-col {
  background: #239f87;
  padding: 0;
  display: flex;
  align-items: center;
  border-left: none !important;
}

.test-page--footer .right-col .btn {
  width: 100%;
  height: 100%;
  font-size: 26px;
  text-transform: uppercase;
}

.test-page--footer .left-col {
  border-top: 1px solid rgba(182, 182, 182, 0.6);
  display: flex;
  padding: 10px 20px !important;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .test-page--footer .left-col {
    padding: 10px 10px !important;
  }
}

.test-page--footer .left-col .button-group {
  display: flex;
}

@media screen and (max-width: 767px) {
  .test-page--footer .left-col .button-group .btn {
    font-size: 14px !important;
  }
}

.test-page--footer .left-col, .test-page--header .left-col, .test-page--body .left-col {
  padding: 20px;
}

.test-page--footer, .test-page--header, .test-page--body {
  display: flex;
}

.test-page--footer .left-col, .test-page--header .left-col, .test-page--body .left-col {
  flex-grow: 1;
}

.test-page--footer .right-col, .test-page--header .right-col, .test-page--body .right-col {
  width: 300px;
  flex-shrink: 0;
  border-left: 1px solid rgba(182, 182, 182, 0.6);
}

.test-detail {
  background-color: #fff;
  padding: 22px 30px;
}

@media screen and (max-width: 767px) {
  .test-detail {
    padding: 20px;
  }
}

.test-detail .price-wrap {
  display: flex;
  align-items: center;
}

.test-detail .content {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .test-detail .content {
    flex-direction: column;
  }
}

.test-detail .desc {
  padding-left: 42px;
  flex-grow: 1;
}

@media screen and (max-width: 767px) {
  .test-detail .desc {
    text-align: center;
    padding-left: 0;
  }
}

.test-detail .desc span {
  font-weight: 600;
  display: block;
  font-size: 16px;
  margin-right: 20px;
  line-height: 18px;
  color: #239f87;
}

.test-detail .desc p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 23px;
  color: #000000;
}

.test-detail .desc ul {
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.test-detail .desc ul li {
  font-size: 16px;
  line-height: 23px;
  color: #636363;
}

.test-detail .desc ul li + li {
  margin-left: 25px;
  position: relative;
}

.test-detail .desc ul li + li::before {
  content: "";
  width: 5px;
  height: 5px;
  background: #c4c4c4;
  position: absolute;
  border-radius: 50%;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}

.test-detail .img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  background: #b5f2e7;
  border-radius: 50%;
  margin-bottom: 21px;
}

.test-detail .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.test-detail .content .left {
  flex-grow: 1;
  padding-right: 24px;
  display: flex;
  width: calc(100% - 351px);
}

@media screen and (max-width: 767px) {
  .test-detail .content .left {
    width: auto;
    padding-right: 0;
    align-items: center;
    flex-direction: column;
  }
}

.test-detail .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #252525;
  margin: 0 0 29px 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .test-detail .heading {
    text-align: center;
    font-size: 20px;
    line-height: normal;
    margin-bottom: 20px;
  }
}

.instruction-box {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .instruction-box--footer > div {
    display: block;
    padding: 20px;
  }
  .instruction-box--footer > div span {
    word-wrap: break-word;
  }
  .instruction-box--footer > div button {
    width: 100%;
    margin-top: 10px;
  }
}

.instruction-box--body {
  padding: 25px 25px 25px 36px;
}

.instruction-box ol {
  margin: 0;
  padding: 0;
}

.test-result {
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px #ddd;
  background-color: white;
}

.test-result .test-score {
  border-top: 1px solid #f3f3f3;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .test-result .test-score {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .test-result .test-score .wrap .icon {
    margin-bottom: 15px;
  }
}

.test-result .test-score .wrap + .wrap {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .test-result .test-score .wrap + .wrap {
    margin-left: 0;
  }
}

.test-result--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 30px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .test-result--header {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .test-result--header {
    padding: 20px;
    display: block;
  }
}

.test-result--header .percentage-info {
  width: 300px;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .test-result--header .percentage-info {
    width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .test-result--header .percentage-info {
    order: 3;
  }
}

@media screen and (max-width: 767px) {
  .test-result--header .percentage-info {
    width: auto;
  }
}

.test-result--header .percentage-info ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  list-style: none;
  padding: 0;
}

.test-result--header .percentage-info ul + ul {
  margin-top: 30px;
}

.test-result--header .percentage-info ul li {
  font-size: 24px;
  color: #505050;
  line-height: 1;
}

.test-result--header .percentage-info ul li + li {
  font-size: 22px;
  color: #239f87;
}

.test-result--header .result {
  width: 300px;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .test-result--header .result {
    width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .test-result--header .result {
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  .test-result--header .result {
    width: auto;
  }
}

.test-result--header .result p {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .test-result--header .result p {
    text-align: center;
  }
}

.test-result--header .result span {
  display: block;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .test-result--header .result span {
    text-align: center;
  }
}

.test-result--header .progress-wrap {
  width: calc(100% - 600px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .test-result--header .progress-wrap {
    width: calc(100% - 400px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .test-result--header .progress-wrap {
    width: 100%;
    order: 1;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .test-result--header .progress-wrap {
    width: auto;
    flex-direction: column;
    padding: 25px 0;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .test-page--footer .right-col,
  .test-page--header .right-col {
    width: auto;
  }
  .test-page--footer {
    flex-direction: column;
  }
  .test-page--body {
    min-height: calc(100vh - (51px + 94px));
    height: auto;
  }
  .test-page--footer .left-col img {
    display: none;
  }
  .test-page--footer .left-col .button-group {
    flex-grow: 1;
    overflow: auto;
    white-space: nowrap;
  }
  .test-page--body .left-col {
    padding-right: 40px;
    height: calc(100vh - (51px + 94px));
    overflow: auto;
  }
  .test-page--body .left-col .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    border-right: none !important;
    min-height: 1px !important;
  }
  .test-page--body .left-col .col-6 + .col-6 {
    border-top: 1px solid #b6b6b6;
    padding-top: 20px;
    margin-top: 20px;
  }
  .test-page--body .right-col {
    position: fixed;
    height: calc(100vh - (51px + 94px));
    right: 0;
    width: 90%;
    top: 51px;
    background-color: #fff;
    margin: 0;
    z-index: 9;
  }
  .test-page--body .right-col.hide {
    margin-right: 0;
    right: -80%;
  }
  .test-page--footer .left-col .button-group .btn {
    flex-grow: 1;
    flex-shrink: 0;
    width: auto;
  }
  .question-responses p {
    font-size: 16px;
  }
}

.common-data-view {
  margin-bottom: 15px;
}

.common-data-view.explanation-box {
  margin-bottom: 0;
  background: #f5f9ff;
  padding: 20px;
}

.common-data-view.explanation-box img {
  max-width: 100%;
}

.common-data-view h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #111111;
}

.common-data-view p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
}

.common-data-view p + p {
  margin-top: 15px;
}

.question-result-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .question-result-view {
    display: block;
  }
}

.question-result-view .attempt-info {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .question-result-view .attempt-info {
    font-size: 16px;
  }
}

.question-result-view .attempt-info p {
  margin: 0;
}

.question-result-view .attempt-info.wrong {
  background: #ff0000;
}

.question-result-view .attempt-info.skip {
  background: darkgray;
}

.question-result-view .attempt-info.correct {
  background: #239f87;
}

.question-result-view .button-group {
  display: flex;
  align-items: center;
  border-top: 1px solid #c4c4c4;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px 0;
}

.question-result-view .button-group a {
  width: 100px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  border: 1px solid #00a689;
  background-color: #00a689;
  color: #fff;
}

.question-result-view .button-group a.outline {
  color: #00a689;
  background-color: transparent;
}

.question-result-view .correct-answer {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 0;
  color: #111111;
}

@media screen and (max-width: 767px) {
  .question-result-view .correct-answer {
    margin-bottom: 10px;
  }
}

.question-result-view .correct-answer strong {
  font-weight: 700;
}

.question-result-view h3 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  color: #111111;
  margin-bottom: 10px;
}

.question-result-view h3 strong {
  font-weight: bold;
}

.explation-test {
  width: 1024px;
}

.explation-test .col-sm-7 {
  border-right: 4px solid #f5f5f5;
}

.radio-button + .radio-button {
  margin-top: 15px;
}

.radio-button.checked > label > span {
  color: #00a689;
}

.radio-button.checked > label > span::before {
  border-color: #00a689;
}

.radio-button.checked > label > span:after {
  transform: scale(1) translateY(-50%);
  transition: all ease-in-out 0.3s;
}

.radio-button > label {
  display: block;
  margin: 0;
}

.radio-button > label input {
  display: none;
}

.radio-button > label > span {
  font-size: 16px;
  display: block;
  color: #111111;
  position: relative;
  padding-left: 35px;
}

.radio-button > label > span * {
  text-indent: 0 !important;
}

.radio-button > label > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0) translateY(-50%);
  transition: all ease-in-out 0.3s;
  left: 7px;
  background: #00a689;
}

.radio-button > label > span::before {
  position: absolute;
  content: "";
  width: 24px;
  border-radius: 50%;
  height: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border: 1px solid rgba(102, 102, 102, 0.52);
}

.test-duration-info {
  display: flex;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  align-items: center;
}

.test-duration-info li {
  font-size: 14px;
  line-height: 1;
  color: #111111;
}

.test-duration-info li + li {
  margin-left: 25px;
  position: relative;
}

.test-duration-info li + li::before {
  content: "";
  height: 100%;
  position: absolute;
  left: -12px;
  top: 0;
  background-color: #111111;
  width: 1px;
}

.section-info {
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-right: 24px;
  color: #111111;
}

.section-info strong {
  font-weight: 700;
}

.section-info span {
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 19px;
  padding: 0 15px;
  background: #239f87;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .attempted-test-view {
    display: flex;
    align-items: flex-start;
  }
}

.attempted-test-view--footer {
  border-top: 1px solid #eee;
  padding: 20px 0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attempted-test-view--footer .btn {
  padding: 0.5rem 1rem;
  font-size: 13px;
  transition: all ease-in-out 0.3s;
  text-transform: uppercase;
  border-radius: 0.25rem;
  height: 40px;
  box-shadow: none;
  min-width: 100px;
  font-weight: 500;
}

.attempted-test-view--footer .btn.btn-success {
  background-color: #00a689;
  border-color: #00a689;
}

.attempted-test-view--footer .btn.btn-success:hover {
  background-color: #008d74;
}

.attempted-test-view--footer .btn.btn-outline-success {
  border-color: #00a689;
  color: #00a689;
}

.attempted-test-view--footer .btn.btn-outline-success:hover {
  background-color: #00a689;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .attempted-test-view .left {
    width: 60%;
    flex-shrink: 0;
    padding-right: 40px;
  }
}

.attempted-test-view .left .audio-view {
  background: #ffffff;
  padding: 15px;
  border-radius: 6px;
}

.attempted-test-view .left .audio-view p {
  font-size: 14px;
  line-height: 20px;
  color: #35363b;
}

.attempted-test-view .left .audio-view .wrap {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.attempted-test-view .left .audio-view .wrap svg {
  font-size: 30px;
  margin-right: 18px;
  flex-shrink: 0;
}

.attempted-test-view .left .audio-view .wrap .bar {
  height: 6px;
  width: 100%;
  position: relative;
  background: linear-gradient(0deg, #c4cdd5, #c4cdd5), linear-gradient(0deg, #c4cdd5, #c4cdd5), #c4cdd5;
  border-radius: 10px;
}

.attempted-test-view .left .audio-view .wrap .bar span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  font-size: 0;
  background: #00a689;
  border-radius: 10px;
}

.attempted-test-view .right {
  flex-grow: 1;
  position: sticky;
  top: 0;
  left: 0;
}

.default-card {
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 40px;
}

@media screen and (max-width: 767px) {
  .default-card {
    padding: 15px;
  }
}

.add-more-info p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  color: #00a689;
  display: flex;
  align-items: center;
}

.add-more-info p svg {
  margin-right: 5px;
}

.add-more-info span {
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-decoration-line: underline;
  color: #6f6f6f;
  margin-top: 8px;
}

.horizontal-card {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  padding: 25px 30px;
}

.horizontal-card + .horizontal-card {
  margin-top: 1px;
}

.horizontal-card .wrap {
  flex-grow: 1;
}

.horizontal-card .wrap h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0;
  color: #000000;
}

.horizontal-card .wrap p {
  font-size: 13px;
  line-height: 18px;
  margin: 9px 0 0;
  color: #000000;
}

.horizontal-card .wrap ul {
  display: flex;
  margin: 9px 0 0;
  padding: 0;
  align-items: center;
}

.horizontal-card .wrap ul.vertcal-list {
  display: block;
  margin-bottom: 18px;
}

.horizontal-card .wrap ul.vertcal-list li span:first-child {
  width: calc(98px + 17px);
}

.horizontal-card .wrap ul.vertcal-list li + li {
  margin-left: 0;
  margin-top: 9px;
}

.horizontal-card .wrap ul li {
  display: flex;
  align-items: center;
}

.horizontal-card .wrap ul li + li {
  margin-left: 35px;
}

.horizontal-card .wrap ul li span {
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}

.horizontal-card .wrap ul li span + span {
  margin-left: 5px;
}

.primary-dropdown {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.primary-dropdown:not(:hover) .primary-dropdown--menu {
  display: none;
}

.primary-dropdown--item {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-dropdown--item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}

.primary-dropdown--menu {
  position: absolute;
  width: auto;
  min-width: 10rem;
  right: 100%;
  top: 15px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e6e6e6;
  padding: 5px 0px;
  z-index: 999;
}

.primary-dropdown--menu li {
  list-style: none;
  padding: 0.5rem 1rem;
  font-size: 14px;
  color: #434544;
  white-space: nowrap;
}

.primary-dropdown--menu li a {
  transition: none;
}

.primary-dropdown--menu li:hover {
  background: #239f87;
  color: white;
  cursor: pointer;
}

.primary-dropdown--menu li:hover a {
  color: white;
  transition: none;
}

.list-with-delete {
  display: flex;
  align-items: flex-end;
}

.list-with-delete > div {
  flex-grow: 1;
  padding-right: 20px !important;
}

.list-with-delete > svg {
  width: 18px;
  cursor: pointer;
  height: 18px;
  margin-bottom: 24px;
  color: #000;
}

@media screen and (min-width: 1280px) {
  .resume-preview {
    width: 80%;
    margin: 0 auto;
  }
}

.resume-preview--wrap {
  background-color: #ffffff;
}

.resume-preview--wrap .main-header {
  padding: 35px 60px;
  display: flex;
  align-items: center;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header {
    padding: 25px;
  }
}

@media screen and (max-widht: 767px) {
  .resume-preview--wrap .main-header {
    display: block;
  }
  .resume-preview--wrap .main-header .button-group {
    justify-content: center;
  }
}

.resume-preview--wrap .main-header .wrp {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media screen and (max-widht: 767px) {
  .resume-preview--wrap .main-header .wrp {
    display: block;
    text-align: center;
  }
}

.resume-preview--wrap .main-header--content {
  padding-left: 60px;
  padding-bottom: 30px;
  padding-right: 20px;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header--content {
    padding-left: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
  }
}

.resume-preview--wrap .main-header--content .skills {
  background: #f6f6f6;
  padding: 30px 35px;
}

.resume-preview--wrap .main-header--content .skills--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.resume-preview--wrap .main-header--content .skills--row p {
  margin: 0;
  font-size: 18px;
  line-height: 1;
  color: #393e41;
}

.resume-preview--wrap .main-header--content .skills--row ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.resume-preview--wrap .main-header--content .skills--row ul li + li {
  margin-left: 5px;
}

.resume-preview--wrap .main-header--content .skills--row ul li svg {
  display: block;
}

.resume-preview--wrap .main-header--content .wrap + .wrap {
  margin-top: 30px;
}

.resume-preview--wrap .main-header--content p {
  font-size: 18px;
  line-height: 26px;
  color: #393e41;
  margin: 0;
}

.resume-preview--wrap .main-header--content p strong {
  font-weight: 600;
}

.resume-preview--wrap .main-header--content p.currently-working {
  color: #239f87;
}

.resume-preview--wrap .main-header--content p + p {
  margin-top: 10px;
}

.resume-preview--wrap .main-header--wrap {
  padding-left: 30px;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header--wrap {
    padding-left: 15px;
  }
}

@media screen and (max-widht: 767px) {
  .resume-preview--wrap .main-header--wrap {
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.resume-preview--wrap .main-header--wrap h2 {
  margin: 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  color: #393e41;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header--wrap h2 {
    font-size: 24px;
  }
}

.resume-preview--wrap .main-header--wrap p {
  margin: 5px 0 0;
  font-size: 18px;
  line-height: 1;
  color: #393e41;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header--wrap p {
    font-size: 14px;
  }
}

.resume-preview--wrap .main-header .icon {
  width: 101px;
  height: 101px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  font-size: 0;
  background-color: #eee;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--wrap .main-header .icon {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-widht: 767px) {
  .resume-preview--wrap .main-header .icon {
    margin: 0 auto;
  }
}

.resume-preview--wrap .main-header .icon img {
  max-width: 100%;
}

.resume-preview--header {
  height: 40px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #239f87;
  justify-content: space-between;
  background: #f6f6f6;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--header {
    padding: 0 25px;
  }
}

.resume-preview--header h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  color: #393e41;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--header h3 {
    font-size: 18px;
  }
}

.resume-preview--body {
  padding: 20px 60px;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--body {
    padding: 25px;
  }
}

.resume-preview--body .wrap + .wrap {
  margin-top: 30px;
}

.resume-preview--body p {
  font-size: 18px;
  line-height: 26px;
  color: #393e41;
  margin: 0;
}

@media screen and (max-widht: 1023px) {
  .resume-preview--body p {
    font-size: 16px;
  }
}

.resume-preview--body p strong {
  font-weight: 600;
}

.resume-preview--body p.currently-working {
  color: #239f87;
}

.resume-preview--body p + p {
  margin-top: 10px;
}

.mock-test-card {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.mock-test-card--header {
  height: 120px;
  overflow: hidden;
  background-color: #eee;
}

.mock-test-card--header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mock-test-card--body {
  padding: 15px;
}

.mock-test-card--body h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 4px;
  color: #252525;
}

.mock-test-card--body p {
  font-size: 12px;
  line-height: 16px;
  color: #636363;
  min-height: 32px;
}

.mock-test-card--body ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mock-test-card--body ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #636363;
}

.mock-test-card--body ul li strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.mock-test-card--body ul li strong.subscribed {
  color: #f9000f;
}

.mock-test-card--body ul li strong.price {
  color: #239f87;
}

.mock-test-card--footer a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  justify-content: center;
  height: 44px;
  background: #ffd100;
  border-radius: 0px 0px 4px 4px;
}

.test-full-view {
  margin-bottom: 40px;
}

.test-full-view img {
  max-width: 100%;
}

.test-full-view .links-wrap {
  display: flex;
  align-items: center;
}

.test-full-view .links-wrap a {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00a689;
  text-decoration: underline;
}

.test-full-view .links-wrap a:hover {
  text-decoration: underline;
}

.test-full-view .links-wrap a + a {
  margin-left: 24px;
}

.test-full-view .wrap {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 22px 27px;
}

.test-full-view .wrap h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #252525;
}

.test-full-view .wrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.test-full-view .wrap ul li {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
}

.test-full-view .wrap ul li strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #239f87;
  margin-right: 18px;
}

.test-full-view .wrap ul li a {
  width: 111px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffd100;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.test-full-view .wrap p {
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.test-full-view .wrap p strong {
  font-weight: 600;
}

.my-resume {
  margin-bottom: 17px;
}

.my-resume--header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: flex-end;
}

.my-resume--header a {
  width: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 18px;
  color: #000000;
  background: #ffd100;
}

.my-resume--body .interview-list {
  background: #ffffff;
  padding: 15px;
}

.my-resume--body .interview-list h4 {
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 20px;
  color: #252525;
}

.my-resume--body .interview-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-resume--body .interview-list ul li a {
  width: 143px;
  height: 44px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  background: #ffd100;
}

.my-resume--body .interview-list ul li p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
}

.my-resume--body .interview-list ul li p + p {
  margin-top: 8px;
}

.attempts-list {
  background: #f5fcff;
  margin-bottom: 10px;
  padding: 8px 15px;
}

.attempts-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (min-width: 768px) {
  .attempts-list ul {
    display: flex;
    align-items: center;
  }
}

.attempts-list ul li + li {
  margin-top: 15px;
}

@media screen and (min-width: 768px) {
  .attempts-list ul li + li {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .attempts-list ul li {
    width: calc(100% / 6);
  }
}

.attempts-list ul li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
}

.attempts-list ul li a:hover {
  text-decoration: underline;
}

.attempts-list ul li p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
  margin: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 1023px) {
  .primary-modal {
    min-width: 1px;
    max-height: calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 767px) {
  .my-performance--box {
    height: auto;
    padding: 15px;
    display: block;
  }
  .my-performance--box .performance-chart {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .my-performance h3,
  .subscribed-test h3,
  .practice-mock h3 {
    font-size: 18px;
    line-height: normal;
    margin: 0 0 10px;
  }
  .my-performance--box p {
    line-height: normal;
    font-size: 16px;
    margin: 0;
  }
  .practice-mock > p {
    line-height: normal;
    font-size: 16px;
  }
  .my-performance--box p span {
    display: initial;
  }
  .my-performance--box p + p {
    margin-top: 10px;
  }
  .mock-test-card {
    margin-bottom: 20px;
  }
  .subscribed-test {
    margin-bottom: 6px;
  }
  .advertisement-block {
    padding: 15px;
  }
  .main-structure {
    padding: 15px;
  }
  .primary-page-header h3 {
    font-size: 20px;
  }
  .test-full-view .wrap ul {
    flex-direction: column;
    align-items: flex-start;
  }
  .test-full-view .wrap ul li + li {
    margin-top: 15px;
  }
  .primary-page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .primary-page-header button {
    margin-top: 10px;
  }
  .primary-tabs ul.tabs-list li + li {
    margin-left: 10px;
  }
  .primary-tabs ul.tabs-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .primary-tabs ul.tabs-list li {
    margin: 0 10px 10px;
  }
  .horizontal-card {
    padding: 15px;
  }
  .horizontal-card .wrap ul li + li {
    margin-left: 14px;
  }
  .list-with-delete {
    padding-right: 10px;
  }
  .list-with-delete > svg {
    margin-left: 10px;
  }
  .resume-preview--wrap .main-header {
    padding: 20px;
    display: block;
  }
  .resume-preview--wrap .main-header .wrp {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  .resume-preview--wrap .main-header .button-group {
    justify-content: center;
  }
  .resume-preview--wrap .main-header .icon {
    margin: 0 auto 15px;
  }
  .resume-preview--wrap .main-header--content {
    padding: 20px;
    padding-top: 0;
  }
  .resume-preview--wrap .main-header--wrap h2 {
    font-size: 24px;
  }
  .resume-preview--wrap .main-header--wrap {
    padding-left: 15px;
  }
  .resume-preview--wrap .main-header--wrap p {
    font-size: 16px;
  }
  .resume-preview--wrap .main-header--content .skills {
    padding: 15px;
    margin-top: 15px;
  }
  .resume-preview--header,
  .resume-preview--body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .resume-preview--body p {
    font-size: 16px;
  }
  .resume-preview--header h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .primary-page-header button {
    white-space: nowrap;
  }
}

.dv-star-rating {
  display: flex !important;
  align-items: center;
}

.dv-star-rating label {
  margin: 0;
  font-weight: normal;
  display: block;
  line-height: normal;
}

.dv-star-rating label i {
  font-size: 24px;
  display: block;
  line-height: normal;
}

.video-close {
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.my-performance {
  margin-bottom: 26px;
}

.my-performance--box {
  height: 273px;
  padding: 33px 62px;
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
}

.my-performance--box .performance-chart {
  width: 247px;
  margin-right: 80px;
}

.my-performance--box .performance-chart img {
  max-width: 100%;
}

.my-performance--box p {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.my-performance--box p span {
  display: block;
}

.my-performance--box p + p {
  margin-top: 14px;
}

.my-performance h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  margin: 0 0 25px;
  color: #00a689;
}

.subscribed-test {
  margin-bottom: 26px;
}

.subscribed-test h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  margin: 0 0 25px;
  color: #00a689;
}

.practice-mock > h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  margin: 0 0 4px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.practice-mock > h3 a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #00A689;
}

.practice-mock > h3 a:hover {
  text-decoration: underline;
}

.practice-mock .mock-test-card {
  margin-bottom: 20px;
}

.practice-mock > p {
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.practice-mock > p strong {
  font-weight: 600;
}

.interview-for-practice {
  margin-bottom: 30px;
}

.interview-for-practice h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #111111;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .interview-for-practice h3 {
    font-size: 18px;
  }
}

.interview-for-practice .wrap {
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  padding: 24px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .interview-for-practice .wrap {
    display: block;
  }
}

.interview-for-practice .wrap .image {
  width: 250px;
}

@media screen and (max-width: 767px) {
  .interview-for-practice .wrap .image {
    width: 100%;
    margin-bottom: 15px;
  }
}

.interview-for-practice .wrap .content {
  width: calc(100% - 250px);
  padding-left: 32px;
}

@media screen and (max-width: 767px) {
  .interview-for-practice .wrap .content {
    width: 100%;
    padding-left: 0;
    padding-top: 0;
  }
}

.interview-for-practice .wrap .content p {
  font-size: 16px;
  line-height: 26px;
  color: #666666;
}

.interview-for-practice .wrap .content .action-wrap {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .interview-for-practice .wrap .content .action-wrap {
    margin-top: 0;
  }
}

.interview-for-practice .wrap .content .action-wrap a {
  background: rgba(0, 166, 137, 0.1);
  width: 129px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00A689;
}

.interview-for-practice .wrap .content .action-wrap a:hover {
  background: rgba(0, 166, 137, 0.2);
}

.mock-interviews-box {
  margin-bottom: 30px;
}

.mock-interviews-box.detail {
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  padding: 24px;
}

.mock-interviews-box.detail .wrap {
  display: flex;
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .mock-interviews-box.detail .wrap {
    display: block;
    margin-top: 15px;
  }
}

.mock-interviews-box.detail .wrap .image {
  width: 250px;
  overflow: hidden;
}

.mock-interviews-box.detail .wrap .image img {
  width: 250px;
  height: 125px;
  object-fit: contain;
  background-color: #f5f9ff;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
}

.mock-interviews-box.detail .wrap .content {
  width: calc(100% - 250px);
  padding-left: 32px;
}

@media screen and (max-width: 767px) {
  .mock-interviews-box.detail .wrap .content {
    width: 100%;
    padding-left: 0;
    padding-top: 32px;
  }
}

.mock-interviews-box.detail .wrap .content p {
  font-size: 16px;
  line-height: 26px;
  color: #666666;
}

.mock-interviews-box.detail .wrap .content .action-wrap {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.mock-interviews-box.detail .wrap .content .action-wrap a {
  background: rgba(0, 166, 137, 0.1);
  width: 129px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00A689;
}

.mock-interviews-box.detail .wrap .content .action-wrap a:hover {
  background: rgba(0, 166, 137, 0.2);
}

.mock-interviews-box.detail .action-wrap {
  display: flex;
  align-items: center;
}

.mock-interviews-box.detail .action-wrap a {
  background: rgba(0, 166, 137, 0.1);
  width: 129px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00A689;
}

.mock-interviews-box.detail .action-wrap a:hover {
  background: rgba(0, 166, 137, 0.2);
}

.mock-interviews-box .custom-thumbnail {
  margin-top: 20px;
}

.mock-interviews-box h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #111111;
  margin: 0 0;
}

@media screen and (max-width: 767px) {
  .mock-interviews-box h4 {
    font-size: 18px;
  }
}

.mock-addition-info {
  background: #fff;
  box-shadow: 0 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 15px;
}

.mock-addition-info .mock-warning {
  background: rgba(249, 182, 182, 0.5);
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #FF0000;
  padding: 9px;
  margin-bottom: 25px;
}

.mock-addition-info .mock-btn {
  height: 44px;
  display: inline-flex;
  align-items: center;
  background: #00A689;
  border-radius: 6px;
  padding: 0 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .mock-addition-info .mock-btn {
    padding: 8px;
    font-size: 12px;
    line-height: 1;
    height: auto;
  }
}

.mock-addition-info .mock-btn + h3 {
  margin-top: 28px;
}

.mock-addition-info .mock-btn:hover {
  background: #008d74;
}

.mock-addition-info h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #111111;
}

@media screen and (max-width: 767px) {
  .mock-addition-info h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.mock-addition-info ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .mock-addition-info ul {
    justify-content: space-between;
  }
}

.mock-addition-info ul li {
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 34px;
  background: rgba(0, 166, 137, 0.1);
  padding: 0 20px;
  font-weight: 600;
  font-size: 12px;
  color: #00A689;
  line-height: 18px;
}

@media screen and (max-width: 767px) {
  .mock-addition-info ul li {
    padding: 0;
    font-size: 11px;
    justify-content: center;
    width: calc((100% - 10px) / 2);
  }
}

@media screen and (max-width: 360px) {
  .mock-addition-info ul li {
    width: 100%;
  }
}

.mock-addition-info ul li + li {
  margin-left: 16px;
}

@media screen and (max-width: 767px) {
  .mock-addition-info ul li + li {
    margin-left: 0;
  }
}

@media screen and (max-width: 360px) {
  .mock-addition-info ul li + li {
    margin-top: 10px;
  }
}

.mock-attempt-info {
  margin-top: 30px;
}

.mock-attempt-row {
  display: flex;
  align-items: center;
  background: #F5F5F5;
  border-radius: 4px;
}

.mock-attempt-row + .mock-attempt-row {
  margin-top: 11px;
}

@media screen and (max-width: 767px) {
  .mock-attempt-row {
    display: block;
    border: 1px solid #eee;
  }
}

.mock-attempt-col {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  padding: 16px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .mock-attempt-col {
    width: 100% !important;
    justify-content: space-between;
    padding: 10px;
  }
  .mock-attempt-col:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
}

.mock-attempt-col:first-child {
  width: 150px;
}

.mock-attempt-col:nth-child(2) {
  flex-grow: 1;
}

.mock-attempt-col p {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #010101;
}

@media screen and (max-width: 767px) {
  .mock-attempt-col p {
    font-size: 16px;
  }
}

.mock-attempt-col a {
  color: #00A689;
}

.mock-attempt-col a:hover {
  text-decoration: underline;
}

.mock-attempt-col a + a {
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  .mock-attempt-col a + a {
    margin-left: 0;
  }
}

.sidebar-dark-dodger-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}

.main-structure {
  padding: 30px 40px;
}

@media screen and (max-width: 767px) {
  .main-structure {
    padding: 15px;
  }
}

embed {
  display: block;
  max-width: 100%;
}

body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Source Sans Pro", Arial, sans-serif;
}

@media screen and (max-width: 565px) {
  .my-progress--action {
    width: 100%;
    flex-direction: row;
  }
  .my-progress--action a + a {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .primary-page-header {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .attempts-list {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 15px;
  }
  .attempts-list ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
  .attempts-list ul li p {
    color: #000;
    font-weight: 600;
  }
  .attempts-list ul li span {
    color: #000;
    font-size: 14px;
    font-weight: normal;
  }
  .attempts-list ul li + li {
    border-top: 1px solid #eee;
    margin-top: 0;
  }
  .batch-accordion .accordion .card-header p div p {
    font-size: 14px;
  }
  .batch-accordion .accordion .card-header p div p + p {
    padding-left: 15px;
  }
  .student-navbar .right .button-dropdown {
    display: none;
  }
  .student-navbar {
    z-index: 2;
    position: relative;
  }
  .mobile-menu-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.15);
  }
  .mobile-menu-tabs a {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    padding: 0;
    width: calc(100% / 4);
    line-height: 1;
    /* identical to box height, or 231% */
    text-align: center;
    letter-spacing: -0.3px;
    color: #666666;
  }
  .mobile-menu-tabs a svg {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .mobile-menu-tabs a.active {
    font-weight: 600;
    color: #00a689;
  }
  .button-dropdown.dropdown {
    padding: 15px;
    background: #f5f9ff;
    z-index: 1;
  }
  .button-dropdown.dropdown .dropdown-toggle {
    width: 100%;
  }
  .button-dropdown.dropdown .dropdown-menu {
    width: calc(100% - 30px) !important;
    left: 15px;
  }
  .avtar-with-text .primary-avtar {
    margin-right: 10px;
  }
  .primary-paginated-table--row {
    display: block;
  }
  .primary-paginated-table--col {
    padding: 5px;
    width: 100% !important;
  }
  .main-mid {
    width: 100%;
  }
  .primary-batch-tabs {
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .primary-batch-tabs::-webkit-scrollbar {
    display: none;
  }
  .primary-batch-tabs li {
    white-space: nowrap;
  }
  .profile-box {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 500px) {
  .profile-box--header {
    display: block;
  }
  .profile-box--header ul {
    margin: 0 -5px;
    flex-wrap: wrap;
  }
  .profile-box--header ul li {
    padding: 10px;
    height: auto;
    width: calc((100% / 3) - 10px);
    flex-direction: column;
    margin: 0 5px !important;
  }
  .profile-box--header ul li + li {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .profile-box--header .image {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 500px) {
  .profile-box--header .image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .profile-box--header .image img {
    margin: 0 auto;
    display: block;
    background: #f5f9ff;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .student-navbar .left .nav {
    display: flex;
  }
  .student-navbar .left .nav a {
    padding: 0;
    font-size: 16px;
    text-transform: none;
  }
  .student-navbar .left .nav a + a {
    margin-left: 20px;
  }
  .student-navbar .left .logo {
    margin-right: 30px;
  }
  .student-navbar .left .logo img {
    width: 120px;
  }
  .notification-wrapper .notification-toggle {
    margin: 0 15px;
  }
  .button-dropdown .dropdown-toggle {
    min-width: 1px;
    font-size: 14px !important;
  }
}

.full-page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.full-page-loader p {
  font-weight: 600;
  font-size: 18px;
  margin: 15px 0 0;
  color: #fff;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-state {
  font-size: 0 !important;
  pointer-events: none;
}

.loading-state.primary-button[shape="popup"] .is-loading::after,
.loading-state.primary-button[shape="popup"] .loader {
  border-color: #fff;
  border-bottom-color: #177c68;
}

.loading-state.primary-button[shape="primary"] .is-loading::after,
.loading-state.primary-button[shape="primary"] .loader {
  border-color: #d4bc04;
  border-bottom-color: #000;
}

.loading-state.primary-button[shape="secondary"] .is-loading::after,
.loading-state.primary-button[shape="secondary"] .loader {
  border-color: #ced3da;
  border-bottom-color: #000;
}

.loading-state .is-loading::after,
.loading-state .loader {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 2px;
  margin: 0 auto;
}

.user-select-none {
  user-select: none;
}

.descriptive {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.descriptive__progress {
  position: relative;
  padding: 20px;
  border-top: 1px solid #f3f3f3;
}

.descriptive__progress ul {
  padding: 0;
  list-style: none;
  max-width: 289px;
  margin: 15px auto 0;
}

@media screen and (min-width: 768px) {
  .descriptive__progress ul {
    margin-top: 0;
  }
}

.descriptive__progress ul + .btn {
  display: block;
  width: 100%;
  max-width: 289px;
  margin: 16px auto 0;
  line-height: 1;
  height: 44px;
}

.descriptive__progress ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.descriptive__progress ul li + li {
  margin-top: 15px;
}

.descriptive__progress ul li span {
  font-size: 18px;
  line-height: normal;
  color: #505050;
}

.descriptive__progress ul li strong {
  font-size: 16px;
  line-height: normal;
  color: #239f87;
}

@media screen and (min-width: 1024px) {
  .descriptive__progress {
    padding: 50px;
  }
}

.descriptive__header {
  padding: 20px;
}

@media screen and (min-width: 1024px) {
  .descriptive__header {
    padding: 50px;
  }
}

.descriptive__header h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #252525;
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .descriptive__header h3 {
    font-size: 24px;
    margin-bottom: 23px;
  }
}

.descriptive__header p {
  font-size: 16px;
  line-height: 18px;
  color: #252525;
  margin: 0;
}

@media screen and (min-width: 1024px) {
  .descriptive__header p {
    font-size: 24px;
  }
}

.descriptive__body .wrap {
  position: relative;
  padding: 20px;
  border-top: 1px solid #f3f3f3;
}

@media screen and (min-width: 1024px) {
  .descriptive__body .wrap {
    padding: 50px;
    padding-left: 120px;
  }
}

.descriptive__body .wrap .answer-block {
  margin-top: 24px;
}

.descriptive__body .wrap .answer-by-student {
  background: #eeffff;
  padding: 15px;
}

.descriptive__body .wrap .answer-by-student + ul {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .descriptive__body .wrap .answer-by-student + ul {
    margin-bottom: 20px;
  }
}

.descriptive__body .wrap .answer-by-student + ul li {
  width: 100px;
  height: 100px;
  margin: 20px 10px 0;
  background: #c4c4c4;
}

.descriptive__body .wrap .answer-by-student + ul li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.descriptive__body .wrap .answer-by-student p a {
  color: #1ea087;
}

.descriptive__body .wrap__header {
  margin-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  .descriptive__body .wrap__header {
    display: flex;
    flex-wrap: wrap;
  }
}

.descriptive__body .wrap__header p {
  width: 100%;
}

.descriptive__body .wrap__header p + p {
  margin-top: 0 !important;
}

.descriptive__body .wrap__header span {
  position: absolute;
  left: 50px;
  top: 79px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  color: #252525;
  height: 46px;
  background: #f3f3f3;
  width: 46px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1023px) {
  .descriptive__body .wrap__header span {
    font-size: 18px;
    position: static;
    width: auto;
    height: auto;
    background-color: transparent;
    line-height: 1;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.descriptive__body .wrap__header em {
  font-size: 18px;
  line-height: 18px;
  font-style: normal;
  margin-bottom: 10px;
  display: block;
  color: #ff0000;
}

.descriptive__body .wrap .feedback-by-educator ul {
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.descriptive__body .wrap .feedback-by-educator ul li {
  width: 67px;
  height: 67px;
  margin: 20px 5px 0;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
}

.descriptive__body .wrap .feedback-by-educator ul li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.descriptive__body .wrap p {
  font-size: 16px;
  line-height: 23px;
  margin: 0;
  color: #252525;
}

@media screen and (max-width: 1023px) {
  .descriptive__body .wrap p {
    font-size: 14px;
  }
}

.descriptive__body .wrap p a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0a836b;
}

.descriptive__body .wrap p a:hover {
  text-decoration: underline;
}

.descriptive__body .wrap p + p {
  margin-top: 10px;
}

.descriptive__body .wrap p strong {
  font-weight: 700;
}

.try-now {
  color: #00a689;
  font-size: 14px;
  font-weight: 600;
}

.rounded-video-player {
  max-width: 100%;
  height: auto !important;
}

.rounded-video-player video {
  border-radius: 15px;
}

.coupon-details {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 12px 17px;
}

.coupon-details h3 {
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #35363b;
}

.coupon-details h3 span {
  font-size: 12px;
  line-height: 17px;
  margin-left: 5px;
  color: #35363b;
}

.coupon-details ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.coupon-details ul li {
  font-size: 12px;
  line-height: 17px;
  color: #35363b;
}

.coupon-details ul li.danger {
  color: #f20808;
}

.coupon-details ul li strong {
  font-weight: 600;
}

.coupon-details ul li + li {
  margin-left: 30px;
}

.coupon-modal {
  width: 820px;
  max-width: 100%;
}

.coupon-modal p {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  color: #000000;
}

@media screen and (max-width: 767px) {
  .coupon-modal button span {
    display: none;
  }
}

.coupon-modal ul {
  display: flex;
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 767px) {
  .coupon-modal ul:nth-child(3) {
    display: block;
  }
  .coupon-modal ul:nth-child(3) li:first-child {
    margin-bottom: 10px;
  }
}

.coupon-modal ul + button {
  margin-top: 20px;
  width: 226px;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .coupon-modal ul + button {
    width: 100%;
  }
}

.coupon-modal ul li {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.coupon-modal ul li .wrap form {
  display: flex;
  align-items: center;
}

.coupon-modal ul li .success {
  font-size: 15px;
  line-height: 20px;
  color: #00a689;
}

.coupon-modal ul li .error {
  font-size: 15px;
  line-height: 20px;
  color: #cf3030;
}

.coupon-modal ul li:first-child {
  width: 150px;
}

.coupon-modal ul li p {
  font-weight: normal;
  font-size: 20px;
  margin-top: 17px;
  line-height: 27px;
}

.coupon-modal ul li strong {
  font-weight: 600;
  margin-top: 17px;
  display: block;
  font-size: 24px;
  line-height: 27px;
}

.coupon-modal h3 {
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .coupon-modal h3 {
    font-size: 18px;
  }
}

.google-btn {
  background-color: white;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px 10px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.google-btn svg {
  margin-right: 20px;
  width: 20px;
  height: 20px;
}

body .react-datepicker__close-icon {
  right: 35px;
  padding: 0;
}

body .react-datepicker__close-icon::after {
  background-color: #ccc;
}

.secondary-header {
  height: 71px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 1280px) {
  .secondary-header {
    padding: 0 40px;
  }
}

.secondary-header span {
  color: #239f87;
  font-weight: 600;
}

.secondary-header span img {
  margin-left: 0.5rem;
}

.secondary-header p {
  font-weight: 600;
  font-size: 24px;
}

.overall-test-info {
  font-weight: 700;
  font-size: 14px;
  line-height: 186%;
  text-align: right;
  color: #000000;
  margin-bottom: 15px;
}

.overall-test-info.secondary {
  margin-bottom: 10px;
  padding: 1px 12px;
  font-weight: normal;
  background: #F8F8F8;
  color: #626262;
}

.topic-tabs {
  margin: 0 0 8px;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
}

.topic-tabs li {
  background: #FFFFFF;
  padding: 0 15px;
  border: 1px solid #CECECE;
  display: flex;
  align-items: center;
  height: 38px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  line-height: 162%;
  color: #000000;
}

.topic-tabs li.active {
  background: #00C385;
  border-color: #00C385;
}

.test-info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.custom-table-ui_row {
  display: flex;
}

.custom-table-ui_body .custom-table-ui_row {
  align-items: flex-start;
  background: #F8F8F8;
}

.custom-table-ui_body .custom-table-ui_row.secondary {
  background: #FBF9FF;
}

.custom-table-ui_body .custom-table-ui_row.secondary + .secondary {
  margin-top: 0 !important;
  border-top: none;
}

.custom-table-ui_body .custom-table-ui_row:not(:first-child) {
  margin-top: 1px;
}

.custom-table-ui_body .custom-table-ui_col {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
}

.custom-table-ui_body .custom-table-ui_col input {
  width: 46px;
  padding: 0 8px;
  height: 27px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  text-align: left;
  color: #262424;
  border: 1px solid #c4cdd5 !important;
  box-sizing: border-box;
}

.custom-table-ui_col {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 217%;
  color: #000000;
  align-items: center;
  padding: 12px 15px;
  width: 200px;
}

.custom-table-ui_col .custom-control.custom-checkbox label.custom-control-label {
  font-weight: 600;
  text-transform: none;
  font-size: 12px;
  color: #000000;
}

.custom-table-ui_col svg {
  cursor: pointer;
}

.custom-table-ui_col:first-child {
  width: 60px;
}

.custom-table-ui_col:last-child {
  width: 50px;
}

.custom-table-ui_col:nth-child(2) {
  width: 160px;
}

.custom-table-ui_col:nth-child(3) {
  width: 160px;
}

.custom-table-ui_col:nth-child(4) {
  width: 220px;
}

.custom-table-ui_col:nth-child(5) {
  width: calc(100% - (60px + 50px + 160px + 160px + 220px + 90px + 90px));
}

.custom-table-ui_col:nth-child(6) {
  width: 76px;
}

.custom-table-ui_col:nth-child(7) {
  width: 90px;
}

/*# sourceMappingURL=theme.css.map */