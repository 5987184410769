.inline-upload-box-ui {
  .image-box {
    width: 100%;
    outline: none;
    label {
      margin: 0;
      outline: none;
    }
  }
}

.custom-file {
  display: block !important;
  height: 36px !important;
  .custom-file-label {
    margin: 0;
    box-shadow: none;
    height: 38px;
    border: 1px solid #e1e1e1;
    font-weight: normal;
    color: #000;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;
    display: flex;
    align-items: center;
    &:after {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 80px;
      background: transparent;
      border: none;
      color: #000;
      font-weight: 600;
    }
  }
}

.primary-helper-text {
  color: grey;
  margin-top: 3px;
  font-size: 12px;
}
.file-upload-with-helpertext {
  margin-bottom: 15px;
  .form-group {
    margin-bottom: 0;
  }
}
