.primary-form-control {
  height: 40px;
  border-radius: 6px;
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  display: block;
  width: 100%;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 11px;
  text-align: left;
  color: #111111;
  border: 1px solid #c4cdd5 !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  &:focus {
    border-color: #239f87 !important;
    box-shadow: none !important;
  }
  &:disabled {
    opacity: 0.55;
  }
}

textarea.primary-form-control {
  height: auto;
  resize: none;
}
.input-group {
  position: relative;
  border: none;
  .input-group-text {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    border: none;
    svg {
      fill: #acb0b5;
    }
  }
  .input-group-append {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .input-group-prepend {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .primary-form-control {
    &.form-control-prepended {
      padding-left: 40px !important;
      border-radius: 6px !important;
    }
    &.form-control-appended {
      padding-right: 40px !important;
      border-radius: 6px !important;
    }
  }
}

.form-group {
  margin-bottom: 15px;
}

.custom-ReactSelect,
.custom-AsyncSelect {
  & > div {
    & > div {
      min-height: 40px;
      border-radius: 6px;
      border: 1px solid #c4cdd5 !important;
      box-sizing: border-box;
      box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
      background: linear-gradient(180deg, #fff 0, #f9fafb 100%);
      & > div {
        &:first-child {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
        }
        & + div {
          & > div {
            padding: 7px 8px;
          }
          & > span {
            display: none;
          }
        }
      }
    }
  }
}

.simple-file-upload {
  height: 36px;
  label {
    margin: 0;
    box-shadow: none;
    border: 1px solid #e1e1e1;
    height: 100%;
    padding: 0 0 0 0.75rem;
    border-radius: 0.25rem;
    /* cursor: pointer; */
    background-color: #fff;
    display: flex;
    align-items: center;
    p {
      width: calc(100% - 80px);
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400;
      font-family: "Source Sans Pro";
      font-size: 14px;
      color: #000;
      line-height: 25px;
    }

    span {
      padding: 0;
      width: 80px;
      text-align: center;
      font-size: 14px;
      border: none;
      color: #000;
      font-weight: 600;
    }
    input {
      display: none;
    }
  }
}

.file-uploading {
  // height: 70px;
  margin-right: 5px;
  margin-top: 10px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  display: flex;
  line-height: 1;
  background-color: #fff;
  align-items: center;
  padding-right: 30px;
  // .load-info {
  //   position: absolute;
  //   display: flex;
  //   background-color: rgba(0,0,0,.1);
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  //   left: 0;
  //   border-radius: 5px;
  //   flex-direction: column;
  //   width: 100%;
  //   small {
  //     margin-top: 5px;
  //   }
  // }
  // .loader {
  //   border-color: #02c6a2;
  //   border-bottom-color: transparent;
  // }
  .remove-icon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    right: -8px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d0d7dd;
    cursor: pointer;
  }
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    // background: #f2f6f9;
    border-radius: 5px;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
  }
  // .wrap {
  //   display: flex;
  //   flex-grow: 1;
  //   flex-wrap: wrap;
  //   flex-direction: column;
  // }
  p {
    margin: 0 0 5px;
    // flex-grow: 1;
    width: 100%;
    font-size: 14px;
  }
  small {
    display: block;
  }
}
