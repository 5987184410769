.primary-image-picker {
	text-align: center;
	width: 160px;
	p {
		font-size: 14px;
		margin: 0 0 1px;
		color: #000000;
	}
	span {
		display: block;
		line-height: 1.42;
		opacity: 0.5;
		font-size: 12px;
		color: #000000;
	}
	.picker-ui {
		margin: 0 0 6px;
		display: block;
		background-size: cover;
		background-position: center center;
		height: 160px;
		border-radius: 6px;
		border: solid 1px #e6e6e6;
		background-color: #ffffff;
		input {
			display: none;
		}
	}
}

.primary-table-view {
	margin: 20px 0 30px;
	&--head {
		.primary-table-view--row {
			margin-bottom: 10px;
			& > div {
				display: flex;
				align-items: center;
				.Checkbox {
					width: auto;
					height: auto;
					position: static;
					svg {
						width: 16px;
						margin-right: 7px;
						transform: scale(1);
					}
				}
				label {
					margin: 0;
					font-family: "Source Sans Pro";
					font-weight: normal;
					font-size: 13px;
					line-height: 25px;
					text-transform: uppercase;
					color: #48506a;
				}
			}
		}
		.heading {
			font-family: "Source Sans Pro";
			font-weight: normal;
			font-size: 13px;
			line-height: 25px;
			text-transform: uppercase;
			color: #48506a;
		}
	}
	&--body {
		.heading {
			font-family: "Source Sans Pro";
			font-weight: normal;
			font-size: 14px;
			line-height: 25px;
			text-align: left;
			color: #000;
		}
	}
	&--row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		& + .primary-table-view--row {
			margin-top: 10px;
		}
		.heading {
			width: calc(55px + 141px);
		}
		.sectional-timing {
			width: calc(16px + 7px + 110px + 47px);
		}
		.total-timing {
			width: calc(16px + 7px + 77px + 47px);
		}
		.negative-marking {
			width: calc(16px + 7px + 107px + 47px);
		}
		.not-attempted {
			width: calc(16px + 7px + 85px + 47px);
		}
	}
}

.secondary-input-group {
	display: flex;
	border: 1px solid #e6e6e6;
	overflow: hidden;
	border-radius: 4px;
	&:hover {
		border-color: #239f87;
	}
	i {
		font-style: normal;
		font-size: 14px;
		line-height: 1;
		display: flex;
		align-items: center;
		padding-left: 15px;
		color: #000;
		background-color: #fff;
	}
	.primary-form-control {
		width: auto;
		border: none !important;
		padding-left: 0;
		flex-grow: 1;
	}
}

.primary-input-group {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	.form-group {
		width: 70px;
	}
	input {
		font-family: "Source Sans Pro";
		font-weight: normal;
		padding: 6px 12px;
		font-size: 13px;
		line-height: 25px;
		text-align: left;
		color: #48506a;
	}
	p {
		margin: 8px 0 0 8px;
		font-family: "Source Sans Pro";
		font-weight: normal;
		font-size: 13px;
		line-height: 25px;
		text-align: left;
		color: #48506a;
	}
}

.question-bank {
	width: 879px;
	&--footer {
		margin-top: 20px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		p {
			font-family: "Source Sans Pro";
			font-weight: normal;
			font-size: 16px;
			margin: 0;
			position: absolute;
			left: 0;
			line-height: 1;
			text-align: left;
			color: #000;
		}
	}
	&--filter {
		.form-group {
			margin: 0;
		}
		.wrap {
			display: flex;
			align-items: center;
		}
		.input-with-icon {
			flex-grow: 1;
		}
		h3 {
			font-family: "Source Sans Pro";
			font-weight: 600;
			font-size: 16px;
			text-align: left;
			margin: 0 30px 0 0;
			color: #000;
			display: flex;
			align-items: center;
			img {
				margin-left: 8px;
			}
		}
		ul {
			list-style: none;
			margin: 20px 0 0;
			padding: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			li {
				& + li {
					margin-left: 10px;
				}
				// width: calc((100% - (111px + 50px)) / 4);
				flex-grow: 1;
				// &:last-child {
				//   width: 111px;
				//   margin-left: 20px;
				// }
				select {
					height: 30px;
					font-family: "Source Sans Pro";
					font-weight: normal;
					font-size: 13px;
					line-height: 13px;
					text-align: left;
					color: #a0a0a0;
				}
				button {
					width: 100%;
					height: 36px;
					border-radius: 4px;
					background: transparent;
					border: 1px solid #00b8be;
					font-family: "Source Sans Pro";
					text-transform: uppercase;
					text-align: center;
					font-weight: 600;
					font-size: 12px;
					line-height: 15px;
					color: #35363b;
					transition: all 0.3s ease-in-out;
					&:hover {
						transition: all 0.3s ease-in-out;
						background-color: #00b8be;
						color: #fff;
					}
				}
			}
		}
	}
	&--questions {
		margin-top: 20px;
		ul {
			display: flex;
			align-items: flex-start;
			margin: 0;
			padding: 0;
			list-style: none;
			& + ul {
				margin-top: 20px;
			}
			li {
				font-family: "Source Sans Pro" !important;
				font-weight: normal;
				font-size: 13px;
				line-height: 15px;
				text-align: left;
				color: #303956;
				.Checkbox {
					width: auto;
					display: block;
					height: auto;
					position: static;
				}
				p {
					margin: 0;
				}
				label {
					display: none;
				}
				svg {
					transform: scale(1);
					height: 16px;
					width: 16px;
				}
				&:not(:first-child) {
					width: 150px;
					// text-align: center;
					padding-left: 10px;
				}
				&:nth-child(2) {
					flex-grow: 1;
				}
				.primary-checkbox span {
					font-family: "Maven Pro";
					font-weight: 500;
					font-size: 13px;
					line-height: 15px;
					text-align: left;
					color: #303956;
					text-transform: none;
				}
			}
		}
	}
}

.input-with-icon {
	position: relative;
	input {
		padding-left: 40px;
	}
	svg {
		position: absolute;
		left: 13px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.add-questions {
	&--header {
		display: flex;
		margin-bottom: 20px;
		align-items: center;
		justify-content: space-between;
		.add-questions-btn {
			width: 147px;
			height: 30px;
			border-radius: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f5f7fa;
			border: 1px solid #acb0b5;
			font-family: "Source Sans Pro";
			font-weight: normal;
			font-size: 13px;
			line-height: 15px;
			text-align: left;
			color: #303956;
			img {
				margin-right: 6px;
			}
		}
	}
}

.primary-table {
	.form-group {
		margin: 0;
	}
	.primary-form-control {
		width: 46px;
		padding: 0 8px;
		height: 27px;
		font-family: "Source Sans Pro";
		font-weight: normal;
		font-size: 12px;
		line-height: 13px;
		text-align: center;
		color: #262424;
	}
	&--head {
		.primary-table--row {
			padding: 0 16px 8px;
			.invalid-feedback {
				width: 100%;
				margin: 0;
			}
			.Checkbox {
				position: static;
				width: auto;
				height: auto;
				display: block;
				& + label {
					margin: 0 0 0 7px;
				}
			}
			svg {
				display: block;
				transform: scale(1);
				width: 16px;
				height: 16px;
			}
		}
		.primary-table--col {
			font-weight: bold;
			font-size: 13px;
			line-height: 15px;
			justify-content: space-between;
			display: flex;
			align-items: center;
			text-align: left;
			color: #303956;
		}
	}
	&--body {
		// .primary-table--col {
		//   height: 27px;
		// }
		.primary-table--row {
			background-color: #fff;
			// height: 55px;
			// .wrap {
			//   height: 33px;
			// }

			&.expand {
				// height: auto;
				align-items: flex-start;
				.expand-icon {
					transform: rotate(90deg);
				}
			}
			& + .primary-table--row {
				margin-top: 1px;
			}
		}
	}
	&--row {
		display: flex;
		overflow: hidden;
		padding: 14px 16px;
		flex-wrap: wrap;
		align-items: center;
		&:not(.expand) {
			.question,
			.correct-answer,
			.explanation,
			.options {
				display: none;
			}
		}
	}
	&--col {
		padding: 0 12px;

		.question {
			margin-top: 9px;
		}
		.options {
			margin-top: 6px;
			strong {
				color: #189f06;
			}
		}

		.correct-answer {
			margin-top: 10px;
			strong {
				color: #f00;
			}
		}

		.explanation {
			margin-top: 6px;
		}

		&:last-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		strong {
			display: block;
			font-family: "Source Sans Pro";
			font-weight: bold;
			margin-bottom: 2px;
			font-size: 13px;
			line-height: 15px;
			text-align: left;
		}
		img {
			cursor: pointer;
		}
		p {
			margin: 0;
			font-family: "Source Sans Pro";
			font-weight: normal;
			font-size: 13px;
			line-height: 15px;
			text-align: left;
			color: #303956;
		}
		&:first-child {
			// width: calc(44px + 45px);
			width: 120px;
		}
		&:nth-child(2) {
			width: 120px;
			// width: calc(31px + 115px);
		}
		&:nth-child(3) {
			// width: calc(
			//   100% - (44px + 45px + 31px + 115px + 46px + 20px + 14px + 24px + 14px)
			// );
			width: calc(100% - (120px + 120px + 137px));
		}
		&:last-child {
			// width: calc(46px + 20px + 14px + 24px + 14px);
			width: 137px;
		}
	}
}

.primary-field {
	width: 46px;
	padding: 0 8px;
	height: 27px;
	font-family: "Source Sans Pro";
	font-weight: normal;
	font-size: 12px;
	line-height: 13px;
	text-align: left;
	color: #262424;
}

.test-reult-explanation {
	padding: 28px;
	.explanation-tabs {
		margin-bottom: 30px;
		ul {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				font-size: 18px;
				line-height: 32px;
				color: #6f6f6f;
				&.active {
					font-weight: 600;
					color: #239f87;
					position: relative;
					&:after {
						content: "";
						height: 2px;
						width: 100%;
						background-color: #ffd100;
						position: absolute;
						bottom: -2px;
						left: 0;
					}
				}
				& + li {
					margin-left: 50px;
				}
			}
		}
	}
}

.secondary-table {
	@media screen and (max-width: 767px) {
		overflow: auto;
	}
	&--body {
		.secondary-table--row {
			& + .secondary-table--row {
				margin-top: 5px;
			}
			h3 {
				font-weight: 600;
				font-size: 18px;
				line-height: 32px;
				margin: 0;
				color: #6f6f6f;
			}
			.wrap {
				display: flex;
				align-items: flex-start;
			}
		}
	}
	&--col {
		display: flex;
		align-items: center;
		@media screen and (max-width: 767px) {
			flex-shrink: 0;
		}
		span {
			flex-grow: 1;
			img {
				margin: 0 12px;
				display: block;
			}
		}
		a {
			font-size: 18px;
			line-height: 32px;
			text-decoration: underline;
			color: #6f6f6f;
			@media screen and (max-width: 767px) {
				font-size: 16px;
				line-height: normal;
			}
		}
		@media screen and (min-width: 768px) {
			p {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				display: block;
				margin: 0;
			}
		}
		padding: 9px 20px;
		&:first-child {
			width: 73px;
		}
		&:nth-child(2) {
			width: calc(100% - (73px + 139px + 80px + 117px + 250px));
			@media screen and (max-width: 767px) {
				width: 200px;
			}
		}
		&:nth-child(3) {
			width: 139px;
		}
		&:nth-child(4) {
			width: 80px;
		}
		// &:nth-child(5) {
		//   width: 117px;
		// }
		&:last-child {
			width: calc(250px + 117px);
			@media screen and (max-width: 767px) {
				width: 230px;
			}
		}
	}
	&--header {
		margin-bottom: 13px;
		.secondary-table {
			&--col {
				padding: 4px 20px;
			}
			&--row {
				height: 40px;
				display: flex;
				align-items: center;
				background: #f8f8f8;
			}
		}
	}
}

.question-responses {
	p {
		margin: 0;
	}

	label.d-flex {
		border-bottom: 1px dashed #ccc;
		padding: 10px 0;
		margin: 0;
	}
}

.priamry-test-card {
	background: #ffffff;
	box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
	border-radius: 8px;
	// overflow: hidden;
	text-align: center;
	margin-bottom: 28px;
	padding: 20px;
	cursor: pointer;
	position: relative;
	.test-card-footer {
		background: #a9eadf;
		border-radius: 0px 0px 8px 8px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #097461;
		margin: 0 -20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;

		.custom-control-label {
			font-weight: 600 !important;
			color: #35363b !important;
			&::before {
				background-color: transparent;
				border-color: #239f87;
			}
		}

		&.view-report {
			background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
			font-weight: normal;
			color: #000000;
			text-decoration: underline;
		}
	}
	&.subscribed {
		&::before {
			// background-image: url(../src/assets/subscribed.svg);
			background-image: url(../../dist/images/batch/subscribed.svg);
			width: 64px;
			height: 64px;
			position: absolute;
			left: -8px;
			content: "";
			top: -8px;
		}
	}
	img {
		width: 76px;
		height: 76px;
		border-radius: 50%;
		margin: 0 auto 17px;
		display: block;
		object-fit: cover;
		background-color: #eee;
	}
	p {
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 7px;
		color: #252525;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0 0 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		li {
			font-size: 16px;
			line-height: 160%;
			color: #636363;
			position: relative;
			&.active {
				color: #00a689;
			}
			& + li {
				margin-left: 16px;
				&::before {
					content: "";
					width: 4px;
					height: 4px;
					background: #c4c4c4;
					position: absolute;
					border-radius: 50%;
					left: -10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.batch-accordion.test-accordian {
	.accordion {
		.card-body {
			.test-wrap {
				.test-card {
					.wrap {
						img {
							width: 50px;
							height: 50px;
							display: block;
							margin: 0 auto 16px;
							border-radius: 50%;
							object-fit: cover;
						}
						h3 {
							margin-bottom: 15px;
						}
					}
				}
			}
		}
		.card-header {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				width: 3px;
				height: 20px;
				left: 0;
				top: 50%;
				transform: translateY(-50%);

				background: #00a689;
				border-radius: 0px 2px 2px 0px;
			}
			p {
				color: #111111;
			}
		}
	}
}

.test-page {
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	background-color: #fff;
	&--header {
		.left-col {
			display: flex;
			align-items: center;
			padding: 10px 20px !important;
			border-bottom: 1px solid rgba(182, 182, 182, 0.6);
			@media screen and (max-width: 767px) {
				padding: 10px 10px !important;
			}
			p {
				margin: 0;
			}
		}
		.right-col {
			padding: 10px 20px;
			border-bottom: 1px solid rgba(182, 182, 182, 0.6);
			border-left: none !important;
			.timer {
				p {
					line-height: normal;
					margin-bottom: 5px;
					text-transform: none !important;
					margin: 0 0 5px;
					font-weight: 600;
					font-size: 13px;
					display: block;
					font-family: "Source Sans Pro", sans-serif;
					color: #6f6f6f;
				}
				span {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					// border: 1px solid rgba(182, 182, 182, 0.26);
					// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
					// height: 42px;
					// border-radius: .25rem;
					// padding: 0 .75rem;
					font-size: 30px;
					font-weight: 800;
					line-height: 1;
					color: #239f87;
				}
			}
		}
	}
	&--body {
		height: calc(100vh - (51px + 56px));
		.section-wrap {
			overflow-y: auto;
			height: calc(100% - 42px);
			overflow-x: hidden;
		}
		.right-col {
			padding-bottom: 10px !important;
			transition: all ease-in-out 0.3s;
			position: relative;
			.toggle-icon {
				width: 35px;
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #f9d54d;
				color: #000;
				position: absolute;
				left: -17.5px;
				top: 11px;
				border-radius: 50%;
			}
			&.hide {
				margin-right: -260px;
				transition: all ease-in-out 0.3s;
			}
			padding: 20px;
			.current-section {
				margin-bottom: 20px;
				p {
					line-height: normal;
					margin-bottom: 5px;
					text-transform: none !important;
					margin: 0 0 5px;
					font-weight: 600;
					font-size: 13px;
					display: block;
					font-family: "Source Sans Pro", sans-serif;
					color: #6f6f6f;
				}
				span {
					display: flex;
					align-items: center;
					border: 1px solid rgba(182, 182, 182, 0.26);
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
					height: 42px;
					border-radius: 0.25rem;
					padding: 0 0.75rem;
					font-size: 16px;
					font-weight: normal;
					line-height: 1;
					color: #000;
				}
			}

			.q-pallete {
				height: 100%;
				&--body {
					height: calc(100% - 32px);
					display: flex;
					flex-direction: column;
					& > .flex-grow-1 {
						overflow-y: auto;
						height: calc(100% - 42px);
						overflow-x: hidden;
					}
					.status-wrap {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: auto;

						& > div {
							width: 50%;
						}
					}
					ul {
						list-style: none;
						margin: 0 -5px;
						padding: 0;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						// justify-content: space-between;
						li {
							display: flex;
							align-items: center;
							// width: 38px;
							width: 30px;
							height: 30px;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 0 7px 15px;
							border: 1px solid;
							// margin: 0 9px 10px;
							// margin:10px;
							// &:child(6n+6){
							//   margin-right:0;
							// }
							cursor: pointer;
						}
					}
				}
				&--header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 10px;
					p {
						line-height: normal;
						margin-bottom: 5px;
						text-transform: none !important;
						margin: 0 0 5px;
						font-weight: 600;
						font-size: 13px;
						display: block;
						font-family: "Source Sans Pro", sans-serif;
						color: #6f6f6f;
					}
					span {
						// display: flex;
						// align-items: center;
						// border: 1px solid rgba(182, 182, 182, 0.26);
						// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
						// height: 42px;
						// border-radius: .25rem;
						// padding: 0 .75rem;
						font-size: 13px;
						text-decoration: underline;
						font-weight: 600;
						color: #239f87;
						line-height: 1;
						cursor: pointer;
					}
				}
			}
		}
		.left-col {
			img {
				max-width: 100%;
				display: block;
			}
			.position-sticky {
				top: 0;
			}
			.badge-pill {
				margin-bottom: 10px;
			}
		}
	}
	&--footer {
		.right-col {
			background: #239f87;
			padding: 0;
			display: flex;
			align-items: center;
			border-left: none !important;
			.btn {
				width: 100%;
				height: 100%;
				font-size: 26px;
				text-transform: uppercase;
			}
		}
		.left-col {
			border-top: 1px solid rgba(182, 182, 182, 0.6);
			display: flex;
			padding: 10px 20px !important;
			align-items: center;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				padding: 10px 10px !important;
			}
			.button-group {
				display: flex;
				@media screen and (max-width: 767px) {
					.btn {
						font-size: 14px !important;
					}
				}
			}
		}
	}
	&--footer,
	&--header,
	&--body {
		.left-col {
			padding: 20px;
		}
	}
	&--footer,
	&--header,
	&--body {
		display: flex;
		// flex-wrap: wrap;
		.left-col {
			flex-grow: 1;
		}
		.right-col {
			width: 300px;
			flex-shrink: 0;
			border-left: 1px solid rgba(182, 182, 182, 0.6);
		}
	}
}

.test-detail {
	background-color: #fff;
	padding: 22px 30px;
	@media screen and (max-width: 767px) {
		padding: 20px;
	}
	.price-wrap {
		display: flex;
		align-items: center;
	}
	.content {
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: 767px) {
			flex-direction: column;
		}
	}
	.desc {
		padding-left: 42px;
		flex-grow: 1;
		@media screen and (max-width: 767px) {
			text-align: center;
			padding-left: 0;
		}
		span {
			font-weight: 600;
			display: block;
			font-size: 16px;
			margin-right: 20px;
			line-height: 18px;
			color: #239f87;
		}
		p {
			font-size: 16px;
			margin-bottom: 30px;
			line-height: 23px;
			color: #000000;
		}
		ul {
			margin: 0 0 10px;
			padding: 0;
			display: flex;
			align-items: center;
			list-style: none;
			li {
				font-size: 16px;
				line-height: 23px;
				color: #636363;

				& + li {
					margin-left: 25px;
					position: relative;
					&::before {
						content: "";
						width: 5px;
						height: 5px;
						background: #c4c4c4;
						position: absolute;
						border-radius: 50%;
						left: -15px;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0.5;
					}
				}
			}
		}
	}
	.img {
		width: 100px;
		height: 100px;
		overflow: hidden;
		background: #b5f2e7;
		border-radius: 50%;
		margin-bottom: 21px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.content .left {
		flex-grow: 1;
		padding-right: 24px;
		display: flex;
		width: calc(100% - 351px);
		@media screen and (max-width: 767px) {
			width: auto;
			padding-right: 0;
			align-items: center;
			flex-direction: column;
		}
	}
	.heading {
		// font-family: Source Sans Pro;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		color: #252525;
		margin: 0 0 29px 0;
		padding: 0;

		@media screen and (max-width: 767px) {
			text-align: center;
			font-size: 20px;
			line-height: normal;
			margin-bottom: 20px;
		}
	}
}

.instruction-box {
	background-color: #fff;
	&--footer {
		@media screen and (max-width: 767px) {
			& > div {
				display: block;
				padding: 20px;
				span {
					word-wrap: break-word;
				}
				button {
					width: 100%;
					margin-top: 10px;
				}
			}
		}
	}
	&--body {
		padding: 25px 25px 25px 36px;
	}
	ol {
		margin: 0;
		padding: 0;
	}
}

.test-result {
	border-radius: 4px;
	box-shadow: 0px 1px 4px 1px #ddd;
	background-color: white;
	.test-score {
		border-top: 1px solid #f3f3f3;
		display: flex;
		padding: 20px;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 767px) {
			display: block;
		}
		// border-bottom: 1px solid #b6b6b6;
		// @media screen and (max-width: 767px) {
		//   flex-direction: column;
		// }
		.wrap {
			@media screen and (max-width: 767px) {
				// flex-direction: column;
				.icon {
					// margin-right: 0 !important;
					margin-bottom: 15px;
				}
			}
			& + .wrap {
				margin-left: 30px;
				@media screen and (max-width: 767px) {
					margin-left: 0;
				}
			}
		}
	}
	&--header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 50px 30px;
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			flex-wrap: wrap;
			align-items: flex-start;
		}
		@media screen and (max-width: 767px) {
			padding: 20px;
			display: block;
		}
		.percentage-info {
			width: 300px;
			// width: calc(100% / 3);
			@media screen and (min-width: 1024px) and (max-width: 1279px) {
				width: 200px;
			}
			@media screen and (min-width: 768px) and (max-width: 1023px) {
				order: 3;
			}
			@media screen and (max-width: 767px) {
				width: auto;
			}
			ul {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0;
				list-style: none;
				padding: 0;
				& + ul {
					margin-top: 30px;
				}
				li {
					font-size: 24px;
					color: #505050;
					line-height: 1;
					& + li {
						font-size: 22px;
						color: #239f87;
					}
				}
			}
		}
		.result {
			width: 300px;
			@media screen and (min-width: 1024px) and (max-width: 1279px) {
				width: 200px;
			}
			@media screen and (min-width: 768px) and (max-width: 1023px) {
				order: 2;
			}
			@media screen and (max-width: 767px) {
				width: auto;
			}
			p {
				font-size: 24px;
				margin: 0;
				font-weight: 600;
				@media screen and (max-width: 767px) {
					text-align: center;
				}
			}
			span {
				display: block;
				font-size: 20px;
				font-weight: 600;
				@media screen and (max-width: 767px) {
					text-align: center;
				}
			}
		}
		.progress-wrap {
			width: calc(100% - 600px);
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (min-width: 1024px) and (max-width: 1279px) {
				width: calc(100% - 400px);
			}
			@media screen and (min-width: 768px) and (max-width: 1023px) {
				width: 100%;
				order: 1;
				margin-bottom: 30px;
			}
			@media screen and (max-width: 767px) {
				width: auto;
				flex-direction: column;
				padding: 25px 0;
				align-items: center;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.test-page--footer .right-col,
	.test-page--header .right-col {
		width: auto;
	}

	.test-page--footer {
		flex-direction: column;
	}

	.test-page--body {
		min-height: calc(100vh - (51px + 94px));
		height: auto;
	}

	.test-page--footer .left-col img {
		display: none;
	}

	.test-page--footer .left-col .button-group {
		flex-grow: 1;
		overflow: auto;
		white-space: nowrap;
	}

	.test-page--body .left-col {
		padding-right: 40px;
		height: calc(100vh - (51px + 94px));
		overflow: auto;
	}

	.test-page--body .left-col .col-6 {
		flex: 0 0 100%;
		max-width: 100%;
		border-right: none !important;
		min-height: 1px !important;
	}

	.test-page--body .left-col .col-6 + .col-6 {
		border-top: 1px solid rgb(182, 182, 182);
		padding-top: 20px;
		margin-top: 20px;
	}

	.test-page--body .right-col {
		position: fixed;
		height: calc(100vh - (51px + 94px));
		right: 0;
		width: 90%;
		top: 51px;
		background-color: #fff;
		margin: 0;
		z-index: 9;
	}

	.test-page--body .right-col.hide {
		margin-right: 0;
		right: -80%;
	}

	.test-page--footer .left-col .button-group .btn {
		flex-grow: 1;
		flex-shrink: 0;
		width: auto;
	}

	.question-responses p {
		font-size: 16px;
	}
}

.common-data-view {
	margin-bottom: 15px;

	&.explanation-box {
		margin-bottom: 0;
		background: #f5f9ff;
		padding: 20px;
		img {
			max-width: 100%;
		}
	}

	h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 18px;
		margin-bottom: 8px;
		color: #111111;
	}
	p {
		margin: 0;
		font-size: 16px;
		line-height: 1.5;
		color: #666666;
		& + p {
			margin-top: 15px;
		}
	}
}

.question-result-view {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 767px) {
		display: block;
	}

	.attempt-info {
		font-weight: 600;
		font-size: 18px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		width: 150px;
		height: 40px;
		color: #ffffff;
		@media screen and (max-width: 767px) {
			font-size: 16px;
		}
		p {
			margin: 0;
		}
		&.wrong {
			background: #ff0000;
		}

		&.skip {
			background: rgb(169, 169, 169);
		}

		&.correct {
			background: #239f87;
		}
	}

	.button-group {
		display: flex;
		align-items: center;
		border-top: 1px solid #c4c4c4;
		justify-content: space-between;
		margin-top: 20px;
		padding: 15px 0;
		a {
			width: 100px;
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			font-size: 16px;
			line-height: 1;
			border: 1px solid #00a689;
			background-color: #00a689;
			color: #fff;

			&.outline {
				color: #00a689;
				background-color: transparent;
			}
		}
	}
	.correct-answer {
		font-size: 16px;
		line-height: 1.5;
		margin-top: 0;
		color: #111111;
		@media screen and (max-width: 767px) {
			margin-bottom: 10px;
		}
		strong {
			font-weight: 700;
		}
	}
	h3 {
		font-size: 16px;
		line-height: 1.5;
		font-weight: normal;
		color: #111111;
		margin-bottom: 10px;
		strong {
			font-weight: bold;
		}
	}
}

.explation-test {
	width: 1024px;
	.col-sm-7 {
		border-right: 4px solid #f5f5f5;
	}
}

.radio-button {
	& + .radio-button {
		margin-top: 15px;
	}
	&.checked {
		& > label {
			& > span {
				color: #00a689;
				&::before {
					border-color: #00a689;
				}
				&:after {
					transform: scale(1) translateY(-50%);
					transition: all ease-in-out 0.3s;
				}
			}
		}
	}
	& > label {
		display: block;
		margin: 0;
		input {
			display: none;
		}
		& > span {
			font-size: 16px;
			display: block;
			color: #111111;
			position: relative;
			padding-left: 35px;
			* {
				text-indent: 0 !important;
			}
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				transform: scale(0) translateY(-50%);
				transition: all ease-in-out 0.3s;
				left: 7px;
				background: #00a689;
			}
			&::before {
				position: absolute;
				content: "";
				width: 24px;
				border-radius: 50%;
				height: 24px;
				left: 0;
				top: 50%;
				transform: translateY(-50%);

				background: #ffffff;
				border: 1px solid rgba(102, 102, 102, 0.52);
			}
		}
	}
}

.test-duration-info {
	display: flex;
	list-style: none;
	margin: 0 0 20px;
	padding: 0;
	align-items: center;
	li {
		font-size: 14px;
		line-height: 1;
		color: #111111;
		& + li {
			margin-left: 25px;
			position: relative;
			&::before {
				content: "";
				height: 100%;
				position: absolute;
				left: -12px;
				top: 0;
				background-color: #111111;
				width: 1px;
			}
		}
	}
}

.section-info {
	font-size: 16px;
	display: flex;
	align-items: center;
	line-height: 1.5;
	margin-bottom: 20px;
	margin-right: 24px;
	color: #111111;
	strong {
		font-weight: 700;
	}
	span {
		height: 30px;
		display: flex;
		align-items: center;
		margin-left: 19px;
		padding: 0 15px;
		background: #239f87;
		border-radius: 20px;
		font-size: 14px;
		line-height: 1;
		color: #ffffff;
	}
}

.attempted-test-view {
	@media screen and (min-width: 768px) {
		display: flex;
		align-items: flex-start;
	}
	&--footer {
		border-top: 1px solid #eee;
		padding: 20px 0;
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn {
			padding: 0.5rem 1rem;
			font-size: 13px;
			transition: all ease-in-out 0.3s;
			text-transform: uppercase;
			border-radius: 0.25rem;
			height: 40px;
			box-shadow: none;
			min-width: 100px;
			font-weight: 500;
			&.btn-success {
				background-color: #00a689;
				border-color: #00a689;

				&:hover {
					background-color: darken($color: #00a689, $amount: 5%);
				}
			}
			&.btn-outline-success {
				border-color: #00a689;
				color: #00a689;
				&:hover {
					background-color: #00a689;
					color: #fff;
				}
			}
		}
	}
	.left {
		@media screen and (min-width: 768px) {
			width: 60%;
			flex-shrink: 0;
			padding-right: 40px;
		}

		.audio-view {
			background: #ffffff;
			padding: 15px;
			border-radius: 6px;

			p {
				font-size: 14px;
				line-height: 20px;
				color: #35363b;
			}

			.wrap {
				margin-top: 12px;
				display: flex;
				align-items: center;

				svg {
					font-size: 30px;
					margin-right: 18px;
					flex-shrink: 0;
				}

				.bar {
					height: 6px;
					width: 100%;
					position: relative;
					background: linear-gradient(0deg, #c4cdd5, #c4cdd5),
						linear-gradient(0deg, #c4cdd5, #c4cdd5), #c4cdd5;
					border-radius: 10px;
					span {
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						font-size: 0;
						background: #00a689;
						border-radius: 10px;
					}
				}
			}
		}
	}
	.right {
		flex-grow: 1;
		position: sticky;
		top: 0;
		left: 0;
	}
}
