.default-card {
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 40px;

  @media screen and (max-width: 767px) {
    padding: 15px;
  }
}

.add-more-info {
  p {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    color: #00a689;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  span {
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-decoration-line: underline;

    color: #6f6f6f;
    margin-top: 8px;
  }
}

.horizontal-card {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  padding: 25px 30px;
  & + .horizontal-card {
    margin-top: 1px;
  }
  .wrap {
    flex-grow: 1;
    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 0;
      color: #000000;
    }
    p {
      font-size: 13px;
      line-height: 18px;
      margin: 9px 0 0;
      color: #000000;
    }
    ul {
      display: flex;
      margin: 9px 0 0;
      padding: 0;
      align-items: center;
      &.vertcal-list {
        display: block;
        margin-bottom: 18px;
        li {
          span {
            &:first-child {
              width: calc(98px + 17px);
            }
          }
          & + li {
            margin-left: 0;
            margin-top: 9px;
          }
        }
      }
      li {
        display: flex;
        align-items: center;
        & + li {
          margin-left: 35px;
        }
        span {
          font-size: 13px;
          line-height: 18px;
          color: #000000;
          & + span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

// Dropdown
.primary-dropdown {
  display: inline-flex;
  align-items: center;
  position: relative;
  &:not(:hover) {
    .primary-dropdown--menu {
      display: none;
    }
  }
  &--item {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
  &--menu {
    position: absolute;
    width: auto;
    min-width: 10rem;
    right: 100%;
    top: 15px;
    border-radius: 6px;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6e6;
    padding: 5px 0px;
    z-index: 999;
    li {
      list-style: none;
      padding: 0.5rem 1rem;
      font-size: 14px;
      color: #434544;
      white-space: nowrap;
      a {
        transition: none;
      }
      &:hover {
        background: #239f87;
        color: white;
        cursor: pointer;
        a {
          color: white;
          transition: none;
        }
      }
    }
  }
}

.list-with-delete {
  display: flex;
  align-items: flex-end;
  & > div {
    flex-grow: 1;
    padding-right: 20px !important;
  }
  & > svg {
    width: 18px;
    cursor: pointer;
    height: 18px;
    margin-bottom: 24px;
    color: #000;
  }
}

.resume-preview {
  @media screen and (min-width: 1280px) {
    width: 80%;
    margin: 0 auto;
  }
  &--wrap {
    background-color: #ffffff;

    .main-header {
      padding: 35px 60px;
      display: flex;
      align-items: center;


      @media screen and (max-widht: 1023px) {
        padding: 25px;
      }

      @media screen and (max-widht: 767px) {
        display: block;

        .button-group {
          justify-content: center;
        }
      }

      .wrp {
        flex-grow: 1;
        display: flex;
        align-items: center;
        @media screen and (max-widht: 767px) {
          display: block;
          text-align: center;
        }
      }

      &--content {
        padding-left: 60px;
        padding-bottom: 30px;
        padding-right: 20px;

        @media screen and (max-widht: 1023px) {
          padding-left: 25px;
          padding-bottom: 25px;
          padding-right: 25px;
        }

        .skills {
          background: #f6f6f6;
          padding: 30px 35px;

          &--row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            p {
              margin: 0;
              font-size: 18px;
              line-height: 1;
              color: #393e41;
            }

            ul {
              list-style: none;
              display: flex;
              margin: 0;
              padding: 0;
              align-items: center;
              li {
                & + li {
                  margin-left: 5px;
                }
                svg {
                  display: block;
                }
              }
            }
          }
        }

        .wrap {
          & + .wrap {
            margin-top: 30px;
          }
        }
        p {
          font-size: 18px;
          line-height: 26px;
          color: #393e41;
          strong {
            font-weight: 600;
          }
          margin: 0;
          &.currently-working {
            color: #239f87;
          }
          & + p {
            margin-top: 10px;
          }
        }
      }
      &--wrap {
        padding-left: 30px;
        @media screen and (max-widht: 1023px) {
            padding-left: 15px;
          }

          @media screen and (max-widht: 767px) {
            padding-left: 0;
            padding-top: 15px;
            padding-bottom: 15px;
          }
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 36px;
          line-height: 1;
          color: #393e41;

          @media screen and (max-widht: 1023px) {
            font-size: 24px;
          }
        }
        p {
          margin: 5px 0 0;
          font-size: 18px;
          line-height: 1;
          color: #393e41;
          @media screen and (max-widht: 1023px) {
            font-size: 14px;
          }
        }
      }
      .icon {
        width: 101px;
        height: 101px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        font-size: 0;
        background-color: #eee;
        @media screen and (max-widht: 1023px) {
          width: 80px;
        height: 80px;
        }
        @media screen and (max-widht: 767px) {
          margin: 0 auto;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  &--header {
    height: 40px;
    padding: 0 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #239f87;
    justify-content: space-between;
    background: #f6f6f6;
    @media screen and (max-widht: 1023px) {
      padding:  0 25px;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin: 0;
      color: #393e41;
      @media screen and (max-widht: 1023px) {
        font-size: 18px;
      }
    }
  }

  &--body {
    padding: 20px 60px;
    @media screen and (max-widht: 1023px) {
      padding:  25px;
    }
    .wrap {
      & + .wrap {
        margin-top: 30px;
      }
    }
    p {
      font-size: 18px;
      line-height: 26px;
      color: #393e41;
      @media screen and (max-widht: 1023px) {
        font-size: 16px;
      }
      strong {
        font-weight: 600;
      }
      margin: 0;
      &.currently-working {
        color: #239f87;
      }
      & + p {
        margin-top: 10px;
      }
    }
  }
}



.mock-test-card {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;

  &--header {
    height: 120px;
    overflow: hidden;
    background-color: #eee;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--body {
    padding: 15px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      margin: 0 0 4px;
      color: #252525;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      color: #636363;
      min-height: 32px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #636363;
        strong {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          &.subscribed {
            color: #f9000f;
          }
          &.price {
            color: #239f87;
          }
        }
      }
    }
  }

  &--footer {
    a {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      justify-content: center;
      height: 44px;
      background: #ffd100;
      border-radius: 0px 0px 4px 4px;
    }
  }
}



.test-full-view {
  margin-bottom: 40px;

  img {
    max-width: 100%;
  }

  .links-wrap {
    display: flex;
    align-items: center;
    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #00a689;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
      & + a {
        margin-left: 24px;
      }
    }
  }
  .wrap {
    background: #ffffff;
    margin-bottom: 10px;
    padding: 22px 27px;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #252525;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        color: #636363;
        strong {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          color: #239f87;
          margin-right: 18px;
        }
        a {
          width: 111px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffd100;
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          color: #000000;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 23px;
      color: #000000;
      strong {
        font-weight: 600;
      }
    }
  }
}

.my-resume {
  margin-bottom: 17px;
  &--header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: flex-end;
    a {
      width: 143px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      line-height: 18px;
      color: #000000;
      background: #ffd100;
    }
  }

  &--body {
    .interview-list {
      background: #ffffff;
      padding: 15px;
      h4 {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 20px;
        color: #252525;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          a {
            width: 143px;
            height: 44px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
            background: #ffd100;
          }
          p {
            margin: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #636363;
            & + p {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.attempts-list {
  background: #f5fcff;
  margin-bottom: 10px;
  padding: 8px 15px;
  ul {
    // justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    li {
      & + li {
        margin-top: 15px;
        @media screen and (min-width: 768px) {
          margin-top: 0;
        }
      }
      @media screen and (min-width: 768px) {
        width: calc(100% / 6);
      }
      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #636363;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #636363;
        margin: 0;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 1023px) {
  .primary-modal {
    min-width: 1px;
    max-height: calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 767px) {
  .my-performance--box {
    height: auto;
    padding: 15px;
    display: block;
  }
  .my-performance--box .performance-chart {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .my-performance h3,
  .subscribed-test h3,
  .practice-mock h3 {
    font-size: 18px;
    line-height: normal;
    margin: 0 0 10px;
  }
  .my-performance--box p {
    line-height: normal;
    font-size: 16px;
    margin: 0;
  }

  .practice-mock > p {
    line-height: normal;
    font-size: 16px;
  }

  .my-performance--box p span {
    display: initial;
  }

  .my-performance--box p + p {
    margin-top: 10px;
  }

  .mock-test-card {
    margin-bottom: 20px;
  }

  .subscribed-test {
    margin-bottom: 6px;
  }

  .advertisement-block {
    padding: 15px;
  }

  .main-structure {
    padding: 15px;
  }
  .primary-page-header h3 {
    font-size: 20px;
  }
  .test-full-view .wrap ul {
    flex-direction: column;
    align-items: flex-start;
  }
  .test-full-view .wrap ul li + li {
    margin-top: 15px;
  }
  .primary-page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .primary-page-header button {
    margin-top: 10px;
  }

  .primary-tabs ul.tabs-list li + li {
    margin-left: 10px;
  }
  .primary-tabs ul.tabs-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .primary-tabs ul.tabs-list li {
    margin: 0 10px 10px;
  }
  .horizontal-card {
    padding: 15px;
  }
  .horizontal-card .wrap ul li + li {
    margin-left: 14px;
  }


  .list-with-delete {
    padding-right: 10px;
  }
  .list-with-delete > svg {
    margin-left: 10px;
  }

  .resume-preview--wrap .main-header {
    padding: 20px;
    display: block;
    .wrp {
      display: block;
      text-align: center;
      margin-bottom: 15px;
    }
    .button-group {
      justify-content: center;
    }
  }
  .resume-preview--wrap .main-header .icon {
    margin: 0 auto 15px;
  }
  .resume-preview--wrap .main-header--content {
    padding: 20px;
    padding-top: 0;
  }
  .resume-preview--wrap .main-header--wrap h2 {
    font-size: 24px;
  }
  .resume-preview--wrap .main-header--wrap {
    padding-left: 15px;
  }
  .resume-preview--wrap .main-header--wrap p {
    font-size: 16px;
  }

  .resume-preview--wrap .main-header--content .skills {
    padding: 15px;
    margin-top: 15px;
  }
  .resume-preview--header,
  .resume-preview--body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .resume-preview--body p {
    font-size: 16px;
  }
  .resume-preview--header h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .primary-page-header button {
    white-space: nowrap;
  }
}

.dv-star-rating {
  display: flex !important;
  align-items: center;
  label {
    margin: 0;
    font-weight: normal;
    display: block;
    line-height: normal;
    i {
      font-size: 24px;
      display: block;
      line-height: normal;
    }
  }
}

.video-close {
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.my-performance {
  margin-bottom: 26px;
  &--box {
    // width: 896px;
    height: 273px;
    padding: 33px 62px;
    background: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
    border-radius: 8px;
    .performance-chart {
      width: 247px;
      margin-right: 80px;
      img {
        max-width: 100%;
      }
    }
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      span {
        display: block;
      }
      & + p {
        margin-top: 14px;
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    margin: 0 0 25px;
    color: #00a689;
  }
}

.subscribed-test {
  margin-bottom: 26px;
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    margin: 0 0 25px;
    color: #00a689;
  }
}

.practice-mock {
  & > h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    margin: 0 0 4px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #00A689;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mock-test-card {
    margin-bottom: 20px;
  }

  & > p {
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    strong {
      font-weight: 600;
    }
  }
}

.interview-for-practice {
  margin-bottom: 30px;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #111111;
    margin: 0 0 20px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .wrap {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
    border-radius: 8px;
    padding: 24px;
    display: flex;

    @media screen and (max-width: 767px) {
      display: block;
    }
    .image {
      width: 250px;
      @media screen and (max-width: 767px) {
      width: 100%;
        margin-bottom: 15px;
    }
    }
    .content {
      width: calc(100% - 250px);
padding-left: 32px;
      @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-top: 0;
    }
      p {
        font-size: 16px;
        line-height: 26px;
        color: #666666;
      }
      .action-wrap {
        display: flex; align-items: center;
        margin-top: 30px;
        @media screen and (max-width: 767px) {
          margin-top: 0;
        }
        a {
          background: rgba(0,166,137, .1);
          width: 129px;
          height: 34px;
          border-radius: 4px;
          display: flex; align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #00A689;
          &:hover {
            background: rgba(0,166,137, .2);
            // background-color: #00A689;
          }
        }
      }
    }
  }
}
.mock-interviews-box {
 margin-bottom: 30px;
 &.detail {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
    border-radius: 8px;
    padding: 24px;

    .wrap {
    display: flex;
    margin-top: 25px;
    @media screen and (max-width: 767px) {
      display: block;
      margin-top: 15px;
    }
    .image {
      width: 250px;
      overflow: hidden;
      img {
        width: 250px;
        height: 125px;
        object-fit: contain;
        background-color: #f5f9ff;
        border-radius: 8px;
        margin: 0 auto;
        display: block;
      }
    }
    .content {
      width: calc(100% - 250px);
      padding-left: 32px;
      @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-top: 32px;
    }
      p {
        font-size: 16px;
        line-height: 26px;
        color: #666666;
      }
      .action-wrap {
        display: flex; align-items: center;
        margin-top: 30px;
        a {
          background: rgba(0,166,137, .1);
          width: 129px;
          height: 34px;
          border-radius: 4px;
          display: flex; align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #00A689;
          &:hover {
            background: rgba(0,166,137, .2);
            // background-color: #00A689;
          }
        }
      }
    }
  }

      .action-wrap {
        display: flex; align-items: center;
        a {
          background: rgba(0,166,137, .1);
          width: 129px;
          height: 34px;
          border-radius: 4px;
          display: flex; align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #00A689;
          &:hover {
            background: rgba(0,166,137, .2);
            // background-color: #00A689;
          }
        }
      }
 }
 .custom-thumbnail {
   margin-top: 20px;
 }
   h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #111111;
    margin: 0 0;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
    // & + p {

    // }
  }
}


.mock-addition-info {
  background: #fff;
  box-shadow: 0 2px 5px rgba(148,148,148,.2);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 15px;
  .mock-warning {
    background: rgba(249, 182, 182, 0.5);
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FF0000;
    padding: 9px;
    margin-bottom: 25px;
  }
  .mock-btn {
    height: 44px;
    display: inline-flex;
    align-items: center;
    background: #00A689;
    border-radius: 6px;
    padding: 0 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    @media screen and (max-width: 767px) {
      padding: 8px;
      font-size: 12px;
      line-height: 1;
      height: auto;
    }
    & + h3 {
      margin-top: 28px;
    }
    &:hover {
      background: darken($color: #00A689, $amount: 5%);
    }
  }
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #111111;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
    li {
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: 34px;
      background: rgba(0, 166, 137, .1);
      padding: 0 20px;
      font-weight: 600;
      font-size: 12px;
      color: #00A689;
      line-height: 18px;
      @media screen and (max-width: 767px) {
        padding: 0;
        font-size: 11px;
        justify-content: center;
        width: calc((100% - 10px) / 2);
      }
      @media screen and (max-width: 360px) {
        width: 100%;
      }
      & + li {
        margin-left: 16px;
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
        @media screen and (max-width: 360px) {
          margin-top: 10px;
        }
      }
    }
  }
}

.mock-attempt {
  &-info {
    margin-top: 30px;
  }
  &-row {
    display: flex;
    align-items: center;
    background: #F5F5F5;
    border-radius: 4px;
    & + .mock-attempt-row {
      margin-top: 11px;
    }
    @media screen and (max-width: 767px) {
      display: block;
      border: 1px solid #eee;
      // background: transparent;
    }
  }
  &-col {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
    padding: 16px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      justify-content: space-between;
      padding: 10px;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
    &:first-child {
      width: 150px;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #010101;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    a {
      color: #00A689;
      &:hover {
        text-decoration: underline;
        // color: darken($color: #00A689, $amount: 10%);
      }
      & + a {
        margin-left: 50px;
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }
    }
  }
}