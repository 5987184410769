.pagination {
  display: flex;
  align-items: center;

  li {
		&:first-child {
			a {
				padding-left: 0;
			}
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
    a {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-align: left;
      padding: 0 8px;
      color: #303956;
      &:hover {
        background-color: transparent;
      }
      span {
        display: block;
        line-height: 15px;
      }
    }
  }
}
