.custom-control {
  z-index: auto;
  &.custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #239f87;
      background-color: #239f87;
      &:focus-within .custom-control-label::before {
        box-shadow: 0 0 0 1px #239f87;
      }
    }
    label.custom-control-label {
      color: #7f8381;
      user-select: none;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      border-color: #239f87;
      line-height: 1.5rem;
      &::before {
        box-shadow: 0 0 0 1px #239f87;
        border-radius: 3px;
      }
    }
  }
}

.secondary-checkbox {
  &--wrap {
    display: flex;
    align-items: flex-start;
    .Checkbox {
      top: 0;
    }
    label {
      font-size: 14px;
      line-height: 1.79;
      margin: 0 0 0 8px;
      color: #000000;
    }
  }
}
