@import "./variables";
@import "./base/app";
@import "./overlay";
@import "./course-nav";
@import "./player";
@import "./accordion";
@import "./quiz";
@import "./partials_ec_wall/main";
@import "./partials_ec_student/main";
@import "./_ec_question";
@import "./avatar";
@import "./ec-ui/ec-ui-main";
@import "./forms/forms";
@import "./batch/batch-main";
@import "./ec-questions/ec-questions";
@import "./wall/wall";
@import "./test/test";
@import "./interviewAndResume/interviewResume";
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
.sidebar-dark-dodger-blue {
	.sidebar-submenu .sidebar-menu-text::after {
		background-color: $accent-red;
	}
}
.main-structure {
	padding: 30px 40px;
	@media screen and (max-width: 767px) {
		padding: 15px;
	}
}

embed {
	display: block;
	max-width: 100%;
}

body {
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Source Sans Pro", Arial, sans-serif;
	}
}

@media screen and (max-width: 565px) {
	.my-progress--action {
		width: 100%;
		flex-direction: row;
		a + a {
			margin-left: 15px;
		}
	}
}

@media screen and (max-width: 1023px) {
	.primary-page-header {
		max-width: 100%;
	}
}
@media screen and (max-width: 767px) {
	.attempts-list {
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		padding: 15px;
		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 0;
				p {
					color: #000;
					font-weight: 600;
				}
				span {
					color: #000;
					font-size: 14px;
					font-weight: normal;
				}
				& + li {
					border-top: 1px solid #eee;
					margin-top: 0;
				}
			}
		}
	}
	.batch-accordion .accordion .card-header p div p {
		font-size: 14px;
		& + p {
			padding-left: 15px;
		}
	}
	.student-navbar .right .button-dropdown {
		display: none;
	}
	.student-navbar {
		z-index: 2;
		position: relative;
	}
	.mobile-menu-tabs {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 70px;
		background: #ffffff;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		display: flex;
		align-items: center;
		box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.15);
		a {
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 14px;
			padding: 0;
			width: calc(100% / 4);
			line-height: 1;
			/* identical to box height, or 231% */

			text-align: center;
			letter-spacing: -0.3px;

			color: #666666;

			svg {
				font-size: 24px;
				margin-bottom: 5px;
			}

			&.active {
				font-weight: 600;
				color: #00a689;
			}
		}
	}
	.button-dropdown.dropdown {
		padding: 15px;
		background: #f5f9ff;
		z-index: 1;
		.dropdown-toggle {
			width: 100%;
		}
		.dropdown-menu {
			width: calc(100% - 30px) !important;
			left: 15px;
		}
	}
	.avtar-with-text .primary-avtar {
		margin-right: 10px;
	}
	.primary-paginated-table {
		&--row {
			display: block;
		}
		&--col {
			padding: 5px;
			width: 100% !important;
		}
	}
	.main-mid {
		width: 100%;
	}
	.primary-batch-tabs {
		overflow: auto;
		/* Hide scrollbar for Chrome, Safari and Opera */

		&::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */

		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		li {
			white-space: nowrap;
		}
	}

	.profile-box {
		padding: 20px;
		&--header {
			@media screen and (max-width: 500px) {
				display: block;
				ul {
					margin: 0 -5px;
					flex-wrap: wrap;
					li {
						padding: 10px;
						height: auto;
						width: calc((100% / 3) - 10px);
						flex-direction: column;
						margin: 0 5px !important;
						& + li {
							margin-left: 0;
						}
					}
				}
			}
			.image {
				margin-right: 20px;
				@media screen and (max-width: 500px) {
					margin-right: 0;
					margin-bottom: 20px;
					img {
						margin: 0 auto;
						display: block;
						background: #f5f9ff;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.student-navbar .left .nav {
		display: flex;
		a {
			padding: 0;
			font-size: 16px;
			text-transform: none;
			& + a {
				margin-left: 20px;
			}
		}
	}
	.student-navbar .left .logo {
		margin-right: 30px;
		img {
			width: 120px;
		}
	}

	.notification-wrapper .notification-toggle {
		margin: 0 15px;
	}

	.button-dropdown .dropdown-toggle {
		min-width: 1px;
		font-size: 14px !important;
	}
}

.full-page-loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;
	p {
		font-weight: 600;
		font-size: 18px;
		margin: 15px 0 0;
		color: #fff;
	}
}

.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #fff;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.loading-state {
	font-size: 0 !important;
	pointer-events: none;
	// opacity: .7;
	&.primary-button {
		&[shape="popup"] {
			.is-loading::after,
			.loader {
				border-color: #fff;
				border-bottom-color: #177c68;
			}
		}

		&[shape="primary"] {
			.is-loading::after,
			.loader {
				border-color: #d4bc04;
				border-bottom-color: #000;
			}
		}
		&[shape="secondary"] {
			.is-loading::after,
			.loader {
				border-color: #ced3da;
				border-bottom-color: #000;
			}
		}
	}
	.is-loading::after,
	.loader {
		width: 1.2rem;
		height: 1.2rem;
		border-width: 2px;
		margin: 0 auto;
	}
}

.user-select-none {
	user-select: none;
}

.descriptive {
	background: #ffffff;
	border: 1px solid #dbdbdb;
	box-sizing: border-box;
	box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	&__progress {
		position: relative;
		padding: 20px;
		border-top: 1px solid #f3f3f3;
		ul {
			@media screen and (min-width: 768px) {
				margin-top: 0;
			}
			padding: 0;
			list-style: none;
			max-width: 289px;
			margin: 15px auto 0;
			& + .btn {
				display: block;
				width: 100%;
				max-width: 289px;
				margin: 16px auto 0;
				line-height: 1;
				height: 44px;
			}
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				& + li {
					margin-top: 15px;
				}
				span {
					font-size: 18px;
					line-height: normal;
					color: #505050;
				}
				strong {
					font-size: 16px;
					line-height: normal;
					color: #239f87;
				}
			}
		}
		@media screen and (min-width: 1024px) {
			padding: 50px;
		}
	}
	&__header {
		padding: 20px;

		@media screen and (min-width: 1024px) {
			padding: 50px;
		}

		h3 {
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			color: #252525;
			margin-bottom: 10px;
			@media screen and (min-width: 1024px) {
				font-size: 24px;
				margin-bottom: 23px;
			}
		}
		p {
			font-size: 16px;
			line-height: 18px;
			color: #252525;
			margin: 0;
			@media screen and (min-width: 1024px) {
				font-size: 24px;
			}
		}
	}

	&__body {
		.wrap {
			position: relative;
			padding: 20px;
			border-top: 1px solid #f3f3f3;
			@media screen and (min-width: 1024px) {
				padding: 50px;
				padding-left: 120px;
			}
			.answer-block {
				margin-top: 24px;
			}
			.answer-by-student {
				background: #eeffff;
				padding: 15px;
				& + ul {
					list-style: none;
					margin: 0 -10px;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					@media screen and (max-width: 1023px) {
						margin-bottom: 20px;
					}
					li {
						width: 100px;
						height: 100px;
						margin: 20px 10px 0;
						background: #c4c4c4;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
				p {
					a {
						color: #1ea087;
					}
				}
			}
			&__header {
				margin-bottom: 20px;
				@media screen and (max-width: 1023px) {
					display: flex;
					flex-wrap: wrap;
				}
				p {
					width: 100%;
					& + p {
						margin-top: 0 !important;
					}
				}
				span {
					position: absolute;
					left: 50px;
					top: 79px;
					font-weight: 600;
					font-size: 20px;
					line-height: 1;
					color: #252525;
					height: 46px;
					background: #f3f3f3;
					width: 46px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					@media screen and (max-width: 1023px) {
						font-size: 18px;
						position: static;
						width: auto;
						height: auto;
						background-color: transparent;
						line-height: 1;
						margin-bottom: 10px;
						margin-right: 10px;
					}
				}
				em {
					font-size: 18px;
					line-height: 18px;
					font-style: normal;
					margin-bottom: 10px;
					display: block;
					color: #ff0000;
				}
			}
			.feedback-by-educator {
				ul {
					list-style: none;
					margin: 0 -5px;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					li {
						width: 67px;
						height: 67px;
						margin: 20px 5px 0;
						background: #fff;
						padding: 5px;
						border-radius: 5px;
						border: 1px solid #d0d7dd;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
			p {
				font-size: 16px;
				line-height: 23px;
				margin: 0;
				color: #252525;
				@media screen and (max-width: 1023px) {
					font-size: 14px;
				}
				a {
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #0a836b;
					&:hover {
						text-decoration: underline;
					}
				}
				& + p {
					margin-top: 10px;
				}
				strong {
					font-weight: 700;
				}
			}
		}
	}
}

.try-now {
	color: #00a689;
	font-size: 14px;
	font-weight: 600;
}

.rounded-video-player {
	max-width: 100%;
	height: auto !important;
	video {
		border-radius: 15px;
	}
}

.coupon-details {
	background: #ffffff;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 12px 17px;
	h3 {
		margin: 0 0 5px;
		font-weight: normal;
		font-size: 18px;
		line-height: 26px;
		color: #35363b;
		span {
			font-size: 12px;
			line-height: 17px;
			margin-left: 5px;
			color: #35363b;
		}
	}
	ul {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			font-size: 12px;
			line-height: 17px;
			color: #35363b;
			&.danger {
				color: #f20808;
			}
			strong {
				font-weight: 600;
			}
			& + li {
				margin-left: 30px;
			}
		}
	}
}

.coupon-modal {
	width: 820px;
	max-width: 100%;
	p {
		font-weight: bold;
		font-size: 15px;
		line-height: 20px;
		margin: 0;
		color: #000000;
	}
	@media screen and (max-width: 767px) {
		button {
			span {
				display: none;
			}
		}
	}
	ul {
		display: flex;
		margin: 15px 0 0;
		padding: 0;
		list-style: none;
		@media screen and (max-width: 767px) {
			&:nth-child(3) {
				display: block;
				li {
					&:first-child {
						margin-bottom: 10px;
					}
				}
			}
		}
		& + button {
			margin-top: 20px;
			width: 226px;
			max-width: 100%;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
		li {
			font-size: 15px;
			line-height: 20px;
			color: #000000;
			.wrap {
				form {
					display: flex;
					align-items: center;
				}
			}
			.success {
				font-size: 15px;
				line-height: 20px;
				color: #00a689;
			}
			.error {
				font-size: 15px;
				line-height: 20px;
				color: #cf3030;
			}
			&:first-child {
				width: 150px;
			}
			p {
				font-weight: normal;
				font-size: 20px;
				margin-top: 17px;
				line-height: 27px;
			}
			strong {
				font-weight: 600;
				margin-top: 17px;
				display: block;
				font-size: 24px;
				line-height: 27px;
			}
		}
	}
	h3 {
		margin-bottom: 20px;
		text-align: center;
		@media screen and (max-width: 767px) {
			font-size: 18px;
		}
	}
}

.google-btn {
	background-color: rgb(255, 255, 255);
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.54);
	font-weight: 500;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
		rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
	padding: 0px 10px;
	border-radius: 2px;
	border: 1px solid transparent;
	font-size: 14px;
	font-weight: 500;
	font-family: Roboto, sans-serif;
	svg {
		margin-right: 20px;
		width: 20px;
		height: 20px;
	}
}

body {
	.react-datepicker {
		&__close-icon {
			right: 35px;
			padding: 0;
			&::after {
				background-color: #ccc;
			}
		}
	}
}

.secondary-header {
	height: 71px;
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 99;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);

	@media screen and (min-width: 1280px) {
		padding: 0 40px;
	}

	span {
		color: #239f87;
		font-weight: 600;

		img {
			margin-left: 0.5rem;
		}
	}

	p {
		font-weight: 600;
		font-size: 24px;
	}
}


.overall-test-info {
  font-weight: 700;
  font-size: 14px;
  line-height: 186%;
  text-align: right;
  color: #000000;
  margin-bottom: 15px;

  &.secondary {
    margin-bottom: 10px;
    padding: 1px 12px;
    font-weight: normal;
    background: #F8F8F8;
    color: #626262;
  }
}

.topic-tabs {
  margin: 0 0 8px;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 20px;
  row-gap: 10px;

  li {
    background: #FFFFFF;
    padding: 0 15px;
    border: 1px solid #CECECE;
    display: flex;
    align-items: center;
    height: 38px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    line-height: 162%;
    color: #000000;

    &.active {
      background: #00C385;
      border-color: #00C385;
    }
  }
}

.test-info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.custom-table-ui {
  &_row {
    display: flex;
  }

  &_body {
    .custom-table-ui {
      &_row {
        align-items: flex-start;
        background: #F8F8F8;

        &.secondary {
          background: #FBF9FF;

          // border-left: 1px dashed #000;
          // border-right: 1px dashed #000;
          // border-top: 1px dashed #000;

          &+.secondary {
            margin-top: 0 !important;
            // border-bottom: 1px dashed #000;
            border-top: none;

          }
        }

        &:not(:first-child) {
          margin-top: 1px;
        }
      }

      &_col {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #000000;

        input {
          width: 46px;
          padding: 0 8px;
          height: 27px;
          font-family: "Source Sans Pro";
          font-weight: 400;
          font-size: 12px;
          line-height: 13px;
          text-align: left;
          color: #262424;
          border: 1px solid #c4cdd5 !important;
          box-sizing: border-box;
        }
      }
    }
  }

  &_col {
    display: flex;
    font-weight: 600;
    font-size: 12px;
    line-height: 217%;
    color: #000000;
    align-items: center;
    padding: 12px 15px;
    width: 200px;

    .custom-control.custom-checkbox label.custom-control-label {
      font-weight: 600;
      text-transform: none;
      font-size: 12px;
      color: #000000;
    }

    svg {
      cursor: pointer;
    }

    &:first-child {
      width: 60px;
    }

    &:last-child {
      width: 50px;
    }

    &:nth-child(2) {
      width: 160px;
    }

    &:nth-child(3) {
      width: 160px;
    }

    &:nth-child(4) {
      width: 220px;
    }

    &:nth-child(5) {
      width: calc(100% - (60px + 50px + 160px + 160px + 220px + 90px + 90px));
    }

    &:nth-child(6) {
      width: 76px;
    }

    &:nth-child(7) {
      width: 90px;
    }
  }
}