//Common
body {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: #f5f9ff !important;
}
// *::first-letter {
//   text-transform: uppercase;
// }
a {
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
  }
}

/* notification-wrapper */
.notification-wrapper {
  position: relative;
  .notification-toggle {
    position: relative;
    margin: 0 20px;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   right: 0;
    //   background-color: #e4e6ec;
    //   height: 44px;
    //   width: 1px;
    // }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   left: 0;
    //   background-color: #e4e6ec;
    //   height: 44px;
    //   width: 1px;
    // }
    .noti-icon {
      height: 24px;
      width: 24px;
      // margin: 0 20px;
      cursor: pointer;
    }
  }
}

/* user-dropdown */
.user-dropdown {
  .dropdown-toggle {
    background: none !important;
    padding: 0;
    border: none;
    box-shadow: none;
    display: flex;
    &:after {
      display: none;
    }
    .user-image {
      height: 32px;
      width: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #eee;
        border-radius: 50%;
      }
    }
    .user-name {
      font-size: 14px;
      font-weight: 500;
      color: #2a2a2a;
      // margin: 0 2px 0 4px;
      margin: 0;
    }
    .icon-down-arrow {
      color: #2a2a2a;
      font-size: 24px;
    }
    &:active {
      background: none !important;
      border: none;
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none !important;
      background: none;
    }
  }
  .dropdown-menu {
    left: auto;
    right: 0;
    border-radius: 4px;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05);
    border: 1px solid #f0f0f0;
    margin-top: 0.5rem !important;
    .dropdown-item {
      font-size: 14px;
      color: #6f6f6f;
      padding: 6px 15px;
      &:last-child {
        color: #e84f4f;
        &:hover {
          color: #e84f4f;
        }
      }
      &:hover {
        color: #239f87;
      }
      &:active {
        font-weight: 500;
      }
      &:after {
        display: none;
      }
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}


/* Inner wrapper */
.inner-wrapper {
  margin-top: 70px;
}

/* right-side-wrapper */
.right-side-wrapper {
  margin-left: 220px;
  padding: 20px 20px 0 20px;
  display: flex;
  position: relative;
}

/* right-ad-wrapper */
.right-ad-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
}

/* reward-point-box */
.reward-point-box {
  background: linear-gradient(360deg, #239f87 -250.95%, #ffffff 100%);
  border-radius: 8px;
  width: 200px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  span {
    font-size: 16px;
    color: #2a2a2a;
    margin-bottom: 8px;
    display: block;
  }
  strong {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #2a2a2a;
    line-height: 1;
    margin-bottom: 20px;
  }
  a {
    min-width: 115px;
    height: 34px;
    background: #239f87;
    border-radius: 4px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;
    &:hover {
      color: white;
    }
  }
}

/* ad-banner */
.ad-banner {
  width: 200px;
  height: 378px;
  background-color: white;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  p {
    font-size: 14px;
    color: #6f6f6f;
    font-weight: bold;
  }
}

/* main-mid */
.main-mid {
  padding: 15px;
  // flex-grow: 1;
  @media screen and (min-width: 1280px) {
    width: calc(100% - 280px);
    padding: 25px 40px;
  }
}

/* row-for-gap */
.row-for-gap {
  margin-left: -10px;
  margin-right: -10px;
  .column-for-gap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Custom-radio */
.custom-radio {
  margin-bottom: 0;
  padding-left: 0;
  input {
    display: none;
    &:checked {
      & + label {
        &:before {
          background-color: #239f87;
        }
        &:after {
          display: block;
        }
      }
    }
  }
  label {
    cursor: pointer;
    &:before {
      content: "";
      height: 21px;
      width: 21px;
      background-color: #f3f3f3;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 4px;
      width: 7px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }
  }
}

/* custom-breadcrumb */
.custom-breadcrumb {
  .breadcrumb {
    background: none;
    padding: 0;
    margin-bottom: 24px;
    .breadcrumb-item {
      font-size: 14px;
      color: #6f6f6f;
      text-transform: capitalize;
      letter-spacing: 0.5px;
      font-weight: 500;
      position: relative;
      &:last-child {
        font-weight: bold;
      }
      & + .breadcrumb-item {
        padding-left: 14px;
        margin-left: 10px;
        &:before {
          content: " ";
          background-image: url("https://educrack-dev.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
          width: 4px;
          height: 7px;
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
    }
  }
}

// custom-search
.custom-search {
  width: 350px;
  position: relative;
  .form-control {
    height: 38px;
    background-color: #e6e6e6;
    border-radius: 4px;
    border-color: #e6e6e6;
    box-shadow: none;
    font-size: 14px;
    color: #6f6f6f;
    padding-left: 40px;
  }
  .search-icon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e6e6e6;
    height: 38px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }
}

// search-subject-row
.search-subject-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .form-group {
    margin-bottom: 0;
  }
  .right {
    display: flex;
    align-items: center;
  }
  select::-ms-expand {
    display: none;
  }
  .custom-option {
    margin-right: 20px;
  }
}

// custom-option
.custom-option {
  width: 230px;
  position: relative;
  .form-control {
    background-color: #e6e6e6;
    box-shadow: none;
    border-color: #e6e6e6;
    font-size: 14px;
    height: 38px;
    -webkit-appearance: none;
  }
  .icon-down-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
  }
}


.notification-page {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 10px;
    line-height: 1;
    background-color: #fff;
    &.active {
      background: #FFFAF0;
      border-color: darken($color: #FFFAF0, $amount: 10%);
    }
    & + li {
      margin-top: 5px;
    }
    .wrap {
      display: flex;
      margin-bottom: 6px;
      align-items: center;
      strong {
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        text-overflow: ellipsis;
        color: #222222;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 20px;
      }
      span {
        font-size: 12px;
        line-height: 17px;
        color: #666666;
        margin-left: auto;
        flex-shrink: 0;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      line-height: 20px;
      color: #686868;
    }
  }
}

.menu-wrap {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    min-width: 300px;
    right: 0;
    top: 34px;
    padding: 10px 6px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1),
      margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
      @media screen and (max-width: 767px) {
        min-width: 250px;
      }
    &.active {
      opacity: 1;
      visibility: visible;
      margin-top: 14px !important;
      transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1),
        margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
    }

    h3 {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #6F6F6F;
      padding: 6px 11px 12px;
      margin: 0;
      text-transform: uppercase;
    }
    .view-all {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #00A689;
      margin-top: 10px;
    }
    ul {
      list-style: none;
        margin: 0;
        padding: 0;
        max-height: 400px;
        overflow: auto;
        @media screen and (max-width: 767px) {
        max-height: 150px;
      }
        li {
          border: 1px solid #E6E6E6;
          border-radius: 4px;
          padding: 10px;
          line-height: 1;
          background-color: #fff;
          &.active {
            cursor: pointer;
            background: #FFFAF0;
            border-color: darken($color: #FFFAF0, $amount: 10%);
          }
          & + li {
            margin-top: 5px;
          }
          .wrap {
            display: flex;
            margin-bottom: 6px;
            align-items: center;
            strong {
              font-weight: bold;
              font-size: 15px;
              line-height: 20px;
              text-overflow: ellipsis;
              color: #222222;
              white-space: nowrap;
              overflow: hidden;
              padding-right: 20px;
            }
            span {
              font-size: 12px;
              line-height: 17px;
              color: #666666;
              margin-left: auto;
              flex-shrink: 0;
            }
          }
          p {
            margin: 0;
            font-size: 13px;
            line-height: 20px;
            color: #686868;
          }
        }
    }
}